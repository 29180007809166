import {
    styled,
    Typography,
    lighten,
    ListItemButton,
    ListItemText,
    Box,
    Tabs,
} from '@mui/material';

export const MuiQuestionsLabels = styled(Typography)(({ theme }) => ({
    fontFamily: 'Raleway',
    fontWeight: 700,
    fontSize: '14px',
    lineHeight: '16.44px',
    letterSpacing: '0.014px',
    color: '#424242',
    margin: '17px 18px'
}));

export const MuiBoxQuestion = styled(ListItemButton)(({ theme }) => ({
    borderRadius: "12px 0 0 12px",
    '&.Mui-selected': {
        backgroundColor: '#FAFAFA',
        color: lighten(theme.palette.primary.main, 0.2),
        fontSize: '14px',
        fontWeight: 500,
    },
    '&:hover': {
        backgroundColor: '#F6FEFF' , 
    }
}));

export const IndicatorSidebar = styled(Box)(({ theme }) => ({
    position: "absolute",
    right: 0,
    top: "50%",
    transform: "translateY(-50%)",
    width: "3px",
    height: "28px",
    backgroundColor: lighten(theme.palette.primary.main, 0.1),
    borderRadius: "2px",
}));

export const MuiTabs = styled(Tabs)(({ theme }) => ({
    "& .MuiTabs-indicator": {
        height: '3px',
    }
}));

export const MuiCKeditorContainer = styled(Box)(({ theme }) => ({
    '.ck-editor__top': {
        borderBottom: 'none',
        boxShadow: 'none',
        backgroundColor: '#F5F5F5',
    },
    '.ck-editor__editable': {
        borderTop: 'none',
        backgroundColor: '#FAFAFA !important',
        padding: '10px',
        borderRadius: '8px',
        '&:focus': {
            backgroundColor: '#FAFAFA !important', // Mantiene el color de fondo al hacer clic
        }
    }
}));

export const MuiListItemText = styled(ListItemText)(({ theme }) => ({
    '& .MuiTypography-root': {
        fontFamily: 'Montserrat',
        fontWeight: 500,
        fontSize: '14px',
    }
}));