import axiosSurveyInstance from "../axios/axiosSurveyInstance";
import {useQuery} from "react-query";
import englishFlag from "../../assets/img/english.png";
import spanishFlag from "../../assets/img/spanish.png";
import frenchFlag from "../../assets/img/french.png";
import brazilFlag from "../../assets/img/portuguese-brazil.png";
import arabicFlag from "../../assets/img/arabic.png";

const getMatchingLanguages = (languageNames, lenguageToRemove) => {
  const additionalLanguages = [
    {name: "english", flag: englishFlag},
    {name: "spanish", flag: spanishFlag},
    {name: "french", flag: frenchFlag},
    {name: "portuguese", flag: brazilFlag},
    {name: "arabic", flag: arabicFlag}
  ];

  return languageNames
    .filter(language => language !== lenguageToRemove)
    .map(language => {
      const found = additionalLanguages.find(lang => lang.name === language);
      return found || {name: language, flag: null};
    });
};


const fetchSurvey = async (id) => {
  try {
    // Fetch the survey definition
    const surveyResponse = await axiosSurveyInstance.get(`/api/survey/find/${id}`);
    const survey = surveyResponse.data?.survey_definition;
    if (!survey) return null;

    // Fetch the configuration using survey's serviceId
    const configResponse = await axiosSurveyInstance.get(
      `/api/survey/config?service_id=${survey.serviceId}&environment=dev`
    );
    const configuration = configResponse.data?.configuration;
    if (configuration) {
      survey.defaultLanguage = configuration.language || null;
      survey.additionalLanguages = getMatchingLanguages(
        configuration.supportedLanguages,
        configuration.language
      ) || null;
    }

    return survey;
  } catch (err) {
    console.error("Error fetching survey", err);
    return null;
  }
};


const useSurvey = (data) => useQuery(
  ['survey', data.id],
  async () => fetchSurvey(data.id),
  {
    enabled: Boolean(data.id) && data.id !== 'create',
    refetchOnWindowFocus: false,
    placeholderData: null
  }
);

export default useSurvey;
