// noinspection XmlDeprecatedElement

import {Grid, Tooltip} from '@mui/material';
import Toast from '../../../../common/Toast';
import * as _ from 'lodash';
import * as React from 'react';
import {useEffect, useState} from 'react';
import {useLocation} from 'react-router-dom';
import {useAuth} from '../../../../providers/AuthProvider';
import useScheme from '../../../../../api/hooks/useScheme';
import AnalysisPage from './AnalysisPage';
import {useLoading} from '../../../../providers/LoadingProvider';
import {
  arrowLeftStyle,
  fabIconInsigthsStyle,
  gridContainerTreeMapStyle,
  gridInsightsStyle,
  MuiContainer,
  MuiGridItem,
  MuiLongName,
  MuiPaper,
  MuiToolbar,
  treeMapGridStyle,
  treePageStyle
} from './styles/treeView';
import {useDispatch, useSelector} from "react-redux";
import {
  setAnalysisVisualizationType,
  setInsightsFilter,
  setSegmentationFilter,
  setSelectedScenarioTab,
  setSimilarityFilter,
  setSimilarityQuery,
  setStatisticFilter,
  setTargetCollection,
  setTargetScheme,
  setTreeMapColorScheme,
  setTreeMapFilter,
  setTreemapLoadingProgress,
  setWidgetFoldedState
} from '../../../../../store/appSlice';
import AIPanel from '../../../../common/widgets/AIPanel';
import {useTranslation} from '../../../../providers/TranslationProvider';
import Fab from '@mui/material/Fab';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import Segments from '../../../../common/widgets/Segments';
import {AudioControlProvider} from "../../../../providers/VoiceProvider";
import {CacheProvider} from "../../../../providers/CacheContext";
import axiosInstance from "../../../../../api/axios/axiosInstance";
import apiPaths from "../../../../../api/apiPaths";
import {status200} from "../../../../../api/status.utils";
import {ActiveFiltersNotification} from "./ActiveFiltersNotification";
import {VisualizationSelector} from "./VisualizationSelector";
import {Questions} from "./Questions";

//remember add to state the scheme name
const AnalysisView = () => {
  const [severity, setSeverity] = React.useState('success')
  const [toastMsg, setToastMsg] = React.useState(null)
  const [openToast, setOpenToast] = React.useState(false);
  const [activeTab, setActiveTab] = React.useState(0);
  const [tabs, setTabs] = React.useState([]);
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);
  const {t, lng} = useTranslation();
  const {user} = useAuth();
  const dispatch = useDispatch();
  const location = useLocation();

  const localTreeMapFilter = useSelector(state => state.app.treeMapFilter);
  const localStatisticFilter = useSelector(state => state.app.statisticFilter);
  const localInsightsFilter = useSelector(state => state.app.insightsFilter);
  const categoryFilter = useSelector(state => state.app.segmentationFilter)
  const referenceAggTopicSegment = useSelector(state => state.app.referenceAggTopicSegment);
  const analysisVisualizationType = useSelector(state => state.app.analysisVisualizationType);

  const [policyAnalysisVisualizationType, setPolicyAnalysisVisualizationType] = useState(analysisVisualizationType[tabs[activeTab]?.config?.policy?.id] || 'bars');
  const [insights, setInsights] = useState({});
  const [widgets, setWidgets] = useState({});
  const [showInsightsCard, setShowInsightsCard] = useState(true);
  const [treeMapSize, setTreeMapSize] = useState(8);
  const [colapseInsightsRightPosition, setColapseInsightsRightPosition] = useState(24.7);
  const gridInsightsRef = React.useRef(null);
  const localTargetScheme = useSelector(state => state.app.targetScheme);
  const [filtersInitialized, setFiltersInitialized] = React.useState(false);
  const questionsContainerRef = React.useRef(null);
  const orgId =
    location.pathname.split('/')[2] === 'workspace' ? user.id : location.pathname.split('/')[2];
  const scenario = useScheme({
    user_id: orgId,
    scheme_id: location.pathname.split('/')[4],
  });

  const {setIsLoading} = useLoading();
  let scheme = {};
  let collection = {};

  const updateBoardState = async (payload) => {
    await axiosInstance.post(apiPaths.boardState, payload, status200).then((resp) => resp.data).catch((error) => {
      console.log('Error updating user board state', error);
    });
  }
  const updateBoardStateFilters = async () => {
    if (
      Object.keys(localTargetScheme || {}).length > 0 &&
      localTargetScheme?.id &&
      filtersInitialized
    ) {
      const treemapFilter = {...localTreeMapFilter};
      const segmentationFilter = {...categoryFilter};
      const statisticFilter = {...localStatisticFilter};
      const insightsFilter = {...localInsightsFilter};

      const updatedBoardState = {
        treeMapFilter: treemapFilter,
        segmentationFilter: segmentationFilter,
        statisticFilter: statisticFilter,
        insightsFilter: insightsFilter,
      };
      await updateBoardState({
        orgId: orgId,
        schemeId: localTargetScheme.id,
        boardState: updatedBoardState,
      });
    }
  };

  useEffect(() => {
    if (tabs?.length > 0 && Object.keys(localTreeMapFilter || {}).length > 0 ||
      Object.keys(categoryFilter || {}).length > 0 ||
      Object.keys(localStatisticFilter || {}).length > 0 ||
      (Object.keys(localInsightsFilter || {}).length > 0)) {
      updateBoardStateFilters().then((resp) => {

      })
    }
  }, [localStatisticFilter, localInsightsFilter, localTreeMapFilter, categoryFilter]);

  const initChartConfig = React.useCallback(() => {
    let newTabs = [];
    if (scenario && scenario.data && (!tabs || tabs.length === 0)) {
      scheme = scenario.data.scheme;
      collection = scenario.data.collection;
      dispatch(setTargetScheme(scenario.data.scheme))
      dispatch(setTargetCollection(scenario.data.collection))
      if (scheme && collection && Object.keys(collection).length > 0) {
        let categoricalVars = collection.variables.filter((v) => v.category === 'categorical');
        let statisticVars = collection.variables.filter((v) => v.category === 'statistic');
        let answerVars = collection.variables.filter((v) => v.category === 'answer');
        const hasAudio = scheme.policies.some((p) => !!p.audioVar);

        // NORMAL POLICIES
        newTabs = scheme.policies.map((d, i) => {
          dispatch(setTreemapLoadingProgress({
            id: d.id,
            progress: 0
          }));
          const answerVarDefinition = answerVars.find((v) => v.propName === d.answerVar);
          return {
            id: 'tab-' + i,
            policy_id: d.id,
            policy_name: 'pol_' + i,
            name: d.name,
            type: 'normal',
            config: {
              policy: d,
              isSegmentedPolicy: d.enabledSegmentClassification || !!d.parentPolicy,
              weight: scheme.weight,
              collection_name: scheme.dataset,
              collection_region: collection.region,
              segmentationVars: categoricalVars,
              statisticVars: statisticVars,
              selectedSegmentationVars: scheme.segmentation_variables || [],
              selectedStatisticVars: scheme.statistic_variables || [],
              answerVars: answerVars,
              answerVarLabel: answerVarDefinition?.label || answerVarDefinition?.propName || '',
              hasAudio: hasAudio,
              analysisObjective: scheme.analysisObjective,
              contextDescription: scheme.contextDescription,
              audienceDescription: scheme.audienceDescription,
            },
          };
        });
      }
      setTabs(newTabs);
    }


  }, [scenario.data.scheme, scenario.data.collection]);

  useEffect(() => {
    const initializeBoardState = async () => {
      await new Promise((resolve) => setTimeout(resolve, 4000)); // Wait 4 seconds
      if (scenario?.data?.scheme?.name) {
        const boardState = scenario?.data?.scheme?.boardState?.[user.id];
        if (boardState) {
          if (boardState.treeMapFilter) {
            dispatch(setTreeMapFilter(_.cloneDeep(boardState.treeMapFilter)));
          }
          if (boardState.segmentationFilter) {
            dispatch(setSegmentationFilter(_.cloneDeep(boardState.segmentationFilter)));
          }
          if (boardState.statisticFilter) {
            dispatch(setStatisticFilter(boardState.statisticFilter));
          }
          if (boardState.insightsFilter) {
            dispatch(setInsightsFilter(boardState.insightsFilter));
          }
        }
        setFiltersInitialized(true);
      }
    };

    initializeBoardState();

    if (scenario?.data?.scheme) {
      dispatch(setTreeMapColorScheme(scenario?.data?.scheme?.colorPalette || 'material_colors'));
    }

  }, [scenario?.data?.scheme?.id, user?.id]);

  const handleCloseToast = () => {
    setOpenToast(false);
    setSeverity('success')
    setToastMsg(null);
  };

  useEffect(() => {
    if (tabs.length > 0 && activeTab >= 0 && tabs[activeTab]?.config?.policy?.id) {
      const vt = analysisVisualizationType[tabs[activeTab]?.config?.policy?.id]
      if (vt === 'art') {
        setShowInsightsCard(false);
      } else {
        setShowInsightsCard(true);
      }
    }
  }, [analysisVisualizationType]);

  const handleTabsChange = (event, newValue) => {
    setActiveTab(newValue);
    if (tabs && newValue >= 0 && tabs[newValue]?.config) {
      dispatch(setSelectedScenarioTab(tabs[newValue].config.policy.id))
    }
  };

  const segmentFilterApplied = (column) => {
    const boardState = scenario?.data?.scheme?.boardState?.[user.id];
    if (boardState) {
      const segmentationFilter = boardState?.segmentationFilter;
      return segmentationFilter && segmentationFilter[column]?.length > 0
    }
    return false;
  }

  const statisticFilterApplied = (column) => {
    const boardState = scenario?.data?.scheme?.boardState?.[user.id];
    if (boardState) {
      const statisticFilter = boardState?.statisticFilter;
      return statisticFilter && statisticFilter?.[column]?.low !== undefined || statisticFilter?.[column]?.high !== undefined
    }
    return false
  }

  const initializeTabWidgets = (tabData, tabIndex) => {
    let localWidgetData = [];
    let categoryWidgetData = tabData.config.selectedSegmentationVars.map((seg_var) => {
      let segmentationLabel = tabData.config.segmentationVars.find(sVar => sVar.propName === seg_var)?.label || seg_var;
      const foldedState = !segmentFilterApplied(seg_var);
      let widgetInfo = {
        id: `${seg_var}`,
        name: segmentationLabel,
        column: seg_var,
        answerVar: tabData.config.policy.answerVar,
        dataSource: tabData.config.collection_name,
        dataRegion: tabData.config.collection_region,
        weight: tabData.config.weight,
        enableSegmentAggregation: !tabData.config.isSegmentedPolicy || tabData.config.policy.parentPolicy !== null,
        type: "category",
        foldedState: foldedState,
        visible: true,
        policyId: tabData.config.policy.id,
        policyTabIndex: tabIndex,
        description: tabData.config.segmentationVars.find((v) => v.propName === seg_var)?.description || ''
      };
      dispatch(setWidgetFoldedState({
        widgetId: `${seg_var}`,
        foldedState: foldedState
      }));
      return widgetInfo;
    });
    let statisticWidgetData = tabData.config.selectedStatisticVars.map((stat) => {
      let statisticLabel = tabData.config.statisticVars.find(sVar => sVar.propName === stat)?.label || stat
      const foldedState = !statisticFilterApplied(stat);
      let widgetInfo = {
        id: `${stat}`,
        name: statisticLabel,
        column: stat,
        answerVar: tabData.config.policy.answerVar,
        dataSource: tabData.config.collection_name,
        dataRegion: tabData.config.collection_region,
        weight: tabData.config.weight,
        type: "histogram",
        foldedState: foldedState,
        visible: true,
        policyId: tabData.config.policy.id,
        policyTabIndex: tabIndex,
        description: tabData.config.statisticVars.find((v) => v.propName === stat)?.description || ''
      };
      dispatch(setWidgetFoldedState({
        widgetId: `${stat}`,
        foldedState: foldedState
      }));
      return widgetInfo;
    });
    localWidgetData = [...localWidgetData, ...categoryWidgetData, ...statisticWidgetData];
    return localWidgetData;
  }

  const initializeWidgets = () => {
    if (activeTab >= 0 && tabs[activeTab] && Object.keys(widgets).length === 0) {
      dispatch(setSegmentationFilter({}));
      dispatch(setStatisticFilter({}));
      dispatch(setTreeMapFilter({}));
      const localWidgets = {};
      tabs.forEach((tabData, index) => {
        localWidgets[index] = initializeTabWidgets(tabData, index);
      })
      setWidgets(localWidgets);
    }
  }
  const initializeInsights = () => {
    if (activeTab >= 0 && tabs[activeTab] && Object.keys(insights).length === 0) {
      dispatch(setInsightsFilter({}));
      dispatch(setSimilarityFilter({}));
      dispatch(setSimilarityQuery({}));
      const localInsights = {};
      tabs.forEach((tabData, index) => {
        let localWidgetData = [];
        const similarityWidgetData = {
          id: `${tabData.config.policy.answerVar}_${tabData.config.policy.id}_similarity`,
          name: t('semantic_analysis'),
          column: tabData.config.policy.answerVar,
          dataSource: tabData.config.collection_name,
          dataRegion: tabData.config.collection_region,
          weight: tabData.config.weight,
          type: "similarity",
          folded_state: true,
          visible: true,
          policyId: tabData.config.policy.id,
          segmentationVariables: tabData.config.segmentationVars.map(segVar => {
            return {
              name: segVar.propName,
              label: segVar.label
            }
          }),
          statisticVariables: tabData.config.statisticVars.map(statVar => {
            return {
              name: statVar.propName,
              label: statVar.label
            }
          }),
          answerVarLabel: tabData.config.answerVarLabel,
          policyTabIndex: index
        };
        dispatch(setWidgetFoldedState({
          widgetId: `${tabData.config.policy.answerVar}_${tabData.config.policy.id}_similarity`,
          foldedState: true
        }))
        localWidgetData.push(similarityWidgetData);
        const segmentationVariables = tabData.config.segmentationVars.map(segVar => {
          return {
            name: segVar.propName,
            label: segVar.label,
            description: segVar.description,
            category: segVar.category
          }
        });

        const listWidgetData = {
          id: `${tabData.config.policy.answerVar}_${tabData.config.policy.id}_list`,
          name: tabData.config.answerVarLabel,
          column: tabData.config.policy.answerVar,
          audioColumn: tabData.config.policy.audioVar || null,
          title: "Quotes",
          dataSource: tabData.config.collection_name,
          dataRegion: tabData.config.collection_region,
          policyId: tabData.config.policy.id,
          statVars: tabData.config.statisticVars.length > 0 ? tabData.config.statisticVars : null,
          type: "list",
          folded_state: false,
          visible: true,
          policyTabIndex: index
        }
        localWidgetData.push(listWidgetData);
        dispatch(setWidgetFoldedState({
          widgetId: `${tabData.config.policy.answerVar}_${tabData.config.policy.id}_list`,
          foldedState: false
        }))
        if (tabData.config.policy.customSegmentations?.enabled && tabData.config.policy.customSegmentations?.segmentations?.length > 0) {
          const policy = tabData.config.policy;
          const customCatWidgetData = policy.customSegmentations?.segmentations?.filter(segmentation => segmentation.classificationPerformed).map((segmentation) => {
            dispatch(setWidgetFoldedState({
              widgetId: segmentation.classificationVariable,
              foldedState: false
            }));
            segmentationVariables.push({
              name: segmentation.classificationVariable,
              label: segmentation.label
            });
            return {
              id: segmentation.classificationVariable,
              name: segmentation.label,
              column: segmentation.classificationVariable,
              answerVar: tabs[activeTab].config.policy.answerVar,
              dataSource: tabs[activeTab].config.collection_name,
              dataRegion: tabs[activeTab].config.collection_region,
              weight: tabs[activeTab].config.weight,
              categories: segmentation.categories,
              type: "segments",
              foldedState: false,
              visible: true,
              policyId: policy.id,
              policyTabIndex: index,
            };

          })
          if (customCatWidgetData?.length > 0) {
            localWidgetData = [...localWidgetData, ...customCatWidgetData];
          }

        }
        const qaWidgetData = {
          id: `${tabData.config.policy.answerVar}_${tabData.config.policy.id}_similarity`,
          name: t('semantic_analysis'),
          column: tabData.config.policy.answerVar,
          dataSource: tabData.config.collection_name,
          dataRegion: tabData.config.collection_region,
          weight: tabData.config.weight,
          type: "qa",
          foldedState: false,
          visible: true,
          policyId: tabData.config.policy.id,
          segmentationVariables: segmentationVariables,
          statisticVariables: tabData.config.statisticVars.map(statVar => {
            return {
              name: statVar.propName,
              label: statVar.label
            }
          }),
          answerVarLabel: tabData.config.answerVarLabel,
          policyTabIndex: index,
          analysisObjective: tabData.config.analysisObjective,
          contextDescription: tabData.config.contextDescription,
          audienceDescription: tabData.config.audienceDescription,
          originalQuestionText: tabData.config.policy.longName,
          mainTopics: tabData.config.policy.topics,
          hints: tabData.config.policy.hints
        };
        localWidgetData.push(qaWidgetData);
        localInsights[index] = localWidgetData;
      });

      setInsights(localInsights);
    }
  }


  const handleToggleInsightsCard = () => {
    setShowInsightsCard(!showInsightsCard);
  };

  React.useEffect(() => {
    setIsLoading(scenario.isLoading);
  }, [scenario.isLoading]);

  React.useEffect(() => {
    if (scenario.data.scheme && scenario.data.collection && Object.keys(scenario.data.collection).length > 0) {
      initChartConfig();
    }
  }, [initChartConfig]);

  React.useEffect(() => {
    initializeWidgets();
    initializeInsights();
  }, [tabs, lng]);

  useEffect(() => {
    if (tabs && activeTab >= 0 && tabs[activeTab]?.config) {
      dispatch(setSelectedScenarioTab(tabs[activeTab].config.policy.id))
    }
  }, [activeTab, tabs]);

  useEffect(() => {
    if (showInsightsCard) {
      setTreeMapSize(8);
    } else {
      setTreeMapSize(12);
    }
    setColapseInsightsRightPosition(24.7);

  }, [widgets, insights, showInsightsCard]);

  useEffect(() => {
    // Function to update window height
    const handleResize = () => {
      setWindowHeight(window.innerHeight);
    };

    // Add event listener
    window.addEventListener('resize', handleResize);

    // Cleanup function
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const anyAudio = tabs.reduce((acc, t) => t.config.hasAudio || acc, false);

  useEffect(() => {
    const value = analysisVisualizationType[tabs[activeTab]?.config.policy.id];
    if (!value) return;
    if (value !== policyAnalysisVisualizationType && value === 'bars') {
      updateAnalysisType(value)
    }
  }, [analysisVisualizationType]);

  function updateAnalysisType(value) {
    if (analysisVisualizationType[tabs[activeTab].config.policy.id] !== "bars") {
      let localSegmentationFilter = {...categoryFilter}
      const policyReferenceAggSegment = referenceAggTopicSegment[tabs[activeTab].config.policy.id] || null
      if (policyReferenceAggSegment) {
        delete localSegmentationFilter[policyReferenceAggSegment];
        dispatch(setSegmentationFilter(localSegmentationFilter));
      }
    }
    setPolicyAnalysisVisualizationType(value)
    const updatedVisualizationType = _.clone(analysisVisualizationType)
    updatedVisualizationType[tabs[activeTab].config.policy.id] = value
    if (JSON.stringify(updatedVisualizationType) !== JSON.stringify(analysisVisualizationType)) {
      dispatch(setAnalysisVisualizationType(updatedVisualizationType));
    }
  }

  const [questionsHeight, setQuestionsHeight] = useState(87);

  useEffect(() => {
    if (tabs.length > 0) {
      setQuestionsHeight((48 * (tabs.length) + (tabs.length - 1) * 2) + 87);
    }
  }, [tabs]);

  const getTrimmedQuestionText = () => {
    const activeTabName = tabs?.[activeTab]?.config.policy.longName || tabs?.[activeTab]?.name || '';
    return activeTabName.length > 120 ? `${activeTabName.slice(0, 120)}...` : activeTabName;
  };

  return (
    <MuiContainer>
      <Toast
        message={toastMsg}
        handleClose={handleCloseToast}
        severity={severity}
        horizontal='left'
        vertical='bottom'
        open={openToast}
      />
      {scenario.data.scheme.dataset !== '' && (
        <CacheProvider>
          <MuiToolbar>
            <Grid container>
              {(tabs[activeTab] !== undefined) && (
                <Grid container direction='column' item
                      xs={3}
                      sx={{
                        overflowY: 'hidden',
                        marginLeft: '0px !important',
                        paddingRight: '0px',
                        paddingTop: '0px'
                      }}
                >
                  {tabs.map((tab, index) => {
                    return (
                      <Grid container item direction='column'
                            sx={{
                              display: activeTab === index ? 'block' : 'none',
                              overflow: 'hidden',
                              height: '100%',
                              width: '100%'
                            }}
                            id={`${tab.config.policy.id}_widgets-${index}`}
                            key={`${tab.config.policy.id}_widgets-${index}`}>
                        <Grid item sx={{borderRight: 'none'}}
                              ref={questionsContainerRef}>
                          <Questions
                            questions={tabs}
                            activeTab={activeTab}
                            handleTabsChange={handleTabsChange}
                            localTreeMapFilter={localTreeMapFilter}
                          />
                        </Grid>
                        {(!!widgets && widgets[activeTab]?.length > 0) && (
                          <Grid container item direction='column' sx={{
                            height: windowHeight - questionsHeight - 30,
                            maxHeight: windowHeight - questionsHeight - 30,
                          }}>
                            <Segments
                              widgets={widgets[index]}
                              policyId={tab.config.policy.id}
                            />
                          </Grid>)}
                      </Grid>
                    )
                  })}
                </Grid>
              )}
              <Grid container item xs={9}
                    direction={'row'}
                    style={gridContainerTreeMapStyle}>
                <MuiPaper
                  elevation={0}
                  style={{
                    height: windowHeight - 75,
                    border: '0px solid #EEEEEE',
                    marginRight: "2px",
                    zIndex: 0
                  }}>
                  <AudioControlProvider>
                    <Grid container direction='row'>
                      <Grid item xs={treeMapSize}
                            style={{
                              ...treeMapGridStyle,
                              height: windowHeight - 74,
                              paddingRight: '2px'
                            }}>
                        <Grid item container direction='row' columnGap={'32px'}
                              sx={{flexWrap: 'nowrap'}}>
                          <MuiGridItem container item xs={true}
                                       justifyContent={'center'}>
                            <Tooltip
                              title={tabs?.[activeTab]?.config.policy.longName || tabs?.[activeTab]?.name}
                              placement='bottom'
                              sx={{
                                '& .MuiTooltip-tooltip': {
                                  fontFamily: 'Montserrat',
                                  fontSize: '12px',
                                }
                              }}
                            >
                              <MuiLongName component="span">
                                {getTrimmedQuestionText()}
                              </MuiLongName>
                            </Tooltip>
                          </MuiGridItem>
                          <Grid item container
                                alignContent={'center'}
                                justifyContent={'flex-end'}
                                sx={{
                                  width: '230px',
                                  padding: '10px',
                                  gap: 1,
                                  marginTop: '8px',
                                  flexWrap: 'nowrap'
                                }}>
                            {tabs.length > 0 && (
                              <>
                                <ActiveFiltersNotification
                                  segmentationVariables={tabs[activeTab]?.config.segmentationVars}
                                  statisticVariables={tabs[activeTab]?.config.statisticVars}

                                />
                                <VisualizationSelector
                                  visualization={policyAnalysisVisualizationType}
                                  updateAnalysisVisualizationType={updateAnalysisType}
                                  hasImages={tabs[activeTab]?.config.policy.topics.filter(t => !!t.image_url).length === tabs[activeTab]?.config.policy.topics.length}
                                />
                              </>
                            )}
                          </Grid>
                        </Grid>
                        {tabs.map((tab, index) => (
                          <AnalysisPage
                            policyAnalysisVisualizationType={policyAnalysisVisualizationType}
                            style={treePageStyle}
                            activeTab={activeTab}
                            index={index}
                            tabData={tab}
                            key={tab.policy_id + "_treemap"}
                            showProgressBar={anyAudio}
                          />
                        ))}
                      </Grid>
                      {(tabs[activeTab] !== undefined && Object.hasOwn(insights || {}, activeTab))
                        && (
                          <Grid
                            id='insights-container'
                            item
                            xs={4}
                            style={{
                              ...gridInsightsStyle,
                              right: showInsightsCard ? '5px' : '-100%',
                              maxHeight: windowHeight - 65,
                              opacity: showInsightsCard ? 1 : 0,
                              visibility: showInsightsCard ? 'visible' : 'hidden',
                            }}
                            ref={gridInsightsRef}
                          >
                            {
                              tabs.map((t, index) => {
                                return (
                                  <div
                                    style={{
                                      display: activeTab === index ? 'block' : 'none',
                                      overflow: 'hidden',
                                      maxHeight: windowHeight - 85
                                    }}
                                    id={`${t.policy_id}_segment-${index}`}
                                    key={`${t.policy_id}_segment-${index}`}>

                                    <AIPanel
                                      widgets={insights[index]}
                                      activePolicyTab={activeTab}
                                      policyId={t.policy_id}
                                      isFilterCommon={false}
                                      height={windowHeight - 85} // 65 is the height of the mi
                                    />
                                  </div>
                                )
                              })
                            }
                          </Grid>
                        )}
                    </Grid>
                  </AudioControlProvider>
                </MuiPaper>
                <div style={{position: 'relative'}}
                     id='toogle-insights-control'>
                  <Fab
                    size='small'
                    sx={{
                      ...fabIconInsigthsStyle,
                      right: showInsightsCard ? `calc(${colapseInsightsRightPosition}%)` : '-1px'
                    }}
                    color="inherit"
                    onClick={handleToggleInsightsCard}
                  >
                    {showInsightsCard ? (<ArrowRightIcon fontSize="large"
                                                         sx={{color: '#757575'}}/>) :
                      (<ArrowLeftIcon fontSize="large" style={arrowLeftStyle}/>)
                    }
                  </Fab>
                </div>
              </Grid>
            </Grid>
          </MuiToolbar>
        </CacheProvider>
      )}
    </MuiContainer>
  );
};

export default AnalysisView;
