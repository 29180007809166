import axiosSurveyInstance from "../axios/axiosSurveyInstance";
import {status200} from "../status.utils";
import {useMutation, useQueryClient} from "react-query";

const deleteSurvey = async (id) => {
  return axiosSurveyInstance.delete(`/api/survey/delete/${id}`, status200)
    .then(resp => resp.data);
}

export const useDeleteSurvey = () => {
  const queryClient = useQueryClient();
  return useMutation(async (id) => deleteSurvey(id), {
    onSuccess: () => queryClient.resetQueries('organizationSurveys'),
    onError: (error) => console.error(error),
    onSettled: () => queryClient.invalidateQueries('organizationSurveys'),
  });
}
