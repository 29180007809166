import {Box, Typography} from "@mui/material";
import {Handle, Position} from "@xyflow/react";
import {Image} from "@mui/icons-material";
import {lighten} from "@mui/material/styles";
import {useTheme} from "../../../providers/CustomThemeProvider";
import AddIcon from '@mui/icons-material/Add';
import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck';
import MessageIcon from '@mui/icons-material/Message';
import SubjectIcon from '@mui/icons-material/Subject';
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";

const CustomNode = ({ data, setShowModal , setShowModalMessage , deleteQuestion }) => {
  const {theme} = useTheme();
  const getIconForType = (type) => {
    switch (type) {
      case 'message':
        return <MessageIcon fontSize="small" />;
      case 'multipleChoice':
        return <PlaylistAddCheckIcon />;
      case 'openEnded':
        return <SubjectIcon fontSize="small" />;
      default:
        return <Image fontSize="small" />;
    }
  }

  return (
    <>
      {data.type === 'addNode' ? (
      <Box
        sx={{
          width: '26px',
          height: '26px',
          border: "1px solid #E0E0E0",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          borderRadius: "50%",
          boxShadow: "0px 4px 6px rgba(0,0,0,0.2)",
          cursor: "pointer",
          backgroundColor: "transparent"
        }}
        onClick={() => {setShowModalMessage(data)}}
      >
        <AddIcon fontSize='16px' sx={{color: '#616161'}} onClick={() => {setShowModal(true)}}/>
        {/* Puerto de conexión superior */}
        <Handle type="target" position={Position.Top} style={{ background: "#F5F5F5", width: "6px", height: "6px", bottom: "0px" }}/>
      </Box>
      ) : (
      <Box
        sx={{
          backgroundColor: "white",
          borderRadius: "12px",
          boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
          padding: "10px",
          textAlign: "center",
          width: "200px",
          position: "relative",
        }}
      >
        <Box sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          gap: "4px",
          color: lighten(theme.palette.primary.main , 0.3) }}
        >
          {getIconForType(data.type)}
          <Typography variant="caption" fontWeight="bold"  onClick={() => {setShowModalMessage(data)}}>
            {data.label.toUpperCase()}
          </Typography>
          {data.type !== 'start' && (
            <DeleteOutlinedIcon sx={{color: 'red', width: '16px', height: '16px', cursor: 'pointer'}} onClick={()=> deleteQuestion(data.id)}/>
          )}
        </Box>
        <Typography color="#424242" sx={{fontFamily: 'Montserrat', fontSize: '16px'}}  onClick={() => {setShowModalMessage(data)}}>
          {data.text}
        </Typography>

        {/* Puerto de conexión inferior */}
        <Handle
          type="source"
          position={Position.Bottom}
          style={{ background: "#F5F5F5", width: "8px", height: "8px", bottom: "0px" }}
        />
        {/* Puerto de conexión superior */}
        <Handle type="target" position={Position.Top} style={{ background: "#F5F5F5", width: "8px", height: "8px", bottom: "0px" }}/>
      </Box>
      )}
    </>

  );
};

export default CustomNode;
