import React from 'react';
import {
  Typography,
  RadioGroup,
  FormControlLabel,
  Radio,
  Paper,
  Box, Button,
} from '@mui/material';

const ChatComponent = ({message, littleMessage, options,view, image}) => {
  return (
    <Paper elevation={2}
           sx={{
             padding: '6px',
             ml: view ? '7px' : '14px',
             borderRadius: '4px !important',
             zIndex: 999,
             position: 'absolute',
             top: view ? '50px' : '90px',
             width: view ? '161px' : '189px' }}>
      <Box sx={{ display: 'flex', alignItems: 'left', marginBottom: 2, flexDirection: 'column' }}>
        {image && (
          <Box sx={{borderRadius: '3px', width: '174px', heigth: '174px'}}>
            <img src={image} alt={image} style={{ width: '100%', height: '100%', objectFit: 'scale-down' , mb: '4px'}} />
          </Box>
          )}
        <Typography sx={{ marginRight: 1 , fontSize: view ? '6px' : '8px', fontFamily: 'Montserrat'}}>
          {message}
        </Typography>
        <Typography sx={{ marginRight: 1 , fontSize: '4px', fontFamily: 'Montserrat'}}>
          {littleMessage}
        </Typography>
      </Box>
      <RadioGroup
        aria-labelledby="ai-question"
        name="ai-question"
      >
        {options.length> 0 && options.map((option) => (
            <Button variant='outlined'
                    disabled value={option.answer}
                    sx={{
                      fontFamily: 'Montserrat',
                      fontSize: view ? '6px' : '8px' ,
                      height: view ? '14px' : '16px',
                      mb: '1px'
                    }}
            >
              {option.answer}
            </Button>
          )
        )}
      </RadioGroup>
    </Paper>
  );
};

export default ChatComponent;
