import {
  styled,
  Paper,
  Typography,
  IconButton,
  Box,
  Divider, Grid, Tab
} from "@mui/material";
import Button from "../../../../../common/Button";
import {lighten} from "@mui/material/styles";

export const MuiEditor = styled(Paper)(({ theme }) => ({
  width: '100%',
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary
}));

export const MuiSectionName = styled(Typography)(({ theme }) => ({
  color: '#424242',
  fontFamily: 'Raleway',
  textTransform:'uppercase',
  fontWeight: 700,
  fontSize: '20px'
}));

export const MuiTypographyQuestionTopic = styled(Typography)(({ theme }) => ({
  color: '#424242',
  fontFamily: 'Raleway',
  fontWeight: 700,
  fontSize: '16px',
}));

export const MuiTypographyHeader = styled(Typography)(({ theme }) => ({
  fontFamily: 'Raleway',
  fontSize: '24px',
  fontWeight: 700,
  color: "#212121",
  marginTop:'21px'
}));

export const MuiTypographyDate = styled(Typography)(({ theme }) => ({
  fontFamily: 'Montserrat',
  fontSize: '14px',
  fontWeight: 400,
  color: "#616161"
}));

export const MuiTypographyLink = styled(Typography)(({ theme }) => ({
  fontFamily: 'Raleway',
  fontSize: '10px',
  fontWeight: 500,
  color: "#616161"
}));

export const MuiOrganizationName = styled(Typography)(({ theme }) => ({
  fontFamily: 'Raleway',
  fontSize: '24px',
  fontWeight: 500,
  color: "#424242"
}));

export const MuiQuestionNumber = styled(Typography)(({ theme }) => ({
  color: '#424242',
  textTransform:'uppercase',
  fontFamily: 'Montserrat',
  fontWeight: 500,
  fontSize: '16px'
}));

export const MuiTitle = styled(Typography)(({ theme }) => ({
  color: '#212121',
  fontFamily: 'Raleway',
  fontWeight: 400,
  fontSize: '35px',
  marginBottom: '24px'
}));

export const MuiTypographyDescription = styled(Typography)(({ theme }) => ({
  color: '#616161',
  fontFamily: 'Montserrat',
  fontWeight: 400,
  fontSize: '16px',
  display: 'inline'
}));

export const MuiGridLeft = styled(Grid)(({ theme }) => ({
  width: '551px',
  height: '100%'
}));

export const MuiGridRight = styled(Grid)(({ theme }) => ({
  width: '551px',
  height: '100%',
  borderRadius: '24px',
  paddingLeft: '24px',
  paddingRight: '24px'
}));

export const MuiGridQuotes = styled(Grid)(({ theme }) => ({
  textAlign: 'center',
  marginLeft: '-2.6rem'
}));

export const MuiGridKeyFindings = styled(Grid)(({ theme }) => ({
  width: '551px',
  height: 'auto',
  borderRadius: '24px',
  paddingLeft: '24px',
  paddingRight: '24px'
}));

export const MuiBoxGallery = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between'
}));

export const MuiContentHTML = styled(Typography)(({ theme }) => ({
  color: '#616161',
  fontFamily: 'Montserrat',
  fontWeight: 400,
  fontSize: '16px',
  display: 'inline'
}));

export const MuiQuestionText = styled(Typography)(({ theme }) => ({
  color: 'white',
  fontFamily: 'Montserrat',
  fontWeight: 500,
  fontSize: '24px',
  alignSelf: 'flex-start',
  marginLeft: '7.5rem'
}));

export const MuiQuestionTitle = styled(Typography)(({ theme }) => ({
  color: 'white',
  fontFamily: 'Raleway',
  fontWeight: 700,
  fontSize: '42px',
  marginLeft: '7.5rem'
}));

export const MuiTopicQuote = styled(Typography)(({ theme }) => ({
  color: '#616161',
  fontFamily: 'Montserrat',
  fontWeight: 700,
  fontSize: '20px',
  marginTop:'-5px'
}));

export const MuiSubTopicQuote = styled(Typography)(({ theme }) => ({
  color: '#424242',
  fontFamily: 'Raleway',
  fontWeight: 400,
  fontSize: '14px'
}));

export const MuiRepresentativity = styled(Typography)(({ theme }) => ({
  color: '#424242',
  fontFamily: 'Raleway',
  fontWeight: 700,
  fontSize: '20px',
  marginBottom:'10px'
}));

export const MuiSummary = styled(Typography)(({ theme }) => ({
  color: '#616161',
  fontFamily: 'Montserrat',
  fontWeight: 700,
  fontSize: '20px'
}));

export const MuiSummaryTitle = styled(Typography)(({ theme }) => ({
  color: '#424242',
  fontFamily: 'Raleway',
  fontWeight: 400,
  fontSize: '14px'
}));

export const MuiBoxContainerSummary = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'flex-start',
  flexDirection: 'column'
}));

export const MuiBoxQuoteHeader = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'flex-start',
  justifyContent: 'space-between'
}));

export const MuiGridSummary = styled(Grid)(({ theme }) => ({
  textAlign: 'center',
  marginLeft: '-4rem'
}));

export const MuiIconButton = styled(IconButton)(({ theme }) => ({
  width: 40,
  height: 40,
  borderRadius: '12px',
  backgroundColor: '#F5F5F5',
  alignSelf: 'center',
  '&:hover': {
    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.3)',
    transition: 'box-shadow ease-in-out',
    backgroundColor: '#F5F5F5',
  }
}));

export const MuiSectionInitial  = styled("section")(({ theme }) => ({
  height: '100vh',
  backgroundColor: 'white',
  overflow: 'hidden'
}));

export const MuiAboutCompanySection  = styled("section")(({ theme }) => ({
  backgroundColor: "white",
  height: 'auto',
  marginTop:'-12rem'
}));

export const MuiExecutiveSummarySection  = styled("section")(({ theme }) => ({
  height: 'auto'
}));

export const MuiPieChartSection  = styled("section")(({ theme }) => ({
  height: 'auto',
  marginTop: '10px'
}));

export const MuiHorizontalBarChartSection  = styled("section")(({ theme }) => ({
  height: 'auto',
  marginTop: '50px'
}));

export const MuiGallerySection  = styled("section")(({ theme }) => ({
  height: 'auto',
}));

export const MuiKeyFindingsSection  = styled("section")(({ theme }) => ({
  height: 'auto',
}));

export const MuiQoutesSection  = styled("section")(({ theme }) => ({
  height: 'auto',
}));

export const MuiBoxTitle = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'color'
})(({ theme,color }) => ({
  width: '43px',
  height: '43px',
  borderRadius: '4px', padding: '4.5px',
  backgroundColor: color
}));

export const MuiGridContainer = styled(Grid, {
  shouldForwardProp: (prop) => prop !== 'primaryColor'
})(({ theme,primaryColor }) => ({
  height: '580px',
  borderTopRightRadius: '30px',
  borderBottomRightRadius: '120px',
  padding: '36px 24px 0px 48px',
  backgroundColor: primaryColor
}));

export const MuiSampleAnswer = styled("p", {
  shouldForwardProp: (prop) => prop !== 'color'
})(({ theme,color }) => ({
  borderRadius: '8px',
  padding: '5px 10px',
  color: 'white',
  fontFamily: 'Montserrat',
  fontWeight: 400,
  fontSize: '14px',
  backgroundColor: color
}));

export const MuiSubtopicSampleAnswer = styled("p", {
  shouldForwardProp: (prop) => prop !== 'color'
})(({ theme,color }) => ({
  backgroundColor: color,
  borderRadius: '8px',
  padding: '5px 10px',
  color: 'white',
  fontFamily: 'Montserrat',
  fontWeight: 400,
  fontSize: '14px'
}));

export const MuiConclusionSection = styled("section", {
  shouldForwardProp: (prop) => prop !== 'primaryColor'
})(({ theme,primaryColor }) => ({
  height: 'auto',
  backgroundColor: primaryColor
}));

export const MuiDiv  = styled("div")(({ theme }) => ({
  width: 167,
  height: 167,
  backgroundColor: 'white',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: '50%'
}));

export const MuiDivEllipse  = styled("div")(({ theme }) => ({
  width: 257,
  height: 258,
  backgroundColor: 'white',
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'flex-end',
  clipPath: "circle(80.9% at 8% 91%)",
}));

export const MuiDivHeader  = styled("div")(({ theme }) => ({
  width: 500,
  display: 'flex',
  flexDirection: 'column',
  marginTop: '110px'
}));

export const MuiDivSummary  = styled("div")(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  marginLeft: '5px',
  marginBottom: '30px'
}));

export const MuiDivTitleContainer = styled("div")(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  marginLeft: '5px',
}));

export const MuiDivQuote  = styled("div")(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  marginLeft: '5px'
}));

export const MuiImgProfile  = styled("img")(({ theme }) => ({
  width: 112,
  height: 89,
  borderColor:'transparent',
}));

export const MuiImgMasonry = styled("img", {
  shouldForwardProp: (prop) => prop !== 'index'
})(({ theme,index }) => ({
  display: "block",
  width: "100%",
  height: index === 0 || index === 2 || index === 4 ? '90px' : "190px",
  objectFit: "cover"
}));

export const MuiDivMasonry = styled("div", {
  shouldForwardProp: (prop) => prop !== 'index' && prop !== 'color'
})(({ theme,index, color }) => ({
  display: "block",
  width: "100%",
  backgroundColor:color,
  height: index === 0 || index === 2 || index === 4 ? '90px' : "190px",
  minHeight: "50px",
}));

export const MuiColors = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'color'
})(({ theme, color }) => ({
  width: 296,
  height: 460,
  backgroundColor:color,
}));

export const MuiDivTabs = styled("div", {
  shouldForwardProp: (prop) => prop !== 'isFixed'
})(({ theme,isFixed }) => ({
  position: isFixed ? 'fixed' : 'sticky',
  top: 0,
  zIndex: 1000,
  backgroundColor: 'white',
  borderRadius: '24px',
  transition: 'all 0.3s ease',
  padding: '0px 48px',
  boxShadow: isFixed ? '0px 4px 12px rgba(0, 0, 0, 0.3)' : 'none',
  marginTop: isFixed ? '16px' : '0px',
}));

export const MuiUserMenu = styled("div", {
  shouldForwardProp: (prop) => prop !== 'primaryColor'
})(({ theme,primaryColor }) => ({
  height: '43px',
  width: '165px',
  backgroundColor: `${primaryColor}`,
  borderRadius: 24,
  position: 'absolute',
  top: 16,
  right: 24,
  display: 'flex',
  alignItems: 'center',
  padding: '4px 18px 4px -8px'
}));

export const MuiDivItem = styled("div", {
  shouldForwardProp: (prop) => prop !== 'primaryColor'
})(({ theme,primaryColor }) => ({
  backgroundColor: `${primaryColor}`,
  padding: '22px 30px',
  borderRadius: '30px'
}));

export const MuiDivContainer = styled("div", {
  shouldForwardProp: (prop) => prop !== 'primaryColor'
})(({ theme,primaryColor }) => ({
  position: 'absolute',
  top: 'calc(37vh - 10px)',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  background: `linear-gradient(to bottom, ${primaryColor} 74%, #EEEEEE 50%)`,
  borderRadius: '50%',
  zIndex: 999,
  padding: '24px 24px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
}));

export const MuiBoxItemQuote = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'primaryColor'
})(({ theme,primaryColor }) => ({
  backgroundColor: primaryColor,
  width: '43px',
  height: '43px',
  borderRadius: '4px',
  padding: '4.5px'
}));

export const MuiIndexSectionName = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'isRoot'
})(({ theme,isRoot }) => ({
  color: 'white',
  textAlign: 'left',
  fontFamily: isRoot ? 'Raleway' : 'Montserrat',
  fontWeight: isRoot ? 600 : 400,
  fontSize: isRoot ? '16px' : '14px',
  marginLeft: isRoot ? '0px' : '-2rem',
}));

export const MuiBoxContainer = styled(Box)(({ theme }) => ({
  position: 'absolute',
  bottom: '15vh',
  backgroundColor: "white",
  paddingTop: theme.spacing(0),
  width: "100vw",
  maxWidth: "100%",
  height: '47vh',
  clipPath: "polygon(50vw 0, 120vw 80%, 100vw 100%, 0 100%, -20vw 80%)",
}));

export const MuiBox = styled(Box)(({ theme }) => ({
  textAlign: "center",
  padding: '0px 4px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center'
}));

export const MuiGridTextHTML = styled(Grid)(({ theme }) => ({
  width: '651px',
  height: 'auto'
}));


export const MuiBoxConclusion = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  marginTop: '24px'
}));

export const MuiBoxListConclusion = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'flex-start',
  flexDirection: 'column'
}));

export const MuiBoxItemConclusion = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'flex-start' ,
  marginBottom: '24px'
}));

export const MuiBoxSummary = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'flex-start',
  justifyContent: 'space-between'
}));

export const MuiBoxButtons = styled(Box)(({ theme }) => ({
  position: 'fixed',
  bottom: 24,
  right: 24,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-end',
}));

export const MuiBoxItem = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'flex-start',
  justifyContent: 'space-between'
}));

export const MuiBoxPartial = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'flex-start',
  flexDirection: 'column'
}));

export const MuiBoxImages = styled(Box)(({ theme }) => ({
  width: "100%",
  maxWidth: 600,
  borderRadius: "35%",
  overflow: "hidden",
  backgroundColor: "#f5f5f5",
}));

export const MuiTypographyTitle= styled(Typography)(({ theme }) => ({
  color: '#616161',
  fontFamily: 'Montserrat',
  fontWeight: 400,
  fontSize: '16px',
  display: 'inline'
}));

export const MuiElementHTML= styled('p')(({ theme }) => ({
  paddingLeft: '24px',
  color: '#616161',
  fontFamily: 'Montserrat',
  fontWeight: 400,
  fontSize: '16px'
}));

export const MuiGrid = styled(Grid)(({ theme }) => ({
  display: 'flex',
  alignItems: 'flex-start',
  flexDirection: 'row'
}));

export const MuiBoxContent = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'flex-start'
}));

export const MuiBoxImgConclusion = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'primaryColor'
})(({ theme,primaryColor }) => ({
  width: 570,
  height: 920,
  maxWidth: 600,
  borderRadius: "50% / 250px",
  overflow: "hidden",
  backgroundColor: primaryColor,
}));

export const MuiKeyFindingsContainer = styled(Grid)(({ theme }) => ({
  textAlign: 'center',
  marginLeft: '-5.6rem',
  marginTop: '50px'
}));

export const MuiEvenGrid = styled(Grid)(({ theme }) => ({
  width: '1275px',
  height: '100%',
  padding: '24px',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  backgroundColor: 'white'
}));

export const MuiOddGrid = styled(Grid)(({ theme }) => ({
  width: '1275px',
  height: '100%',
  padding: '24px',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  backgroundColor: '#FAFAFA',
  borderTopRightRadius: '24px',
  borderBottomRightRadius: '24px'
}));

export const MuiImgPartialConclusion = styled(Grid)(({ theme }) => ({
  height: '137px',
  width:'283px'
}));

export const MuiImgConclusion = styled(Grid, {
  shouldForwardProp: (prop) => prop !== 'height'
})(({ theme,height }) => ({
  width:'283px',
  height: height,
}));


export const MuiDivider = styled(Divider)(({ theme }) => ({
  borderColor: '#E0E0E0',
  width: '86%',
  margin: ' 50px 0px 50px 40px'
}));

export const MuiMoreVertButton = styled(IconButton)(({ theme }) => ({
  width: 56,
  height: 56,
  borderRadius: '16px',
  backgroundColor: '#F5F5F5',
  '&:hover': {
    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.3)',
    transition: 'box-shadow ease-in-out',
    backgroundColor: '#F5F5F5',
  }
}));

export const MuiAcceptButton = styled(Button, {
  shouldForwardProp: (prop) => prop !== 'primaryColor'
})(({ theme,primaryColor }) => ({
  marginTop: '10px',
  borderRadius: '20px',
  height: '37px',
  color: primaryColor,
  borderColor: primaryColor,
  '&:hover': {
    borderColor: primaryColor,
    backgroundColor: lighten(primaryColor, 0.8),
  }
}));

export const MuiBoxList = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'primaryColor'
})(({ theme,primaryColor }) => ({
  display: 'flex',
  alignItems: 'flex-start',
  padding: '24px',
  borderRadius: '24px',
  gap: '10',
  backgroundColor: primaryColor
}));

export const MuiTypographyReportName = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'primaryColor'
})(({ theme,primaryColor }) => ({
  fontWeight: 500,
  color: primaryColor,
  fontFamily: 'Raleway',
  fontSize:'40px',
}));

export const MuiTab = styled(Tab, {
  shouldForwardProp: (prop) => prop !== 'activeTab' && prop !== 'tab' && prop !== 'primaryColor'
})(({ theme,activeTab, tab,primaryColor }) => ({
  color:
    activeTab === tab.id
      ? lighten(primaryColor, 0.2)
      : '#424242',
  fontSize: 14,
  fontFamily: 'Montserrat',
  fontWeight: 500,
}));

export const MuiSaveAsButton = styled(IconButton, {
  shouldForwardProp: (prop) => prop !== 'primaryColor'
})(({ theme,primaryColor }) => ({
  width: 56,
  height: 56,
  borderRadius: '16px',
  color: 'white',
  backgroundColor: primaryColor,
  '&:hover': {
    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.3)',
    transition: 'box-shadow ease-in-out',
    backgroundColor: primaryColor,
  },
}));

export const MuiArrowButton = styled(IconButton, {
  shouldForwardProp: (prop) => prop !== 'primaryColor'
})(({ theme,primaryColor }) => ({
  width: 56,
  height: 56,
  borderRadius: '16px',
  backgroundColor: primaryColor,
  '&:hover': {
    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.3)',
    transition: 'box-shadow ease-in-out',
    backgroundColor: primaryColor,
  },
}));

export const MuibackToAnalysisButton = styled(IconButton, {
  shouldForwardProp: (prop) => prop !== 'primaryColor'
})(({ theme,primaryColor }) => ({
  width: 205,
  height: 38,
  borderRadius: '20px',
  backgroundColor: `${primaryColor}`,
  position: 'absolute',
  top: 16,
  left: 24,
  fontFamily: 'Montserrat',
  fontSize: '14px',
  color: 'white',
  fontWeight: 500,
  '&:hover': {
    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.3)',
    transition: 'box-shadow ease-in-out',
    backgroundColor: `${primaryColor}`,
  }
}));


export const iconButtonStyle = {
  verticalAlign: 'baseline',
  marginLeft: '8px',
  border: '1px solid #e0e0e0',
  width: '26px',
  height: '26px',
};

export const iconStyle = {
  width: '16px',
  height: '16px',
};

export const imgLogoStyle = {
  width: 145,
  height: 16,
  backgroundColor: 'white',
};

export const imgOrgStyle = {
  width: '47%',
  height: '10%',
  clipPath: "circle(80.9% at 8% 91%)",
};

export const imgEllipseStyle = {
  width: '47%',
  height: '10%',
  alignSelf:'flex-end',
  marginLeft: '-9rem'
};

export const checkCircleStyle = {
  position: 'absolute',
  top: -3,
  right: -3,
  width: 16,
  height: 16
};

export const imgConclusionStyle = {
  width: '100%',
  height: '100%',
  objectFit: "cover",
};

export const subItemStyle = {
  fontSize: '14px',
  fontFamily: 'Montserrat',
  color: '#424242'
};

export const circleIconStyle = {
  color: 'white',
  height: '5px',
  width: '5px',
  marginLeft: '10px'
};

export const tabsStyle = {
  '& .Mui-selected': {
    fontSize: 14,
    fontFamily: 'Montserrat',
    fontWeight: 500,
  }
};

export const imgQuoteStyle = {
  width: '28px',
  height: '28px',
  margin: '2px',
  objectFit: 'contain'
};

export const editorStyle = `.ck.ck-editor__editable {
                             border-radius: 0 0 8px 8px!important;
                              border: none !important;
                              border-bottom: 1px solid #B0BEC5 !important;
                              box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1) !important;
                              background-color: #FAFAFA !important;
                              padding: 10px !important;
                            }
                             .ck.ck-editor__top .ck-sticky-panel .ck-sticky-panel__content {
                              border: none !important;
                            }
                            .ck.ck-toolbar {
                              border-radius: 8px 8px 0 0 !important;
                              background-color: #ECEFF1 !important;
                              border: none !important;
                              box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1) !important;
                            }
                            .ck.ck-toolbar .ck-button {
                              margin: 4px !important;
                            }
                            .ck.ck-toolbar .ck-button:hover,
                            .ck.ck-toolbar .ck-button:active {
                              background-color: #CFD8DC !important;
                            }`

