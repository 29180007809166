import {Chip, styled} from "@mui/material";

export const StatusBadge = styled(Chip)(({theme, label}) => {
  const getBackground = (label) => {
    switch (label.toLowerCase()) {
      case 'published':
        return '#C8E6C9';
      case 'draft':
        return '#CFD8DC';
      case 'running':
        return '#B2EBF2'
      case 'partially provisioned':
        return '#FFE0B2';
      default:
        return '#FFCDD2';
    }
  };
  const getColor = (label) => {
    switch (label.toLowerCase()) {
      case 'published':
        return '#1B5E20';
      case 'draft':
        return '#263238';
      case 'running':
        return '#006064';
      case 'partially provisioned':
        return '#E65100';
      default:
        return '#B71C1C';

    }
  };
  return ({
    backgroundColor: getBackground(label),
    color: getColor(label),
    fontFamily: 'Montserrat',
    fontSize: '12px',
  });
})
