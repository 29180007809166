import {
    styled,
    Typography,
    Box,
    lighten
} from '@mui/material';

import {
    ArrowForward as ArrowForwardIcon
} from "@mui/icons-material";

export const MuiHeaderTranslateTextPopover = styled(Typography)(({ theme }) => ({
    fontFamily: 'Raleway',
    fontWeight: 500,
    fontSize: '21px',
    lineHeight: '24.65px',
    letterSpacing: '0.03px',
    color: '#616161',
}));

export const MuiSubHeaderTranslateTextPopover = styled(Typography)(({ theme }) => ({
    fontFamily: 'Montserrat',
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '14.63px',
    color: '#757575',
    marginBottom:'20px'
}));

export const MuiLanguageTranslateTextBox = styled(Box)(({ theme }) => ({
    width: '167px',
    borderRadius: '8px',
    padding: '8px',
    backgroundColor: '#FAFAFA',
}));

export const MuiArrowForwardIcon = styled(ArrowForwardIcon)(({ theme }) => ({
    width: '12px',
    height: '12px',
    color: lighten(theme.palette.primary.main, 0.4),
}));