// noinspection XmlDeprecatedElement

import {Box, CircularProgress, Grid, IconButton, Modal} from "@mui/material";
import OpenInFullIcon from '@mui/icons-material/OpenInFull';
import ActionBar from "./analytics/ActionBar";
import React, {useEffect, useState} from "react";
import {useLocation} from "react-router-dom";
import useSurvey from "../../../../api/hooks/useSurvey";
import FilterControls from "./analytics/FilterControls";
import InteractionsChart from "./analytics/InteractionsChart";
import GeneralStats from "./analytics/GeneralStats";
import SegmentAggregation from "./analytics/SegmentAggregation";
import ResponsesByUser from "./analytics/ResponsesByUser";
import ResponsesByQuestion from "./analytics/ResponsesByQuestion";
import DetailedInteractionsChart from "./analytics/DetailedInteractionsChart";
import QRModal from "./QRModal";

const AnalyticsDashboard = () => {
  const location = useLocation();
  const [aggregationLevel, setAggregationLevel] = useState('year-month-day')
  const [interactionKind, setInteractionKind] = useState('all')
  const [selectedChannels, setSelectedChannels] = useState(['whatsapp', 'telegram', 'web_message']);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [segmentationQuestion, setSegmentationQuestion] = useState(null);
  const [selectedSegmentationCategories, setSelectedSegmentationCategories] = useState([]);
  const surveyId = location.pathname.split('/')[4];
  const {data: survey, isLoading: isLoadingSurvey} = useSurvey({id: surveyId});
  const [openDetailedInteractions, setOpenDetailedInteractions] = useState(false);
  const [openQRModal, setOpenQrModal] = useState(false);
  const [autoRefresh, setAutoRefresh] = useState('off');

  const handleUpdateFilters = (sDate, eDate, segmentQuestion) => {
    if (sDate !== startDate) {
      setStartDate(sDate)
    }
    if (eDate !== endDate) {
      setEndDate(eDate);
    }
    if (segmentationQuestion !== segmentQuestion) {
      setSegmentationQuestion(segmentQuestion);
      setSelectedSegmentationCategories([]);
    }
  }

  useEffect(() => {
    if (autoRefresh !== 'off') {
      const interval = setInterval(() => {
        setAutoRefresh(`on-${Date.now()}`);
      }, 10000);
      return () => clearInterval(interval);
    }

  }, [autoRefresh]);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        maxHeight: 'calc(100vh - 90px)',
        overflowY: 'auto',
        gap: '25px',
        boxSizing: 'border-box',
        padding: {
          xl: '46px 100px 46px 100px',
          lg: '46px 70px 46px 70px',
          md: '46px 60px 46px 60px',
          sm: '46px 40px 46px 40px'
        }
      }}
    >
      <ActionBar handleTestInterview={() => setOpenQrModal(true)} surveyStatus={survey?.status}/>
      <FilterControls startDate={startDate}
                      endDate={endDate}
                      detailLevel={aggregationLevel}
                      setDetailLevel={setAggregationLevel}
                      segmentationVariable={segmentationQuestion}
                      survey={survey}
                      updateFilters={handleUpdateFilters}
                      autoRefresh={autoRefresh}
                      setAutoRefresh={setAutoRefresh}
      />
      <GeneralStats survey={survey}
                    aggregationKind={interactionKind}
                    changeAggregationKind={setInteractionKind}
                    segmentQuestion={segmentationQuestion}
                    selectedSegmentCategories={selectedSegmentationCategories}
                    startDate={startDate}
                    endDate={endDate}
                    selectedChannels={selectedChannels}
                    setSelectedChannels={setSelectedChannels}
                    autoRefresh={autoRefresh}
      />
      <Grid container direction={'row'} sx={{width: '100%', height: '582px'}}
            columnGap={'24px'} wrap={'nowrap'}>
        <Grid item xs={!!segmentationQuestion ? 7 : 12}
              sx={{height: '582px', position: 'relative'}}>
          <InteractionsChart survey={survey}
                             aggregationLevel={aggregationLevel}
                             interactionKind={interactionKind}
                             segment={segmentationQuestion}
                             startDate={startDate}
                             endDate={endDate}
                             selectedSegmentAnswers={selectedSegmentationCategories}
                             selectedChannels={selectedChannels}
                             autoRefresh={autoRefresh}
          />
          <IconButton
            sx={{
              position: 'absolute',
              top: 16, // adjust as needed
              right: 16, // adjust as needed
              zIndex: 1,
              width: '24px',
              height: '24px',
              borderRadius: '12px',
              border: '1px solid #E0E0E0',
              padding: '4px'
            }}
            onClick={() => {
              setOpenDetailedInteractions(true);
            }}
          >
            <OpenInFullIcon sx={{width: '12px', height: '12px'}}/>
          </IconButton>
        </Grid>
        {(!!segmentationQuestion) && (
          <Grid container item xs={5} sx={{height: '582px', padding: 0}}
                justifyContent={'center'}>
            <SegmentAggregation survey={survey}
                                selectedSegment={segmentationQuestion}
                                changeSelectedCategories={setSelectedSegmentationCategories}
                                startDate={startDate}
                                endDate={endDate}
                                interactionKind={interactionKind}
                                selectedChannels={selectedChannels}
                                autoRefresh={autoRefresh}
            />
          </Grid>
        )}
      </Grid>
      <Grid container direction={'row'}
            sx={{width: '100%', height: '582px', marginTop: '24px'}}
            columnGap={'24px'} wrap={'nowrap'}
            alignContent={'flex-start'} alignItems={'flex-start'}>
        <Grid container item xs={true} alignContent={'flex-start'}>
          {(!isLoadingSurvey && survey) &&
            (<ResponsesByUser
              survey={survey}
              filterQuestion={segmentationQuestion}
              filterResponses={selectedSegmentationCategories}
              startDate={startDate}
              endDate={endDate}
              interactionKind={interactionKind}
              selectedChannels={selectedChannels}
              autoRefresh={autoRefresh}
            />)}
          {isLoadingSurvey || !survey && (
            <Box
              sx={{
                width: '100%',
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <CircularProgress size={'100px'}/>
            </Box>
          )}
        </Grid>
        <Grid container item xs={6} justifyContent={'center'}
              alignContent={'flexStart'}>
          <Box sx={{width: '100%'}}>
            <ResponsesByQuestion
              survey={survey}
              filterQuestion={segmentationQuestion}
              filterResponses={selectedSegmentationCategories}
              startDate={startDate}
              endDate={endDate}
              interactionKind={interactionKind}
              selectedChannels={selectedChannels}
              autoRefresh={autoRefresh}
            />
          </Box>
        </Grid>

      </Grid>
      <Modal open={openDetailedInteractions}
             onClose={() => setOpenDetailedInteractions(false)}
             sx={{
               borderRadius: '12px'
             }}
      >
        <Box sx={{
          display: 'flex',
          position: 'absolute',
          width: '1400px',
          height: '793px',
          maxWidth: '95vw',
          maxHeight: '95vh',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          flexDirection: 'column',
        }}>
          <DetailedInteractionsChart survey={survey}
                                     detailLevel={aggregationLevel}
                                     setDetailLevel={setAggregationLevel}
                                     interactionKind={interactionKind}
                                     segment={segmentationQuestion}
                                     startDate={startDate}
                                     endDate={endDate}
                                     selectedSegmentAnswers={selectedSegmentationCategories}
                                     onClose={() => setOpenDetailedInteractions(false)}
                                     autoRefresh={autoRefresh}
          />
        </Box>
      </Modal>
      {(!!survey) && (<QRModal
        survey={{id: survey.serviceId, environment: survey.environment}}
        open={openQRModal}
        handleClose={() => setOpenQrModal(false)}/>)}
    </Box>
  );
};

export default AnalyticsDashboard;
