import React, { useEffect, useState } from 'react';
import ToggleButton from '@mui/material/ToggleButton';
import Refresh from '@mui/icons-material/Refresh';
import CircularProgress from '@mui/material/CircularProgress';

function AnimatedToggleButton({ autoRefresh, setAutoRefresh }) {
  // progress value in percentage (100 means full circle)
  const [progress, setProgress] = useState(100);
  const [running, setRunning] = useState(false);

  useEffect(() => {
    let interval;
    // When autoRefresh is "on", start the progress countdown
    if (autoRefresh !== 'off') {
      setRunning(true);
      setProgress(100);
      interval = setInterval(() => {
        setProgress((prev) => {
          if (prev <= 10) {
            clearInterval(interval);
            setRunning(false);
            return 0;
          }
          return prev - 10;
        });
      }, 1000);
    } else {
      // If autoRefresh is turned off, reset the progress
      setProgress(100);
      setRunning(false);
    }
    return () => clearInterval(interval);
  }, [autoRefresh]);

  const containerStyle = {
    position: 'relative',
    display: 'inline-block',
  };

  const buttonStyle = {
    width: '44px',
    height: '44px',
    backgroundColor: '#FFFFFF',
    borderRadius: '50%',
    position: 'relative',
    zIndex: 2,
  };

  const progressStyle = {
    position: 'absolute',
    top: '-4px', // adjust to center the progress indicator around the button
    left: '-4px',
    zIndex: 1,
  };

  return (
    <div style={containerStyle}>
      <ToggleButton
        value={autoRefresh !== 'off'}
        selected={autoRefresh && autoRefresh !== 'off'}
        onClick={() => setAutoRefresh(autoRefresh === 'off' ? 'on' : 'off')}
        sx={buttonStyle}
      >
        <Refresh />
      </ToggleButton>

      {running && (
        <CircularProgress
          variant="determinate"
          value={progress}
          size={52} // slightly larger than the ToggleButton to act as an overlay ring
          thickness={4}
          style={progressStyle}
        />
      )}
    </div>
  );
}

export default AnimatedToggleButton;
