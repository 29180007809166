import {useCallback, useEffect, useRef, useState,} from "react";
import {
  Autocomplete,
  Box,
  Button, CircularProgress,
  Grid2 as Grid,
  ListItemIcon,
  MenuItem,
  Typography,
} from '@mui/material';
import {
  MuiAutocompleteTextfield,
  MuiAvatarFlags,
  MuiChip,
  MuiInfoIconButton,
  MuiInfoOutlinedIcon,
  MuiMenuItem,
  MuiTextfield,
  MuiTitleTypography,
} from 'components/views/organizations/surveys/styles/generalInformation';
import {useTranslation} from 'components/providers/TranslationProvider';
import spanishFlag from "assets/img/spanish.png";
import englishFlag from "assets/img/english.png";
import frenchFlag from "assets/img/french.png";
import brazilFlag from "assets/img/portuguese-brazil.png";
import arabicFlag from "assets/img/arabic.png";
import LanguageIcon from "@mui/icons-material/Language";
import {lighten} from "@mui/material/styles";
import {useTheme} from "../../../../providers/CustomThemeProvider";
import {useDropzone} from "react-dropzone";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import axiosInstance from "../../../../../api/axios/axiosInstance";

const GeneralInformation = (props) => {
  const {t} = useTranslation();
  const {
    survey,
    setSurvey,
    defaultLanguage,
    setDefaultLanguage,
    isEditing
  } = props;

  const [name, setName] = useState(survey.name || '');
  const [region, setRegion] = useState(survey.region || 'SA');
  const [additionalLanguages, setAdditionalLanguages] = useState(survey.additionalLanguages || []);
  const [file, setFile] = useState(null);
  const [imageUrl, setImageUrl] = useState(survey.profileImageUrl || '')
  const listLanguages = useRef([
    {name: "english", flag: englishFlag},
    {name: "spanish", flag: spanishFlag},
    {name: "french", flag: frenchFlag},
    {name: "portuguese", flag: brazilFlag},
    {name: "arabic", flag: arabicFlag}
  ]);
  const {theme} = useTheme();
  const fileInputRef = useRef(null);

  useEffect(() => {
    if (Object.keys(survey).length > 0) {
      const instanceLanguage = listLanguages.current.find(lang => lang.name === survey.defaultLanguage) || {
        name: survey.defaultLanguage,
        flag: null
      };
      setDefaultLanguage(instanceLanguage)
      setName(survey.name);
      setRegion(survey.region);
      setFile(survey.profileImageUrl);
      survey.additionalLanguages.forEach(lang => {
        if (!listLanguages.current.find(item => item.name === lang.name)) {
          listLanguages.current.push(lang)
        }
      })
      setAdditionalLanguages(survey.additionalLanguages);
    }
  }, [survey])

  const onDrop = useCallback(async (acceptedFiles) => {
    const uploadedFile = acceptedFiles[0];
    if (uploadedFile) {
      const imageFile = new File([uploadedFile], `${uploadedFile.name}`);
      const formData = new FormData();
      formData.append('image_file', imageFile);
      const axiosResp = await axiosInstance.post(`${process.env.REACT_APP_API_URL}/collection/upload`, formData).then((resp) => resp.data);
      const url = axiosResp.file;
      setImageUrl(url);
      setFile(URL.createObjectURL(uploadedFile));
      setSurvey((prevSurvey) => ({
        ...prevSurvey,
        profileImageUrl: url
      }));
    }
  }, []);

  useEffect(() => {
    if (imageUrl) {
      fetch(imageUrl)
        .then(response => response.blob())
        .then(blob => {
          const objectURL = URL.createObjectURL(blob);
          setFile(objectURL);
        })
        .catch(error => console.error('Error loading image:', error));
    }
  }, [imageUrl]);


  const {getRootProps, getInputProps} = useDropzone({
    onDrop,
    accept: 'image/jpeg, image/png',
    maxFiles: 1
  });
  if(isEditing&&!survey.name){
    return <Box sx={{
      display:'flex',
      height:'600px',
      justifyContent:'center',
      alignItems:'center',
      width:'100%'
    }}>
      <CircularProgress size={'150px'}/>
    </Box>
  }
  return (
    <Box sx={{minHeight: '600px'}}>
      <Grid item xs={12} sx={{marginTop: '35px'}}>
        <MuiTitleTypography variant="h6">
          {t('general_info')}
        </MuiTitleTypography>
        <Typography sx={{
          fontSize: '12px',
          fontWeight: 400,
          fontFamily: 'Montserrat',
          marginBottom: '8px'
        }}>
          Profile image
        </Typography>
      </Grid>
      <Box sx={{display: 'flex'}}>
        <Box sx={{
          display: 'flex',
          alignItems: 'center',
          gap: '12px',
          marginRight: '32px'
        }}>
          <Box
            {...getRootProps()}
            sx={{
              border: '2px dashed #757575',
              borderRadius: '12px',
              padding: '19px 18px 19px 18px',
              textAlign: 'center',
              cursor: 'pointer',
              width: '236px',
              height: '236px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <input {...getInputProps()} />
            {file ? (
              <img src={file} alt="Preview"
                   style={{width: '100%', height: '100%', objectFit: 'cover'}}/>
            ) : (
              <Box>
                <CloudUploadIcon
                  sx={{color: '#9E9E9E', width: '36px', height: '24px'}}/>
                <Typography sx={{
                  color: '#616161',
                  fontFamily: 'Montserrat',
                  fontSize: '14px'
                }}>
                  Select file or drag here
                </Typography>
                <Typography sx={{
                  fontWeight: 600,
                  fontFamily: 'Montserrat',
                  fontSize: '10px',
                  color: '#9E9E9E'
                }}>
                  <strong style={{}}>Format: </strong>JPG or
                  PNG <strong>Proportion: </strong>1x1
                </Typography>
                <Button variant="outlined" color="primary"
                        sx={{
                          marginTop: 2,
                          borderRadius: '13px',
                          height: '32px',
                          fontFamily: 'Montserrat',
                          fontSize: '10px',
                          color: lighten(theme.palette.primary.main, 0.2),
                          border: `1px solid ${lighten(theme.palette.primary.main, 0.1)}`
                        }}
                >
                  Select File
                  <input ref={fileInputRef} type="file" hidden/>
                </Button>
              </Box>
            )}
          </Box>
        </Box>
        <Box>
          <Box sx={{display: 'flex'}}>
            <MuiTextfield
              label={t('interview_name')}
              name="Name"
              variant="outlined"
              value={name}
              onChange={(e) => setSurvey({...survey, name: e.target.value})}
            />
            <MuiInfoIconButton sx={{alignSelf: 'center'}}>
              <MuiInfoOutlinedIcon/>
            </MuiInfoIconButton>
          </Box>
          <Box sx={{display: 'flex', marginTop: '34px'}}>
            <MuiTextfield
              select
              label={t('data_region')}
              name="region"
              variant="outlined"
              value={region}
              disabled={isEditing}
              onChange={(e) => setSurvey({...survey, region: e.target.value})}
            >
              <MenuItem key="US" value="US">US</MenuItem>
              <MenuItem key="SA" value="SA">SA</MenuItem>
            </MuiTextfield>
            <MuiInfoIconButton sx={{alignSelf: 'center'}}>
              <MuiInfoOutlinedIcon/>
            </MuiInfoIconButton>
          </Box>
          <Box sx={{display: "flex", marginTop: '34px'}}>
            <MuiTextfield
              select
              label={t('default_language')}
              name="defaultLanguage"
              variant="outlined"
              value={defaultLanguage?.name}
              disabled={isEditing}
              onChange={(e) => setSurvey({
                ...survey,
                defaultLanguage: e.target.value
              })}
              sx={{width: '158px !important'}}>
              {listLanguages.current.map((lang) => (
                <MuiMenuItem key={lang.name} value={lang.name}>
                  <Box sx={{display: "flex", alignItems: "center"}}>
                    <ListItemIcon sx={{minWidth: '20px', marginRight: '8px'}}>
                      {(lang.flag) ? (<img src={lang.flag} alt={lang.name}
                                           style={{
                                             width: '20px',
                                             height: '20px'
                                           }}/>) : (
                        <LanguageIcon/>
                      )}
                    </ListItemIcon>
                    <Typography sx={{
                      fontFamily: 'Montserrat',
                      fontSize: '16px'
                    }}>{lang.name}</Typography>
                  </Box>
                </MuiMenuItem>
              ))}
            </MuiTextfield>
            <Autocomplete
              id="additional_languages"
              options={listLanguages.current.filter(
                (lang) =>
                  lang.name !== defaultLanguage?.name
              )}
              filterSelectedOptions
              // disabled={isEditing}
              getOptionLabel={(option) => option.name}
              disableCloseOnSelect
              multiple
              variant="outlined"
              value={additionalLanguages}
              onChange={(event, newValue) => {
                setSurvey((prevSurvey) => ({
                  ...prevSurvey,
                  additionalLanguages: newValue,
                }));
              }}
              isOptionEqualToValue={(option, value) => option?.name === value?.name}
              renderInput={(params) => (
                <MuiAutocompleteTextfield
                  {...params}
                  variant="outlined"
                  label={t('additional_languages')}
                  sx={{fontFamily: "Montserrat !important"}}
                />
              )}
              renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                  <MuiChip
                    key={index}
                    label={option.name}
                    {...getTagProps({index})}
                    avatar={option.flag?<MuiAvatarFlags src={option.flag}/>:<LanguageIcon/>}
                  />
                ))
              }
              renderOption={(props, option) => (
                <MuiMenuItem {...props} key={option.name}>
                  <ListItemIcon sx={{minWidth: '20px', marginRight: '8px'}}>
                    {(!!option.flag)?(
                      <img src={option.flag} alt={option.name}
                           style={{width: '20px', height: '20px'}}/>
                    ):(
                      <LanguageIcon/>
                    )}
                  </ListItemIcon>
                  {option.name}
                </MuiMenuItem>
              )}
              sx={{width: '501px', height: '56px', marginLeft: '9px'}}
            />
            <MuiInfoIconButton sx={{alignSelf: 'center'}}>
              <MuiInfoOutlinedIcon/>
            </MuiInfoIconButton>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default GeneralInformation;
