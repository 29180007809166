import {
    styled,
    Typography,
    Box,
} from '@mui/material';

export const MuiStatus = styled(Typography)(({ theme }) => ({
    fontFamily: 'Montserrat',
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '18.78px',
    color: '#254957',
}));

export const MuiIndicatorStatus = styled(Box)(({ theme }) => ({
    backgroundColor: '#254957',
    minWidth: '111px',
    height: '28px',
    borderRadius: '14px',
    padding: '4px 16px',
    justifyContent: 'center',
    alignContent: 'center',
}));