import {
  Alert,
  Box,
  Chip,
  Grid,
  IconButton,
  styled,
  Typography
} from "@mui/material";
import {lighten} from "@mui/material/styles";

export const MuiGridContainer = styled(Grid)(({ theme }) => ({
  display:'flex',
  flexDirection:'column'
}));

export const MuiGridHeader = styled(Grid)(({ theme }) => ({
  display:'flex',
  justifyContent:'space-between',
  marginTop:'34px'
}));

export const MuiGridItem = styled(Grid)(({ theme }) => ({
  display:'flex',
  justifyContent:'space-between',
  marginTop:'18px'
}));

export const MuiAlertApp = styled(Alert)(({ theme }) => ({
  height: '37px',
  padding: '8px 16px',
  borderRadius: '8px',
  display: 'flex',
  alignItems: 'center'
}));

export const MuiPhoneNumber = styled(Chip)(({ theme }) => ({
  backgroundColor: "#e0f7fa",
  color: "#254957",
  fontWeight: "bold",
  "& .MuiChip-deleteIcon": {
    color: "#254957",
    "&:hover": {
      color: "#1b353f",
    },
  },
}));

export const MuiBoxWhatsapp = styled(Box)(({ theme }) => ({
  display:'flex',
  justifyContent:'space-between',
  width:'65%',
  marginTop:'24px'
}));

export const MuiBoxTelegram = styled(Box)(({ theme }) => ({
  display:'flex',
  justifyContent:'space-between',
  width:'65%',
  marginTop:'4rem'
}));

export const MuiBoxAppLogo = styled(Box)(({ theme }) => ({
  width:'43px',
  height:'43px',
  marginRight:'12px'
}));

export const MuiBoxForm = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'enabledAppForm'
})(({ theme,enabledAppForm }) => ({
  width:'65%',
  marginTop:'24px',
  opacity: enabledAppForm ? 1 : 0.5,
  pointerEvents: enabledAppForm ? 'auto' : 'none'
}));

export const MuiIconButton = styled(IconButton, {
  shouldForwardProp: (prop) => prop !== 'primaryColor'
})(({ theme,primaryColor }) => ({
  display: "flex",
  alignItems: "center",
  gap: "5px",
  border: `1px solid ${lighten(primaryColor, 0.2)}`,
  borderRadius: "13px",
  height: "26px",
  padding: "4px 12px 4px 8px",
  color: '#00828F',
  borderColor:'#00828F',
  fontWeight:500,
  fontFamily: "Montserrat",
  textTransform:'uppercase',
  fontSize: "10px",
}));

export const MuiAlertMessage = styled(Typography)(({ theme }) => ({
  color: '#4CAF50',
  fontFamily: 'Montserrat',
  fontWeight: 400,
  fontSize: '16px'
}));

export const MuiTextGenerateToken = styled(Typography)(({ theme }) => ({
  color: '#005F64',
  fontFamily: 'Montserrat',
  fontWeight: 500,
  fontSize: '14px'
}));

export const MuiTitle = styled(Typography)(({ theme }) => ({
  color: '#616161',
  fontFamily: 'Raleway',
  fontWeight: 500,
  fontSize: '21px'
}));

export const MuiAppTitle = styled(Typography)(({ theme }) => ({
  color: '#616161',
  fontFamily: 'Raleway',
  fontWeight: 400,
  fontSize: '16px'
}));

export const MuiTextCheckBox = styled(Typography)(({ theme }) => ({
  fontFamily: 'Montserrat',
  fontSize: '14px',
  color:'#616161',
  display: 'flex',
  alignItems: 'center',
  gap: '11px'
}));

export const checkStyle = {
  color: '#4CAF50',
  fontSize: 20,
};

export const dangerStyle = {
  color: 'red',
  fontSize: 20,
};

export const infoStyle = {
  fontSize: 16,
  color: "#9E9E9E",
  cursor: "pointer"
};

export const textInputStyle = {
  "& input": {
    fontFamily: "Montserrat",
    fontSize: "16px",
    fontWeight: 400,
    color: "#417183",
    cursor: "default",
  },
};
