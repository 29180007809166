import {Box, CircularProgress, Grid, Typography, useTheme} from "@mui/material";
import useSurveyParticipationStats
  from "../../../../../api/hooks/useSurveyParticipationStats";
import {
  ChannelStatChip,
  FilterableIndicator,
  StatBox,
  StatTitle,
  StatValue
} from "./styles/generalStats";
import {useTranslation} from "../../../../providers/TranslationProvider";
import {useEffect, useMemo} from "react";
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import TelegramIcon from '@mui/icons-material/Telegram';
import LanguageIcon from "@mui/icons-material/Language";

const GeneralStats = ({
                        survey,
                        aggregationKind,
                        changeAggregationKind,
                        segmentQuestion,
                        selectedSegmentCategories,
                        startDate,
                        endDate,
                        selectedChannels,
                        setSelectedChannels,
                        autoRefresh
                      }) => {
  const memoPayload = useMemo(() => ({
    service_id: survey?.serviceId,
    environment: survey?.environment,
    filterQuestionId: segmentQuestion?.id && selectedSegmentCategories?.length > 0 ? segmentQuestion.id : null,
    filterResponses: selectedSegmentCategories?.length > 0 ? selectedSegmentCategories : null,
    startDate,
    endDate
  }), [survey, segmentQuestion, selectedSegmentCategories, startDate, endDate]);
  const {
    isLoading: isLoadingStats,
    data: stats,
    refetch
  } = useSurveyParticipationStats(memoPayload);
  const {t} = useTranslation();
  const theme = useTheme();

  useEffect(() => {
    if (autoRefresh && autoRefresh !== 'off') {
      refetch();
    }
  }, [autoRefresh]);

  const TimeDisplay = ({totalSeconds}) => {
    const seconds = Math.floor(totalSeconds);

    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const secs = seconds % 60;

    // Format each part to ensure two digits for minutes and seconds when necessary
    const formattedMinutes = minutes.toString().padStart(2, '0');
    const formattedSeconds = secs.toString().padStart(2, '0');

    if (hours > 0) {
      return <Box sx={{display: 'flex', alignItems: 'flex-end',maxWidth:'152px'}}>
        <Typography
          sx={{
            fontFamily: 'Raleway',
            fontSize: '35px',
            fontWeight: 400,
            letterSpacing: '0.0025em',
            color: '#424242'
          }}
        >{`${hours}:${formattedMinutes}`}</Typography>
        <Typography
          sx={{
            fontFamily: 'Montserrat',
            fontSize: '16px',
            color: '#424242'
          }}
        >hrs.</Typography>
      </Box>;
    } else if (minutes >= 0) {
      return <Box sx={{display: 'flex', alignItems: 'flex-end'}}>
        <Typography
          sx={{
            fontFamily: 'Raleway',
            fontSize: '35px',
            fontWeight: 400,
            letterSpacing: '0.0025em',
            color: '#424242'
          }}
        >{`${minutes}:${formattedSeconds}`}</Typography>
        <Typography
          sx={{
            fontFamily: 'Montserrat',
            fontSize: '16px',
            color: '#424242'
          }}
        >min.</Typography>
      </Box>;
    }
  }


  return (
    <Box sx={{
      display: 'flex',
      width: '100%',
      alignItems: 'center',
    }}>
      <Grid container direction={'row'} justifyContent={'space-between'}
            spacing={2} wrap={'wrap'}>
        <Grid item sx={{width:'184px'}}
        >
          <StatBox selected={aggregationKind === 'all'}
                   onClick={() => {
                     changeAggregationKind('all');
                     setSelectedChannels(['whatsapp', 'telegram', 'web_message']);
                   }}
                   sx={{cursor: 'pointer'}}
          >
            <Box sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between'
            }}>
              <StatTitle
                selected={aggregationKind === 'all'}>{t('total_interactions_initiated')}</StatTitle>
              <FilterableIndicator selected={aggregationKind === 'all'}/>
            </Box>
            {(isLoadingStats || !survey) ? (
              <Box sx={{
                display: 'flex',
                width: '100%',
                height: '41px',
                justifyContent: 'center',
                alignItems: 'center'
              }}>
                <CircularProgress size={'20px'}/>
              </Box>
            ) : (
              <>
                <StatValue
                  selected={aggregationKind === 'all'}
                >
                  {stats.totalInterviewsStarted.toLocaleString('en-US')}
                </StatValue>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    gap: '12px'
                  }}>
                  <ChannelStatChip
                    selected={selectedChannels.includes('whatsapp') && aggregationKind === 'all'}
                    onClick={(evt) => {
                      console.log("WhatsApp selected");
                      if (aggregationKind !== 'all') {
                        changeAggregationKind('all');
                      }
                      if (selectedChannels.includes('whatsapp')) {
                        setSelectedChannels(selectedChannels.filter(channel => channel !== 'whatsapp'));
                      } else {
                        setSelectedChannels([...selectedChannels, 'whatsapp']);
                      }
                      evt.stopPropagation();
                      evt.preventDefault();
                    }}
                  >
                    <WhatsAppIcon fontSize={'small'} sx={{
                      width: '12px',
                      height: '12px',
                      backgroundColor: '#25D366',
                      color: '#ffffff',
                      borderRadius: '6px'
                    }}/>
                    <Typography
                      sx={{
                        fontFamily: 'Montserrat',
                        fontSize: '12px',
                        color: '#424242'
                      }}
                    >
                      {stats.totalWhatsAppInterviews.toLocaleString('en-US')}
                    </Typography>
                  </ChannelStatChip>
                  <ChannelStatChip
                    selected={selectedChannels.includes('telegram') && aggregationKind === 'all'}
                    onClick={(evt) => {
                      if (aggregationKind !== 'all') {
                        changeAggregationKind('all');
                      }
                      if (selectedChannels.includes('telegram')) {
                        setSelectedChannels(selectedChannels.filter(channel => channel !== 'telegram'));
                      } else {
                        setSelectedChannels([...selectedChannels, 'telegram']);
                      }
                      evt.stopPropagation();
                      evt.preventDefault();
                    }}
                  >
                    <TelegramIcon fontSize={'small'} sx={{
                      width: '12px',
                      height: '12px',
                      backgroundColor: '#0088cc',
                      color: '#ffffff',
                      borderRadius: '6px'
                    }}/>
                    <Typography
                      sx={{
                        fontFamily: 'Montserrat',
                        fontSize: '12px',
                        color: '#424242'
                      }}
                    >
                      {stats.totalTelegramInterviews.toLocaleString('en-US')}
                    </Typography>
                  </ChannelStatChip>
                  <ChannelStatChip
                    selected={selectedChannels.includes('web_message') && aggregationKind === 'all'}
                    onClick={(evt) => {
                      if (aggregationKind !== 'all') {
                        changeAggregationKind('all');
                      }
                      if (selectedChannels.includes('web_message')) {
                        setSelectedChannels(selectedChannels.filter(channel => channel !== 'web_message'));
                      } else {
                        setSelectedChannels([...selectedChannels, 'web_message']);
                      }
                      evt.stopPropagation();
                      evt.preventDefault();
                    }}
                  >
                    <LanguageIcon fontSize={'small'} sx={{
                      width: '12px',
                      height: '12px',
                      backgroundColor: 'transparent',
                      color: '#757575',
                      borderRadius: '6px'
                    }}/>
                    <Typography
                      sx={{
                        fontFamily: 'Montserrat',
                        fontSize: '12px',
                        color: '#424242'
                      }}
                    >
                      {stats.totalWebInterviews.toLocaleString('en-US')}
                    </Typography>
                  </ChannelStatChip>
                </Box>
              </>
            )}
          </StatBox>
        </Grid>
        <Grid item sx={{width:'184px'}}>
          <StatBox selected={aggregationKind === 'incomplete'}
                   onClick={() => {
                     changeAggregationKind('incomplete');
                     setSelectedChannels(['whatsapp', 'telegram', 'web_message']);
                   }}
                   sx={{cursor: 'pointer'}}
          >
            <Box sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between'
            }}>
              <StatTitle
                selected={aggregationKind === 'incomplete'}>{t('total_interactions_incomplete')}</StatTitle>
              <FilterableIndicator selected={aggregationKind === 'incomplete'}/>
            </Box>
            {(isLoadingStats || !survey) ? (
              <Box sx={{
                display: 'flex',
                width: '100%',
                height: '41px',
                justifyContent: 'center',
                alignItems: 'center'
              }}>
                <CircularProgress size={'20px'}/>
              </Box>
            ) : (
              <>
                <StatValue
                  selected={aggregationKind === 'incomplete'}>{stats.totalIncompleteInterviews.toLocaleString('en-US')}</StatValue>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    gap: '12px'
                  }}>
                  <ChannelStatChip
                    selected={selectedChannels.includes('whatsapp') && aggregationKind === 'incomplete'}
                    onClick={(evt) => {
                      if (aggregationKind !== 'incomplete') {
                        changeAggregationKind('incomplete');
                      }
                      if (selectedChannels.includes('whatsapp')) {
                        setSelectedChannels(selectedChannels.filter(channel => channel !== 'whatsapp'));
                      } else {
                        setSelectedChannels([...selectedChannels, 'whatsapp']);
                      }
                      evt.stopPropagation();
                      evt.preventDefault();
                    }}
                  >
                    <WhatsAppIcon fontSize={'small'} sx={{
                      width: '12px',
                      height: '12px',
                      backgroundColor: '#25D366',
                      color: '#ffffff',
                      borderRadius: '6px'
                    }}/>
                    <Typography
                      sx={{
                        fontFamily: 'Montserrat',
                        fontSize: '12px',
                        color: '#424242'
                      }}
                    >
                      {stats.incompleteWhatsAppInterviews.toLocaleString('en-US')}
                    </Typography>
                  </ChannelStatChip>
                  <ChannelStatChip
                    selected={selectedChannels.includes('telegram') && aggregationKind === 'incomplete'}
                    onClick={(evt) => {
                      if (aggregationKind !== 'incomplete') {
                        changeAggregationKind('incomplete');
                      }
                      if (selectedChannels.includes('telegram')) {
                        setSelectedChannels(selectedChannels.filter(channel => channel !== 'telegram'));
                      } else {
                        setSelectedChannels([...selectedChannels, 'telegram']);
                      }
                      evt.stopPropagation();
                      evt.preventDefault();
                    }}
                  >
                    <TelegramIcon fontSize={'small'} sx={{
                      width: '12px',
                      height: '12px',
                      backgroundColor: '#0088cc',
                      color: '#ffffff',
                      borderRadius: '6px'
                    }}/>
                    <Typography
                      sx={{
                        fontFamily: 'Montserrat',
                        fontSize: '12px',
                        color: '#424242'
                      }}
                    >
                      {stats.incompleteTelegramInterviews.toLocaleString('en-US')}
                    </Typography>
                  </ChannelStatChip>
                  <ChannelStatChip
                    selected={selectedChannels.includes('web_message') && aggregationKind === 'incomplete'}
                    onClick={(evt) => {
                      if (aggregationKind !== 'incomplete') {
                        changeAggregationKind('incomplete');
                      }
                      if (selectedChannels.includes('web_message')) {
                        setSelectedChannels(selectedChannels.filter(channel => channel !== 'web_message'));
                      } else {
                        setSelectedChannels([...selectedChannels, 'web_message']);
                      }
                      evt.stopPropagation();
                      evt.preventDefault();
                    }}
                  >
                    <LanguageIcon fontSize={'small'} sx={{
                      width: '12px',
                      height: '12px',
                      backgroundColor: 'transparent',
                      color: '#757575',
                      borderRadius: '6px'
                    }}/>
                    <Typography
                      sx={{
                        fontFamily: 'Montserrat',
                        fontSize: '12px',
                        color: '#424242'
                      }}
                    >
                      {stats.incompleteWebInterviews.toLocaleString('en-US')}
                    </Typography>
                  </ChannelStatChip>
                </Box>
              </>

            )}
          </StatBox>
        </Grid>
        <Grid item sx={{width:'184px'}}>
          <StatBox selected={aggregationKind === 'pending'}
                   onClick={() => {
                     changeAggregationKind('pending');
                     setSelectedChannels(['whatsapp', 'telegram', 'web_message']);
                   }}
                   sx={{cursor: 'pointer'}}
          >
            <Box sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between'
            }}>
              <StatTitle
                selected={aggregationKind === 'pending'}>{t('pending_interactions')}</StatTitle>
              <FilterableIndicator selected={aggregationKind === 'pending'}/>
            </Box>
            {(isLoadingStats || !survey) ? (
              <Box sx={{
                display: 'flex',
                width: '100%',
                height: '41px',
                justifyContent: 'center',
                alignItems: 'center'
              }}>
                <CircularProgress size={'20px'}/>
              </Box>
            ) : (
              <>
                <StatValue
                  selected={aggregationKind === 'pending'}>{stats.pendingConfirmation.toLocaleString('en-US')}</StatValue>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    gap: '12px'
                  }}>
                  <ChannelStatChip
                    selected={selectedChannels.includes('whatsapp') && aggregationKind === 'pending'}
                    onClick={(evt) => {
                      if (aggregationKind !== 'pending') {
                        changeAggregationKind('pending');
                      }
                      if (selectedChannels.includes('whatsapp')) {
                        setSelectedChannels(selectedChannels.filter(channel => channel !== 'whatsapp'));
                      } else {
                        setSelectedChannels([...selectedChannels, 'whatsapp']);
                      }
                      evt.stopPropagation();
                      evt.preventDefault();
                    }}
                  >
                    <WhatsAppIcon fontSize={'small'} sx={{
                      width: '12px',
                      height: '12px',
                      backgroundColor: '#25D366',
                      color: '#ffffff',
                      borderRadius: '6px'
                    }}/>
                    <Typography
                      sx={{
                        fontFamily: 'Montserrat',
                        fontSize: '12px',
                        color: '#424242'
                      }}
                    >
                      {stats.pendingWhatsAppInterviews.toLocaleString('en-US')}
                    </Typography>
                  </ChannelStatChip>
                  <ChannelStatChip
                    selected={selectedChannels.includes('telegram') && aggregationKind === 'pending'}
                    onClick={(evt) => {
                      if (aggregationKind !== 'pending') {
                        changeAggregationKind('pending');
                      }
                      if (selectedChannels.includes('telegram')) {
                        setSelectedChannels(selectedChannels.filter(channel => channel !== 'telegram'));
                      } else {
                        setSelectedChannels([...selectedChannels, 'telegram']);
                      }
                      evt.stopPropagation();
                      evt.preventDefault();
                    }}
                  >
                    <TelegramIcon fontSize={'small'} sx={{
                      width: '12px',
                      height: '12px',
                      backgroundColor: '#0088cc',
                      color: '#ffffff',
                      borderRadius: '6px'
                    }}/>
                    <Typography
                      sx={{
                        fontFamily: 'Montserrat',
                        fontSize: '12px',
                        color: '#424242'
                      }}
                    >
                      {stats.pendingTelegramInterviews.toLocaleString('en-US')}
                    </Typography>
                  </ChannelStatChip>
                  <ChannelStatChip
                    selected={selectedChannels.includes('web_message') && aggregationKind === 'pending'}
                    onClick={(evt) => {
                      if (aggregationKind !== 'pending') {
                        changeAggregationKind('pending');
                      }
                      if (selectedChannels.includes('web_message')) {
                        setSelectedChannels(selectedChannels.filter(channel => channel !== 'web_message'));
                      } else {
                        setSelectedChannels([...selectedChannels, 'web_message']);
                      }
                      evt.stopPropagation();
                      evt.preventDefault();
                    }}
                  >
                    <LanguageIcon fontSize={'small'} sx={{
                      width: '12px',
                      height: '12px',
                      backgroundColor: 'transparent',
                      color: '#757575',
                      borderRadius: '6px'
                    }}/>
                    <Typography
                      sx={{
                        fontFamily: 'Montserrat',
                        fontSize: '12px',
                        color: '#424242'
                      }}
                    >
                      {stats.pendingWebInterviews.toLocaleString('en-US')}
                    </Typography>
                  </ChannelStatChip>
                </Box>
              </>

            )}
          </StatBox>
        </Grid>
        <Grid item sx={{width:'184px'}}>
          <StatBox
            selected={aggregationKind === 'active'}
            onClick={() => {
              changeAggregationKind('active');
              setSelectedChannels(['whatsapp', 'telegram', 'web_message']);
            }}
            sx={{cursor: 'pointer'}}
          >
            <Box sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between'
            }}>
              <StatTitle
                selected={aggregationKind === 'active'}>{t('total_interview_execution')}</StatTitle>
              <FilterableIndicator selected={aggregationKind === 'active'}/>
            </Box>
            {(isLoadingStats || !survey) ? (
              <Box sx={{
                display: 'flex',
                width: '100%',
                height: '41px',
                justifyContent: 'center',
                alignItems: 'center'
              }}>
                <CircularProgress size={'20px'}/>
              </Box>
            ) : (
              <>
                <StatValue
                  selected={aggregationKind === 'active'}>{stats.currentlyActiveInterviews.toLocaleString('en-US')}</StatValue>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    gap: '12px'
                  }}>
                  <ChannelStatChip
                    selected={selectedChannels.includes('whatsapp') && aggregationKind === 'active'}
                    onClick={(evt) => {
                      if (aggregationKind !== 'active') {
                        changeAggregationKind('active');
                      }
                      if (selectedChannels.includes('whatsapp')) {
                        setSelectedChannels(selectedChannels.filter(channel => channel !== 'whatsapp'));
                      } else {
                        setSelectedChannels([...selectedChannels, 'whatsapp']);
                      }
                      evt.stopPropagation();
                      evt.preventDefault();
                    }}
                  >
                    <WhatsAppIcon fontSize={'small'} sx={{
                      width: '12px',
                      height: '12px',
                      backgroundColor: '#25D366',
                      color: '#ffffff',
                      borderRadius: '6px'
                    }}/>
                    <Typography
                      sx={{
                        fontFamily: 'Montserrat',
                        fontSize: '12px',
                        color: '#424242'
                      }}
                    >
                      {stats.currentlyActiveWhatsAppInterviews.toLocaleString('en-US')}
                    </Typography>
                  </ChannelStatChip>
                  <ChannelStatChip
                    selected={selectedChannels.includes('telegram') && aggregationKind === 'active'}
                    onClick={(evt) => {
                      if (aggregationKind !== 'active') {
                        changeAggregationKind('active');
                      }
                      if (selectedChannels.includes('telegram')) {
                        setSelectedChannels(selectedChannels.filter(channel => channel !== 'telegram'));
                      } else {
                        setSelectedChannels([...selectedChannels, 'telegram']);
                      }
                      evt.stopPropagation();
                      evt.preventDefault();
                    }}
                  >
                    <TelegramIcon fontSize={'small'} sx={{
                      width: '12px',
                      height: '12px',
                      backgroundColor: '#0088cc',
                      color: '#ffffff',
                      borderRadius: '6px'
                    }}/>
                    <Typography
                      sx={{
                        fontFamily: 'Montserrat',
                        fontSize: '12px',
                        color: '#424242'
                      }}
                    >
                      {stats.currentlyActiveTelegramInterviews.toLocaleString('en-US')}
                    </Typography>
                  </ChannelStatChip>
                  <ChannelStatChip
                    selected={selectedChannels.includes('web_message') && aggregationKind === 'active'}
                    onClick={(evt) => {
                      if (aggregationKind !== 'active') {
                        changeAggregationKind('active');
                      }
                      if (selectedChannels.includes('web_message')) {
                        setSelectedChannels(selectedChannels.filter(channel => channel !== 'web_message'));
                      } else {
                        setSelectedChannels([...selectedChannels, 'web_message']);
                      }
                      evt.stopPropagation();
                      evt.preventDefault();
                    }}
                  >
                    <LanguageIcon fontSize={'small'} sx={{
                      width: '12px',
                      height: '12px',
                      backgroundColor: 'transparent',
                      color: '#757575',
                      borderRadius: '6px'
                    }}/>
                    <Typography
                      sx={{
                        fontFamily: 'Montserrat',
                        fontSize: '12px',
                        color: '#424242'
                      }}
                    >
                      {stats.currentlyActiveWebInterviews.toLocaleString('en-US')}
                    </Typography>
                  </ChannelStatChip>
                </Box>
              </>
            )}
          </StatBox>
        </Grid>
        <Grid item sx={{width:'184px'}}
        >
          <StatBox selected={aggregationKind === 'completed'}
                   onClick={() => {
                     changeAggregationKind('completed');
                     setSelectedChannels(['whatsapp', 'telegram', 'web_message']);
                   }}
                   sx={{cursor: 'pointer'}}
          >
            <Box sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between'
            }}
            >
              <StatTitle
                selected={aggregationKind === 'completed'}>{t('total_interactions_completed')}</StatTitle>
              <FilterableIndicator selected={aggregationKind === 'completed'}/>
            </Box>
            {(isLoadingStats || !survey) ? (
              <Box sx={{
                display: 'flex',
                width: '100%',
                height: '41px',
                justifyContent: 'center',
                alignItems: 'center'
              }}>
                <CircularProgress size={'20px'}/>
              </Box>
            ) : (
              <>
                <StatValue
                  selected={aggregationKind === 'completed'}>{stats.totalCompletedInterviews.toLocaleString('en-US')}</StatValue>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    gap: '12px'
                  }}>
                  <ChannelStatChip
                    selected={selectedChannels.includes('whatsapp') && aggregationKind === 'completed'}
                    onClick={(evt) => {
                      if (aggregationKind !== 'completed') {
                        changeAggregationKind('completed');
                      }
                      if (selectedChannels.includes('whatsapp')) {
                        setSelectedChannels(selectedChannels.filter(channel => channel !== 'whatsapp'));
                      } else {
                        setSelectedChannels([...selectedChannels, 'whatsapp']);
                      }
                      evt.stopPropagation();
                      evt.preventDefault();
                    }}
                  >
                    <WhatsAppIcon fontSize={'small'} sx={{
                      width: '12px',
                      height: '12px',
                      backgroundColor: '#25D366',
                      color: '#ffffff',
                      borderRadius: '6px'
                    }}/>
                    <Typography
                      sx={{
                        fontFamily: 'Montserrat',
                        fontSize: '12px',
                        color: '#424242'
                      }}
                    >
                      {stats.completedWhatsAppInterviews.toLocaleString('en-US')}
                    </Typography>
                  </ChannelStatChip>
                  <ChannelStatChip
                    selected={selectedChannels.includes('telegram') && aggregationKind === 'completed'}
                    onClick={(evt) => {
                      if (aggregationKind !== 'completed') {
                        changeAggregationKind('completed');
                      }
                      if (selectedChannels.includes('telegram')) {
                        setSelectedChannels(selectedChannels.filter(channel => channel !== 'telegram'));
                      } else {
                        setSelectedChannels([...selectedChannels, 'telegram']);
                      }
                      evt.stopPropagation();
                      evt.preventDefault();
                    }}
                  >
                    <TelegramIcon fontSize={'small'} sx={{
                      width: '12px',
                      height: '12px',
                      backgroundColor: '#0088cc',
                      color: '#ffffff',
                      borderRadius: '6px'
                    }}/>
                    <Typography
                      sx={{
                        fontFamily: 'Montserrat',
                        fontSize: '12px',
                        color: '#424242'
                      }}
                    >
                      {stats.completedTelegramInterviews.toLocaleString('en-US')}
                    </Typography>
                  </ChannelStatChip>
                  <ChannelStatChip
                    selected={selectedChannels.includes('web_message') && aggregationKind === 'completed'}
                    onClick={(evt) => {
                      if (aggregationKind !== 'completed') {
                        changeAggregationKind('completed');
                      }
                      if (selectedChannels.includes('web_message')) {
                        setSelectedChannels(selectedChannels.filter(channel => channel !== 'web_message'));
                      } else {
                        setSelectedChannels([...selectedChannels, 'web_message']);
                      }
                      evt.stopPropagation();
                      evt.preventDefault();
                    }}
                  >
                    <LanguageIcon fontSize={'small'} sx={{
                      width: '12px',
                      height: '12px',
                      backgroundColor: 'transparent',
                      color: '#757575',
                      borderRadius: '6px'
                    }}/>
                    <Typography
                      sx={{
                        fontFamily: 'Montserrat',
                        fontSize: '12px',
                        color: '#424242'
                      }}
                    >
                      {stats.completedWebInterviews.toLocaleString('en-US')}
                    </Typography>
                  </ChannelStatChip>
                </Box>
              </>
            )}
          </StatBox>
        </Grid>
        <Grid item sx={{width:'184px'}}
        >
          <StatBox>
            <Box sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between'
            }}>
              <StatTitle>{t('interview_finalization_rate')}</StatTitle>
            </Box>
            {(isLoadingStats || !survey) ? (
              <Box sx={{
                display: 'flex',
                width: '100%',
                height: '41px',
                justifyContent: 'center',
                alignItems: 'center'
              }}>
                <CircularProgress size={'20px'}/>
              </Box>
            ) : (
              <>
                <StatValue>{(stats.completionRate * 100).toFixed(0)}%</StatValue>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    gap: '12px'
                  }}>
                  <ChannelStatChip
                    sx={{
                      cursor: 'default',
                      '&:hover': {
                        backgroundColor: 'transparent'
                      }
                    }}
                  >
                    <WhatsAppIcon fontSize={'small'} sx={{
                      width: '12px',
                      height: '12px',
                      backgroundColor: '#25D366',
                      color: '#ffffff',
                      borderRadius: '6px'
                    }}/>
                    <Typography
                      sx={{
                        fontFamily: 'Montserrat',
                        fontSize: '12px',
                        color: '#424242'
                      }}
                    >
                      {(Math.round(stats.completionRateWhatsApp * 100)).toLocaleString('en-US')}%
                    </Typography>
                  </ChannelStatChip>
                  <ChannelStatChip
                    sx={{
                      cursor: 'default',
                      '&:hover': {
                        backgroundColor: 'transparent'
                      }
                    }}
                  >
                    <TelegramIcon fontSize={'small'} sx={{
                      width: '12px',
                      height: '12px',
                      backgroundColor: '#0088cc',
                      color: '#ffffff',
                      borderRadius: '6px'
                    }}/>
                    <Typography
                      sx={{
                        fontFamily: 'Montserrat',
                        fontSize: '12px',
                        color: '#424242'
                      }}
                    >
                      {(Math.round(stats.completionRateTelegram * 100)).toLocaleString('en-US')}%
                    </Typography>
                  </ChannelStatChip>
                  <ChannelStatChip
                    sx={{
                      cursor: 'default',
                      '&:hover': {
                        backgroundColor: 'transparent'
                      }
                    }}
                  >
                    <LanguageIcon fontSize={'small'} sx={{
                      width: '12px',
                      height: '12px',
                      backgroundColor: 'transparent',
                      color: '#757575',
                      borderRadius: '6px'
                    }}/>
                    <Typography
                      sx={{
                        fontFamily: 'Montserrat',
                        fontSize: '12px',
                        color: '#424242'
                      }}
                    >
                      {(Math.round(stats.completionRateWeb * 100)).toLocaleString('en-US')}%
                    </Typography>
                  </ChannelStatChip>
                </Box>
              </>
            )}
          </StatBox>
        </Grid>
        <Grid item sx={{width:'184px'}}
        >
          <StatBox>
            <Box sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between'
            }}>
              <StatTitle>{t('interview_avg_duration')}</StatTitle>
            </Box>
            {(isLoadingStats || !survey) ? (
              <Box sx={{
                display: 'flex',
                width: '100%',
                height: '41px',
                justifyContent: 'center',
                alignItems: 'center'
              }}>
                <CircularProgress size={'20px'}/>
              </Box>
            ) : (
              <TimeDisplay totalSeconds={stats.averageDurationSeconds}/>
            )}
          </StatBox>
        </Grid>
      </Grid>
    </Box>
  )
}

export default GeneralStats;
