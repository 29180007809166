import * as React from 'react';
import useScheme from '../../../../../api/hooks/useScheme';
import {useLocation} from 'react-router-dom';
import {useAuth} from '../../../../providers/AuthProvider';
import {
  setTargetCollection,
  setTargetScheme
} from '../../../../../store/appSlice';
import {useDispatch} from 'react-redux';
import useSchemes from '../../../../../api/hooks/useSchemes';
import {SnackbarProvider} from 'notistack';
import {Box, Grid} from '@mui/material'
import ConfigurationSections from "./ConfigurationSections";


const Specification = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const {user} = useAuth();
  const orgId = location.pathname.split('/')[2] === 'workspace'
    ? user.id
    : location.pathname.split('/')[2];
  const isWorkspace = location.pathname.split('/')[2] === 'workspace'

  const scenario = useScheme({
    user_id: orgId,
    scheme_id: location.pathname.split('/')[4],
    enabled: location.pathname.split('/')[4] !== 'create'
  });
  const schemes = useSchemes(orgId)

  React.useEffect(() => {
    if (scenario.data) {
      dispatch(setTargetCollection(scenario.data.collection))
      let jsonSchemePDF = scenario.data.scheme.jsonSchemePDF || {};
      if (typeof jsonSchemePDF === 'string') {
        jsonSchemePDF = JSON.parse(jsonSchemePDF)
      }
      dispatch(setTargetScheme({
        ...scenario.data.scheme,
        jsonSchemePDF: jsonSchemePDF
      }));
    }
  }, [scenario.data])


  return (
    <SnackbarProvider maxSnack={10}>
      {/*<Grid container justifyContent='center' spacing={1}*/}
      {/*      id={'specification-container'}>*/}
      {/*  */}
      {/*</Grid>*/}
      <Box sx={{
        display:'flex',
        alignItems:'center',
        justifyContent:'center',
        width:'100%',
        height:'100%',
        maxHeight:'100%',
        boxSizing:'border-box',
        overflowY:'auto',
        overflowX:'hidden',
        padding:'20px 100px 25px 100px'
      }}
           id={'specification-container'}
      >
        {(scenario.data && schemes.data) &&
          <ConfigurationSections
            scenario={scenario.data}
            schemes={schemes.data}
            orgId={orgId}
            isWorkspace={isWorkspace}/>
        }
      </Box>
    </SnackbarProvider>
  );
};

export default Specification;
