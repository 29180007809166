import {Box, IconButton, Typography} from "@mui/material";
import {useTranslation} from "../../../../providers/TranslationProvider";
import React, {useState} from "react";
import CloseIcon from "@mui/icons-material/Close";
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import InteractionsChart from "./InteractionsChart";
import {
  DetailLevelToggleButton,
  DetailLevelToggleGroup
} from "./styles/detailedInteractionsChart";

dayjs.extend(utc);

const DetailedInteractionsChart = ({
                                     survey,
                                     detailLevel = 'year-month-day',
                                     startDate,
                                     endDate,
                                     interactionKind = 'all',
                                     segment = null,
                                     selectedSegmentAnswers = null,
                                     onClose,
                                     autoRefresh
                                   }) => {
  const [currentDetailLevel, setCurrentDetailLevel] = useState(detailLevel);
  const {t} = useTranslation();

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: '100%',
        backgroundColor: '#FFFFFF',
        borderRadius: '12px',
        padding: '24px',
        gap: '24px',
        overflowY: 'hidden'
      }}>
        <Box sx={{
          display: 'flex',
          flexDirection: 'row',
          width: '100%',
          justifyContent: 'space-between',
          alignItems: 'center'
        }}
        >
          <Typography sx={{
            fontFamily: 'Raleway',
            fontSize: '21px',
            fontWeight: 500,
            letterSpacing: '0.0015em'
          }}>
            {t('detailed_interactions')}
          </Typography>
          <IconButton sx={{
            width: '24px',
            height: '24px'
          }}
                      onClick={onClose}
          >
            <CloseIcon sx={{
              width: '16px',
              height: '16px'
            }}/>
          </IconButton>
        </Box>
        <Box sx={{
          display: 'flex',
          flexDirection: 'row',
          width: '100%',
          justifyContent: 'center',
          alignContent: 'center',
          height: '32px'
        }}>
          <DetailLevelToggleGroup
            value={currentDetailLevel}
            exclusive
            aria-label={'Detail Level'}
            onChange={(evt, value) => {
              setCurrentDetailLevel(value);
            }}
          >
            <DetailLevelToggleButton
              value='year-month-day'
            >
              {t('date_detail_level')}
            </DetailLevelToggleButton>
            <DetailLevelToggleButton
              value='year-month-day-hour'
            >
              {t('hour_detail_level')}
            </DetailLevelToggleButton>
            <DetailLevelToggleButton
              value='year-month-day-hour-minute'
            >
              {t('minute_detail_level')}
            </DetailLevelToggleButton>
          </DetailLevelToggleGroup>
        </Box>
        <Box sx={{
          width: '100%',
          flexGrow: 1, // This makes the box take up available vertical space.
          minHeight: 0 // Ensures proper shrinking behavior in flex containers.
        }}>
          <InteractionsChart survey={survey}
                             aggregationLevel={currentDetailLevel}
                             interactionKind={interactionKind}
                             segment={segment}
                             startDate={startDate}
                             endDate={endDate}
                             selectedSegmentAnswers={selectedSegmentAnswers}
                             isDetailedView={true}
                             autoRefresh={autoRefresh}
          />
        </Box>
      </Box>
    </LocalizationProvider>
  )
};

export default DetailedInteractionsChart;
