import {Box, IconButton, InputAdornment, TextField} from "@mui/material";
import {DateTimePicker} from "@mui/x-date-pickers";
import dayjs from "dayjs";
import ClearIcon from "@mui/icons-material/Clear";
import React from "react";

const DateRangePicker = ({sDate, setSDate, eDate, setEDate}) => {
  return <Box sx={{
    display: 'flex',
    height: '56px',
    alignItems: 'center',
    gap: '5px',
  }}>
    <DateTimePicker
      label={'Start date'}
      value={sDate ? dayjs(sDate) : null}
      onChange={(value) => {
        // Convert selected value to a UTC ISO string
        const s = dayjs(value).format("YYYY-MM-DD HH:mm");
        console.log("Converted start date", s);
        setSDate(s);
      }}
      ampm={false}
      inputFormat="YYYY-MM-DD HH:mm"
      sx={{
        minWidth: '324px'
      }}
      slots={{
        textField: (params) => (
          <TextField
            {...params}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {params.InputProps.endAdornment}
                  {sDate && (
                    <InputAdornment position="end">
                      <IconButton onClick={() => setSDate(null)}>
                        <ClearIcon/>
                      </IconButton>
                    </InputAdornment>
                  )}
                </>
              ),
            }}
          />
        ),
      }}
    />
    <span><b>-</b></span>
    <DateTimePicker
      label={'End date'}
      value={eDate ? dayjs(eDate) : null}
      onChange={(value) => {
        // Convert selected value to a UTC ISO string
        setEDate(dayjs(value).format("YYYY-MM-DD HH:mm"));
      }}
      ampm={false}
      inputFormat="YYYY-MM-DD HH:mm"
      sx={{
        minWidth: '324px'
      }}
      slots={{
        textField: (params) => (
          <TextField
            {...params}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {params.InputProps.endAdornment}
                  {eDate && (
                    <InputAdornment position="end">
                      <IconButton onClick={() => setEDate(null)}>
                        <ClearIcon/>
                      </IconButton>
                    </InputAdornment>
                  )}
                </>
              ),
            }}
          />
        ),
      }}
    />
  </Box>;
}

export default DateRangePicker;
