import {useMutation, useQueryClient} from 'react-query';
import axiosSurveyInstance from 'api/axios/axiosSurveyInstance';

const requestQuestionTemplate = (data) => {
    const {
        serviceId,
        environment,
        questionId,
        language
    } = data;

    return axiosSurveyInstance.post(`/api/survey/question/${questionId}/request-template/${language}?service_id=${serviceId}&environment=${environment}`).then(resp => resp.data);
};

const useSurveyRequestQuestionTemplate = () => {
  const queryClient = useQueryClient();
  return useMutation((parameters) => requestQuestionTemplate(parameters), {
    onSuccess: (data, parameters) => {
      queryClient.resetQueries('questions');
      queryClient.resetQueries('survey_template_status')
      return data;
    },
    onError: (error) => error,
    onSettled: (data, error, parameters) => {
      queryClient.invalidateQueries('questions');
      queryClient.invalidateQueries('survey_template_status')
      return data;
    },
  });
};
export default useSurveyRequestQuestionTemplate;
