import axiosSurveyInstance from "../axios/axiosSurveyInstance";
import {useQuery} from "react-query";

const fetchParticipationStats = async (data) => {
  return axiosSurveyInstance.request({
    url: '/api/survey/analytics/stats',
    data: data,
    method: 'post',
    maxBodyLength: Infinity
  }).then(rsp => rsp.data).then((data) => {
    return data || {
      averageDurationSeconds: 0.0,
      completionRate: 0.0,
      currentlyActiveInterviews: 0,
      droppedInterviews: 0,
      pendingConfirmation: 0,
      totalCompletedInterviews: 0,
      totalIncompleteInterviews: 0,
      totalInterviewsStarted: 0
    }
  }).catch(err => {
    console.log("Error fetching participation aggregation bins", err)
    return {
      averageDurationSeconds: 0.0,
      completionRate: 0.0,
      currentlyActiveInterviews: 0,
      droppedInterviews: 0,
      pendingConfirmation: 0,
      totalCompletedInterviews: 0,
      totalIncompleteInterviews: 0,
      totalInterviewsStarted: 0
    };
  })
}

const useSurveyParticipationStats = (data) => useQuery(
  ['survey-analytics-stats', data?.service_id, data?.filterQuestionId, data?.filterResponses, data?.startDate, data?.endDate, data?.autoRefresh],
  () => fetchParticipationStats(data),
  {
    enabled: Boolean(data) && Boolean(data?.service_id),
    refetchOnWindowFocus: false,
    placeholderData: {
      averageDurationSeconds: 0.0,
      completionRate: 0.0,
      currentlyActiveInterviews: 0,
      droppedInterviews: 0,
      pendingConfirmation: 0,
      totalCompletedInterviews: 0,
      totalIncompleteInterviews: 0,
      totalInterviewsStarted: 0,
      totalWhatsAppInterviews: 0,
      totalWebInterviews: 0,
      totalTelegramInterviews: 0,
      pendingWhatsAppInterviews: 0,
      pendingWebInterviews: 0,
      pendingTelegramInterviews: 0,
      completedWhatsAppInterviews: 0,
      completedWebInterviews: 0,
      completedTelegramInterviews: 0,
      incompleteWhatsAppInterviews: 0,
      incompleteWebInterviews: 0,
      incompleteTelegramInterviews: 0,
      currentlyActiveWhatsAppInterviews: 0,
      currentlyActiveWebInterviews: 0,
      currentlyActiveTelegramInterviews: 0,
      droppedWhatsAppInterviews: 0,
      droppedWebInterviews: 0,
      droppedTelegramInterviews: 0,
      completionRateWhatsApp:0,
      completionRateWeb:0,
      completionRateTelegram:0
    }
  }
);
export default useSurveyParticipationStats;


