import * as React from 'react';
import { useRoutes, useLocation, useNavigate } from 'react-router-dom';

import { secureRoutes, routes } from '../../routes';
import { useAuth } from 'components/providers/AuthProvider';
import { useLoading } from '../providers/LoadingProvider';

const Views = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { isAuthenticated, user , login} = useAuth();
  const {setIsLoading} = useLoading();
  const data = {
    email: process.env.REACT_APP_USER_PUBLIC,
    password: process.env.REACT_APP_USER_PASSWORD
  }

  React.useEffect(() => {
    setIsLoading(false)
    if (isAuthenticated && location?.pathname.includes('report') && user.type === 'level_0') {
      navigate(location.pathname);
    }
    if (isAuthenticated ){
      if (location.pathname === '/' || location.pathname === '/login')
        navigate('/orgs');
      else navigate(location.pathname);
    }else {
      if (location?.pathname.includes('report'))
        login(data, location?.pathname, null);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const selectedRoutes = useRoutes(
    isAuthenticated && user ? secureRoutes(user.type, user.org_id, user.orgs_user_level) : routes
  );
  return <div id={'ViewsDiv'} style={{height:'calc(100vh - 66px)'}}>{selectedRoutes}</div>;
};
export default Views;
