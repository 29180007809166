import axiosSurveyInstance from "../axios/axiosSurveyInstance";
import {useQuery} from "react-query";

const fetchParticipationRateBins = async (data) => {
  return axiosSurveyInstance.request({
    url: `/api/survey/analytics/participant-count/${data.which || 'all'}`,
    data: data,
    method: 'post',
    maxBodyLength: Infinity
  }).then(rsp => rsp.data).then((data) => {
    return data?.bins || []
  }).catch(err => {
    console.log("Error fetching participation aggregation bins", err)
    return [];
  })
}

const useSurveyParticipationRateBins = (data) => useQuery(
  ['survey-analytics', data?.service_id, data?.which,data?.granularity,data?.filterQuestionId,data?.filterResponses,data?.startDate,data?.endDate,data?.channels],
  () => fetchParticipationRateBins(data),
  {
    enabled: Boolean(data) && Boolean(data?.service_id),
    refetchOnWindowFocus: false,
    placeholderData: []
  }
);
export default useSurveyParticipationRateBins;


