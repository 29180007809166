import axios from 'axios';

const baseUrl = process.env.SUREVEYS_API_URL || "https://crowdvoice.infrastructure.prosperia.ai";

const axiosSurveyInstance = axios.create({
  baseURL: baseUrl,
});
// axiosRetry(axiosSurveyInstance, {
//   retries: 3,
//   retryDelay: (retryCount) => {
//     return retryCount * 1000;
//   },
//   retryCondition: (error) => {
//     return (
//       axiosRetry.isNetworkError(error) ||
//       axiosRetry.isRetryableError(error) ||
//       error.response?.status >= 500
//     );
//   }
// })
export default axiosSurveyInstance;
