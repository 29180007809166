import {Button, styled} from "@mui/material";
import {darken} from "@mui/material/styles";

export const MuiButton=styled((props)=>(
  <Button
    variant={'outlined'}
    {...props}
  />))(({ theme }) => ({
  height:'38px',
  borderRadius:'19px',
  alignContent:'center',
  '& .MuiButton-startIcon': {
    color: theme.palette.primary.main,
  },
  fontFamily:'Montserrat',
  fontSize:'14px',
  letterSpacing:'0.0025em',
  color: darken(theme.palette.primary.main,0.5),
  border:`1px solid ${darken(theme.palette.primary.main,0.5)}`,
  padding:'10px 24px 10px 16px'
}));
