import axiosSurveyInstance from "../axios/axiosSurveyInstance";
import {useQuery} from "react-query";

const fetchSurveyTranslations = (data) => {
  return axiosSurveyInstance.get(`/api/survey/translation/list?service_id=${data.serviceId}&environment=${data.env}`).then((resp) => {
    const {data} = resp;
    return data?.translations || [];
  }).catch((err) => {
    console.error("Error fetching survey", err);
    return [];
  });
};

const useSurveyTranslations = ({serviceId, env='dev'}) => useQuery(
  ['survey_translations', serviceId, env],
  () => fetchSurveyTranslations({serviceId, env}),
  {
    refetchOnWindowFocus: false,
  }
);

export default useSurveyTranslations;
