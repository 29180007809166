import {useMutation, useQueryClient} from "react-query";
import axiosSurveyInstance from "../axios/axiosSurveyInstance";

const generateQuestionTranslate = (data) => {
  const {
    serviceId,
    sourceLanguage,
    targetLanguages,
    updateAtributes,
    internationalizations
  } = data;

  return axiosSurveyInstance.post(`/api/survey/question/translate`, {
    service_id: serviceId,
    src_language: sourceLanguage,
    target_languages: targetLanguages,
    update_attributes: updateAtributes,
    internationalizations: internationalizations
  }).then(resp => resp.data);
};

const useQuestionTranslate= () => {
  const queryClient = useQueryClient();
  return useMutation((parameters) => generateQuestionTranslate(parameters), {
    onSuccess: (data, parameters) => {
      queryClient.resetQueries(['questions-translate', parameters.serviceId]);
    },
    onError: (error) => error,
    onSettled: (data, error, parameters) => {
      queryClient.invalidateQueries(['questions-translate', parameters.serviceId]);
    },
  });
};
export default useQuestionTranslate;
