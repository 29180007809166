import * as React from 'react';
import {Box, Grid, IconButton, InputAdornment, Typography} from '@mui/material';
import {FormProvider, useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import * as yup from 'yup';
import {NavLink, useLocation} from 'react-router-dom';

import {useTranslation} from 'components/providers/TranslationProvider';
import {useAuth} from 'components/providers/AuthProvider';
import Toast from 'components/common/Toast';

// Style and asset imports
import {
  fieldsForm,
  inputAdornmentStyle,
  langContainer,
  MuiButtonColored,
  MuiDivPaper,
  MuiForm,
  MuiGridContainerButtons,
  MuiTextField,
  MuiTypographyReset,
  paswordForgotSuccess, subTitleStyle,
} from './styles/login';
import LanguageSelector from "../../common/LanguageSelector";
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import {MdOutlinePerson} from 'react-icons/md';

const schema = yup.object().shape({
  email: yup.string().email('email_required').required('required'),
});

const ForgotPassword = () => {
  const { t } = useTranslation();
  const [openToast, setOpenToast] = React.useState(false);
  const [error, setError] = React.useState('');
  const [successfully, setSuccessfully] = React.useState(false);
  const location = useLocation();

  const getDefaultValues = () => {
    return location.state
      ? {
          email: location.state,
        }
      : {};
  };

  const { forgotPassword, isPending } = useAuth();

  const methods = useForm({
    resolver: yupResolver(schema),
    defaultValues: getDefaultValues(),
    mode: 'all',
  });

  const {
    handleSubmit,
    formState: { errors, isValid },
  } = methods;

  const handleCloseToast = () => setOpenToast(false);

  const handleSuccess = () => setSuccessfully(true);

  const handleError = (e) => {
    setError(t(e));
    setOpenToast(true);
  };

  const onSubmit = (data) =>
    forgotPassword({ email: data.email, redirectUrl: '/' }, handleError, handleSuccess);

  return (
    <MuiDivPaper>
      <Toast message={error} handleClose={handleCloseToast} severity='error' open={openToast} />
      <Box sx={{
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems:'center',
        width:'100%',
      }}>
        <LanguageSelector/>
      </Box>
      <Box sx={{
        display:'flex',
        flexDirection:'row',
        alignItems:'center',
        justifyContent:'flex-start',
        width:'100%',
        mt:'100px'
      }}>
        <IconButton
          component={NavLink}
          to='/login'
          sx={{width: '24px', height: '24px', color: '#212121'}}
        > <ArrowBackOutlinedIcon/></IconButton>
      </Box>
      <Box sx={{
        display:'flex',
        flexDirection:'column',
        alignItems:'center',
        justifyContent:'flex-start',
        width:'100%',
        gap:'18px',
        mt:'18px'
      }}>
        <MuiTypographyReset sx={{width:'100%' }}>
          {t('forgot_password')}
        </MuiTypographyReset>
        <Typography sx={{fontSize: '12px', fontFamily: 'Montserrat', fontWeight: 400, lineHeight: '14.63px', letterSpacing: '0.048px'}}>{t('password_forgot_info')}</Typography>
      </Box>

      <Box sx={{width:'100%',mt:'18px'}}>
        <FormProvider {...methods}>
          <MuiForm onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={1} sx={{width: '328px'}}>
              <Grid item xs={12}>
                {successfully && <Typography sx={paswordForgotSuccess}>{t('pasword_forgot_success')}</Typography>}
                <MuiTextField
                  variant='outlined'
                  fullWidth
                  label={t('email')}
                  placeholder="email@example.com"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <MdOutlinePerson style={inputAdornmentStyle}/>
                      </InputAdornment>
                    ),
                  }}
                  name='email'
                  autoComplete='email'
                  error={t(errors.email?.message)}
                  sx={{...fieldsForm, paddingBottom: '0px !important', marginBottom: 0}}
                  disabled={successfully}
                />
              </Grid>
            </Grid>
            <MuiGridContainerButtons container>
              <MuiGridContainerButtons item >
                {!successfully &&
                  <MuiButtonColored
                    loading={isPending}
                    type='submit'
                    disabled={!isValid || isPending || successfully}
                    sx={{ marginTop: '28px !important' }}
                  >
                    {isPending ? t('sending...') : t('send_email')}
                  </MuiButtonColored>}
              </MuiGridContainerButtons>
            </MuiGridContainerButtons>
          </MuiForm>
        </FormProvider>
      </Box>
    </MuiDivPaper>
  );
};

export default ForgotPassword;
