import {Box, Button, Container, Grid, styled, Typography} from '@mui/material';

export const MuiButtonSpacing = styled(Button)(({ theme }) => ({
  marginLeft: theme.spacing(1),
  marginRight: theme.spacing(4),
  justifySelf: 'end',
  borderRadius:'16px',
  fontFamily:'Montserrat',
  fontSize:14,
  color: '#616161',
  borderColor: '#BDBDBD',
  padding:'10px 24px 10px 16px',
  height:'32px'
}));

export const MuiButton = styled(Button)(({ theme }) => ({
  justifySelf: 'end',
  borderRadius:'16px',
  fontFamily:'Montserrat',
  fontSize:14,
  color: '#616161',
  borderColor: '#BDBDBD',
  padding:'10px 24px 10px 16px',
  height:'32px'
}));

export const MuiContainer = styled(Container)(({ theme }) => ({
  marginTop: theme.spacing(0),
  paddingTop: theme.spacing(0.4),
  paddingBottom: theme.spacing(3),
  alignItems: 'left',
  backgroundColor:'#FAFAFA',
  borderRadius:'28px',
  '& .MuiContainer-root': {
    maxWidth: '1360px !important',
  },
  overflowY:'auto',
  overflowX:'hidden',
  maxHeight:'100%',
  boxSizing:'border-box',
  display:'flex',
  flexDirection:'column'
}));

export const MuiDivContainer = styled("div")(({ theme }) => ({
  height: '94vh',
  position: 'relative',
  left: '-60px',
}));

export const MuiGridButtonsContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-start',
  width:'100%'
}));

export const MuiGridTitle = styled(Box)(({ theme }) => ({
  paddingLeft:'0px !important',
  paddingTop:'0px !important',
  display:'flex',
  flexGrow:1
}));

export const MuiGridActions = styled(Grid)(({ theme }) => ({
  paddingRight:'0px',
  minWidth:'625px',
  marginRight:'-2rem',
  justifyContent:'flex-end',
  alignContent:'center'
}));

export const MuiTypographyTitle = styled(Typography)(({ theme }) => ({
  fontSize:21,
  fontFamily:'Raleway',
  textAlign:'left',
  fontWeight:500
}));
