import {
  Autocomplete,
  Box, CircularProgress, Divider, List,
  Pagination,
  TextField,
  Typography, useTheme
} from "@mui/material";
import useSurveyQuestions from "../../../../../api/hooks/useSurveyQuestions";
import React, {useEffect, useMemo, useState} from "react";
import {useTranslation} from "../../../../providers/TranslationProvider";
import useSurveyResponsesByQuestion
  from "../../../../../api/hooks/useSurveyResponsesByQuestion";
import {darken} from "@mui/material/styles";
import ResponseItem from "./ResponseItem";

const ResponsesByQuestion = ({
                               survey,
                               filterQuestion,
                               filterResponses,
                               startDate,
                               endDate,
                               interactionKind = 'all',
                               selectedChannels,
                               autoRefresh
                             }) => {
  const theme = useTheme();
  const memoQuestionsPayload = useMemo(() => ({
    serviceId: survey?.serviceId,
    environment: survey?.environment
  }), [survey]);
  const {
    data: questions,
    isLoading: isLoadingQuestions
  } = useSurveyQuestions(memoQuestionsPayload);

  const pageInterval = 5;
  const [amountPages, setAmountPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalResponses, setTotalResponses] = useState(0);
  const [pageResponses, setPageResponses] = useState([]);
  const [selectedQuestion, setSelectedQuestion] = useState(null);
  const {t, lng} = useTranslation();

  const memoizedPayload = useMemo(() => ({
    service_id: survey?.serviceId,
    environment: survey?.environment,
    pageStart: Math.max((currentPage - 1) * pageInterval, 0),
    pageEnd: currentPage * pageInterval,
    startDate,
    endDate,
    questionId: selectedQuestion?.id,
    filterQuestionId: filterQuestion && filterResponses?.length > 0 ? filterQuestion.id : null,
    filterResponses: filterResponses?.length > 0 ? filterResponses : null,
    targetStatus: interactionKind,
    channels: selectedChannels?.length < 3 && selectedChannels.length > 0 ? selectedChannels : null
  }), [survey, currentPage, filterQuestion, filterResponses, startDate, endDate, selectedQuestion, interactionKind, selectedChannels]);

  const {
    isLoading: isLoadingResponses,
    data,
    refetch
  } = useSurveyResponsesByQuestion(memoizedPayload);

  useEffect(() => {
    if (autoRefresh&&autoRefresh!=='off') {
      refetch();
    }
  }, [autoRefresh]);

  useEffect(() => {
    if (!isLoadingQuestions && questions?.length > 0 && !selectedQuestion) {
      setSelectedQuestion(questions.at(0));
    }
  }, [isLoadingQuestions, questions]);

  useEffect(() => {
    if (!isLoadingResponses && data) {
      const localTotalRsp = data.totalResponses;
      if (localTotalRsp !== totalResponses) {
        console.log("Data changed")
        let pagesLimit = Math.ceil(localTotalRsp / pageInterval);
        setAmountPages(Math.max(1, pagesLimit));
        const localCurrentPage = Math.max(Math.min(currentPage, pagesLimit), 1);
        if (localCurrentPage !== currentPage) {
          setCurrentPage(localCurrentPage);
        }
        setTotalResponses(localTotalRsp);
      }
      setPageResponses(data.responses);
    }
  }, [isLoadingResponses, data]);

  if (isLoadingQuestions || isLoadingResponses || !survey) {
    return <Box
      sx={{
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <CircularProgress size={'100px'}/>
    </Box>
  }
  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      height: '100%',
      borderRadius: '16px',
      backgroundColor: '#FAFAFA',
      boxSizing: 'border-box',
      padding: '24px',
      gap: '12px',
      justifyContent: 'center'
    }}>
      <Typography sx={{
        fontFamily: 'Raleway',
        fontSize: '21px',
        letterSpacing: '0.0015em',
        fontWeight: 500,
        color: '#000000',
        textAlign: 'center'
      }}
      >
        {t('interview_responses_by_question')}
      </Typography>
      <Box sx={{
        display: 'flex',
        width: '100%',
        justifyContent: 'space-between',
        boxSizing: 'border-box',
        alignItems: 'center'
      }}>
        <Box sx={{
          display: 'flex',
          height: '56px',
          flexGrow: 1
        }}>
          {(!isLoadingQuestions) ? (
            <Autocomplete
              options={questions || []}
              value={selectedQuestion}
              onChange={(event, newValue) => {
                setSelectedQuestion(newValue);
                setCurrentPage(1);
              }}
              getOptionLabel={(q) => {
                if (!q) return '';
                const internationalization = q?.internationalization?.find(intl => intl.language?.toLowerCase() === lng.toLowerCase());
                let labelText = internationalization.title;
                if (!labelText) {
                  labelText = q.shortLabel || q.questionId || q.question;
                }
                return labelText;
              }}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              disableClearable
              sx={{width: '100%', height: '56px'}}
              renderInput={(params) => (
                <TextField {...params} label="Segmentation variable"/>
              )}
            />
          ) : (
            <Box sx={{
              display: 'flex'
            }}>
              <CircularProgress size={'56px'}/>
            </Box>
          )}
        </Box>
        <Pagination
          count={amountPages}
          page={currentPage}
          onChange={(e, page) => {
            setCurrentPage(page)
          }}
          sx={{
            '& .MuiPaginationItem-root': {
              fontFamily: 'Montserrat',
              fontSize: '12px',
              color: '#475569',
            }
          }}
        />
      </Box>
      <Divider/>
      <Typography
        sx={{
          fontFamily: 'Montserrat',
          fontSize: '16px',
          letterSpacing: '0.005em',
          color: darken(theme.palette.primary.main, 0.2)
        }}
      >
        {selectedQuestion?.internationalization.find(intl => intl.language.toLowerCase() === lng.toLowerCase())?.text || selectedQuestion?.question || ''}
      </Typography>
      <Box sx={{
        display: 'flex',
        width: '100%',
        height: '100%',
        boxSizing: 'border-box',
        flexDirection: 'column',
      }}>
        <List
          sx={{
            width: '100%',
            height: '100%',
            backgroundColor: '#FAFAFA',
            pt: 0
          }}
        >
          {pageResponses?.map((item, index) => {
            return (
              <ResponseItem key={index} headerText={item.respondent}
                            responseText={item.response}/>
            )
          })}
        </List>
      </Box>
    </Box>
  )
}

export default ResponsesByQuestion;
