import {useSnackbar} from "notistack";
import {useTranslation} from "../../../../providers/TranslationProvider";
import {useDispatch, useSelector} from "react-redux";
import React, {useMemo, useState} from "react";
import {useLoading} from "../../../../providers/LoadingProvider";
import useCreateScheme from "../../../../../api/hooks/useCreateScheme";
import useUpdateScheme from "../../../../../api/hooks/useUpdateScheme";
import {useLocation, useNavigate} from "react-router-dom";
import _ from "lodash";
import axiosEngineInstance from "../../../../../api/axios/axiosEngineInstance";
import enginePaths from "../../../../../api/enginePaths";
import {status200} from "../../../../../api/status.utils";
import {getRandomId} from "../../../../../utils/formatter";
import {generateID, schemeChanged} from "../../../../../utils/utils";
import axios from "../../../../../api/axios/axiosInstance";
import apiPaths from "../../../../../api/apiPaths";
import {
  setTargetSchemeProperty,
  setTargetCollection,
  setTargetScheme
} from "../../../../../store/appSlice";
import {Box, Divider, Grid, Modal, Typography} from "@mui/material";
import SaveIcon from '@mui/icons-material/Save';
import GeneralParameters from "./sections/GeneralParameters";
import SegmentationParameters from "./sections/SegmentationParameters";
import OpenQuestions from "./sections/OpenQuestions";
import {
  MuiBackupIcon,
  MuiBoxSuccess,
  MuiButton,
  MuiCloudDownloadIcon,
  MuiCommonButton,
  MuiDownloadButton,
  MuiInfoReport,
  MuiPictureAsPdfIcon,
  MuiReportText,
  MuiSpaceDashboardOutlinedIcon,
  MuiSubTitle,
  MuiTitle,
  MuiTitleTypography,
  MuiTypography,
  pictureAsPdfOutlinedStyle
} from "./styles/configurationSteps";
import ContextualizationParameters
  from "./sections/ContextualizationParameters";
import ColorPalette from "./sections/ColorPalette";
import GenerateReportModal, {sleep} from "./GenerateReportModal";
import {Download, PictureAsPdfOutlined} from "@mui/icons-material";
import {MuiStartsIcon} from "../../../../common/styles/navigationMenu";
import lz from "lz-string";

const ConfigurationSections = ({scenario, schemes, orgId, isWorkspace}) => {
  const initialValues = {
    dataset: '',
    name: '',
    description: '',
    analysisObjective: '',
    contextDescription: '',
    audienceDescription: '',
    colorPalette: 'material_colors',
    jsonSchemePDF: {}
  };

  const defaultWeight = {
    propName: 'weight',
    label: 'weight',
    category: 'weight',
  }

  const {enqueueSnackbar} = useSnackbar()
  const {t, lng} = useTranslation();
  const dispatch = useDispatch();
  const [scheme, setScheme] = React.useState(initialValues);
  const [selectedCollection, setSelectedCollection] = React.useState(null)
  const [weightFields, setWeightFields] = React.useState([defaultWeight])
  const [variables, setVariables] = React.useState([])
  const [questions, setQuestions] = React.useState([])
  const [schemeCollectionConfig, setSchemeCollectionConfig] = React.useState([])
  const [enableSave, setEnableSave] = React.useState(false)
  const [dataRegion, setDataRegion] = React.useState("US");
  const [currentPaletteKey, setCurrentPaletteKey] = React.useState(scheme?.colorPalette || 'material_colors');

  const {
    setIsLoading,
    setIsClassificationLoading,
    setTitle,
    setShowProgressBar,
    setProgressMessage,
    setProgress
  } = useLoading();
  const targetScheme = useSelector(state => state.app.targetScheme)
  const targetCollection = useSelector(state => state.app.targetCollection)
  const schemeNameChange = false
  const {mutateAsync: createScheme} = useCreateScheme();
  const {mutateAsync: updateScheme} = useUpdateScheme();
  const location = useLocation();
  const isCreating = location.pathname.split('/')[4] === 'create'
  let schemeId = null
  const [disableQuestionsConfig, setDisableQuestionsConfig] = useState(false)
  const [showReportResult, setShowReportResult] = useState(false)
  const errorToast = (msg) => enqueueSnackbar(msg, {variant: 'error'})

  const successToast = (msg) => enqueueSnackbar(msg, {variant: 'success'})


  //methods
  const buildFilters = () => {
    let localCollection = _.cloneDeep(targetCollection)
    setDataRegion(localCollection.region || "US");
    setVariables(localCollection.variables);

    let localWeightFields = localCollection.variables
      .filter((d) => d.category === 'weight')
    setWeightFields(
      localWeightFields.length > 0 ? localWeightFields : [defaultWeight]
    )

    let schemeCollectionConfigLocal = scenario.scheme.schemeCollectionConfig ? _.cloneDeep(scenario.scheme.schemeCollectionConfig) : []

    setSchemeCollectionConfig(schemeCollectionConfigLocal)
  }

  const setLocalTargetScheme = () => {
    setSelectedCollection(scheme.dataset);
    setQuestions(_.cloneDeep(scheme.policies) || []);
    setSchemeCollectionConfig(scheme.schemeCollectionConfig)
  };

  const questionDataSize = async (question) => {
    const payload = {
      surveyId: selectedCollection,
      region: dataRegion || "US",
      questionId: question.answerVar,
      audioVariable: question.audioVar || null,
      weight: scheme.weight || defaultWeight,
      slice: question.amountSamples ? question.amountSamples : 5,
      segmentation: {},
      statistics: {},
      similarity_query: {},
      similarity: {},
      pick_closest: process.env.REACT_APP_PICK_CLOSEST_ANSWER === 'true',
      includeIcons: false,
      topics: question.topics,
      customSegments: question.customSegmentations,
      maxClassificationTopics: question.maxClassificationTopics
    }
    return await axiosEngineInstance.post(enginePaths.aggregate_answers + '?head=True', payload, {
      ...status200,
    }).catch((err) => {
      console.log("Error fetching data", err);
      return err;
    }).then((res) => {
      const data = res.data;
      return data["Content-Length"];
    });

  }

  const fetchContextualizedHints = async (analysisObjective, contextDescription, audienceDescription, surveyId, question, weight) => {
    if (!analysisObjective || !contextDescription || !audienceDescription || !question.longName) return [];

    const payload = {
      originalQuestionText: question.longName || '',
      analysisObjective: analysisObjective || '',
      contextDescription: contextDescription || '',
      stakeholdersDescription: audienceDescription || '',
      surveyId: surveyId,
      questionId: question.answerVar,
      language: lng,
      amount: 4,
      topics: question.topics,
      weight: weight
    };
    return await axiosEngineInstance.post(enginePaths.qa_hints, payload, {
      ...status200
    }).then((resp) => {
      return resp.data?.hints || [];
    });
  }

  const saveTargetScheme = async () => {
    setIsLoading(true)
    setTitle("Saving your dashboard configuration...")
    const {analysisObjective, contextDescription, audienceDescription} = scheme
    const updatedPolicies = await Promise.all(questions.map(async (policy) => {
      const size = await questionDataSize(policy);
      const policyHints = await fetchContextualizedHints(
        analysisObjective,
        contextDescription,
        audienceDescription,
        selectedCollection,
        policy,
        scheme.weight
      );
      const policyCopy = {...policy, size, hints: policyHints};
      if (!policyCopy.classified) {
        policyCopy.classified = false
      }
      return policyCopy;
    }));
    let jsonSchemePDF = JSON.stringify(scheme.jsonSchemePDF || {});
    const compressedJsonSchemePDF = lz.compressToBase64(jsonSchemePDF);
    let localTargetScheme = {
      id: scheme && scheme.id ? scheme.id : getRandomId(),
      name: scheme && scheme.name !== '' ? scheme.name : "scheme_" + getRandomId(),
      analysisObjective: scheme.analysisObjective,
      contextDescription: scheme.contextDescription,
      audienceDescription: scheme.audienceDescription,
      colorPalette: currentPaletteKey,
      description: scheme.description,
      dataset: selectedCollection,
      reportUpdateDate: scheme.reportUpdateDate,
      weight: scheme.weight,
      collection_method: scheme.collection_method || [],
      segmentation_variables: scheme.segmentation_variables || [],
      statistic_variables: scheme.statistic_variables || [],
      policies: updatedPolicies,
      nameChange: schemeNameChange,
      schemeCollectionConfig: schemeCollectionConfig,
      jsonSchemePDF: compressedJsonSchemePDF
    };
    schemeId = localTargetScheme.id
    if (schemes.schemes.some((d) => d.id === localTargetScheme.id)) {

      let _schemeChanged = schemeChanged(localTargetScheme, scenario.scheme);
      if (_schemeChanged) {
        return updateScheme({
          user_id: orgId,
          scheme: localTargetScheme,
        }).then(rsp => {
          setIsLoading(false);
          setTitle('');
          return rsp;
        }).catch(err => {
          setIsLoading(false);
          setTitle('');
          return err;
        });
      } else {
        return await new Promise((resolve) => {
          setIsLoading(false);
          setTitle('')
          resolve(true);
        });
      }
    } else {

      return await createScheme({
        user_id: orgId,
        scheme: localTargetScheme,
      }).then((resp) => {
        setIsLoading(false);
        setTitle('')
        return resp;
      }).catch(err => {
        setIsLoading(false);
        setTitle('');
        return err;
      });
    }
  }


  const handleGoToChart = async () => {
    setIsLoading(true)
    setTitle("Verifying your dashboard configuration...");
    let errors = []
    if (!validateQuestions(questions)) {
      errorToast('Fill all fields. You need to specify an answer column and at least 2 topics.')
      setIsLoading(false);
      return false
    }
    let classifiedQuestions = await handleComputeClassification();

    for (const question of classifiedQuestions) {
      if (!question.classified) {
        errors.push(`Query ${question.name} is not classified`)
      }
    }
    if (errors.length > 0) {
      for (const error of errors) {
        errorToast(error)
      }
      setIsLoading(false);
      return false
    }

    try {
      setTitle("Saving your dashboard configuration...");
      const response = await saveTargetScheme();
      if (response) {
        successToast('Scheme Saved')
        const protocol = window.location.protocol
        const domain = window.location.host
        setIsLoading(false)
        window.location.href = `${protocol}//${domain}/orgs/${isWorkspace ? 'workspace' : orgId}/scenarios/${schemeId}/analysis`;
      }
    } catch (e) {
      errorToast('Scheme name is already be taken')

    }
    setIsLoading(false)
  }

  const processQuestion = async (question) => {
    if (question.classified) return question;
    const inferenceMode = ["llm", "vector"].includes(question.inferenceMode) ? question.inferenceMode : "llm";
    const requestId = generateID();
    question.requestId = requestId;

    const payload = {
      surveyId: scheme.dataset,
      questionId: question.answerVar,
      sourceClassificationVariable: !!question.parentPolicy ? question.parentPolicy.answerVar + "_label" : null,
      parentTopics: !!question.parentPolicy ? question.parentPolicy.topics : null,
      topics: question.topics,
      model: "llm",
      lang: lng,
      requestId: requestId,
      saveClassification: true,
      classification_type: "topics",
      max_topics: 1,
      region: dataRegion,
      updateIcons: true,
      analysisObjective: scheme.analysisObjective || '',
      contextDescription: scheme.contextDescription || '',
      stakeholderDescription: scheme.audienceDescription || '',
      originalQuestionText: question.longName || ''
    };
    axiosEngineInstance.post(enginePaths.classification, payload, status200).catch((error) => {
      console.error(error);
      question.classified = false;
      errorToast(`Error computing classification for Question: ${question.name}`);
    });
    return question
  };

  const pollClassificationTaskProgress = async (requestId, classifiedQuestions, seenProgress, currentProgress, total_progress) => {
    let allCompleted = true;

    const progress_response = await axios.get(`${apiPaths.progress_status}?id=${requestId}`, status200);
    const response_body = progress_response.data;

    if (!response_body || !response_body.data) {
      return false;
    }

    const policy = classifiedQuestions.find(p => p.requestId === requestId);
    const {message, status, progress} = response_body.data;

    if (status !== "success" && status !== "failed" && status !== 'error') {
      allCompleted = false;
    }

    if (status === 'failed' || status === 'error') {
      policy.classified = false;

    } else if (status === 'success') {
      policy.classified = true;
      const representativity = response_body.data.additionalData?.topic_representativity;
      if (representativity >= 0) {
        for (let topic of policy.topics) {
          topic.representativity = representativity[topic.topic];
          if (topic.subtopics && topic.subtopics.length > 0) {
            let overallRep = 0;
            for (let subtopic of topic.subtopics) {
              let stRepresentativity = representativity[subtopic.topic];
              subtopic.representativity = stRepresentativity;
              overallRep += stRepresentativity;
            }
            topic.representativity = overallRep;
          }

        }
      }
    }

    if (!seenProgress.includes(`${requestId}_${message}:${progress}`)) {
      seenProgress.push(`${requestId}_${message}:${progress}`);
      setProgressMessage(`${policy.name}: ${message}`);
      if (progress) {
        let norm_progress = Math.min(progress, 100)
        const updated_progress = ((currentProgress + norm_progress) / total_progress) * 100;
        setProgress(updated_progress);
      }
    }

    return allCompleted;
  };

  const pollUntilCompleted = (requestId, classifiedQuestions, seenProgress, currentProgress, total_progress) => {
    return new Promise(async (resolve) => {
      if (await pollClassificationTaskProgress(requestId, classifiedQuestions, seenProgress, currentProgress, total_progress)) {
        resolve();
      } else {
        setTimeout(() => {
          pollUntilCompleted(requestId, classifiedQuestions, seenProgress, currentProgress, total_progress).then(resolve);
        }, 1200);
      }
    });
  };

  const validateQuestions = (questions) => {
    const missingSomething = questions.some((x) => {
      return (
        x.answerVar === '' || x?.topics?.length < 2
      );
    });

    if (questions.length === 0 || missingSomething) {
      return false
    }
    return !missingSomething;
  }

  const handleComputeClassification = async () => {
    const classifiedQuestions = [...questions];
    let total_progress = classifiedQuestions.map(policy => policy.classified ? 0 : 1).reduce((acc, currentValue) => {
      return acc + currentValue;
    });
    let policyContribution = 0;
    total_progress *= 100;
    setShowProgressBar(true);
    setProgress(0);
    setIsClassificationLoading(true);
    setTitle("Categorizing your data with topics...");
    const seenProgress = [];
    for (let policy of classifiedQuestions) {
      let processed_policy = await processQuestion(policy);
      if (processed_policy.requestId && !processed_policy.classified) {
        await pollUntilCompleted(processed_policy.requestId, classifiedQuestions, seenProgress, policyContribution, total_progress);
        policyContribution += 100;
        if (policy.classified) {
          successToast("Question successfully classified");
        } else {
          errorToast(`Failed to classify '${policy.name}'`)
        }
      }
    }
    setShowProgressBar(false);
    setProgressMessage('');
    setTitle("");
    setIsClassificationLoading(false);
    handleQuestionsChange(classifiedQuestions);
    return classifiedQuestions;
  }


  const handleSaveScheme = async () => {
    setIsLoading(true)
    let errors = []
    // validate policies
    const validQuestions = validateQuestions(questions);
    if (!validQuestions) {
      setIsLoading(false);
      errorToast("Fill all fields. You need to specify an answer column and at least 2 topics.")
      return false
    }
    setIsLoading(false);
    setShowProgressBar(true);
    let classifiedQuestions = await handleComputeClassification();

    setIsLoading(true);
    handleQuestionsChange(classifiedQuestions);
    for (const question of classifiedQuestions) {
      if (!question.classified) {
        console.log(`Question ${question.name} is not classified`)
        errors.push(`Query ${question.name} is not classified`)
      }
    }
    if (errors.length > 0) {
      for (const error of errors) {
        errorToast(error)
      }
      setIsLoading(false)
      return false;
    }


    try {
      const response = await saveTargetScheme();

      if (response) {
        try {
          setIsLoading(false)
          successToast('Scheme Saved')
        } catch (error) {
          console.log(error)
        }
        if (schemeId && location.pathname.split('/')[4] === 'create') {
          const protocol = window.location.protocol
          const domain = window.location.host
          window.location.href = `${protocol}//${domain}/orgs/${isWorkspace ? 'workspace' : orgId}/scenarios/${schemeId}/specification`;
        }
        return true;
      }
    } catch (e) {
      errorToast('Scheme name is already be taken')
      setIsLoading(false);
    }
    setIsLoading(false);
    return false;
  }


  const handleExportScheme = () => {
    let schemeDataToExport = {
      name: scheme && scheme.name !== '' ? scheme.name : "scheme_" + getRandomId(),
      weight: scheme.weight,
      segmentation_variables: scheme.segmentation_variables,
      statistic_variables: scheme.statistic_variables,
      analysisObjective: scheme.analysisObjective || '',
      audienceDescription: scheme.audienceDescription || '',
      contextDescription: scheme.contextDescription || '',
      policies: questions
    }

    const schemeDataToExportJson = JSON.stringify(schemeDataToExport);

    const anchorLinkDonwload = document.createElement('a');
    anchorLinkDonwload.href = 'data:text/json;charset=utf-8,' + encodeURIComponent(schemeDataToExportJson);
    anchorLinkDonwload.download = `${scheme.name}_export.json`;

    anchorLinkDonwload.click();
  }

  const errorMsg = (msg) => {
    enqueueSnackbar(msg, {
      variant: 'error',
      preventDuplicate: true,
    })
  }

  const handleImportScenario = async (ev) => {
    const fr = new FileReader();
    fr.onload = async e => {
      try {
        const schemeData = JSON.parse(e.target.result)
        schemeData['id'] = getRandomId()
        schemeData['dataset'] = scheme.dataset
        schemeData['name'] = scheme.name
        let errors = []

        if (scheme.weight === '') {
          errors.push("Choose weight variable.")
        }

        // validate policies
        schemeData.policies.forEach((x) => {
          x.classified = false;
          x.id = getRandomId()
          if (x.name === '') {
            errors.push('You need fill the name of the policy')
          }
          if (x.answerVar === '') {
            errors.push('You need to specify the answer variable')
          }
          if (x.topics.length === 0) {
            errors.push('You need to specify at least one topic')
            for (const topic of x.topics) {
              if (!topic.topic) {
                errors.push('You need to specify the topic')
              }
              if (!topic.description) {
                topic.description = ''
              }
            }
          }
        });

        if (schemeData.policies.length === 0) {
          errors.push("You need at least one policy")
        }

        if (errors.length > 0) {
          for (const error of errors) {
            errorMsg(error)
          }
        } else {
          if (schemeData.jsonSchemePDF) {
            if (typeof schemeData.jsonSchemePDF === 'string') {
              schemeData.jsonSchemePDF = JSON.parse(schemeData.jsonSchemePDF)
            }
          }
          setScheme(prev => ({...prev, ...schemeData}))
        }

        ev.target.value = null;
      } catch (e) {
        errorMsg('Invalid json file')
      }
    }

    if (ev.target.files.length > 0 && ev.target.files[0]) {
      fr.readAsText(ev.target.files[0]);
    }
  }

  React.useEffect(() => {
    const scenarioScheme = {...scenario.scheme};
    if (typeof scenarioScheme?.jsonSchemePDF === 'string') {
      scenarioScheme.jsonSchemePDF = JSON.parse(scenarioScheme.jsonSchemePDF || '{}')
    }
    setScheme(scenarioScheme)
    dispatch(setTargetCollection(scenario.collection));
    dispatch(setTargetScheme(scenarioScheme));
  }, [scenario.scheme])

  React.useEffect(() => {
    if (targetCollection && Object.keys(targetCollection).length > 0) {
      buildFilters();
    }
  }, [scheme, targetCollection]);

  React.useEffect(() => {
    if (isCreating && targetCollection && Object.keys(targetCollection).length > 0) {
      buildFilters()
    }
  }, [isCreating, targetCollection]);

  React.useEffect(() => {
    if (targetScheme
      && Object.keys(targetScheme).length > 0
      && targetCollection
      && Object.keys(targetCollection).length > 0
    ) {
      setLocalTargetScheme();
      buildFilters();
    }
  }, [targetScheme, targetCollection, scheme]);

  React.useEffect(() => {
    if (scheme && selectedCollection && validateQuestions(questions)) {
      setEnableSave(true);
    } else {
      setEnableSave(false);
    }
  }, [scheme, targetCollection, questions]);

  React.useEffect(() => {
    const validGeneralInfo = scheme && scheme.name && scheme.dataset !== '' && scheme.weight;
    setDisableQuestionsConfig(!validGeneralInfo);
  }, [scheme])

  const handleQuestionsChange = (newQuestions) => {
    setQuestions(newQuestions || []);
    if (scheme) {
      const localScheme = _.cloneDeep(scheme);
      localScheme.policies = newQuestions || [];
      setScheme(localScheme);
    }
  }
  const [open, setOpen] = React.useState(false);
  const query = new URLSearchParams(location.search);

  React.useEffect(() => {
    const createQuery = query.get('generate');
    setOpen(createQuery === 'report');
  }, [query]);

  const handleGenerateReport = () =>
    navigate(`${location.pathname}?generate=report`, {
      replace: true,
    });

  const handleClose = (showResult = false) => {
    query.delete('generate');
    navigate(`${location.pathname}${query && ''}${query}`, {replace: true});
    setShowReportResult(showResult);

  };
  const navigate = useNavigate();
  const goToReport = async () => {
    const urlReport = location.pathname.split('/specification')[0];
    dispatch(setTargetSchemeProperty({
      key: 'jsonSchemePDF',
      value: scheme.jsonSchemePDF
    }));
    setIsLoading(true)
    setTitle("Verifying your dashboard configuration...");
    await sleep(100);
    setTitle("Saving your dashboard configuration...");
    await saveTargetScheme();
    setIsLoading(false)
    window.open(`${urlReport}/analysis/report`, '_blank');
    setShowReportResult(false);
  };

  return (
    <Grid container direction={'row'} spacing={1}
          id={'config-sections-container'} sx={{
            width:'100%',
            maxHeight:'100%'
    }}>
      <Grid item xs={true} sx={{marginTop: '13px'}}>
        <MuiTitle>
          {t('configuration')}
        </MuiTitle>
      </Grid>
      <Grid container item xs={6}
            sx={{marginTop: '20px', minWidth: '702px', flexWrap: 'nowrap'}}
            justifyContent='flex-end'>
        <input
          accept=".json"
          style={{display: 'none'}}
          id="import_scheme_button"
          name="import_scheme_button"
          multiple
          type="file"
          readOnly={!scheme.dataset && scheme.dataset === ''}
          onChange={(e) => handleImportScenario(e)}
        />
        <label htmlFor="import_scheme_button">
          <MuiCommonButton
            variant='outlined'
            color='primary'
            startIcon={<MuiBackupIcon/>}
            disabled={!scheme.dataset && scheme.dataset === ''}
            component="span"
          >
            <MuiTypography>
              {t('btn_import_scheme')}
            </MuiTypography>
          </MuiCommonButton>
        </label>
        <MuiCommonButton
          variant='outlined'
          color='primary'
          style={{marginLeft: 12}}
          data-cy='export_scheme_button'
          disabled={isCreating}
          onClick={() => handleExportScheme()}
          startIcon={<MuiCloudDownloadIcon/>}
        >
          <MuiTypography>
            {t('btn_export_scheme')}
          </MuiTypography>
        </MuiCommonButton>
        <MuiCommonButton
          variant='outlined'
          color='primary'
          style={{marginLeft: 12}}
          data-cy='generate_report_button'
          onClick={handleGenerateReport}
          startIcon={<MuiPictureAsPdfIcon/>}
          disabled={!scheme || !scheme.analysisObjective || !scheme.audienceDescription || !scheme.contextDescription}
        >
          <MuiTypography>
            {t('generate_report')}
          </MuiTypography>
        </MuiCommonButton>
        {(open && scheme && selectedCollection && validateQuestions(questions)) &&
          (<GenerateReportModal action='create' open={open}
                                onClose={handleClose}
                                scheme={scheme} updateScheme={setScheme}
                                collection={targetCollection}/>)}
      </Grid>
      <Grid item md={12} style={{marginTop: '24px'}}>
        <MuiSubTitle>
          {t('general_information')}
        </MuiSubTitle>
        <Grid item container spacing={1} justifyContent="center">
          <Box sx={{flexGrow: 1}} style={{maxWidth: '100%'}}>
            <Grid container spacing={2} direction="column">
              <Grid item>
                <GeneralParameters
                  collections={schemes.collections.filter((c) => {
                    const collectionScheme = schemes.schemes.find(s => s.collection === c.collection);
                    return !collectionScheme || collectionScheme?.id === scheme?.id
                  })}
                  scheme={scheme}
                  weightFields={weightFields}
                  isCreating={isCreating}
                  setScheme={setScheme}
                  orgId={orgId}
                  handleExport={handleExportScheme}/>
              </Grid>
            </Grid>
            <Grid container spacing={2}
                  sx={{
                    marginTop: '20px',
                    opacity: 1,
                    pointerEvents: 'auto',
                    height: 'fit-content'
                  }}
                  direction='column'
            >
              <Grid container item>
                <ContextualizationParameters
                  scheme={scheme}
                  orgId={orgId}
                  setScheme={setScheme}
                />
              </Grid>
            </Grid>
            {(!disableQuestionsConfig || true) && (
              <Grid
                container
                spacing={2}
                sx={{
                  marginTop: '20px',
                  opacity: disableQuestionsConfig ? 0.5 : 1,
                  pointerEvents: disableQuestionsConfig ? 'none' : 'auto',
                  height: 'fit-content'
                }}
                direction='column'
              >
                <Grid container item>
                  <SegmentationParameters
                    scheme={scheme}
                    setScheme={setScheme}
                    categoricalFields={
                      variables.filter((v) => v.category === 'categorical')
                    }
                    statisticFields={
                      variables.filter((v) => v.category === 'statistic')
                    }
                  />
                </Grid>
              </Grid>
            )}
            <Grid container spacing={2}
                  sx={{
                    marginTop: '20px',
                    opacity: 1,
                    pointerEvents: 'auto',
                    height: 'fit-content'
                  }}
                  direction='column'
            >
              <Grid container item>
                <ColorPalette
                  scheme={scheme}
                  currentPaletteKey={currentPaletteKey}
                  setCurrentPaletteKey={setCurrentPaletteKey}
                />
              </Grid>
            </Grid>
            {(!disableQuestionsConfig) && (
              <>
                <Grid
                  container
                  rowGap={'16px'}
                  direction='column'
                  sx={{
                    opacity: disableQuestionsConfig ? 0.5 : 1,
                    pointerEvents: disableQuestionsConfig ? 'none' : 'auto',
                    mt: '34px',
                    maxWidth: '100%'
                  }}
                  id={'survey-questions-container'}
                >
                  <Grid item>
                    <MuiTitleTypography>
                      {t('survey_questions')}
                    </MuiTitleTypography>
                  </Grid>
                  <Grid container item sx={{width: '100%', maxWidth: '100%'}}>
                    <OpenQuestions
                      questions={questions}
                      dataRegion={dataRegion}
                      setQuestions={handleQuestionsChange}
                      variables={variables}
                      dataset={scheme.dataset}
                      weight={scheme.weight}
                      analysisObjective={scheme.analysisObjective}
                      contextDescription={scheme.contextDescription}
                      audienceDescription={scheme.audienceDescription}
                      handleSaveScheme={saveTargetScheme}
                    />
                  </Grid>
                </Grid>
              </>
            )}
          </Box>
        </Grid>
        <Divider sx={{marginTop: '32px', width: '99%'}}/>
        <Grid container item sx={{paddingTop:'25px',paddingBottom:'25px'}}>
          <Box sx={{display:'flex',width:'100%',justifyContent:'flex-end',gap:'16px'}}>
            <MuiButton
              data-cy='btn-goto-chart'
              variant="contained"
              color="secondary"
              type="Submit"
              onClick={(e) => handleSaveScheme(e)}
              startIcon={<SaveIcon sx={{width:'14px', height:'14px'}}/>}
              disabled={!enableSave}
              sx={{width:'141px'}}
            >
              {t('generic_save')}
            </MuiButton>
            <MuiButton
              data-cy='btn-goto-chart'
              variant="contained"
              color="secondary"
              type="Submit"
              sx={{marginLeft: '16px'}}
              disabled={!enableSave}
              startIcon={<MuiSpaceDashboardOutlinedIcon sx={{width:'14px', height:'14px'}}/>}
              onClick={() => handleGoToChart()}>
              {t('go_to_chart')}
            </MuiButton>
          </Box>
        </Grid>
      </Grid>
      {(showReportResult && scheme?.reportUrl) && (<Modal
        open={showReportResult}
        onClose={() => setShowReportResult(false)}
      >
        <MuiBoxSuccess>
          <Grid container sx={{width: '100%', height: '100%'}} direction='row'>
            <Grid container item xs={12} justifyContent={'center'}
                  alignContent={'center'}>
              <PictureAsPdfOutlined
                color={'primary'}
                sx={pictureAsPdfOutlinedStyle}
              />
            </Grid>
            <Grid container item xs={12}>
              <Grid item container xs={2} justifyContent={'flex-end'}>
                <MuiStartsIcon/>
              </Grid>
              <Grid item container xs={8} justifyContent={'center'}>
                <MuiReportText variant='h5'>
                  {t('report_generated')}
                </MuiReportText>
                <MuiInfoReport variant='body2'>
                  {t('info_download_report')}
                </MuiInfoReport>
              </Grid>
              <Grid container item justifyContent={'center'}>
                <MuiDownloadButton
                  onClick={goToReport}
                  variant='outlined'
                  startIcon={<Download sx={{width: '18px', height: '18px'}}/>}
                >
                  <Typography sx={{
                    fontFamily: 'Montserrat',
                    fontSize: '14px',
                    fontWeight: 500,
                  }}
                  >
                    {t('download_report')}
                  </Typography>
                </MuiDownloadButton>
              </Grid>
            </Grid>
          </Grid>
        </MuiBoxSuccess>
      </Modal>)}
    </Grid>
  )
}

export default ConfigurationSections;
