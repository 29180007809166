import * as React from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';

import {useTranslation} from 'components/providers/TranslationProvider';
import {MuiButton} from '../styles/user';
import {usePermissions} from "../../../hooks/usePermissions";

const NewSurvey = () => {
  const { t } = useTranslation();

  const location = useLocation();
  const navigate = useNavigate();
  const {hasPermissionFor}= usePermissions();

  // eslint-disable-next-line react-hooks/exhaustive-deps

  return hasPermissionFor('create_schemes') && (
    <MuiButton
        variant='outlined'
        color='primary'
        startIcon={<AddIcon />}
        sx={{ width: '217px', padding: '10px 24px 10px 16px 8px' }}
        onClick={() => navigate(`${location.pathname}/create/specification`)}
      >
        {t('create_survey')}
    </MuiButton>
  );
};
export default NewSurvey;
