import {useEffect, useState,} from 'react';

import {useTranslation} from 'components/providers/TranslationProvider';
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  IconButton,
  Popover,
  Radio,
  RadioGroup,
} from '@mui/material';

import {
  MuiArrowForwardIcon,
  MuiHeaderTranslateTextPopover,
  MuiLanguageTranslateTextBox,
  MuiSubHeaderTranslateTextPopover,
} from './styles/translateTextPopover'

import {MuiChip} from './styles/generalInformation';

import {MuiButton,} from './styles/translations'

import {Close as CloseIcon,} from "@mui/icons-material";

import spanishFlag from "assets/img/spanish.png";
import englishFlag from "assets/img/english.png";
import frenchFlag from "assets/img/french.png";
import brazilFlag from "assets/img/portuguese-brazil.png";
import arabicFlag from "assets/img/arabic.png";
import SingleLineAutocomplete from 'components/common/SingleLineAutocomplete';


const additionalLanguages = [
  { name: "english", code: "en", flag: englishFlag },
  { name: "spanish", code: "es", flag: spanishFlag },
  { name: "french", code: "fr", flag: frenchFlag },
  { name: "portuguese", code: "pt", flag: brazilFlag },
  { name: "arabic", code: "ar", flag: arabicFlag },
];

const TranslateTextPopover = (props) => {

  const { t } = useTranslation();
  const {
    anchorEl,
    onClose,
    dataToTranslate = '',
    dataKeys=[],
    contentType = 'text',
    setTranslationData,
    onTranslate,
    translationState,
    presetFromLanguage = '',
    presetExcludeToLanguage = [],
    genericMessagesToTranslate = []
  } = props
  const open = Boolean(anchorEl);
  const id = open ? 'translate-text-popover' : undefined;
  const [selectedLanguages, setSelectedLanguages] = useState([]);
  const [selectedLanguageFrom, setSelectedLanguageFrom] = useState(presetFromLanguage);
  const [selectedQuestions, setSelectedQuestions] = useState([]);
  const [selectedGenericMessages, setSelectedGenericMessages] = useState([]);
  const [selectedQuestionsSections, setSelectedQuestionsSections] = useState([]);

  useEffect(() => {
    if (setTranslationData) {
      setTranslationData((prevData) => ({
        ...prevData,
        to: selectedLanguages,
      }));
    }
  }, [selectedLanguages]);

  useEffect(() => {
    if (setTranslationData) {
      setTranslationData((prevData) => ({
        ...prevData,
        from: selectedLanguageFrom,
      }));
    }
  }, [selectedLanguageFrom]);

  useEffect(() => {
    if (presetFromLanguage) {
      setSelectedLanguageFrom(presetFromLanguage);
    }
  }, [presetFromLanguage]);

  const handleChange = (event) => {
    const { value, checked } = event.target;
    setSelectedLanguages((prev) =>
      checked ? [...prev, value] : prev.filter((lang) => lang !== value)
    );
  };

  const handleRadioChange = (event) => {
    setSelectedLanguageFrom(event.target.value);
  };

  const handleQuestionsChange = (event, newValue) => {
    setSelectedQuestions(newValue);

    if (setTranslationData) {
      setTranslationData((prevData) => ({
        ...prevData,
        selectedQuestions: newValue,
      }));
    }
  };

  const handleGenericMessages = (event, newValue) => {
    setSelectedGenericMessages(newValue);

    if (setTranslationData) {
      setTranslationData((prevData) => ({
        ...prevData,
        selectedGenericMessages: newValue,
      }));
    }
  };

  const handleQuestionsSectionsChange = (event, newValue) => {
    setSelectedQuestionsSections(newValue);

    if (setTranslationData) {
      setTranslationData((prevData) => ({
        ...prevData,
        selectedQuestionsSections: newValue,
      }));
    }
  };

  useEffect(() => {
    if (dataKeys.length > 0) {
      setSelectedQuestionsSections(dataKeys);
      if (setTranslationData) {
        setTranslationData((prevData) => ({
          ...prevData,
          selectedQuestionsSections: dataKeys,
        }));
      }
    }
  }, [dataKeys, setTranslationData]);

  return (
    <Popover
      id={id}
      open={open}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      sx={{
        '& .MuiPaper-root': {
          borderRadius: '16px',
        }
      }}
    >
      <Box sx={{ width: '427px', padding: '16px 16px 24px 16px' }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <MuiHeaderTranslateTextPopover>{t('translate_texts')}</MuiHeaderTranslateTextPopover>
          <IconButton size="small" onClick={onClose}>
            <CloseIcon sx={{ width: '14px', height: '14px' }} />
          </IconButton>
        </Box>
        <MuiSubHeaderTranslateTextPopover>{t('all_text_translated')}</MuiSubHeaderTranslateTextPopover>
        {contentType === 'multipleObjects'  && (
          <SingleLineAutocomplete
          id="questions"
          options={dataToTranslate}
          value={selectedQuestions}
          onChange={handleQuestionsChange}
          label="Select Questions" // Opcional: puedes personalizar el label
        />

)}
        {contentType === 'multipleObjects' && genericMessagesToTranslate.length > 0 && (
          <Box sx={{ marginTop: '20px' }}>
            <SingleLineAutocomplete
              id="translations"
              options={genericMessagesToTranslate}
              value={selectedGenericMessages}
              onChange={handleGenericMessages}
              label="Select translations" // Opcional: puedes personalizar el label
            />


          </Box>
        )}
        {contentType !== 'text' && (
          <Box sx={{ marginTop: '20px' }}>
            <SingleLineAutocomplete
              id="questions_texts"
              options={dataKeys
                .filter(option => !selectedQuestionsSections.includes(option))
            }
              value={selectedQuestionsSections}
              onChange={handleQuestionsSectionsChange}
              label={t('select_text')}
              getOptionLabel={(option) => option.label}
              renderOption={(props, option, index) => (
                <li {...props} key={option.name || index}>
                  {option.label}
                </li>
              )}
              renderTags={(value, getTagProps) => {
                return value.map((option, index) => (
                  <MuiChip
                    key={option.name} // Usar option.name como key
                    label={option.label} // Usar option.label como label
                    {...getTagProps({ index })}
                    sx={{
                      maxWidth: "100px",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                      flexShrink: 0,
                    }}
                  />
                ))
              }}
        />
          </Box>

        )}
        <Box sx={{ display: "flex", justifyContent: "space-between", marginTop: '20px', marginBottom: '24px' }}>
          <MuiLanguageTranslateTextBox>
            <FormControl>
              <FormLabel id="survey-language-from">{t('language_from')}</FormLabel>
              <RadioGroup
                aria-labelledby="survey-language-from"
                name="controlled-radio-buttons-group"
                value={selectedLanguageFrom}
                onChange={handleRadioChange}
              >
                {additionalLanguages.map((language) => (
                  <FormControlLabel
                    key={language.name}
                    value={language.name}
                    control={<Radio />}
                    label={
                      <Box display="flex" alignItems="center">
                        <img
                          src={language.flag}
                          alt={`${language.name} flag`}
                          style={{ width: '20px', height: '20px', borderRadius: '250px', marginRight: 8 }}
                        />
                        {language.name}
                      </Box>
                    }
                  />
                ))}
              </RadioGroup>
            </FormControl>
          </MuiLanguageTranslateTextBox>
          <Box sx={{ width: '25px', height: '24px', borderRadius: '12px', padding: '3px', backgroundColor: '#FAFAFA', alignSelf: 'center', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <MuiArrowForwardIcon sx={{ width: '12px', height: '12px' }} />
          </Box>
          <MuiLanguageTranslateTextBox>
            <FormControl component="fieldset">
              <FormLabel component="legend">{t('language_to')}</FormLabel>
              <FormGroup>
                {additionalLanguages.map((language) => (
                  <FormControlLabel
                    key={language.name}
                    control={
                      <Checkbox
                        checked={selectedLanguages.includes(language.name)}
                        onChange={handleChange}
                        value={language.name}
                        disabled={
                          selectedLanguageFrom === language.name ||
                          presetExcludeToLanguage.includes(language.name)
                        }
                      />
                    }
                    label={
                      <Box display="flex" alignItems="center">
                        <img
                          src={language.flag}
                          alt={`${language.name} flag`}
                          style={{ width: "20px", height: "20px", borderRadius: "50%", marginRight: 8 }}
                        />
                        {language.name}
                      </Box>
                    }
                  />
                ))}
              </FormGroup>
            </FormControl>
          </MuiLanguageTranslateTextBox>
        </Box>
        <Box sx={{ display:"flex", justifyContent: "end" }}>
          <MuiButton
            sx={{ borderRadius: '20px', width: '92px', height: '37px', fontWeight: 500, fontSize: '14px', padding: '10px 16px', lineHeight: '17.07px' }}
            onClick={onClose}
          >
            {t('cancel_btn')}
          </MuiButton>
          <MuiButton
          onClick={onTranslate}
            variant="outlined"
            disabled={translationState?.isLoading || (contentType !== 'text' && selectedQuestionsSections.length === 0)}
            sx={{ borderRadius: '20px', width: '133px', height: '37px', fontWeight: 500, fontSize: '14px', padding: '10px 24px', lineHeight: '17.07px' }}
          >
            { translationState?.isLoading ? t('translating') : t('language_translate')}
          </MuiButton>
        </Box>
      </Box>
    </Popover>
  );
};
export default TranslateTextPopover;
