// External library imports
import {IconButton, lighten, styled, Typography} from '@mui/material';

// Internal component and function imports
import {TextField} from 'components/common/ReactHooksFormFields';
import Button from 'components/common/Button';

export const MuiButtonClose = styled(Button)(({ theme }) => ({
  marginRight: theme.spacing(1),
  color:'#005F64',
  borderRadius: '20px',
  fontFamily: 'Montserrat'
}));

export const MuiButtonDelete = styled(Button)(({ theme }) => ({
  padding:'10px 16px 10px 16px',
  borderRadius: '20px',
  width:'105px',
  height:'37px',
  color:'#FF5549',
  borderColor:'#FF5549',
  fontFamily: 'Montserrat',
  '&:hover':{
    borderColor:'#FF5549',
    backgroundColor: lighten('#F63B2B', 0.8),
  }
}));

export const MuiTypographyContent = styled(Typography)(({ theme }) => ({
  textAlign: 'center',
  fontFamily:'Raleway',
  margin: theme.spacing(1.3, 1),
  fontSize: theme.spacing(2),
}));

export const MuiTypographyAssociated = styled(Typography)(({ theme }) => ({
  textAlign: 'center',
  letterSpacing: '0.5px',
  fontFamily: 'Montserrat',
  fontWeight: 400,
  textTransform:'uppercase',
  margin: theme.spacing(2, 1),
  fontSize: theme.spacing(1.5),
}));

export const MuiTypographyName = styled(Typography)(({ theme }) => ({
  textAlign: 'center',
  margin: theme.spacing(1, 2, 1.5, 2),
  fontWeight: 600,
  fontFamily:'Raleway',
  fontSize: theme.spacing(1.8),
}));

export const MuiIconButtonDelete = styled(IconButton)(({ theme }) => ({
  padding: '7px',
  color: '#ff1744',
  backgroundColor: lighten('#ff1744', 0.9),
  '&:hover': {
    backgroundColor: lighten('#ff1744', 0.9),
  },
}));

export const MuiInputDelete = styled(TextField)(({ theme }) => ({
  '& .MuiInputBase-root': {
    height: '56px',
  },
  '& input': {
    fontSize: '16px',
    fontFamily:'Montserrat',
  },
}));
