import {useEffect, useMemo, useRef, useState} from "react";
import useSurveySegmentAggregation
  from "../../../../../api/hooks/useSurveySegmentAggregation";
import {Box} from "@mui/material";
import {pieArcLabelClasses, PieChart} from "@mui/x-charts";
import {generateScaledPalette} from "../../../../../utils/colorPaletteUtils";
import {useSelector} from "react-redux";
import {PieArc} from '@mui/x-charts/PieChart';
import {darken} from "@mui/material/styles";

const SegmentAggregation = ({
                              survey,
                              selectedSegment,
                              changeSelectedCategories,
                              startDate,
                              endDate,
                              interactionKind,
                              selectedChannels,
                              autoRefresh
                            }) => {
  const currentColorSchemeName = useSelector(state => state.app.treeMapColorScheme)
  const [colorPalette, setColorPalette] = useState(generateScaledPalette(currentColorSchemeName, 5));
  const containerRef = useRef(null);
  const [dimensions, setDimensions] = useState({width: 0, height: 0});
  const [highlightedItems, setHighlightedItems] = useState([]);

  const memoPayload = useMemo(() => ({
    service_id: survey?.serviceId,
    environment: survey?.environment,
    segmentQuestionId: selectedSegment?.id || null,
    startDate,
    endDate,
    targetStatus: interactionKind,
    channels: selectedChannels?.length < 3 && selectedChannels.length > 0 ? selectedChannels : null
  }), [survey, selectedSegment, startDate, endDate, interactionKind, selectedChannels]);

  const {
    data: categories,
    isLoading,
    refetch
  } = useSurveySegmentAggregation(memoPayload);

  useEffect(() => {
    if (autoRefresh&&autoRefresh!=='off') {
      refetch();
    }
  }, [autoRefresh])

  useEffect(() => {
    if (!isLoading && categories?.length > 5) {
      setColorPalette(generateScaledPalette('material_colors', categories.length))
    }
  }, [isLoading, categories]);

  useEffect(() => {
    const updateDimensions = () => {
      if (containerRef.current) {
        const {width, height} = containerRef.current.getBoundingClientRect();
        setDimensions({width, height});
      }
    };

    updateDimensions();
    window.addEventListener('resize', updateDimensions);
    return () => window.removeEventListener('resize', updateDimensions);
  }, []);

  // Compute outer radius (half of the smallest dimension)
  const outerRadius = Math.min(dimensions.width - 150, dimensions.height - 150) / 2;
  // Set inner radius to 60% of outer radius
  const innerRadius = outerRadius * 0.6;

  const CustomPieArc = (props) => {
    const {dataIndex: id} = props;
    if (highlightedItems?.length > 0) {
      if (highlightedItems.includes(id)) {

        return <PieArc {...props} style={{
          stroke: darken(colorPalette[id], 0.1),
          strokeWidth: 5
        }}
        />
      } else {
        return <PieArc {...props}
                       color='gray'
                       opacity={0.4}
                       outerRadius={outerRadius - 10}
                       innerRadius={innerRadius + 10}
        />
      }
    }
    // Apply custom styles or behaviors based on data properties


    return <PieArc {...props} isFaded={false} isHighlighted={false}
                   highlightScope={null}/>;
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: '582px',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '16px',
        backgroundColor: '#FAFAFA',
        padding: '24px',
        boxSizing: 'border-box'
      }}
      ref={containerRef}
    >
      <PieChart
        colors={colorPalette}
        slots={{pieArc: CustomPieArc}}
        resolveSizeBeforeRender={true}
        margin={{top: 24, bottom: 24, left: 24, right: 24}}
        slotProps={{
          legend: {
            direction: 'row',
            position: {vertical: 'bottom', horizontal: 'middle'},
            padding: 0,
          },
        }}
        series={
          [
            {
              data: categories,
              arcLabel: (item) => `${item.value.toLocaleString('en-US')}`,
              innerRadius: innerRadius,
              outerRadius: outerRadius,
            }
          ]
        }
        loading={isLoading}
        onItemClick={(event, data) => {
          const {dataIndex: id} = data;
          setHighlightedItems((prevSelected) => prevSelected.includes(id)
            ? prevSelected.filter((segmentId) => segmentId !== id)
            : [...prevSelected, id])

          const selectedData = categories[id];
          const label = selectedData?.label === 'None' ? null : selectedData?.label;
          if (changeSelectedCategories) {
            changeSelectedCategories((prevSelected) => prevSelected.includes(label) ? prevSelected.filter((segmentId) => segmentId !== label) : [...prevSelected, label])
          }
        }}
        sx={{
          [`& .${pieArcLabelClasses.root}`]: {
            fontFamily: 'Raleway',
            fontSize: '25px',
            fill: '#FFFFFF'
          },
        }}

      />
    </Box>
  )
}

export default SegmentAggregation;
