import React from "react";
import {Box, Typography} from "@mui/material";
import crow_icon from "assets/img/crow-logo.png";
import crow_logo from "assets/img/survey_img.png";
import whatsapp_chat from "assets/img/whatsapp_chat.png";
import phone_frame from "assets/img/phone_frame.png";
import {MuiImage} from "../../../common/styles/orgInfo";
import ChatBubble from "./ChatBubble";

const MessageVisualizator = ({ message, littleMessage='', options = [],  view= 'chat', image= null, selectedOption, survey}) => {
  const isChat = view === 'chat';
  const textImage = selectedOption === 'Closing Message' || selectedOption === 'Confirmation Card' ? image || crow_logo : null;
  const iconChat = survey ? survey.profileImageUrl !== '' ? survey.profileImageUrl : crow_icon : crow_icon ;

  return (
    <Box sx={{ width: isChat ? 226 : 212, height: isChat ? 401 : 524, position: 'relative'}}>
      {isChat ? (
        <>
          <MuiImage src={whatsapp_chat} alt={'chat'} sx={{width: '226px' , height: '401px'}}/>
          <Typography
            sx={{
              fontFamily: 'Montserrat',
              fontSize: '9px',
              fontWeight: 600,
              color: '#212121',
              position: 'relative',
              top: '-400px',
              left: '65px'
            }}>
            CROW-INTERVIEW
          </Typography>
        </>
      ) : (
        <>
          <MuiImage src={phone_frame} alt={'phone'} sx={{ height: '524px'}}/>
          <MuiImage src={iconChat} alt={'logo'} sx={{ml: '11px' , height: '22px', position: 'relative', top: '-490px', left: '30px'}}/>
          <Typography
            sx={{
              fontFamily: 'Montserrat',
              fontSize: '9px',
              fontWeight: 600,
              color: '#212121',
              position: 'relative',
              top: '-513px',
              left: '70px'
            }}>
            {survey?.name}
          </Typography>
        </>
      )}
      <ChatBubble options={options} message={message} view={isChat} littleMessage={littleMessage} image={textImage}/>
    </Box>
  );
};

export default MessageVisualizator;
