import React from "react";
import { TextField } from "@mui/material";
import { DndContext, closestCorners } from "@dnd-kit/core";
import { SortableContext, useSortable, arrayMove } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { MuiDraggableAutocomplete } from "components/common/styles/draggableAutocomplete.js";
import { MuiChip } from "components/views/organizations/scenarios/specification/sections/styles/segmentationParameters.js";
import { labelInputStyle } from "components/views/organizations/scenarios/specification/sections/styles/generalParameters.js";
import { DragHandle as DragHandleIcon } from "@mui/icons-material";

const SortableChip = ({ item, getItemKey, getItemLabel, onRemove }) => {
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id: getItemKey(item) });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    cursor: "grab",
  };

  return (
    <MuiChip
      ref={setNodeRef}
      {...attributes}
      avatar={
        <DragHandleIcon sx={{ fontSize: 18 }} {...listeners} />
      }
      label={
        <span {...listeners} style={{ display: "inline-block" }}>
          {getItemLabel(item)}
        </span>
      }
      onDelete={(e) => {
        e.stopPropagation();
        onRemove(item);
      }}
      style={style}
      sx={{
        "& .MuiChip-avatar": { width: 18, height: 18 },
        margin: "3px",
      }}
    />
  );
};

const DraggableAutocomplete = ({
  options = [],
  selectedItems = [],
  setSelectedItems,
  label = "",
  onOrderChange,
  id,
  getItemKey = (item) => (typeof item === "object" ? item.propName : item),
  getItemLabel = (item) => (typeof item === "object" ? item.label : item),
}) => {
  const handleDragEnd = (event) => {
    const { active, over } = event;
    if (!over || active.id === over.id) return;

    const oldIndex = selectedItems.findIndex((item) => getItemKey(item) === active.id);
    const newIndex = selectedItems.findIndex((item) => getItemKey(item) === over.id);
    if (oldIndex === -1 || newIndex === -1) return;

    const newItems = arrayMove(selectedItems, oldIndex, newIndex);
    setSelectedItems(newItems);
    if (typeof onOrderChange === "function") {
      onOrderChange(newItems, oldIndex, newIndex);
    }
  };

  return (
    <DndContext collisionDetection={closestCorners} onDragEnd={handleDragEnd}>
      <MuiDraggableAutocomplete
        id={id}
        disableCloseOnSelect
        multiple
        options={options.filter((opt) => !selectedItems.some((item) => getItemKey(item) === getItemKey(opt)))}
        getOptionLabel={getItemLabel}
        isOptionEqualToValue={(option, value) => getItemKey(option) === getItemKey(value)}
        value={selectedItems}
        onChange={(_, newValue) => setSelectedItems(newValue)}
        variant="outlined"
        filterSelectedOptions
        renderTags={(tagValue, getTagProps) => (
          <SortableContext items={tagValue.map(getItemKey)}>
            {tagValue.map((option, index) => (
              <SortableChip
                key={`autocomplete-chip-${getItemKey(option)}-${index}`}
                item={option}
                getItemKey={getItemKey}
                getItemLabel={getItemLabel}
                onRemove={(val) => setSelectedItems( selectedItems.filter((item) => getItemKey(item) !== getItemKey(val)))}
                {...getTagProps({ index })}
              />
            ))}
          </SortableContext>
        )}
        renderInput={(params) => <TextField {...params} label={label} InputLabelProps={{ style: labelInputStyle }} />}
      />
    </DndContext>
  );
};

export default DraggableAutocomplete;
