import {useMutation, useQueryClient} from 'react-query';
import axiosSurveyInstance from 'api/axios/axiosSurveyInstance';

const updateTranslationMessage = (data) => {
    const {
        translationData,
        serviceId,
        environment,
        translationKey,
    } = data;
    
    return axiosSurveyInstance.post(`/api/survey/translation/${translationKey}/update`, {
        service_id: serviceId,
        environment,
        translation_data: translationData,
    }).then(resp => resp.data);
};

const useSurveyUpdateTranslationMessage = () => {
  const queryClient = useQueryClient();
  return useMutation((parameters) => updateTranslationMessage(parameters), {
    onSuccess: (data, parameters) => {
      queryClient.resetQueries(['survey_translations', parameters.serviceId, parameters.environment]);
    },
    onError: (error) => error,
    onSettled: (data, error, parameters) => {
      queryClient.invalidateQueries(['survey_translations', parameters.serviceId, parameters.environment]);
    },
  });
};
export default useSurveyUpdateTranslationMessage;
