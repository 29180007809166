import {Button, lighten, styled, Typography,} from '@mui/material';

export const MuiButton = styled(Button)(({ theme }) => ({
    fontFamily: 'Montserrat',
    fontWeight: 500,
    fontSize: '10px',
    lineHeight: '12.19px',
    color: lighten(theme.palette.primary.main, 0.1),
    borderRadius:'16px',
    borderColor: lighten(theme.palette.primary.main, 0.2),
}));


export const MuiSubTitle = styled(Typography)(() => ({
    fontFamily: 'Raleway',
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '18.78px',
    letterSpacing: '0.024px',
    color: '#616161',
    textTransform: 'uppercase',
}));
