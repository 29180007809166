import {
  Autocomplete,
  Chip,
  IconButton,
  styled,
  Typography
} from "@mui/material";
import * as React from "react";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import {darken, lighten} from "@mui/material/styles";

export const MuiChip = styled(Chip)(({theme}) => {
  // Lighten and darken the primary color for background and border
  const lightenedColor = lighten(theme.palette.primary.main, 0.875);
  const textColor = darken(theme.palette.primary.main, 0.5);
  return {
    backgroundColor: lightenedColor,
    color: textColor,
    fontSize: '14px',
    fontFamily: 'Montserrat',
    fontWeight: 400,
    maxWidth: '100%',
    overflow: 'visible',
    borderRadius: '8px',
    border: `1px solid transparent`,
    height: '30px',
    '& .MuiChip-deleteIcon': {
      color: textColor
    }
  };
});

export const MuiAutocomplete = styled(
  (props) => (
    <Autocomplete
      {...props}
      multiple
      disableCloseOnSelect
      variant="outlined"
      getOptionLabel={(option) => option.label || option.propName}
      isOptionEqualToValue={(option, value) => option.propName === value.propName}
      filterSelectedOptions
      renderTags={(value, getTagProps) => {
        return value.map((option, index) => (
          <MuiChip
            {...getTagProps({index})}
            key={`autocomplete-chip-${option.propName}-${index}`}
            label={option.label || option.propName}/>
        ));
      }}
    />
  )
)(({theme}) => {
  let closeIconColor = darken(theme.palette.primary.main, 0.5);
  return {
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#325D6C', // Custom border color
        borderRadius: '4px 4px 0 0',
      },
      '&:hover fieldset': {
        borderColor: '#143440', // Border color on hover
      },
      '&.Mui-focused fieldset': {
        borderColor: '#254957', // Border color when focused
      },
    },
    '& .MuiInputBase-root': {
      width: '100%',
      borderRadius: '4px 4px 0 0',
    },
    '& .MuiChip-deleteIcon': {
      color: closeIconColor,
      backgroundColor: 'transparent',
      width: '18px',
      height: '18px',
    },
  }
});

export const MuiSegmentTitle = styled(Typography)(({theme}) => ({
  height: '25px',
  fontFamily: 'Raleway',
  fontSize: '21px',
  fontStyle: 'normal',
  fontWeight: '500',
  lineHeight: '25px',
  letterSpacing: '0.0015em',
  color: '#616161'
}));

export const MuiSegmentCaption = styled(Typography)(({theme}) => ({
  height: '15px',
  fontFamily: 'Montserrat',
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: '12px',
  lineHeight: '15px',
  textAlign: 'left',
  letterSpacing: '0.004em',
  color: '#616161',
}));


export const MuiInfoIconButton = styled(IconButton)(({theme}) => ({
  width: '16px',
  height: '16px',
}));

export const MuiInfoOutlinedIcon = styled(InfoOutlinedIcon)(({theme}) => ({
  width: '16px',
  height: '16px',
  color: '#9E9E9E',
}));
