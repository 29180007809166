/* eslint-disable no-unused-vars */
/* eslint-disable prefer-object-spread */
/* eslint-disable one-var */
/* eslint-disable prefer-const */
/* eslint-disable no-plusplus */
/* eslint-disable no-use-before-define */
/* eslint-disable no-underscore-dangle */
/* eslint-disable func-names */
/* eslint-disable prefer-destructuring */
/* eslint-disable prefer-template */
/* eslint-disable no-shadow */
/* eslint-disable no-var */
/* eslint-disable react/self-closing-comp */
/* eslint-disable no-param-reassign */
import {selection, select, pointer} from 'd3-selection';
import {scaleLinear, scaleBand} from 'd3-scale';
import {max, range} from 'd3-array';
import {axisBottom, axisLeft} from 'd3-axis';
import {useEffect, useRef, forwardRef} from 'react';
import {brushX} from 'd3-brush';
import tippy from 'tippy.js';
import {format} from 'd3-format';
import {useTheme} from "../../../providers/CustomThemeProvider";

const d3 = {
  selection,
  select,
  scaleLinear,
  scaleBand,
  max,
  axisBottom,
  axisLeft,
  format,
};

function Histogram(params) {
  const attrs = Object.assign(
    {
      id: Math.floor(Math.random() * 100000),
      width: 300,
      height: 300,
      margin: {top: 10, right: 10, bottom: 20, left: 10},
      container: 'body',
      option: {},
      onEvents: {},
      color: '#0b3ccd',
      xFormat: ".1f"
    },
    params
  );

  const numberFormat = d3.format(',');

  let container,
    chartWidth,
    chartHeight,
    svg,
    chart,
    xScale,
    yScale,
    brush,
    fgBars,
    barWidth,
    brushSel,
    bars,
    selectedBands = params.selectedBars;

  function main() {
    container = d3.select(attrs.container);

    initPattern();
    setDimensions();

    drawContainers();
    setupScales();
    drawAxes();
    drawBars();

    if (selectedBands && selectedBands.length) {
      const startBand = selectedBands[0];
      const endBand = selectedBands[selectedBands.length - 1];
      brushSel.call(
        brush.move,
        [startBand, endBand].map((d, i) => {
          const x = xScale(d);
          if (i) return x + barWidth;
          return x;
        })
      );
    }
  }

  function mousemove(event) {
    const [x] = pointer(event);

    fgBars.each(function (d, i) {
      const start = xScale(i);
      const end = start + barWidth;
      const check = x >= start && x <= end;
      d3.select(this).attr('fill', check ? 'rgba(0, 0, 0, 0.2)' : 'transparent');

      if (check) {
        this._tippy.show();
      } else {
        this._tippy.hide();
      }
    });
  }

  function mouseout() {
    fgBars.each(function (d, i) {
      this._tippy.hide();
    });
  }

  function brushended(event) {
    if (!event.sourceEvent || !event.selection) return;
    if (event.sourceEvent && !event.sourceEvent.type) return;

    let [x1, x2] = event.selection.slice().sort((a, b) => a - b);

    const gap = xScale.step() * xScale.paddingInner();

    if (Math.abs(x2 - x1) > 2) {
      let startBand = xScale.domain().find((d) => {
        const start = xScale(d) - gap;
        const end = xScale(d) + barWidth + gap;
        return x1 >= start && x1 <= end;
      });

      let endBand = xScale.domain().find((d) => {
        const start = xScale(d) - gap;
        const end = xScale(d) + barWidth + gap;
        return x2 >= start && x2 <= end;
      });

      if (startBand !== undefined && endBand !== undefined) {
        if (startBand !== endBand) {
          const startX = xScale(startBand);
          const endX = xScale(endBand);

          if (x1 > startX + barWidth / 2) {
            // drag less than 50% of barWidth
            if (x2 >= endX + barWidth / 2) {
              startBand++;
            }
          }
          if (x2 < endX + barWidth / 2) {
            // drag more than 50% of barWidth
            endBand--;
          }
        }

        d3.select(this)
          .transition()
          .call(
            brush.move,
            [startBand, endBand].map((d, i) => {
              const x = xScale(d);
              if (i) return x + barWidth;
              return x;
            })
          );

        const bands = range(startBand, endBand + 1, 1);
        brushSelected(bands);
      }
    }
  }

  function brushSelected(bands) {
    selectedBands = bands;
    attrs.onEvents.brushSelected({bars: bands});
    bars.attr('fill', colorize);
  }

  function clearBrush() {
    brushSel.call(brush.clear);
    selectedBands = null;
    bars.attr('fill', colorize);
  }

  function setupScales() {
    const {series} = attrs.option;
    const yDomain = max(series[0].data, (d) => d.value);

    xScale = scaleBand()
      .domain(series[0].data.map((d, i) => i))
      .range([0, chartWidth])
      .paddingInner(0.05);

    barWidth = xScale.bandwidth();
    yScale = scaleLinear().domain([0, yDomain]).range([chartHeight, 0]);
  }

  function colorize(_, i) {
    const active = selectedBands && selectedBands.length > 0 ? selectedBands.indexOf(i) > -1 : true;

    if (!active) {
      return '#E1E5EC';
    }

    if (attrs.color && attrs.color.enabled) {
      return `url(#grad-${attrs.id}-${i})`;
    }

    return attrs.color;
  }

  function drawBars() {
    const {series} = attrs.option;
    const cornerRadius = 5;

    const roundedRectPath = (x, y, width, height, cornerRadius) => {
      const effectiveRadius = height > cornerRadius ? cornerRadius : 0;
      return `M${x + effectiveRadius},${y}
            H${x + width - effectiveRadius}
            A${effectiveRadius},${effectiveRadius} 0 0 1 ${x + width},${y + effectiveRadius}
            V${y + height}
            H${x}
            V${y + effectiveRadius}
            A${effectiveRadius},${effectiveRadius} 0 0 1 ${x + effectiveRadius},${y}`;
    };

    bars = chart
      .patternify({
        tag: 'path',
        selector: 'bar',
        data: series[0].data,
      })
      .attr('d', (d, i) => roundedRectPath(xScale(i), chartHeight, barWidth, 0, 0))
      .attr('fill', colorize)
      .attr('d', (d, i) => roundedRectPath(xScale(i), yScale(d.value), barWidth, chartHeight - yScale(d.value), cornerRadius));
    // .transition()
    // .delay((d, i) => i * 100)
    // .duration(1000)

    fgBars = chart
      .patternify({
        tag: 'rect',
        selector: 'bar-foreground',
        data: series[0].data,
      })
      .attr('x', (d, i) => xScale(i))
      .attr('y', (d) => yScale(d.value))
      .attr('height', (d) => chartHeight - yScale(d.value))
      .attr('width', xScale.bandwidth())
      .attr('fill', 'transparent');

    fgBars.each(function (d) {
      if (this._tippy) {
        if (typeof chart.destroy === 'function') {
          this._tippy.destroy();
        }

      }

      tippy(this, {
        content: numberFormat(d.value),
      });
    });
  }

  function drawAxes() {
    const {
      xAxis: {data},
    } = attrs.option;

    const values = [];
    let maxLength=0;
    data.map((d, i) => {
      if (i === 0) {
        values.push(data[0] - (data[1] - data[0]));
        values.push(d);

      } else {
        values.push(d);
      }
      if(`${d}`.length>maxLength){
        maxLength=`${d}`.length
      }
      return true;
    });

    const shouldRotate=values.some(v=>`${v}`.length>3)


    console.log("Mx lenght",maxLength)
    const xScale = scaleLinear()
      .domain([values[0], values[values.length - 1]])
      .range([0, chartWidth])

    const xAxis = axisBottom(xScale)
      .tickValues(values)
      .tickFormat(d => d3.format(attrs.xFormat)(d))
      .tickSizeInner([-chartHeight]);

    chart
      .patternify({
        tag: 'g',
        selector: 'x-axis',
      })
      .classed('axis', true)
      .attr('transform', `translate(0, ${chartHeight})`)
      .call(xAxis)
      .attr('transform', `translate(0,${chartHeight})`)
      .attr('text-anchor', 'flex-end')
      .call((g) => {
        g.selectAll('.domain').remove();
        g.selectAll('text')
          .attr('transform', function () {
            if (shouldRotate) {
              // Here we shift the text downward by maxLength*1.5 plus an extra offset (e.g., 10)
              // then rotate it -45 degrees so that it doesn't get clipped.
              return `translate(${-attrs.margin.left-10}, ${maxLength * 1.5 + 10}) rotate(-45)`;
            } else {
              return `translate(${-attrs.margin.left}, 0)`;
            }
          }).attr("font-family","Montserrat")
          .attr("font-size", "10px");
        g.selectAll(".tick line")
          .attr("stroke", "none")
          .attr("stroke-dasharray", "2")
          .attr('stroke-opacity', 0.2);
      });
  }

  function drawContainers() {
    const {width, height, margin, color} = attrs;
    brush = brushX()
      .extent([
        [0, 0],
        [chartWidth, chartHeight],
      ])
      .on('end', brushended);

    svg = container
      .patternify({
        tag: 'svg',
        selector: 'chart-svg',
      })
      .attr('width', width + 5)
      .attr('height', height+50);

    chart = svg
      .patternify({
        tag: 'g',
        selector: 'chart',
      })
      .attr('transform', `translate(${margin.left}, ${margin.right})`);
    if (attrs.filterable) {
      brushSel = svg
        .patternify({
          tag: 'g',
          selector: 'brush',
        })
        .attr('transform', `translate(${margin.left}, ${margin.right})`)
        .call(brush);

      brushSel.on('mousemove', mousemove).on('mouseout', mouseout);

      brushSel
        .selectAll('.handle')
        .attr('fill', '#fff')
        .attr('stroke', color)//'#1181fb'
        .attr('stroke-width', 1)
        .attr('rx', 3)
        .attr('ry', 3);

      brushSel.selectAll('.selection').attr('fill', '#ddd');
    }

    if (attrs.color && attrs.color.type === 'by_value' && attrs.color.customColors) {
      const defs = svg.patternify({
        tag: 'defs',
        selector: 'defs',
      });

      const grad = defs
        .patternify({
          tag: 'linearGradient',
          selector: 'gradient',
          data: attrs.color.customColors,
        })
        .attr('id', (d) => `grad-${attrs.id}-${d.bucket}`)
        .attr('x1', '0%')
        .attr('x2', '100%')
        .attr('y1', '0%')
        .attr('y2', '100%');

      grad
        .patternify({
          tag: 'stop',
          selector: 'stop',
          data: (d) => d.stops,
        })
        .attr('offset', (d) => d.p + '%')
        .attr('stop-color', (d) => d.c);
    }
  }

  function setDimensions() {
    const {width} = container.node().getBoundingClientRect();

    if (width) {
      attrs.width = width;
    }

    const {top, right, bottom, left} = attrs.margin;

    chartWidth = attrs.width - left - right;
    chartHeight = attrs.height - top - bottom;
  }

  function initPattern() {
    d3.selection.prototype.patternify = function (params) {
      let container = this;
      let selector = params.selector;
      let elementTag = params.tag;
      let data = params.data || [selector];

      // Pattern in action
      let selection = container.selectAll('.' + selector).data(data, (d, i) => {
        if (typeof d === 'object') {
          if (d.id) {
            return d.id;
          }
        }
        return i;
      });
      selection.exit().remove();
      selection = selection.enter().append(elementTag).merge(selection);
      selection.attr('class', selector);
      return selection;
    };
  }

  main.draw = () => {
    main();
    return main;
  };

  main.redraw = ({option, onEvents, height, id, color, selectedBars}) => {
    attrs.option = option;
    attrs.onEvents = onEvents;
    attrs.height = height;
    attrs.id = id;
    attrs.color = color;
    selectedBands = selectedBars;

    main();
    return main;
  };

  main.clear = () => {
    clearBrush();
  };

  return main;
}

const HistogramChart = forwardRef((props, ref) => {
  const contRef = useRef(null);
  const chartRef = useRef(null);
  const {
    option,
    height,
    onEvents,
    color,
    id,
    selectedBars,
    filterable,
    xFormat
  } = props;

  useEffect(
    () => {
      if (contRef.current) {
        const obj = {
          option,
          height,
          onEvents,
          color,
          id,
          selectedBars,
          filterable,
          xFormat
        };

        if (chartRef.current) {
          chartRef.current.redraw(obj);
        } else {
          const chart = Histogram({
            container: contRef.current,
            ...obj,
          }).draw();

          chartRef.current = chart;

          if (ref) {
            ref.current = chart;
          }
        }
      }
    },
    // eslint-disable-next-line
    [id, color, height, onEvents, option, ref]
  );

  return <div ref={contRef}></div>;
});

export default HistogramChart;
