import {useQuery} from "react-query";
import axiosSurveyInstance from "../axios/axiosSurveyInstance";

const fetchQuestionResponses = async (data) => {
  return axiosSurveyInstance.request({
    url: `/api/survey/analytics/responses/${data.questionId}`,
    data: data,
    method: 'post',
    maxBodyLength: Infinity
  }).then(rsp => rsp.data).then((dta) => {
    return dta?.at(0) || {
      responses: [],
      totalResponses: 0,
      question_text: '',
      question_id: data.questionId
    }
  }).catch(err => {
    console.log("Error fetching survey responses by question", err)
    return {
      responses: [],
      totalResponses: 0,
      question_text: '',
      question_id: data.questionId
    };
  })
};

const useSurveyResponsesByQuestion = (data) => useQuery(
  ['survey-responses-by-question', data?.service_id, data?.environment, data?.questionId, data?.pageStart, data?.pageEnd, data?.startDate, data?.endDate, data?.filterQuestionId, data?.filterResponses, data?.targetStatus, data?.channels],
  () => fetchQuestionResponses(data),
  {
    enabled: Boolean(data?.service_id && data?.environment && data?.questionId),
    refetchOnWindowFocus: false,
    placeholderData: {
      responses: [],
      totalResponses: 0,
      question_text: '',
      question_id: data.questionId
    }
  }
);

export default useSurveyResponsesByQuestion;
