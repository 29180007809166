import * as React from 'react';
import {Outlet, useLocation} from 'react-router-dom';
import useOrganization from 'api/hooks/useOrganization';
import logo from '../../../../assets/img/logo.0c7a85ba.png';
import {useTheme} from 'components/providers/CustomThemeProvider';
import {
  MuiContainerNavBar,
  MuiGridContainer,
  MuiGridImgContainer,
  MuiImage,
  MuiTitle,
} from './styles/organization';
import {Box, Grid} from "@mui/material";


const Organization = () => {
  const location = useLocation();
  const orgId = location.pathname.split('/')[2];
  const {data, isLoading} = useOrganization(orgId);
  const {setColors} = useTheme();
  const isSpecificationView = location.pathname.split('/').includes('specification') || location.pathname.split('/').includes('analytics')

  React.useEffect(() => {
    if (data) setColors(data.attrs.primary_color, data.attrs.secondary_color);
  }, [data]);

  return (
    <MuiContainerNavBar id={'OrgContainer'} sx={{
      paddingTop:'20px',
      paddingBottom:'20px',
      overflow:'hidden'
    }}>
      <MuiGridContainer container id={'org-content-grid-container'}>
        {!isSpecificationView && (
          <MuiGridImgContainer item xs={3}>
            <MuiTitle>{data?.name}</MuiTitle>
            {isLoading ? (
              <>
                <MuiImage src={logo} alt={'logo'}/>
              </>
            ) : (
              <>
                <MuiImage src={data?.attrs.profile_img_url} alt={'logo'}/>
              </>
            )}
          </MuiGridImgContainer>
        )}
        <Grid container item xs={isSpecificationView ? 12 : 9} id={'org-content-container'} sx={{height:'100%',maxHeight:'calc(100vh - 100px)',boxSizing:'border-box'}}>
          <Box sx={{
            height:'100%',
            maxHeight:'100%',
            boxSizing:'border-box',
            display:'flex',
            overflowY:isSpecificationView ? 'auto' : 'hidden',
          }}>
            <Outlet/>
          </Box>
        </Grid>
      </MuiGridContainer>
    </MuiContainerNavBar>
  );
};

export default Organization;
