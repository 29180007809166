import axiosSurveyInstance from "../axios/axiosSurveyInstance";
import {useMutation, useQueryClient} from "react-query";
import {status200} from "../status.utils";

const createSurvey = ({
                        survey_name,
                        region_name = "US",
                        organization_id,
                        main_language = "english",
                        additional_languages = ["english", "spanish", "french", "portuguese", "arabic"],
                        environment = "prod",
                        profileImageUrl	 = ''
                      }) => {

  return axiosSurveyInstance.post('api/survey/create', {
    survey_name,
    region_name,
    organization_id,
    main_language,
    additional_languages,
    environment,
    profileImageUrl
  }, status200).then((resp) => {
    const {data} = resp;
    return data?.survey_definition || null;
  })
}

export const useCreateSurvey = () => {
  const queryClient = useQueryClient();
  return useMutation(async (values) => await createSurvey(values), {
    onSuccess: (data) => {
      queryClient.resetQueries('surveys');
      queryClient.resetQueries('survey');
      return data;
    },
    onError: (error) => {
      console.error(error);
      return error;
    },
    onSettled: (data) => {
      queryClient.invalidateQueries('surveys');
      queryClient.invalidateQueries('survey');
      return data;
    },
  })
};
