import {
  Autocomplete,
  Box,
  CircularProgress,
  IconButton,
  MenuItem,
  TextField
} from "@mui/material";
import React, {useState} from "react";
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import useSurveyQuestions from "../../../../../api/hooks/useSurveyQuestions";
import {useTranslation} from "../../../../providers/TranslationProvider";
import SearchIcon from "@mui/icons-material/Search";
import DateRangePicker from "./DateRangePicker";
import AnimatedToggleButton from "./AnimatedToggleButton";

dayjs.extend(utc);

const FilterControls = ({
                          detailLevel,
                          setDetailLevel,
                          startDate = null,
                          endDate = null,
                          segmentationVariable = null,
                          survey,
                          updateFilters,
                          autoRefresh,
                          setAutoRefresh
                        }) => {
  const [sDate, setSDate] = useState(startDate);
  const [eDate, setEDate] = useState(endDate);
  const [segment, setSegment] = useState(segmentationVariable);
  const {data: questions, isLoading} = useSurveyQuestions(survey)
  const {t, lng} = useTranslation();

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Box sx={{
        width: '100%',
        display: 'flex',
        height: '93px',
        borderRadius: '16px',
        mb: '3px',
        boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.1), 0px 1px 3px 1px rgba(0, 0, 0, 0.1)',
        alignItems: 'center',
        padding: '16px',
        gap: '16px'
        // justifyContent:'space-between'
      }}>
        {(!survey) && (
          <Box sx={{
            width: '100%',
            height: '100%',
            justifyContent: 'center',
            alignItems: 'center'
          }}>
            <CircularProgress size={'50px'}/>
          </Box>
        )}
        {(!!survey) && (
          <>
            <TextField
              select
              value={detailLevel}
              variant={'outlined'}
              label={'Detail level'}
              onChange={(evt) => {
                setDetailLevel(evt.target.value);
              }}
              sx={{
                width: '272px',
                height: '56px',
                '& .MuiInputBase-input': {
                  fontFamily: 'Montserrat',
                  fontSize: '16px',
                  color: '#143440'
                },
                '& .MuiInputLabel-root': {
                  fontFamily: 'Montserrat',
                  fontSize: '12px',
                  color: '#254957'
                },
              }}
            >
              <MenuItem
                sx={{
                  fontFamily: 'Montserrat',
                  fontSize: '16px',
                  color: '#143440'
                }}
                value={'year-month-day'}
              >
                {t('date_detail_level')}
              </MenuItem>
              <MenuItem
                sx={{
                  fontFamily: 'Montserrat',
                  fontSize: '16px',
                  color: '#143440'
                }}
                value={'year-month-day-hour'}
              >
                {t('hour_detail_level')}
              </MenuItem>
              <MenuItem
                sx={{
                  fontFamily: 'Montserrat',
                  fontSize: '16px',
                  color: '#143440'
                }}
                value={'year-month-day-hour-minute'}
              >
                {t('minute_detail_level')}
              </MenuItem>
            </TextField>
            <DateRangePicker sDate={sDate} setSDate={setSDate} eDate={eDate}
                             setEDate={setEDate}/>
            <Box sx={{
              display: 'flex',
              height: '56px',
              flexGrow: 1
            }}>
              {(!isLoading) ? (
                <Autocomplete
                  options={questions?.filter(q => q.type === "CHOICE") || []}
                  value={segment}
                  onChange={(event, newValue) => setSegment(newValue)}
                  getOptionLabel={(q) => {
                    if (!q) return '';
                    const internationalization = q?.internationalization?.find(intl => intl.language?.toLowerCase() === lng.toLowerCase());
                    let labelText = internationalization.title;
                    if (!labelText) {
                      labelText = q.shortLabel || q.questionId || q.question;
                    }
                    return labelText;
                  }}
                  isOptionEqualToValue={(option, value) => option.id === value.id}
                  clearOnEscape
                  sx={{width: '100%', height: '56px'}}
                  renderInput={(params) => (
                    <TextField {...params} label="Segmentation variable"/>
                  )}
                />
              ) : (
                <Box sx={{
                  display: 'flex'
                }}>
                  <CircularProgress size={'56px'}/>
                </Box>
              )}
            </Box>
            <IconButton sx={{
              width: '44px',
              height: '44px',
              backgroundColor: 'primary.main',
              '&:hover': {
                backgroundColor: 'primary.main'
              }
            }}
                        onClick={() => updateFilters(sDate, eDate, segment)}
            >
              <SearchIcon sx={{color: '#FFFFFF'}}/>
            </IconButton>
            <AnimatedToggleButton autoRefresh={autoRefresh} setAutoRefresh={setAutoRefresh}/>
          </>
        )}
      </Box>

    </LocalizationProvider>

  )
}

export default FilterControls;
