import {useMutation, useQueryClient} from "react-query";
import axiosSurveyInstance from "../axios/axiosSurveyInstance";

const startSurveyService = async (serviceId, environment) => {
  if (!serviceId || !environment) {
    throw new Error("Error starting survey service, no service_id or environment provided");
  }
  return axiosSurveyInstance.post(`/api/srv/start`, {
    service_id: serviceId,
    environment
  }).then((resp) => resp.data);
}

const useStartSurveyService = (data) => {
  const queryClient = useQueryClient();
  return useMutation(async ({
                              serviceId,
                              environment
                            }) => {
    console.log("Starting, service id: ", serviceId, "environment: ", environment);
    return startSurveyService(serviceId, environment)
  }, {
    onSuccess: (data) => data,
    onError: (error) => {
      console.log(error);
      return error;
    },
    onSettled: () => {
      queryClient.resetQueries('survey');
      queryClient.resetQueries('surveyStatus');
    }
  })
}
/**
 * Usage example:
 * const {
 *      mutateAsync: startSurveyService,
 *      isError: startSurveyServiceError,
 *      error: startSurveyServiceError
 * }=useStartSurveyService();
 *
 * //Turn's on the server for the WhatsApp and WebChat API.
 * const data={
 *      service_id: "cvc-ai-voices-clone",
 *      environment:"dev",
 *      type:"wa" //can be either "wa" for WhatsApp and WebChat or "tg" for Telegram
 * }
 *
 * resp=await startSurveyService(data);
 *
 * console.log(resp?.data);
 * >>>{status:"success",message:"The service has started successfully."}
 *
 * If the service does not exist,it will result in a bad request error indicating
 * that the service could not be found.
 */
export default useStartSurveyService;
