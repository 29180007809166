import axiosSurveyInstance from "../axios/axiosSurveyInstance";
import {useQuery} from "react-query";

const fetchSurveyTemplateStatus = (data) => {
  return axiosSurveyInstance.get(`/api/survey/question/${data.questionId}/template-status/${data.language}?service_id=${data.serviceId}&environment=${data.env}`)
    .then((resp) => {
      return resp?.data;
    })
    .catch((err) => {
      throw new Error(err.response?.data?.message);
    });
};

const useSurveyTemplateStatus = ({serviceId, env='dev', language, questionId}) => useQuery(
  ['survey_template_status', serviceId, env, language, questionId],
  () => fetchSurveyTemplateStatus({serviceId, env, language, questionId}),
  {
    refetchOnWindowFocus: false,
    enabled: Boolean(questionId) && Boolean(language),
    retry: false,
    onError: (error) => { console.error("Error fetching survey_template_status:", error.message);  },
  }
);

export default useSurveyTemplateStatus;
