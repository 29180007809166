import React from "react";
import {Autocomplete, TextField, Chip, Box} from "@mui/material";
import {
  MuiChip
} from 'components/views/organizations/surveys/styles/generalInformation';

const SingleLineAutocomplete = ({
                                  id,
                                  options,
                                  value,
                                  onChange,
                                  label = "Select Options",
                                  ...otherProps
                                }) => {
  return (
    <Autocomplete
      id={id}
      options={options}
      getOptionLabel={(option) => option.shortLabel}
      disableCloseOnSelect

      multiple
      value={value}
      onChange={onChange}
      renderOption={(props, option) => (
        <li {...props} key={option.id}>
          {option.shortLabel}
        </li>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          label={label} // Usar el label proporcionado
          autoComplete="off"
        />
      )}
      renderTags={(value, getTagProps) => {
        return value.map((option, index) => (
          <MuiChip
            key={option.id}
            label={option.shortLabel}
            {...getTagProps({index})}
            sx={{
              maxWidth: "100px",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              flexShrink: 0,
            }}
          />
        ))
      }}
      sx={{
        width: "395px",
        // height: "56px",
        "& .MuiOutlinedInput-root": {
          display: "flex",
          alignItems: "center",
          // height: "56px",
          // overflow: "hidden",
          // flexWrap: "nowrap",
          position: "relative",
        },
        "& .MuiAutocomplete-tagList": {
          display: "flex",
          // flexWrap: "nowrap",
          // overflow: "hidden",
          // maxWidth: "calc(100% - 50px)",
        },
        "& .MuiAutocomplete-endAdornment": {
          position: "absolute",
          right: 0,
          display: "flex",
          alignItems: "center",
          backgroundColor: "white",
          zIndex: 3,
          padding: "0",
          width: "50px",
        },
        "& .MuiAutocomplete-clearIndicator, & .MuiAutocomplete-popupIndicator": {
          margin: 0,
          minWidth: "16px",
        },
      }}
      {...otherProps} // Permitir pasar otras props al Autocomplete
    />
  );
};
export default SingleLineAutocomplete;
