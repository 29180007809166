import useSurveyParticipationRateBins
  from "../../../../../api/hooks/useSurveyParticipationRateBins";
import {Box, Typography, useTheme} from "@mui/material";
import {useEffect, useMemo, useRef, useState} from "react";
import {BarChart, barLabelClasses} from "@mui/x-charts/BarChart";
import {axisClasses} from '@mui/x-charts/ChartsAxis';
import {useTranslation} from "../../../../providers/TranslationProvider";

const InteractionsChart = ({
                             survey,
                             aggregationLevel = 'year-month-day',
                             interactionKind = 'all',
                             segment = null,
                             startDate,
                             endDate,
                             selectedSegmentAnswers = null,
                             isDetailedView,
                             selectedChannels,
                             autoRefresh
                           }) => {
  const theme = useTheme();

  const memoPayload = useMemo(() => ({
    service_id: survey?.serviceId,
    environment: survey?.environment,
    granularity: aggregationLevel,
    filterQuestionId: segment?.id && selectedSegmentAnswers?.length > 0 ? segment.id : null,
    filterResponses: selectedSegmentAnswers?.length > 0 ? selectedSegmentAnswers : null,
    which: interactionKind,
    startDate,
    endDate,
    limit: 50,
    channels: selectedChannels?.length < 3 && selectedChannels.length > 0 ? selectedChannels : null
  }), [survey, aggregationLevel, segment, selectedSegmentAnswers, interactionKind, startDate, endDate, selectedChannels]);

  const {
    data: bins,
    isLoading,
    refetch
  } = useSurveyParticipationRateBins(memoPayload);
  const {t} = useTranslation();
  const containerRef = useRef(null);
  const [dimensions, setDimensions] = useState({width: 0, height: 0});

  useEffect(() => {
    if (autoRefresh&&autoRefresh!=='off') {
      refetch();
    }
  }, [autoRefresh]);

  const readableAggregationType = () => {
    switch (aggregationLevel) {
      case 'year-month-day':
        return t('interactions_by_day');
      case 'year-month-day-hour':
        return t('interactions_by_hour');
      case 'year-month-day-hour-minute':
        return t('interactions_by_minute');
      default:
        return t('interactions_by_day');
    }
  }

  function formatDate(input) {
    // Split the string into date and time parts (if available)
    const [datePart, timePart] = input.split(" ");

    // Split the date part into year, month, and day
    const [_, month, day] = datePart.split("-");

    // Start with the "day/month" format
    let formattedDate = `${day}/${month}`;

    // If a time part exists, simply append it
    if (timePart) {
      formattedDate += ` ${timePart}`;
    }

    return formattedDate;
  }

  useEffect(() => {
    const updateDimensions = () => {
      if (containerRef.current) {
        const {width, height} = containerRef.current.getBoundingClientRect();
        setDimensions({width, height});
      }
    };

    updateDimensions();
    window.addEventListener('resize', updateDimensions);
    return () => window.removeEventListener('resize', updateDimensions);
  }, [selectedSegmentAnswers]);

  const chartSetting = useMemo(() => ({
    xAxis: [{
      scaleType: 'band',
      dataKey: 'date',
      tickPlacement: 'middle',
      valueFormatter: formatDate,
      tickLabelStyle: {
        angle: -45,
        textAnchor: 'end',
        fontSize: isDetailedView ? 10 : 14,
        fontFamily: 'Montserrat'
      },
    }],
    yAxis: [
      {
        label: 'Number of Interactions',
        labelStyle: {
          fontSize: 14,
          fontFamily: 'Montserrat'
        },
        tickLabelStyle: {
          fontSize: 14,
          fontFamily: 'Montserrat',
        }
      }
    ],
    series: [
      {
        dataKey: 'count',
        label: 'date',
        valueFormatter: (v) => v?.toFixed(0)
      }
    ],
    sx: {
      [`& .${axisClasses.directionY} .${axisClasses.label}`]: {
        transform: 'translateX(-10px)',
        fontSize: '14px',
        fontFamily: 'Montserrat',
        color: '#212121'
      },
      [`& .${axisClasses.directionY} .${axisClasses.tickLabel}`]: {
        fontFamily: 'Montserrat',
        fontSize: '14px',
        color: '#212121'
      },
      [`& .${axisClasses.directionX} .${axisClasses.tickLabel}`]: {
        fontFamily: 'Montserrat',
        fontSize: '14px',
        color: '#212121'
      },
      [`& .${barLabelClasses.root}`]: {
        fill: '#FFFFFF',
        fontFamily: 'Montserrat',
        fontSize: '14px'
      }
    },
    colors: [theme.palette.primary.main],
    borderRadius: 4,
    barLabel: "value",
    margin: {
      top: 30,
      bottom: 80,
      left: isDetailedView || selectedSegmentAnswers ? 60 : 10,
      right: 0
    },
    height: dimensions.height - 40,
    width: dimensions.width - 48
  }), [isDetailedView, dimensions, selectedSegmentAnswers])

  if (!isDetailedView) {
    console.log("Dimensions:", dimensions);
  }
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: '100%',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: isDetailedView ? 0 : '16px',
        backgroundColor: isDetailedView ? '#FFFFFF' : '#FAFAFA',
        padding: isDetailedView ? 0 : '24px',
        boxSizing: 'border-box',
        gap: '1px'
      }}
      ref={containerRef}
    >
      <Typography
        sx={{
          fontFamily: 'Montserrat',
          fontSize: '14px',
          fontWeight: 500,
          color: '#212121',
          letterSpacing: '0.0024em'
        }}
      >
        {readableAggregationType()}
      </Typography>
      <BarChart
        loading={isLoading}
        dataset={bins}
        {...chartSetting}
        slotProps={{legend: {hidden: true}}}
      />
    </Box>
  );
};

export default InteractionsChart;

