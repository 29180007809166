import * as _ from "lodash";
import {
  fetchCollection,
  useCollection
} from "../../../../../../api/hooks/useCollection";
import {useDispatch} from "react-redux";
import {useTranslation} from "../../../../../providers/TranslationProvider";
import {setTargetCollection} from "../../../../../../store/appSlice";
import React, {useState} from "react";
import {FormControl, Grid, MenuItem, Popover} from "@mui/material";
import {
  MuiInfoIconButton,
  MuiInfoOutlinedIcon
} from "./styles/segmentationParameters";
import {
  closeStyle,
  MuiBoxPopover,
  MuiGridField,
  MuiGridPopover,
  MuiIconButton,
  MuiInfoTypography
} from "../styles/configurationSteps";
import CloseIcon from "@mui/icons-material/HighlightOffOutlined";
import {
  inputStyle,
  MuiTextField
} from "./styles/generalParameters";
import {useSnackbar} from "notistack";

const GeneralParameters = ({
                             collections,
                             scheme,
                             setScheme,
                             weightFields,
                             isCreating,
                             orgId,
                           }) => {


  const [openPopover, setOpenPopover] = useState(null);
  const [infoText, setInfoText] = useState('');
  const {enqueueSnackbar} = useSnackbar();
  const {t} = useTranslation()
  const warningToast = (msg) => enqueueSnackbar(msg, {variant: 'warning'})
  const {data: collection} = useCollection({
    user_id: orgId,
    collection: scheme.dataset,
    enabled: scheme.dataset !== '' && isCreating,
  })
  const dispatch = useDispatch()

  const handleChange = async (event) => {
    if (event.target.name === 'dataset') {
      const localCollection = await fetchCollection({
        user_id: orgId,
        collection: event.target.value
      })
      if (localCollection) {
        dispatch(setTargetCollection(localCollection))
      }
      const localScheme = {...scheme}
      if (!!scheme.segmentation_variables) {
        const validSegmentationVariables = scheme.segmentation_variables?.filter((sVar) => {
          return localCollection.variables.some((v) => v.propName === sVar);
        })
        if (validSegmentationVariables?.length !== scheme.segmentation_variables?.length || !_.isEqual(validSegmentationVariables, scheme.segmentation_variables)) {
          warningToast("The selected dataset does not contain all segmentation variables.");
          localScheme.segmentation_variables = validSegmentationVariables;
        }
      }
      if (!!scheme.statistic_variables) {
        const validStatisticVariables = scheme.statistic_variables?.filter((sVar) => {
          return localCollection.variables.some((v) => v.propName === sVar);
        })
        if (validStatisticVariables?.length !== scheme.statistic_variables?.length || !_.isEqual(validStatisticVariables, scheme.statistic_variables)) {
          warningToast("The selected dataset does not contain all statistic variables.");
          localScheme.statistic_variables = validStatisticVariables;
        }
      }
      if (!!scheme.policies) {
        const validPolicies = scheme.policies?.filter((p) => {
          return localCollection.variables.some((v) => v.propName === p.answerVar) && (!p.audioVar || localCollection.variables.some((v) => v.propName === p.audioVar));
        }).map(p => ({
          ...p,
          classified: false,
          topics: p.topics.map(t => ({...t, representativity: -1}))
        }));

        if (validPolicies?.length !== scheme.policies?.length) {
          warningToast("The selected dataset does not contain all the referenced variables in the questions.");
        }
        localScheme.policies = validPolicies;
      }
      localScheme[event.target.name] = event.target.value;
      setScheme(localScheme);
    } else {
      setScheme(prevState => ({
        ...prevState,
        [event.target.name]: event.target.value
      }));
    }

  };

  const closePopover = () => setOpenPopover(null);

  const showDatasetFieldInfo = (event) => {
    setInfoText(t('dataset_info'));
    setOpenPopover(event.currentTarget);
  }

  const showWeightFieldInfo = (event) => {
    setInfoText(t('weight_info'));
    setOpenPopover(event.currentTarget);
  }

  React.useEffect(() => {
    if (collection) {
      dispatch(setTargetCollection(collection))
    }
  }, [collection])

  return (
    <Grid container spacing={2} noValidate style={{marginTop: '12px'}}>
      <Grid item xs={6}>
        <Grid item sx={{pr: '25px', maxWidth: '100%'}}>
          <FormControl fullWidth variant="outlined">
            <MuiTextField
              fullWidth
              id="analysis_name"
              label={t('analysis_name')}
              name="name"
              value={scheme.name}
              onChange={async (e) => await handleChange(e)}
              inputProps={{maxLength: 80}}
              InputLabelProps={{
                sx: { fontFamily: 'Montserrat' }
              }}
              sx={inputStyle}
            />
          </FormControl>
        </Grid>
        <MuiGridField item>
          <FormControl fullWidth variant="outlined">
            <MuiTextField
              id="weight_selection"
              label="Weight"
              name="weight"
              InputLabelProps={{
                sx: { fontFamily: 'Montserrat' }
              }}
              sx={{
                mr: '8px'
              }}
              placeholder='How many people an answer represents?'
              select
              onChange={async (e) => await handleChange(e)}
              value={scheme.weight ? scheme.weight : ""}>
              {weightFields?.map((weight) =>
                <MenuItem key={weight.propName}
                          value={weight.propName}>{weight.label ? weight.label : weight.propName}</MenuItem>
              )}
            </MuiTextField>
          </FormControl>
          <MuiInfoIconButton onClick={showWeightFieldInfo}>
            <MuiInfoOutlinedIcon/>
          </MuiInfoIconButton>
        </MuiGridField>
      </Grid>
      <Grid item xs={6}>
        <MuiGridField item style={{marginTop: '0px',}}>
          <FormControl fullWidth variant="outlined">
            <MuiTextField
              id="dataset_selection"
              name="dataset"
              label="Dataset"
              InputLabelProps={{
                sx: { fontFamily: 'Montserrat' }
              }}
              select
              onChange={async (e) => await handleChange(e)}
              value={scheme.dataset}
              sx={{
                mr: '8px'
              }}
            >
              {collections?.map(d =>
                <MenuItem key={d.collection}
                          value={d.collection}>{d.name}</MenuItem>
              )}
            </MuiTextField>
          </FormControl>
          <MuiInfoIconButton onClick={showDatasetFieldInfo}>
            <MuiInfoOutlinedIcon/>
          </MuiInfoIconButton>
        </MuiGridField>
      </Grid>
      <Popover
        open={Boolean(openPopover)}
        anchorEl={openPopover}
        onClose={closePopover}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <MuiBoxPopover>
          <Grid container direction='row'>
            <MuiGridPopover>
              <MuiInfoTypography variant="caption">
                {infoText}
              </MuiInfoTypography>
            </MuiGridPopover>
            <Grid item>
              <MuiIconButton aria-label="close" onClick={closePopover}>
                <CloseIcon sx={closeStyle}/>
              </MuiIconButton>
            </Grid>
          </Grid>
        </MuiBoxPopover>
      </Popover>
    </Grid>
  );
}

export default GeneralParameters;
