import * as React from 'react';

import axios from '../../api/axios/axiosInstance';
import axiosEngineInstance from '../../api/axios/axiosEngineInstance';
import axiosSurveyInstance from "../../api/axios/axiosSurveyInstance";
import {useAuth} from '../providers/AuthProvider';


const TokenInterceptor = ({
                            children,
                            accessToken: getAccessToken,
                            engineToken: getEngineToken
                          }) => {
  const {logout, isAuthenticated} = useAuth();
  const [interceptorsSet, setInterceptorsSet] = React.useState(false);

  React.useEffect(() => {
    const addResponseInterceptor = (instance) =>
      instance.interceptors.response.use(
        (response) => {
          return response;
        },
        (error) => {
          const shouldRefreshToken = isAuthenticated && error.response?.status === 401;

          if (shouldRefreshToken) {
            return logout();
          }

          return Promise.reject(error.response || error);
        }
      );

    const errorInterceptor = addResponseInterceptor(axios);

    return () => {
      axios.interceptors.response.eject(errorInterceptor);
    };
  }, [isAuthenticated, logout]);

  React.useEffect(() => {
    const addRequestInterceptor = (axiosInstance) =>
      axiosInstance.interceptors.request.use(
        (config) => {
          return {
            ...config,
            headers: {
              ...config.headers,
              Authorization: `Bearer ${getAccessToken}`,
            },
          };
        },
        (error) => Promise.reject(error)
      );

    const authInterceptor = addRequestInterceptor(axios);
    const engineAuthInterceptor = axiosEngineInstance.interceptors.request.use(
      (config) => {
        return {
          ...config,
          headers: {
            ...config.headers,
            Authorization: `Bearer ${getEngineToken()}`,
          },
        };
      },
      (error) => Promise.reject(error)
    )
    const surveyAuthInterceptor = axiosSurveyInstance.interceptors.request.use(
      (config) => {
        return {
          ...config,
          headers: {
            ...config.headers,
            Authorization: `Bearer ${getEngineToken()}`,
          },
        };
      },
      (error) => Promise.reject(error)
    )
    setInterceptorsSet(true);
    return () => {
      axios.interceptors.request.eject(authInterceptor);
      axiosEngineInstance.interceptors.request.eject(engineAuthInterceptor)
      axiosSurveyInstance.interceptors.request.eject(surveyAuthInterceptor)
    };
  }, [getAccessToken, getEngineToken]);

  return interceptorsSet ? children : null;
};

export default TokenInterceptor;
