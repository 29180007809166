import {createTheme} from '@mui/material/styles';

const themeCrowdView = createTheme({
  palette: {
    warning: {
      main: '#FF9800', // Customize the warning color (e.g., orange)
      light: '#FFB74D',
      dark: '#F57C00',
    },
    success: {
      main: '#4CAF50',
      light: '#81C784',
      dark: '#388E3C'
    },
    error: {
      main: '#F44336', // Customize the error color (e.g., red)
      light: '#E57373',
      dark: '#D32F2F',
    }
  },
});
export default themeCrowdView
