import {Box, lighten, styled, Typography} from "@mui/material";
import {darken} from "@mui/material/styles";
import FilterIcon from "@mui/icons-material/FilterList";

export const StatBox = styled((props) =>
  <Box {...props}/>, {shouldForwardProp: (prop) => prop !== 'selected'})(({
                                                                            theme,
                                                                            selected
                                                                          }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  borderRadius: '16px',
  backgroundColor: selected ? lighten(theme.palette.primary.main, 0.8) : '#F5F5F5',
  width: {
    xl: '184px',
    lg: '184px',
    md: '184px',
    sm: '184px',
    xs: '184px'
  },        // Allow it to be responsive
  height: '108px',
  padding: '13px 16px'
}));

export const StatTitle = styled(Typography, {shouldForwardProp: (prop) => prop !== 'selected'})(({
                                                                                                   theme,
                                                                                                   selected
                                                                                                 }) => ({
  fontFamily: 'Montserrat',
  fontSize: '8px',
  letterSpacing: '0.015em',
  color: selected ? darken(theme.palette.primary.main, 0.3) : '#616161',
  textTransform: 'uppercase'
}));

export const StatValue = styled(Typography, {shouldForwardProp: (prop) => prop !== 'selected'})(({
                                                                                                   theme,
                                                                                                   selected
                                                                                                 }) => ({
  fontFamily: 'Raleway',
  fontSize: '35px',
  fontWeight: 400,
  letterSpacing: '0.0025em',
  color: selected ? darken(theme.palette.primary.main, 0.5) : '#424242'
}));

export const FilterableIndicator = styled(FilterIcon, {shouldForwardProp: (prop) => prop !== 'selected'})(({
                                                                                                             theme,
                                                                                                             selected
                                                                                                           }) => ({
  color: selected ? darken(theme.palette.primary.main, 0.5) : '#616161',
  width: '16px',
  height: '16px'
}))

export const ChannelStatChip = styled(Box, {shouldForwardProp: (prop) => prop !== 'selected'})(({
                                                                                                  theme,
                                                                                                  selected
                                                                                                }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: '5px',
  minWidth: '35px',
  height: '17px',
  borderRadius: '9px',
  backgroundColor: 'transparent',
  border: `1px solid ${selected ? darken(theme.palette.primary.main, 0.3) : 'transparent'}`,
  '&:hover': {
    backgroundColor: lighten(theme.palette.primary.main, 0.6)
  },
  padding: '1px 6px 1px 3px',
  cursor: 'pointer'
}))

export const ChannelStatContainer = styled(Box)(({
                                                   theme
                                                 }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: '10px'
}))
