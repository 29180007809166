import {useMutation, useQueryClient} from 'react-query';
import axiosSurveyInstance from 'api/axios/axiosSurveyInstance';

const generateTranslation = (data) => {
    const {
        translationKey,
        serviceId,
        sourceLanguage,
        targetLanguages,
        questionSections,
        environment,
    } = data;
    
    return axiosSurveyInstance.post(`/api/survey/translation/${translationKey}/auto-generate`, {
        service_id: serviceId,
        environment,
        src_language: sourceLanguage,
        target_languages: targetLanguages,
        update_attributes: questionSections
    }).then(resp => resp.data);
};

const useSurveyGenerateTranslationMessage = () => {
  const queryClient = useQueryClient();
  return useMutation((parameters) => generateTranslation(parameters), {
    onSuccess: (data, parameters) => {
      queryClient.resetQueries(['survey_translations', parameters.serviceId, parameters.environment]);
    },
    onError: (error) => error,
    onSettled: (data, error, parameters) => {
      queryClient.invalidateQueries(['survey_translations', parameters.serviceId, parameters.environment]);
    },
  });
};
export default useSurveyGenerateTranslationMessage;
