import React, {useState} from 'react';
import {
  Box,
  Button,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography
} from '@mui/material';
import Modal from "../../../../common/Modal";
import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck';
import SubjectIcon from '@mui/icons-material/Subject';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import ImageIcon from '@mui/icons-material/Image';
import WavingHandIcon from '@mui/icons-material/WavingHand';
import {lighten} from "@mui/material/styles";
import {useTheme} from "../../../../providers/CustomThemeProvider";
import MessageVisualizator from "../MessageVisualizator";
import {useLocation, useNavigate} from "react-router-dom";
import GenericModalMessage from "../GenericModalMessage";

const Messages = ({
                    open,
                    handleClose,
                    showModalGeneric,
                    nodeSelected,
                    setNodeSelected,
                    survey,
                    questions,
                    listLanguagesInterview,
                    refrechDataGraph
                  }) => {
  const [optionsSelected, setOptionsSelected] = useState([]);
  const [selectedOption, setSelectedOption] = useState("Multiple-Choice");
  const [message, setMessage] = useState('');
  const [littleMessage, setLittleMessage] = useState('');
  const [openGenericModal, setOpenGenericModal] = React.useState(showModalGeneric);
  const type =  questions.find(item => item.id === nodeSelected?.sourceId)?.type;
  const isOpenEnded = type === 'OPEN';

  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const {theme} = useTheme();
  const navigate = useNavigate();
  const options = [
    {option: "Multiple-Choice", icon: <PlaylistAddCheckIcon/>},
    {option: "Open-Ended", icon: <SubjectIcon/>},
    {option: "Follow-Up", icon: <AutoAwesomeIcon/>},
    {option: "Greeting Message", icon: <ImageIcon/>},
    {option: "Closing Message", icon: <WavingHandIcon/>}
  ];

  function handleOption(option) {
    setSelectedOption(option);
    if (option === 'Multiple-Choice') {
      setOptionsSelected(['Yes a lot', 'Yes a bit', 'No, nothing']);
      setMessage('Do you know or have you heard about artificial intelligence (AI), such as ChatGPT or image recognition?');
      setLittleMessage('');
    } else if (option === 'Open-Ended') {
      setOptionsSelected([]);
      setMessage('Can you tell us about a positive impact you think AI can have on society?');
      setLittleMessage('Send us a voice note (audio) of up to 1 min!');
    } else if (option === 'Follow-Up') {
      setOptionsSelected([]);
      setMessage('For [the challenge you mentioned], can you suggest any recommendations on how ' +
        'Takaful could improve their current programs or introduce new types of programs to help children like you with that challenge?');
      setLittleMessage('Send us a voice note (audio) of up to 1 min!');
    }
  }

  const handleGenericModal = () =>
    navigate(`${location.pathname}?modal=genericModal`, {
      replace: true,
    });

  const handleCloseGenericModal = () => {
    handleClose(true);
    query.delete('modal');
    navigate(`${location.pathname}${query && ''}${query}`, {replace: true});
    setNodeSelected(null);
    setSelectedOption('Multiple-Choice');
  };

  React.useEffect(() => {
    setOptionsSelected(['Yes a lot', 'Yes a bit', 'No, nothing']);
    setMessage('Do you know or have you heard about artificial intelligence (AI), such as ChatGPT or image recognition?');
    setLittleMessage('');
  }, [])

  React.useEffect(() => {
    if (nodeSelected) {
      switch (nodeSelected.type) {
        case 'multipleChoice':
          setSelectedOption('Multiple-Choice');
          break;
        case 'openEnded':
          setSelectedOption('Open-Ended');
          break;
        case 'followUp':
          setSelectedOption('Follow-Up');
          break;
        case 'start':
          setSelectedOption('Confirmation Card');
          break;
        case 'end':
          setSelectedOption('Closing Message');
          break;
        default:
          setSelectedOption('Multiple-Choice');
          break
      }
    }
  }, [nodeSelected]);

  React.useEffect(() => {
    const createQuery = query.get('modal');
    setOpenGenericModal(createQuery === 'genericModal');
  }, [query]);

  const messageForm = (
    <>
      <Box>
        <Typography variant='body1' sx={{fontFamily: 'Montserrat'}}>
          Select the template to use
        </Typography>

        <Box sx={{display: "flex", mt: '24px'}}>
          <Box sx={{minWidth: "230px"}}>
            <List sx={{padding: 0}}>
              {options.map(({option, icon}, index) => {
                const isDisabled = index >= options.length - 2 || !isOpenEnded && option === 'Follow-Up';
                return (
                  <ListItem
                    button
                    key={option}
                    selected={selectedOption === option}
                    onClick={() => handleOption(option)}
                    disabled={isDisabled}
                    sx={{
                      backgroundColor:
                        selectedOption === option ? "#FAFAFA" : "transparent",
                      borderRadius: selectedOption === option ? '12px 0px 0px 12px' : 0,
                      "&:hover":
                        {
                          backgroundColor: isDisabled ? "transparent" : "#f5f5f5",
                          borderRadius: '12px 0px 0px 12px'
                        }
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        fontSize: 20,
                        color: isDisabled ? '#BDBDBD' : selectedOption === option ? lighten(theme.palette.primary.main, 0.2) : '#616161'
                      }}>
                      {icon}
                    </ListItemIcon>
                    <ListItemText
                      primary={option}
                      primaryTypographyProps={{
                        color: isDisabled ? '#BDBDBD' : selectedOption === option ? lighten(theme.palette.primary.main, 0.2) : '#616161',
                        fontFamily: 'Montserrat',
                        fontSize: '14px'
                      }}
                    />
                  </ListItem>
                )
              })}
            </List>
          </Box>
          <Box sx={{
            display: 'flex',
            minWidth: "570px",
            backgroundColor: '#FAFAFA',
            padding: '24px',
            borderRadius: '0px 12px 12px 0px'
          }}>
            <Box
              sx={{width: '50%'}}
            >

              {selectedOption === 'Multiple-Choice' && (
                <>
                  <Typography sx={{
                    fontFamily: 'Montserrat',
                    fontSize: '14px',
                    fontWeight: 600
                  }}>
                    Build navigation menus
                  </Typography>
                  <Typography variant="body2" sx={{
                    mt: 1,
                    color: '#616161',
                    fontFamily: 'Montserrat'
                  }}>
                    It continues in different steps, depending on the contact's
                    response.
                  </Typography>
                  <Typography variant="body2" sx={{
                    mt: 1,
                    color: '#616161',
                    fontFamily: 'Montserrat'
                  }}>
                    • Displays menu options as buttons; if there are more than
                    three, a
                    drop-down list will appear.
                  </Typography>
                  <Typography variant="body2" sx={{
                    mt: 1,
                    color: '#616161',
                    fontFamily: 'Montserrat'
                  }}>
                    • If the contact has not responded in a certain amount of
                    time, a
                    follow-up message will be sent reminding them that they have
                    questions to answer.
                  </Typography>
                  <Typography variant="body2" sx={{
                    mt: 1,
                    color: '#616161',
                    fontFamily: 'Montserrat'
                  }}>
                    • If the contact fails to respond correctly, a follow-up
                    message
                    will be sent to help them respond correctly.
                  </Typography>
                </>
              )}
              {selectedOption === 'Open-Ended' && (
                <>
                  <Typography sx={{
                    fontFamily: 'Montserrat',
                    fontSize: '14px',
                    fontWeight: 600
                  }}>
                    Get an elaborate answer, rather than a simple "yes" or "no"
                  </Typography>
                  <Typography variant="body2" sx={{
                    mt: 1,
                    color: '#616161',
                    fontFamily: 'Montserrat'
                  }}>
                    Allows the interviewee to respond freely:
                  </Typography>
                  <Typography variant="body2" sx={{
                    mt: 1,
                    color: '#616161',
                    fontFamily: 'Montserrat'
                  }}>
                    • It encourages deeper conversation and gives the
                    interviewer a better understanding
                    of the interviewee's experiences, opinions, and feelings.
                  </Typography>
                  <Typography variant="body2" sx={{
                    mt: 1,
                    color: '#616161',
                    fontFamily: 'Montserrat'
                  }}>
                    • If the contact has not responded in a certain amount of
                    time, a follow-up message
                    will be sent reminding them that they have questions to
                    answer.
                  </Typography>
                  <Typography variant="body2" sx={{
                    mt: 1,
                    color: '#616161',
                    fontFamily: 'Montserrat'
                  }}>
                    • If the contact fails to respond correctly, a follow-up
                    message will be sent
                    helping them respond correctly.
                  </Typography>
                </>
              )}
              {selectedOption === 'Follow-Up' && (
                <>
                  <Typography sx={{
                    fontFamily: 'Montserrat',
                    fontSize: '14px',
                    fontWeight: 600
                  }}>
                    Use artificial intelligence to make conversations natural
                  </Typography>
                  <Typography variant="body2" sx={{
                    mt: 1,
                    color: '#616161',
                    fontFamily: 'Montserrat'
                  }}>
                    It is asked after the interviewee has answered an open
                    question.
                  </Typography>
                  <Typography variant="body2" sx={{
                    mt: 1,
                    color: '#616161',
                    fontFamily: 'Montserrat'
                  }}>
                    • Its purpose is to go deeper into the answer given, get
                    more details or clarify certain points.
                  </Typography>
                  <Typography variant="body2" sx={{
                    mt: 1,
                    color: '#616161',
                    fontFamily: 'Montserrat'
                  }}>
                    • If the contact has not responded in a certain amount of
                    time, a follow-up message
                    will be sent reminding them that they have questions to
                    answer.
                  </Typography>
                  <Typography variant="body2" sx={{
                    mt: 1,
                    color: '#616161',
                    fontFamily: 'Montserrat'
                  }}>
                    • If the contact fails to respond correctly, a follow-up
                    message will
                    be sent helping them to respond correctly.
                  </Typography>
                </>
              )}
            </Box>
            <Box
              sx={{width: '255px', pl: '16px'}}
            >
              <Box
              >
                <MessageVisualizator
                  message={message}
                  littleMessage={littleMessage}
                  options={optionsSelected}
                />
              </Box>
              <Button
                variant="contained"
                sx={{
                  borderRadius: '20px',
                  width: '195px',
                  height: '37px',
                  mt: '16px',
                  ml: '30px',
                  fontFamily: 'Montserrat',
                  fontSize: '14px'
                }}
                onClick={handleGenericModal}
              >
                USE THIS TEMPLATE
              </Button>
              {(openGenericModal || showModalGeneric) &&
                (<GenericModalMessage
                    action='save'
                    selectedOption={selectedOption}
                    open={openGenericModal || showModalGeneric}
                    onClose={handleCloseGenericModal}
                    nodeSelected={nodeSelected}
                    setNodeSelected={setNodeSelected}
                    survey={survey}
                    questions={questions}
                    listLanguagesInterview={listLanguagesInterview}
                    refrechDataGraph={refrechDataGraph}
                  />
                )}
            </Box>

          </Box>
        </Box>
      </Box>
    </>
  )

  return (
    <Modal open={open}
           onClose={handleClose}
           widthInPixels={848}
           heightInPixels={636}
           title={'Create message'}
    >
      {messageForm}
    </Modal>
  );
};

export default Messages;
