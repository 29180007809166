import * as React from "react";
import * as _ from "lodash";
import {useCallback, useEffect, useState} from "react";
import {useTranslation} from "../../../../../providers/TranslationProvider";
import {
  Box,
  FormControl,
  Grid,
  IconButton,
  Popover,
  Typography
} from "@mui/material";
import {
  MuiInfoIconButton,
  MuiInfoOutlinedIcon,
  MuiSegmentCaption,
  MuiSegmentTitle
} from "./styles/segmentationParameters";
import CloseIcon from "@mui/icons-material/HighlightOffOutlined";
import DraggableAutocomplete from "components/common/DraggableAutocomplete";

const SegmentationParameters = ({
                                  scheme,
                                  setScheme, categoricalFields, statisticFields
                                }) => {

  const [selectedCategoricalFields, setSelectedCategoricalFields] = useState([]);
  const [selectedStatisticFields, setSelectedStatisticFields] = useState([]);
  const {t} = useTranslation();
  const [anchorSegmentInfo, setAnchorSegmentInfo] = useState(null);
  const [infoText, setInfoText] = useState('');

  const showCategorySegmentInfo = (event) => {
    let defaultText = "Categories derived from multi-choice answers in the dataset. These\n" +
      "                segments allow you to filter and organize data based on the selected\n" +
      "                responses, making it easier to analyze specific groups within the\n" +
      "                survey results.";
    setInfoText(t("categories_info") || defaultText);
    setAnchorSegmentInfo(event.currentTarget);
  }

  const showStatisticSegmentInfo = (event) => {
    let defaultText = "Variables in the dataset that correspond to continuous data, " +
      "such as household income and other demographic information. " +
      "These statistics provide quantitative insights and help in understanding the distribution " +
      "and trends within the survey population.";
    setInfoText(t("statistics_info") || defaultText);
    setAnchorSegmentInfo(event.currentTarget);
  }

  const closeSegmentInfo = () => {
    setAnchorSegmentInfo(null);
  }

  useEffect(() => {
    if (!scheme) return;

    let localSelectedCategoricalFields = scheme.segmentation_variables;
    let localSelectedStatisticFields = scheme.statistic_variables;

    const updateFields = (localFields, selectedFields, fields, setFields) => {
      if (localFields) {
        setFields(
          localFields?.map(prop => fields.find(d => d.propName === prop)).filter(Boolean) || []
        );
      }
    };

    updateFields(localSelectedCategoricalFields, selectedCategoricalFields, categoricalFields, setSelectedCategoricalFields);
    updateFields(localSelectedStatisticFields, selectedStatisticFields, statisticFields, setSelectedStatisticFields);

  }, [scheme, categoricalFields, statisticFields]);

  useEffect(() => {
    if (!scheme?.id && !scheme?.dataset) {
      setSelectedStatisticFields([]);
      setSelectedCategoricalFields([]);
    }
  }, [scheme]);

  const handleOrderChange = (newItems, oldIndex, newIndex) => {
    setScheme(prevScheme => ({
      ...prevScheme,
      segmentation_variables: [...newItems.map((item) => item.propName)]
    }));
  };

  const handleStatisticOrderChange = (newItems, oldIndex, newIndex) => {
    setScheme(prevScheme => ({
      ...prevScheme,
      statistic_variables: [...newItems.map((item) => item.propName)]
    }));
  };

  return (
    <Box style={{padding: "8px", width: '100%'}}>
      <Grid container direction="row" sx={{width: '100%'}}
            alignContent={'center'}>
        <Grid container item xs={6} direction='column'
              sx={{paddingRight: '16px'}}>
          <Grid item sx={{
            mb: '8px',
            display: 'flex',
            alignItems: 'center',
          }}>
            <MuiSegmentTitle
              variant="h6">{t('segments_conf_heading')}</MuiSegmentTitle>
            <MuiInfoIconButton onClick={showCategorySegmentInfo}>
              <MuiInfoOutlinedIcon/>
            </MuiInfoIconButton>
          </Grid>
          <Grid item sx={{mb: '16px'}}>
            <MuiSegmentCaption>
              {t('segments_conf_caption')}
            </MuiSegmentCaption>
          </Grid>
          <Grid item container direction={"row"} sx={{maxWidth: '100%'}}>
            <FormControl fullWidth variant="outlined">
              <DraggableAutocomplete
                options={categoricalFields || []}
                selectedItems={selectedCategoricalFields || []}
                setSelectedItems={(items) => {
                  const newSelection = items?.map((item) => item.propName);
                  console.log("newSelection", newSelection);
                  setScheme(prevScheme => ({
                    ...prevScheme,
                    segmentation_variables: newSelection || []
                  }));
                }}
                label={t('segmentation_criteria')}
                onOrderChange={handleOrderChange}
                id="categorical_segment_selection"
              />
            </FormControl>
          </Grid>
        </Grid>
        <Grid container item xs={6} direction='column'
              sx={{paddingLeft: '10px'}}>
          <Grid item sx={{
            mb: '8px',
            display: 'flex',
            alignItems: 'center',
            maxWidth: '100%'
          }}>
            <MuiSegmentTitle
              variant="h6">{t('statistics_conf_heading')}</MuiSegmentTitle>
            <MuiInfoIconButton onClick={showStatisticSegmentInfo}>
              <MuiInfoOutlinedIcon/>
            </MuiInfoIconButton>
          </Grid>
          <Grid item sx={{mb: '16px', maxWidth: '100%'}}>
            <MuiSegmentCaption>
              {t('statistics_conf_caption')}
            </MuiSegmentCaption>
          </Grid>
          <Grid item container direction={'row'} sx={{maxWidth: '100%'}}>
            <FormControl fullWidth variant="outlined">
              <DraggableAutocomplete
                options={statisticFields || []}
                selectedItems={selectedStatisticFields || []}
                setSelectedItems={(items) => {
                  const newSelection = items?.map((item) => item.propName);
                  setScheme(prevScheme => ({
                    ...prevScheme,
                    statistic_variables: newSelection || []
                  }));
                }}
                label={t('statistic_column')}
                onOrderChange={handleStatisticOrderChange}
                id="statistic_segment_selection"
              />
            </FormControl>
          </Grid>
        </Grid>
      </Grid>
      <Popover
        open={Boolean(anchorSegmentInfo)}
        anchorEl={anchorSegmentInfo}
        onClose={closeSegmentInfo}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <Box style={{position: 'relative', width: '420px', padding: '10px'}}>
          <Grid container direction='row'>
            <Grid item sx={{padding: '5px', maxWidth: '374px'}}>
              <Typography sx={{
                fontFamily: 'Montserrat',
                fontSize: '12px',
                fontWeight: 400,
                lineHeight: '15px',
                letterSpacing: '0.004em',
                color: '#616161',
                fontStyle: 'normal',
                marginLeft: '5px'
              }} variant="caption">
                {infoText}
              </Typography>
            </Grid>
            <Grid item>
              <IconButton
                aria-label="close"
                onClick={closeSegmentInfo}
                sx={{
                  position: 'absolute',
                  right: 8,
                  top: 8,
                  width: '15px',
                  height: '15px'
                }}
              >
                <CloseIcon sx={{width: '15px', height: '15px'}}/>
              </IconButton>
            </Grid>
          </Grid>
        </Box>
      </Popover>
    </Box>
  )
}

export default SegmentationParameters;
