import {createContext, useContext, useState} from "react";

const InfrastructureProgressContext = createContext();

const InfrastructureProgressProvider = ({children}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [statusMessage, setStatusMessage] = useState('Initializing...');
  const [showProgressBar, setShowProgressBar] = useState(true);

  return (<InfrastructureProgressContext.Provider value={{
    isLoading,
    setIsLoading,
    progress,
    setProgress,
    showProgressBar,
    setShowProgressBar,
    statusMessage,
    setStatusMessage
  }}>
    {children}
  </InfrastructureProgressContext.Provider>)
}

const useInfrastructureProgress = () => useContext(InfrastructureProgressContext);

export {InfrastructureProgressContext, useInfrastructureProgress};
export default InfrastructureProgressProvider;
