import * as React from 'react';
import {TableContainer} from '@mui/material';
import {useLocation, useNavigate} from 'react-router-dom';
import {usePermissions} from 'components/hooks/usePermissions';
import Table from '../common/Table';
import ActionBar from './ActionBar';
import {MuiContainer} from '../styles/user';
import TabsCustoms from '../common/TabsCustoms';
import TPagination from '../common/TPagination';
import useOrganizationSurveys
  from "../../../../api/hooks/useOrganizationSurveys";
import {setTargetSurvey} from "../../../../store/appSlice";
import {useDispatch} from "react-redux";
import {useDeleteSurvey} from "../../../../api/hooks/useDeleteSurvey";


const columns = ['Name', 'Service ID', 'Infrastructure Status', "Interview Status", 'Region', 'Actions'];
const row = ['name', 'serviceId', 'status', "environment", 'region'];
const Surveys = () => {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [searchTerm, setSearchTerm] = React.useState(new Array(columns.length).fill(''));

  const {pathname} = useLocation();
  const location = useLocation();
  const orgId = pathname.split('/')[2];
  const {hasPermissionFor} = usePermissions();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {mutateAsync: deleteSurvey} = useDeleteSurvey()
  const {
    data: surveys,
    isLoading
  } = useOrganizationSurveys({organization_id: orgId});

  const handleDelete = (id, data) => {
    const originalPath = location.pathname
    navigate(`${originalPath}?delete=${id}`, {
      replace: true,
      state: data
    });
    deleteSurvey(id).then((rsp) => {
      console.log("Survey deleted", rsp);
      navigate(originalPath, {replace: true})
    }).catch((err) => {
      console.log("Failed to delete survey:", id)
    })

  };

  const handleEdit = (id, survey) => {
    const payload = {...survey, region: survey.region};
    dispatch(setTargetSurvey(payload));
    navigate(`${location.pathname}/${id}/specification`, {
      replace: true,
      state: payload
    });
  };

  const handleShowStats = (id, survey, evt) => {
    const payload = {...survey}
    dispatch(setTargetSurvey(payload));
    navigate(`${location.pathname}/${id}/analytics`, {
      replace: true,
      state: payload
    })
  }
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const filteredData = surveys
    ? surveys?.filter((item) => {
      const lowerCaseSearchTerms = searchTerm.map((term) => term.toLowerCase());
      const itemString = `${item.name} ${item.serviceId}`.toLowerCase();
      return lowerCaseSearchTerms.every((term) => itemString.includes(term));
    }).map((item) => ({
      ...item,
      id: item._id,
      environment: item.environment === "prod" ? "Published" : "Draft"
    }))
    : [];

  const offset = page * rowsPerPage;
  const currentPageData = filteredData.slice(offset, offset + rowsPerPage);

  const handlePreviousPage = () =>
    setPage((prevPage) => Math.max(0, prevPage - 1));


  const handleNextPage = () =>
    setPage((prevPage) => Math.min(Math.ceil((filteredData?.length || 0) / rowsPerPage) - 1, prevPage + 1));


  const handleFirstPage = () => setPage(0);

  const handleLastPage = () => {
    const lastPage = Math.max(0, Math.ceil((filteredData?.length || 0) / rowsPerPage) - 1);
    setPage(lastPage);
  };


  const handleSearch = (event, index) => {
    const newSearchTerms = [...searchTerm];
    newSearchTerms[index] = event.target.value;
    setSearchTerm(newSearchTerms);
    setPage(0);
  };
  return (
      <MuiContainer>
        <TabsCustoms/>
        <ActionBar/>
        <TableContainer sx={{background: '#FAFAFA'}}>
          <Table
            handleSearch={handleSearch}
            searchTerm={searchTerm}
            data={currentPageData}
            row={row}
            columns={columns}
            onDelete={handleDelete}
            showDelete={hasPermissionFor('delete_schemes')}
            showStats={true}
            onShowStats={handleShowStats}
            showEdit={hasPermissionFor('update_schemes')}
            onEdit={handleEdit}
            isLoading={isLoading}
            filterableTerms={['Name', 'Service ID']}
          />
        </TableContainer>
        <TPagination
          data={filteredData}
          rowsPerPage={rowsPerPage}
          page={page}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          handleFirstPage={handleFirstPage}
          handlePreviousPage={handlePreviousPage}
          handleNextPage={handleNextPage}
          handleLastPage={handleLastPage}
        />
      </MuiContainer>
  );
};

export default Surveys;
