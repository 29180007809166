import axiosSurveyInstance from "../axios/axiosSurveyInstance";
import {useQuery} from "react-query";

const fetchQuestions = (data) => {
  return axiosSurveyInstance.get(`/api/survey/question/list?service_id=${data.serviceId}&environment=${data.env}`).then((resp) => {
    const {data} = resp;
    return data?.questions || [];
  }).catch((err) => {
    console.error("Error fetching survey", err);
    return [];
  });
};

const useQuestions = ({serviceId, env='dev'}) => useQuery(
  ['questions', serviceId, env],
  () => fetchQuestions({serviceId, env}),
  {
    refetchOnWindowFocus: false,
  }
);

export default useQuestions;
