import {
    styled,
    Typography,
    TextField,
    Select,
    IconButton,
    Avatar,
    Chip,
    lighten,
    MenuItem,
} from '@mui/material';
import {
    InfoOutlined as InfoOutlinedIcon
} from "@mui/icons-material";


export const MuiTitleTypography = styled(Typography)(({ theme }) => ({
    fontFamily: 'Raleway',
    fontWeight: 500,
    fontSize: '21px',
    lineHeight: '24.65px',
    letterSpacing: '0.03px',
    color: '#616161'
}));

export const MuiTextfield = styled(TextField)(({ theme }) => ({
    fontFamily: 'Montserrat',
    height: '56px',
    width: '668px',
    color: '#616161',
    borderRadius: '4px',
    border: '1px',
    '& .MuiInputBase-input': {
        fontFamily: 'Montserrat !important',
    },
    '& .MuiSelect-select': {
        fontFamily: 'Montserrat !important',
    },
    '& .MuiMenuItem-root': {
        fontFamily: 'Montserrat !important',
    },
    '&.MuiInputLabel-root':{
        fontFamily: 'Montserrat',
        fontSize: '12px !important',
        lineHeight: '20px',
        fontStyle: 'normal',
        letterSpacing: '0.08px',
        fontWeight: 400,
        color: '#143440'
    },
}));

export const MuiSelect = styled(Select)(({ theme }) => ({
    fontFamily: 'Raleway',
    height: '56px',
    width: '668px',
    color: '#616161',
    borderRadius: '4px',
    border: '1px',
    '&.MuiInputLabel-root':{
        fontFamily: 'Montserrat',
        fontSize: '12px !important',
        lineHeight: '20px',
        fontStyle: 'normal',
        letterSpacing: '0.08px',
        fontWeight: 400,
        color: '#143440'
    },
}));

export const MuiInfoIconButton = styled(IconButton)(({theme}) => ({
    width: '16px',
    height: '16px',
    marginLeft: '8px',
  }));

export const MuiInfoOutlinedIcon = styled(InfoOutlinedIcon)(({theme}) => ({
    width: '16px',
    height: '16px',
    color: '#9E9E9E',
}));

export const MuiAvatarFlags = styled(Avatar)(({theme}) => ({
    width: '20px',
    height: '20px',
    "& img": { width: '20px', height: '20px' }
}));

export const MuiChip = styled(Chip)(({theme}) => ({
    gap: '8px',
    backgroundColor: lighten(theme.palette.primary.main, 0.9),
    '& .MuiChip-deleteIcon': { color: '#254957' },
    borderRadius: '8px',
    padding: '6px 8px',
    "& .MuiChip-label": {
        fontSize: "14px",
        color: "#254957",
        fontFamily: "Montserrat !important",
    },
}));

export const MuiMenuItem = styled(MenuItem)({
    fontFamily: 'Montserrat !important',
});

export const MuiAutocompleteTextfield = styled(TextField)(({ theme }) => ({
    fontFamily: 'Montserrat',
    '& .MuiInputBase-input': {
        fontFamily: 'Montserrat !important',
    },
    '& .MuiSelect-select': {
        fontFamily: 'Montserrat !important',
    },
    '& .MuiMenuItem-root': {
        fontFamily: 'Montserrat !important',
    },
    '&.MuiInputLabel-root':{
        fontFamily: 'Montserrat',
    },
}));
  
