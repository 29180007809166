import {useState} from "react";
import {Box, Collapse, ListItemButton, ListItemText} from "@mui/material";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import VoicePlayer from "../../../../common/VoicePlayer";

const ResponseItem = ({headerText, responseText, audio = null, audioKey=null}) => {
  const [expanded, setExpanded] = useState(false);
  return responseText && (
    <>
      <ListItemButton onClick={() => setExpanded(prev => !prev)} sx={{pl: 0}}>
        <ListItemText primary={headerText}
                      slotProps={{
                        primary: {
                          style: {
                            fontFamily: 'Montserrat',
                            fontSize: '12px',
                            letterSpacing: '0.004em'
                          }
                        }
                      }}
        />
        {expanded ? <ExpandLess/> : <ExpandMore/>}
      </ListItemButton>
      <Collapse in={expanded} timeout="auto" unmountOnExit sx={{
        alignItems: 'flex-start'
      }}>
        <Box sx={{pl: 0, width: '100%'}}>
          <ListItemText
            primary={responseText ? `"${responseText}"` : 'Not answered'}
            slotProps={{
              primary: {
                style: {
                  fontFamily: 'Montserrat',
                  fontSize: '16px',
                  letterSpacing: '0.005em',
                  fontStyle: responseText ? 'italic' : 'normal'
                }
              }
            }}
          />
          {audio&&(
            <VoicePlayer audio={audio} audioKey={`audio-${audioKey}`}/>
          )}
        </Box>
      </Collapse>
    </>
  )
}

export default ResponseItem;
