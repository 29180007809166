// noinspection XmlDeprecatedElement

import * as React from 'react';
import {useCallback, useRef, useState} from 'react';
import {
  Box,
  Button as MuiButton, CircularProgress,
  Grid,
  Tab,
  Tabs,
  ThemeProvider,
  Typography,
} from '@mui/material';
import * as yup from 'yup';
import {FormProvider, useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import {useTranslation} from 'components/providers/TranslationProvider';
import themeCrowdView from 'theme/crowdView';
import Modal from 'components/common/Modal';
import {
  MuiBtnNext
} from "../scenarios/specification/sections/steps/styles/question";
import {lighten} from "@mui/material/styles";
import {useTheme} from "../../../providers/CustomThemeProvider";
import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck';
import AutoAwesomeOutlinedIcon from '@mui/icons-material/AutoAwesomeOutlined';
import FormatAlignJustifyOutlinedIcon
  from '@mui/icons-material/FormatAlignJustifyOutlined';
import MessageVisualizator from "./MessageVisualizator";
import ImageIcon from "@mui/icons-material/Image";
import {useDropzone} from "react-dropzone";
import Button from "../../../common/Button";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import WavingHandIcon from "@mui/icons-material/WavingHand";
import axiosInstance from "../../../../api/axios/axiosInstance";
import useManageQuestion from "../../../../api/hooks/useCreateQuestion";
import useQuestionTranslate from "../../../../api/hooks/useQuestionTranslate";
import MessageForm from "./steps/MessageForm";

const schema = yup.object().shape({
  shortName: yup.string(),
  footerText: yup.string(),
  followUpText: yup.string(),
  mainText: yup.string(),
  title: yup.string(),
});

const GenericModalMessage = ({action, open, onClose, selectedOption, nodeSelected,
                               setNodeSelected, survey, questions,
                               listLanguagesInterview, refrechDataGraph}) => {

  const [activeTab, setActiveTab] = React.useState(0);
  const [mainTextEditor, setMainTextEditor] = React.useState(nodeSelected?.mainText ||'')
  const [textCardPhone, setTextCardPhone] = React.useState('')
  const [littleMessage, setLittleMessage] = useState('');
  const [charTitleCount, setCharTitleCount] = React.useState(0);
  const [footerCount, setFooterCount] = React.useState(0);
  const [indicatorWidth, setIndicatorWidth] = React.useState(80);
  const [options, setOptions] = React.useState([])
  const [imageUrl, setImageUrl] = React.useState(nodeSelected?.profileImageUrl || '');
  const {createQuestion} = useManageQuestion();
  const [conditions, setConditions] = React.useState([]);
  const [parentQuestion, setParentQuestion] = React.useState(nodeSelected?.sourceId || '');
  const [publicName, setPublicName] = React.useState(nodeSelected?.title ||'');
  const [updateTranslation, setUpdateTranslation] = React.useState([])
  const [disabledTabsLanguage, setDisabledTabsLanguage] = React.useState(true)
  const { t } = useTranslation();
  const {theme:  currentTheme} = useTheme();

  const {
    mutateAsync: questionTranslate,
    isLoading: questionTranslateIsLoading,
  } = useQuestionTranslate();

  const getDefaultValues = () => {
    return {
      mainText: '',
      footerText: '',
      followUpText: '',
      shortName: '',
      title: ''
    };
  };

  const methods = useForm({
    resolver: yupResolver(schema),
    defaultValues: getDefaultValues(selectedOption),
    mode: 'all',
  });
  const {handleSubmit} = methods;

  function getTypeOfQuestion(selectedOption) {
    switch (selectedOption) {
      case 'Multiple-Choice':
        return 'CHOICE';
      case 'Confirmation Card':
        return 'CONFIRMATION';
      case 'Open-Ended':
        return 'OPEN';
      case 'Follow-Up':
        return 'OPEN';
    }
  }

  function getPreconditions(conditions) {
    let preconditions = [];
    if (conditions.length === 0){
      const question  = questions.find(item => item.id === nodeSelected?.sourceId);
      if (question){
        preconditions.push({
          delete: false,
          question:{id: question?.id}
        })
      }
    }
    conditions.forEach((condition) => {
      const selectedQuestion = questions.find(item => item.id === condition.selectedQuestion) || {};
      const answers = condition.selectedAnswer.map((answer)=> answer.id);
      preconditions.push({
        delete: condition.delete|| false,
        question:{id: selectedQuestion?.id,
                  question: selectedQuestion?.question ,
                  shortLabel: selectedQuestion?.shortLabel,
                  type: selectedQuestion?.type
                  },
        validAnswerIds: answers,
      });
    });
    return preconditions;
  }

  function getDefaultAttr(selectedOption) {
    switch (selectedOption) {
      case 'Multiple-Choice':
        return {
          activeListening: false,
          languageModeration: false,
          maxFollowUps: 0,
          moderateAnswers: true,
          minOpenAnswerLength: 0,
          relevanceModeration: true,
          parentQuestion: null
        };
      default:
        return {
          activeListening: true,
          languageModeration: true,
          maxFollowUps: 2,
          moderateAnswers: true,
          minOpenAnswerLength: 5,
          relevanceModeration: true,
          parentQuestion: methods?.getValues()?.parentQuestion ? {id: methods?.getValues()?.parentQuestion} : null
        };
    }
  }

  function getOrderQuestion() {
    if (questions.length > 0) {
      return questions.filter( item => item.type !== 'CONFIRMATION' ).length + 1;
    }
    return 0;
  }

  function getLanguagesOfQuestion(data, additionalLanguages, imageUrl) {
    const languagesOfQuestion = [];
    if (updateTranslation.length === 0){
      additionalLanguages.forEach((language) => {
        languagesOfQuestion.push({
          language: language.name,
          text: data.mainText || '',
          footerText: data.footerText || '',
          headerImageUrl: imageUrl,
          templateId: '',
          templateIdStatusLink: '',
          allowedAnswers: data.options,
          title: data.title
        });
      });
    }else {
      updateTranslation.updatedInternationalization.map((translation) => {
        languagesOfQuestion.push({
          language: translation.language,
          text: translation.text || '',
          footerText: translation.footerText || '',
          headerImageUrl: imageUrl,
          templateId: '',
          templateIdStatusLink: '',
          allowedAnswers: translation.allowedAnswers,
          title: translation.title
        });
      })
    }
    return languagesOfQuestion;
  }

  const submitData = async (data) => {
    let position = { x: 0, y: 0 };
    if (nodeSelected.type === "addNode" && nodeSelected?.position){
      position =  { x: nodeSelected.position.x -87, y: nodeSelected.position.y+5};
    }else if (nodeSelected && typeof nodeSelected.x === "number" && typeof nodeSelected.y === "number"){
      position =  { x: nodeSelected.x, y: nodeSelected.y};
    }
    const payload= {
      service_id: survey.serviceId,
      environment: survey.environment,
      question_data: {
        ...(nodeSelected?.id && { id: nodeSelected.id }),
        type: getTypeOfQuestion(selectedOption),
        question: data.mainText,
        footerText: data.footerText || '',
        shortLabel: data.shortName,
        ...getDefaultAttr(selectedOption),
        preconditions: getPreconditions(conditions),
        x: position.x,
        y: position.y,
        order: getOrderQuestion(),
        internationalization: [
          ...getLanguagesOfQuestion(data, listLanguagesInterview, imageUrl)
          ]
      }
    }
    await createQuestion(payload);
    refrechDataGraph()
    setNodeSelected(null);
    onClose();
  }
  React.useEffect(() => {
    if (nodeSelected?.internationalization) {
      setUpdateTranslation({updatedInternationalization: nodeSelected?.internationalization});
      setDisabledTabsLanguage(false);
    }
  },[nodeSelected]);

  const onSubmit = handleSubmit((data) => (submitData(data)));

  const handleChange = (_, newValue) => {
    let activeLanguage = listLanguagesInterview[newValue]?.name

    if(updateTranslation){
      const instanceTranslation = updateTranslation?.updatedInternationalization.find(
        ({ language }) => language === activeLanguage
      );
      setMainTextEditor(instanceTranslation?.text)
      methods.setValue('shortName', instanceTranslation?.title)
      methods.setValue('footerText', instanceTranslation?.footerText)
    }

    setActiveTab(newValue);
  }

  const handleChangeShortName = (event) => {
    setCharTitleCount(event.target.value.length);
    methods.setValue('shortName', event.target.value);
  }

  const handleFooterChange = (event) => {
    setFooterCount(event.target.value.length);
    methods.setValue('footerText', event.target.value);
    setLittleMessage(event.target.value);
  }

  const  getIcon = () => {
    if(selectedOption === 'Multiple-Choice') return <PlaylistAddCheckIcon/>
    if(selectedOption === 'Open-Ended') return <AutoAwesomeOutlinedIcon/>
    if(selectedOption === 'Follow-Up') return <FormatAlignJustifyOutlinedIcon/>
    if(selectedOption === 'Closing Message') return <WavingHandIcon/>
    else return <ImageIcon/>
  }
  const [file, setFile] = useState(null);
  const fileInputRef = useRef(null);
  const showFileSection = selectedOption === 'Confirmation Card' || selectedOption === 'Closing Message';
  const handleFile = () => {
    fileInputRef.current.click();
  };

  const onDrop = useCallback(async(acceptedFiles) => {
    const uploadedFile = acceptedFiles[0];
    if (uploadedFile) {
       const imageFile = new File([uploadedFile], `${uploadedFile.name}`);
       const formData = new FormData();
       formData.append('image_file', imageFile);
       const axiosResp = await axiosInstance.post(`${process.env.REACT_APP_API_URL}/collection/upload`, formData).then((resp) => resp.data);
       const url = axiosResp.file;
       setImageUrl(url) ;
       setFile(URL.createObjectURL(uploadedFile));
    }

  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: 'image/jpeg, image/png',
    maxFiles: 1
  });

  const handleParentQuestionChange = (event) => {
    setParentQuestion(event.target.value);
    methods.setValue('parentQuestion', event.target.value);
  }

  React.useEffect(() => {
    if(selectedOption === 'Multiple-Choice' || selectedOption === 'Confirmation Card') {
      methods.setValue('options', options)
    }
  },[options])
  const getMatchingAnswersByLanguage = (internationalization, validAnswerIds, languageFilter) => {
    const entry = internationalization.find(entry => entry.language === languageFilter);
    if (!entry) return [];

    return entry.allowedAnswers
      .filter(answer => validAnswerIds.includes(answer.id))
      .map(({ id, answer }) => ({ id, propName: answer }));
  };

  React.useEffect(() => {
    if(nodeSelected?.preconditions?.length > 0) {
      let conditions = [];
      nodeSelected.preconditions.forEach((condition, index) => {
        const question = condition.question;
        conditions.push({
          id: index,
          name: question.shortLabel,
          selectedQuestion: question.id,
          selectedAnswer : getMatchingAnswersByLanguage(
            question.internationalization,
            condition.validAnswerIds,
            listLanguagesInterview[activeTab]?.name
          )
        })
      })
      setConditions(conditions);
    }
  },[nodeSelected])

  React.useEffect(() => {
    if (nodeSelected?.type === "addNode") {
      let question = questions.find(item => item.id === nodeSelected.sourceId);
      let condition = [];
      if (question && question.type === 'OPEN') {
        condition.push({
          id: 0,
          name: question.shortLabel,
          selectedQuestion: question.id,
          selectedAnswer: []
        })
      } else {
        condition.push({
          id: 0,
          name: question.shortLabel,
          selectedQuestion: question.id,
          selectedAnswer : getMatchingAnswersByLanguage(
            question.internationalization,
            [nodeSelected.option],
            listLanguagesInterview[activeTab]?.name
          )
        })
      }
      setConditions(condition);
    }
  }, [nodeSelected]);

  React.useEffect(() => {
    if (imageUrl) {
      fetch(imageUrl)
        .then(response => response.blob())
        .then(blob => {
          const objectURL = URL.createObjectURL(blob);
          setFile(objectURL);
        })
        .catch(error => console.error('Error loading image:', error));
    }
  }, [imageUrl]);

  React.useEffect(() => {
    if(nodeSelected) {
      methods.setValue('shortName', nodeSelected?.text);
      methods.setValue('mainText', nodeSelected?.mainText);
      methods.setValue('footerText', nodeSelected?.footerText);
      setCharTitleCount(nodeSelected?.text?.length||0);
      setOptions(nodeSelected?.options || []);
    }
  }, [nodeSelected]);

  React.useEffect(() => {
    const updateIndicatorWidth = () => {
      const selectedTab = document.querySelector('.Mui-selected');
      if (selectedTab) {
        setIndicatorWidth(selectedTab.offsetWidth);
      }
    };

    updateIndicatorWidth();

    window.addEventListener('resize', updateIndicatorWidth);
    return () => window.removeEventListener('resize', updateIndicatorWidth);
  }, [activeTab]);

  const actions = (
    <>
      <MuiButton
        onClick={() => onClose(false)}
        sx={{color: '#6793A5', borderColor: '#6793A5'}}
        variant="text"
      >
        <Typography sx={{fontFamily: 'Montserrat', fontSize: '14px'}}>
          {t('cancel_btn')}
        </Typography>
      </MuiButton>
      <MuiBtnNext
        onClick={onSubmit}
        variant="contained"
        type="submit"
        disabled={false}
        data-cy="btn_update_org"
      >
        <Typography sx={{fontFamily: 'Montserrat', fontSize: '14px', color:'white'}}>
          {t(action)}
        </Typography>
      </MuiBtnNext>
    </>
  );

  const modalForm = (
    <ThemeProvider theme={themeCrowdView}>
      <Grid container display={'flex'} justifyContent={'space-between'}>
        <Grid item xs={8}>
          {showFileSection && (
            <Grid item >
            <Box>
            <Typography style={{
            color: '#212121', fontFamily: 'Montserrat',
            fontWeight: 500, fontSize: '14px', margin:'12px 0px'
          }}>
          COVER IMAGE
        </Typography>
        <Box sx={{display: 'flex', alignItems: 'center', gap: '12px'}}>
          <Box
            {...getRootProps()}
            sx={{
              border: '2px dashed #ccc',
              borderRadius: '8px',
              padding: '16px',
              textAlign: 'center',
              cursor: 'pointer',
              width: '150px',
              height: '150px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              backgroundColor: '#f9f9f9'
            }}
          >
            <input {...getInputProps()} />
            {file ? (
              <img src={file} alt="Preview" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
            ) : (
              <Box>
                <CloudUploadIcon sx={{color: '#9E9E9E'}}/>
                <Typography sx={{ color: '#616161', fontFamily: 'Montserrat', fontSize: '12px' }}>
                  Select file or drag here
                </Typography>
              </Box>
            )}
          </Box>
          <Box>
            <Button variant="outlined" color="primary"
                    sx={{ marginTop: 2 ,
                      borderRadius: '13px',
                      height: '32px',
                      fontFamily: 'Montserrat',
                      fontSize: '10px',
                      color: lighten(currentTheme.palette.primary.main, 0.2),
                      border: `1px solid ${lighten(currentTheme.palette.primary.main, 0.1)}`
                    }}
                    onClick={handleFile}
            >
              Select File
              <input ref={fileInputRef} type="file" hidden onChange={(e) => onDrop(e.target.files)} />
            </Button>
            <Typography sx={{ display: 'block', marginTop: 1 ,fontFamily: 'Montserrat', fontSize: '10px'}}>
              <p><strong>Format: </strong>JPG or PNG <strong>Proportion: </strong>1x1</p>
            </Typography>
          </Box>
        </Box>
      </Box>
    </Grid>
            )}
          <Tabs
            sx={{
              marginTop: currentTheme.spacing(0),
              maxHeight: '60px',
              padding: currentTheme.spacing(0),
              borderBottom:'1px solid',
              borderColor: currentTheme.palette.primary.main,
              '& .Mui-selected': {
                fontSize: 14,
                fontFamily: 'Montserrat',
                fontWeight: 500,
              },
            }}
            value={activeTab}
            onChange={handleChange}
            orientation="horizontal"
            variant="scrollable"
            scrollButtons="auto"
            allowScrollButtonsMobile
            indicatorColor="secondary"
            TabIndicatorProps={{
              style: {
                backgroundColor: lighten( currentTheme.palette.primary.main, 0.1),
                height: 3,
                borderRadius: '10px 10px 0 0',
                transition: 'width 0.3s',
                marginBottom: currentTheme.spacing(0),
                width: indicatorWidth > 100 ? indicatorWidth - 33 : 100,
                marginLeft: 18
              }
            }}
          >
            {listLanguagesInterview.map((tab, index) => (
              <Tab
                icon={
                  questionTranslateIsLoading ? (
                    <CircularProgress size={18} />
                  ) : (
                    <img src={tab.flag} alt={tab.name} style={{ width: 20, height: 20 }} />
                  )
                }
                iconPosition="start"
                key={index}
                style={{
                  color:
                    activeTab === index
                      ? lighten(currentTheme.palette.primary.main, 0.2)
                      : '#424242',
                  fontSize: 14,
                  fontFamily: 'Montserrat',
                  fontWeight: 500,
                }}
                label={tab.name}
                value={index}
                onClick={() => setActiveTab(index)}
                disabled={disabledTabsLanguage}
              />
            ))}
          </Tabs>
          <div id={activeTab}>
            {<MessageForm
              tabId={activeTab}
              survey={survey}
              imageUrl={imageUrl}
              setUpdateTranslation={setUpdateTranslation}
              getLanguagesOfQuestion={getLanguagesOfQuestion}
              questionTranslate={questionTranslate}
              listLanguagesInterview={listLanguagesInterview}
              t={t}
              mainTextEditor={mainTextEditor}
              textCardPhone={textCardPhone}
              setDisabledTabsLanguage={setDisabledTabsLanguage}
              setMainTextEditor={setMainTextEditor}
              setTextCardPhone={setTextCardPhone}
              selectedOption={selectedOption}
              charTitleCount={charTitleCount}
              footerCount={footerCount}
              handleChangeShortName={handleChangeShortName}
              handleFooterChange={handleFooterChange}
              methods={methods}
              options={options}
              setOptions={setOptions}
              setConditions={setConditions}
              conditions={conditions}
              questions={questions}
              parentQuestion={parentQuestion}
              handleParentQuestionChange={handleParentQuestionChange}
              language={survey.language}
              publicName={publicName}
              setPublicName={setPublicName}
            />}
          </div>
        </Grid>
        <Grid item xs={4} sx={{
          width:'260px !important',
          maxWidth:'260px !important',
        }}>
          <MessageVisualizator
            message={textCardPhone}
            littleMessage={littleMessage}
            options={options}
            view={'phone'}
            image={file}
            selectedOption={selectedOption}
            survey={survey}
          />
        </Grid>
      </Grid>
    </ThemeProvider>
  );


  return (
    <FormProvider {...methods}>
      <form>
        <Modal
          open={open}
          onClose={() => onClose(false)}
          title={selectedOption}
          icon={getIcon()}
          actions={actions}
          widthInPixels={960}
        >
          {modalForm}
        </Modal>
      </form>
    </FormProvider>
  );
};

export default GenericModalMessage;
