import axiosSurveyInstance from "../axios/axiosSurveyInstance";
import {useQueries} from "react-query";

const fetchSurveyStatus = async (data) => {
  if (data.service_id) {
    return axiosSurveyInstance.get(`/api/srv/status?service_id=${data.service_id}&environment=${data.environment}&type=${data.type}`)
      .then((resp) => resp.data)
      .catch((err) => {
        if(err.status === 503 || err.status === 400){
          return err.response.data
        }else {
          console.error("Error fetching survey", err);
          return null;
        }
      });
  }
  throw new Error("Error fetching survey status, no id or service_id and environment provided");
}

const useCommunicationStatus = (data) => useQueries([
  {
    queryKey: ['communicationStatusWhatsapp', data],
    queryFn: () => fetchSurveyStatus(data),
    options:{
      enabled: data.service_id,
      refetchOnWindowFocus: false
    }
  },
  {
    queryKey: ['communicationStatusTelegram', data],
    queryFn: () => fetchSurveyStatus({...data, type:'tg'}),
    options:{
      enabled: data.service_id,
      refetchOnWindowFocus: false
    }
  }
]);

export default useCommunicationStatus;
