import {useEffect, useMemo, useState,} from 'react';

import {Box, List, Tab, Typography,} from '@mui/material';
import {useTranslation} from 'components/providers/TranslationProvider';
import {
  IndicatorSidebar,
  MuiBoxQuestion,
  MuiCKeditorContainer,
  MuiListItemText,
  MuiQuestionsLabels,
  MuiTabs,
} from 'components/views/organizations/surveys/styles/surveytranslations';
import {
  MuiButton,
} from 'components/views/organizations/surveys/styles/translations';
import {Translate as TranslateIcon,} from '@mui/icons-material';
import spanishFlag from "assets/img/spanish.png";
import englishFlag from "assets/img/english.png";
import frenchFlag from "assets/img/french.png";
import brazilFlag from "assets/img/portuguese-brazil.png";
import arabicFlag from "assets/img/arabic.png";
import {CKEditor} from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
// import SurveySectionsTranslatorPlugin from './SurveySectionsTranslatorPlugin'
import TranslateTextPopover
  from "components/views/organizations/surveys/TranslateTextPopover";
import useSurveyUpdateQuestionTraslation
  from "api/hooks/useSurveyUpdateQuestionTraslation";
import useSurveyUpdateTranslationMessage
  from "api/hooks/useSurveyUpdateTranslationMessage";
import cloneDeep from "lodash/cloneDeep";
import TurndownService from 'turndown';
import LanguageIcon from "@mui/icons-material/Language";

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const predefinedLanguages = [
  {name: "english", code: "en", flag: englishFlag},
  {name: "spanish", code: "es", flag: spanishFlag},
  {name: "french", code: "fr", flag: frenchFlag},
  {name: "portuguese", code: "pt", flag: brazilFlag},
  {name: "arabic", code: "ar", flag: arabicFlag},
];

const SurveyTranslations = (props) => {
  const {
    survey,
    messages,
    onGenerateTranslation,
    messageType,
    translationState,
    children,
    onInternationalizationDataChange,
    onQuestionChange,
    templateId,
  } = props;

  const {t} = useTranslation();
  const [selectedQuestionId, setSelectedQuestionId] = useState(null);
  const [selectedLanguage, setSelectedLanguage] = useState(0);
  const [anchorEl, setAnchorEl] = useState(null);


  const [translationData, setTranslationData] = useState({});
  const [internationalizationData, setInternationalizationData] = useState([]);

  const {
    mutateAsync: updateQuestionTraslation,
    isLoading: updateQuestionTraslationIsLoading,
  } = useSurveyUpdateQuestionTraslation();

  const {
    mutateAsync: updateTranslationMessage,
    isLoading: updateTranslationMessageIsLoading,
  } = useSurveyUpdateTranslationMessage();

  const turndownService = new TurndownService({
    headingStyle: 'atx',
    codeBlockStyle: 'fenced',
  });

  turndownService.addRule('bold', {
    filter: ['strong', 'b'],
    replacement: (content) => `**${content}**`
  });

  const additionalLanguages = [...survey.additionalLanguages.filter(l => l.name !== survey.defaultLanguage).map(l => ({
    ...l,
    code: l.name.substring(0, 2)
  })), predefinedLanguages.find(l => l.name === survey.defaultLanguage) || {
    name: survey.defaultLanguage,
    flag: null,
    code: survey.defaultLanguage.substring(0, 2)
  }].sort((a,b)=>{
      if (a.name === survey.defaultLanguage) return -1;
      if (b.name === survey.defaultLanguage) return 1;
      return 0;
  });

  const handleMainListItemClick = (event, id) => {
    setSelectedQuestionId(id);
  };

  const handleLanguageSelection = (event, newValue) => {
    setSelectedLanguage(newValue);
  };

  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const messageKeys = useMemo(() => {
    const selectedMessage = messages.find(msg => msg.id === selectedQuestionId);
    if (!selectedMessage || !selectedMessage?.internationalization.length) return [];

    const validKeys = Object.keys(selectedMessage.internationalization[0]);

    const keys = [
      {code: 'shortLabel', label: 'ShortName', remote: ''},
      {code: 'text', label: 'Main text', remote: 'MAIN_TEXT'},
      {code: 'footerText', label: 'Footer', remote: 'FOOTER_TEXT'},
      {code: 'allowedAnswers', label: 'Options', remote: 'OPTIONS'},
      {code: 'title', label: 'Title', remote: 'TITLE'},
    ];

    return keys
      .filter(key => validKeys.includes(key.code))
      .filter(key => messageType !== "translation" || key.code !== "footerText");
  }, [selectedQuestionId, messages, messageType]);


  const containsFooterText = useMemo(() => {
    return messageKeys.some(item => item.code === 'footerText');
  }, [messageKeys]);

  const languagesToExclude = useMemo(() => {
    return additionalLanguages
      .map(lang => lang.name)
      .filter(name =>
        name !== survey.defaultLanguage &&
        !survey.additionalLanguages.some(lang => lang.name === name)
      );
  }, [survey.additionalLanguages, survey.defaultLanguage]);

  const getMessageInternationalization = useMemo(() => {
    const selectedMessage = messages.find(msg => msg.id === selectedQuestionId);
    if (!selectedMessage || !selectedMessage.internationalization.length) return null;
    return selectedMessage?.internationalization.find((i => i.language === (additionalLanguages[selectedLanguage]?.name)));
  }, [messages, selectedQuestionId, selectedLanguage]);

  const getMessageEntity = useMemo(() => {
    return messages.find(msg => msg.id === selectedQuestionId) || null;
  }, [messages, selectedQuestionId]);

  const getAllowedAnswers = useMemo(() => {
    return getMessageInternationalization?.allowedAnswers || [];
  }, [getMessageInternationalization]);

  useEffect(() => {
    if (onInternationalizationDataChange) {
      onInternationalizationDataChange(getMessageInternationalization);
    }
  }, [getMessageInternationalization]);

  useEffect(() => {
    setInternationalizationData(cloneDeep(getMessageEntity)?.internationalization);

    if (onQuestionChange) {
      onQuestionChange(getMessageEntity);
    }
  }, [selectedQuestionId]);

  useEffect(() => {
    if (messages.length > 0 && messages[0]?.id) {
      setSelectedQuestionId((prev) => prev || messages[0]?.id);
    }
  }, [messages.length]);

  useEffect(() => {
    if (templateId) {
      setInternationalizationData(prevState => {
        if (!Array.isArray(prevState)) return prevState;
        return prevState.map(entry =>
          entry.language === additionalLanguages[selectedLanguage]?.name
            ? {...entry, templateId: templateId || ""}
            : {...entry}
        );
      });
    }
  }, [templateId]);

  const handleTranslate = async () => {
    if (onGenerateTranslation) {
      if (messageType === 'question' || messageType === 'whatsappTemplate') {
        await onGenerateTranslation(translationData, selectedQuestionId);
      } else {
        await onGenerateTranslation(translationData, getMessageEntity.key);
      }
      handleClose();
    }
  }

  const handleMainTextEditorChange = (event, editor) => {
    const htmlText = editor.getData();
    const markdownText = turndownService.turndown(htmlText);
    setInternationalizationData(prevState => {
      if (!Array.isArray(prevState)) return prevState;
      return prevState.map(entry =>
        entry.language === additionalLanguages[selectedLanguage]?.name
          ? {...entry, text: markdownText}
          : entry
      );
    });
  };

  const handleTitleTextEditorChange = (event, editor) => {
    const htmlText = editor.getData();
    const markdownText = turndownService.turndown(htmlText);
    setInternationalizationData(prevState => {
      if (!Array.isArray(prevState)) return prevState;
      return prevState.map(entry =>
        entry.language === additionalLanguages[selectedLanguage]?.name
          ? {...entry, title: markdownText}
          : entry
      );
    });
  };

  const handleFooterEditorChange = (event, editor) => {
    const htmlText = editor.getData();
    const markdownText = turndownService.turndown(htmlText);
    const updatedInternationalization = internationalizationData.map(intl => {
      return intl.language === additionalLanguages[selectedLanguage]?.name ? {
        ...intl,
        footerText: markdownText
      } : {...intl};
    });
    setInternationalizationData(updatedInternationalization);
  };

  const handleOptionsEditorChange = (event, editor, index) => {
    const htmlText = editor.getData();
    const markdownText = turndownService.turndown(htmlText);
    setInternationalizationData(prevState => {
      if (!Array.isArray(prevState)) return prevState;

      return prevState.map(entry => {
        if (entry.language === additionalLanguages[selectedLanguage]?.name) {
          if (Array.isArray(entry.allowedAnswers) && entry.allowedAnswers.length > index) {
            const updatedAnswers = [...entry.allowedAnswers];
            updatedAnswers[index].answer = markdownText;
            return {...entry, allowedAnswers: updatedAnswers};
          }
        }
        return entry;
      });
    });
  };

  const handleSaveTranslation = async () => {
    if (messageType === 'question' || messageType === 'whatsappTemplate') {
      await updateQuestionTraslation({
        serviceId: survey.serviceId,
        environment: survey.environment,
        translationData: internationalizationData,
        questionId: selectedQuestionId
      });
    } else {
      await updateTranslationMessage({
        serviceId: survey.serviceId,
        environment: survey.environment,
        translationData: internationalizationData,
        translationKey: getMessageEntity.key
      })
    }
  }

  return (
    <Box sx={{
      display: "flex",
      border: 'solid 1px #E0E0E0',
      borderRadius: '20px',
      width: '1400px'
    }}>
      <Box sx={{width: '425px'}}>
        <MuiQuestionsLabels>
          {t('question_texts')}
        </MuiQuestionsLabels>
        <List component="nav" aria-label="survey main questions translations">
          {
            messages && messages.map(
              message => (
                <MuiBoxQuestion
                  key={message.id}
                  selected={selectedQuestionId === message.id}
                  onClick={(event) => handleMainListItemClick(event, message.id)}
                >
                  <MuiListItemText primary={message.shortLabel}/>
                  {selectedQuestionId === message.id && <IndicatorSidebar/>}
                </MuiBoxQuestion>
              )
            )
          }
        </List>
      </Box>
      <Box sx={{
        width: "975px",
        backgroundColor: "#FAFAFA",
        borderRadius: '20px',
        padding: '24px'
      }}>
        <Box sx={{display: "flex", justifyContent: "space-between"}}>
          <MuiTabs value={selectedLanguage} aria-label="language selector"
                   onChange={handleLanguageSelection} sx={{minHeight: '44px'}}>
            {
              additionalLanguages.map((language, index) => (
                <Tab
                  label={language.name}
                  {...a11yProps(index)}
                  icon={(!!language.flag)?(<img src={language.flag} alt={language.name}
                             style={{width: '20px', height: '20px'}}/>):(<LanguageIcon sx={{width: '20px', height: '20px'}}/>)}
                  iconPosition="start"
                  sx={{minHeight: '44px', fontFamily: "Montserrat !important"}}
                />
              ))
            }
          </MuiTabs>
          <MuiButton
            startIcon={<TranslateIcon/>}
            variant="outlined"
            sx={{
              width: '182px',
              height: '26px',
              fontWeight: 500,
              fontSize: '10px',
              padding: '8px 4px',
              alignSelf: 'center'
            }}
            onClick={handleOpen}
          >
            {t('translate_message')}
          </MuiButton>
          <TranslateTextPopover
            anchorEl={anchorEl}
            onClose={handleClose}
            dataToTranslate={messages}
            dataKeys={messageKeys}
            contentType="singleObject"
            setTranslationData={setTranslationData}
            onTranslate={handleTranslate}
            translationState={translationState}
            presetFromLanguage={additionalLanguages[selectedLanguage]?.name}
            presetExcludeToLanguage={languagesToExclude}
          />
        </Box>
        {messageType === 'question' && <Box sx={{marginTop: '16px'}}>
          <Typography sx={{
            fontFamily: 'Montserrat',
            fontWeight: 400,
            fontSize: '12px',
            marginBottom: '6px'
          }}>
            {t("public_name_interview")}
          </Typography>
          <MuiCKeditorContainer>
            <CKEditor
              key={`editor-title-${selectedLanguage}`}
              editor={ClassicEditor}
              data={getMessageInternationalization?.title || ''}
              config={{
                removePlugins: ['ImageUpload', 'EasyImage', 'MediaEmbed', 'BlockQuote'],
                toolbar: {
                  items: ['bold', 'italic', 'underline', 'strikethrough', 'surveySectionsTranslator']
                },
              }}
              onReady={(editor) => {
                editor.ui.view.toolbar.element.style.backgroundColor = "#F5F5F5";
                editor.ui.view.editable.element.style.backgroundColor = "#FAFAFA";
              }}
              onChange={(e, editor) => handleTitleTextEditorChange(e, editor)}
            />
          </MuiCKeditorContainer>
        </Box>}
        <Box sx={{marginTop: '16px'}}>
          <Typography sx={{
            fontFamily: 'Montserrat',
            fontWeight: 400,
            fontSize: '12px',
            marginBottom: '6px'
          }}>
            {t('main_text')}
          </Typography>
          <MuiCKeditorContainer>
            <CKEditor
              key={`editor-main-text-${selectedLanguage}`}
              editor={ClassicEditor}
              data={getMessageInternationalization?.text || ''}
              config={{
                removePlugins: ['ImageUpload', 'EasyImage', 'MediaEmbed', 'BlockQuote'],
                toolbar: {
                  items: ['bold', 'italic', 'underline', 'strikethrough', 'surveySectionsTranslator']
                },

              }}
              onReady={(editor) => {
                editor.ui.view.toolbar.element.style.backgroundColor = "#F5F5F5";
                editor.ui.view.editable.element.style.backgroundColor = "#FAFAFA";
              }}
              onChange={(e, editor) => handleMainTextEditorChange(e, editor)}
            />
          </MuiCKeditorContainer>
        </Box>
        {containsFooterText && messageType !== 'translation' &&
          <Box sx={{marginTop: '16px'}}>
            <Typography sx={{
              fontFamily: 'Montserrat',
              fontWeight: 400,
              fontSize: '12px',
              marginBottom: '6px'
            }}>
              {t('footer')}
            </Typography>
            <MuiCKeditorContainer>
              <CKEditor
                key={`editor-footer-${selectedLanguage}`}
                editor={ClassicEditor}
                data={getMessageInternationalization?.footerText || ''}
                config={{
                  removePlugins: ['ImageUpload', 'EasyImage', 'MediaEmbed', 'BlockQuote'],
                  toolbar: [
                    'bold', 'italic', 'underline', 'underline', 'strikethrough'
                  ],
                }}
                onReady={(editor) => {
                  editor.ui.view.toolbar.element.style.backgroundColor = "#F5F5F5";
                  editor.ui.view.editable.element.style.backgroundColor = "#FAFAFA";
                }}
                onChange={(e, editor) => handleFooterEditorChange(e, editor)}
              />
            </MuiCKeditorContainer>
          </Box>}
        <Box sx={{
          display: 'grid',
          gap: '16px',
          width: '100%',
          gridTemplateColumns: 'repeat(2, 1fr)'
        }}>
          {getAllowedAnswers && getAllowedAnswers.map((answer, index) => (
            <Box key={index} sx={{
              display: 'flex',
              flexDirection: 'column',
              width: '452px'
            }}>
              <Typography sx={{
                fontFamily: 'Montserrat',
                fontWeight: 400,
                fontSize: '12px',
                marginBottom: '6px',
                marginTop: '12px'
              }}>
                {t('option')} {index + 1}
              </Typography>
              <MuiCKeditorContainer>
                <CKEditor
                  key={`editor-options-${selectedLanguage}`}
                  editor={ClassicEditor}
                  data={answer?.answer || ''}
                  config={{
                    removePlugins: ['ImageUpload', 'EasyImage', 'MediaEmbed', 'BlockQuote'],
                    toolbar: ['bold', 'italic', 'underline', 'strikethrough']
                  }}
                  onReady={(editor) => {
                    editor.ui.view.toolbar.element.style.backgroundColor = "#F5F5F5";
                    editor.ui.view.editable.element.style.backgroundColor = "#FAFAFA";
                  }}
                  onChange={(e, editor) => handleOptionsEditorChange(e, editor, index)}
                />
              </MuiCKeditorContainer>
            </Box>
          ))}
        </Box>
        {messageType === 'whatsappTemplate' && children}
        <MuiButton
          variant="outlined"
          sx={{
            marginTop: '24px',
            borderRadius: '20px',
            width: '162px',
            height: '37px',
            fontWeight: 500,
            fontSize: '13px',
            padding: '10px 24px',
            lineHeight: '17.07px'
          }}
          onClick={handleSaveTranslation}
          disabled={updateTranslationMessageIsLoading || updateQuestionTraslationIsLoading}
        >
          {t('save_changes')}
        </MuiButton>
      </Box>
    </Box>
  );
};
export default SurveyTranslations;
