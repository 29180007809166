import * as React from 'react';
import {useLocation} from 'react-router-dom';
import {
  Autocomplete,
  Button as MuiButton,
  Divider,
  Grid,
  MenuItem,
  TextField,
  ThemeProvider,
  Typography,
} from '@mui/material';
import {
  buttonActionStyle,
  MuiContainer,
  MuiSelectField,
  optionLabelStyle
} from '../styles/formModal';

import themeCrowdView from 'theme/crowdView';

import {useTranslation} from 'components/providers/TranslationProvider';
import {useDispatch} from 'react-redux';
import {setPrivateLoading} from '../../../../store/appSlice';
import {Controller, FormProvider, useForm} from 'react-hook-form';

import {useAuth} from '../../../providers/AuthProvider';
import giveMeId from '../../../../utils/supportComponents';

import Button from 'components/common/Button';
import Toast from 'components/common/Toast';
import Modal from 'components/common/Modal';

import * as yup from 'yup';
import {yupResolver} from '@hookform/resolvers/yup';
import useOrganizations from "../../../../api/hooks/useOrganizations";
import {buttonCancelStyle} from '../scenarios/styles/formModal';
import useOrganizationSurveys
  from "../../../../api/hooks/useOrganizationSurveys";
import {useCopySurvey} from "../../../../api/hooks/useCopySurvey";

const schema = yup.object().shape({
  userToId: yup.string().required(),
  selectedInterviewId: yup.string().required(),
});

const CopyInterviewModal = ({open, onClose}) => {
  const [error, setError] = React.useState('');
  const [openToast, setOpenToast] = React.useState(false);
  const [inputValue, setInputValue] = React.useState('');
  const [selectedOption, setSelectedOption] = React.useState(null);

  const {t} = useTranslation();
  const location = useLocation();
  const dispatch = useDispatch();
  const {user} = useAuth();
  const id = giveMeId(location.pathname, user);
  const {data: organizations, isLoading: isLoadingOrgs} = useOrganizations();
  const {
    data: surveys,
    isLoading
  } = useOrganizationSurveys({organization_id: id});

  const [allOptions] = React.useState(surveys || []);

  const {
    mutateAsync: copySurvey,
    isError: copyIsError,
    isSuccess: copyIsSuccess,
    error: copyError,
    isLoading: copyIsLoading,
  } = useCopySurvey();

  const methods = useForm({
    resolver: yupResolver(schema),
    mode: 'all',
  });

  const {
    control,
    setValue,
    formState: {errors, isDirty},
    getValues,
  } = methods;

  const copyInterview = () => {
    const values = getValues()
    if (values.userToId !== '' && values.interviews) {
      const interviewToCopy = {
        organization_id: values.userToId,
        id: values.interviews._id,
      };
      copySurvey(interviewToCopy).then(rsp => {
        console.log("Interview copied", rsp);
      });
    }
  };

  React.useEffect(() => {
    if (copyIsError) {
      dispatch(setPrivateLoading(false));
      setError(copyError.data?.error ? t(copyError.data.error) : JSON.stringify(copyError));
      setOpenToast(true);
    }
  }, [copyIsError, copyError, t]);

  React.useEffect(() => {
    if (copyIsSuccess) {
      onClose(copyIsSuccess);
    }
  }, [copyIsSuccess]);

  const copyForm = (
    <ThemeProvider theme={themeCrowdView}>
      <MuiContainer display='flex'>
        <Grid item xs={12} sx={{marginTop: '26px'}}>
          <MuiSelectField
            id='userToId'
            name='userToId'
            variant='outlined'
            fullWidth
            error={t(errors.userToId?.message)}
            label={t('copy_to')}
            sx={{
              '& .MuiInputBase-root': {
                width: '400px',
              },
            }}
          >
            <Divider light value=' '/>
            {organizations?.map((org) => {
              return (
                <MenuItem key={org._id} value={org._id}>
                  <Typography
                    variant='body1'
                    fontFamily='Montserrat'
                    fontSize={16}
                    letterSpacing={0.5}
                  >
                    {org.name}
                  </Typography>
                </MenuItem>
              );
            })}
          </MuiSelectField>
        </Grid>
        <Grid style={{paddingTop: 20}} item xs={12}>
          <Controller
            control={control}
            name='interviews'
            defaultValue={[]}
            render={() => (
              <Autocomplete
                id='selectedInterviewId'
                options={allOptions}
                getOptionLabel={(option) => option?.name || ''}
                slotProps={{
                  listbox: {
                    sx: optionLabelStyle
                  }
                }}
                // ListboxProps={{sx: optionLabelStyle}}
                getOptionSelected={(option, value) => option?._id === value?._id}
                disabled={isLoading}
                value={selectedOption}
                onChange={(e, value) => {
                  console.log("Selected interview", value);
                  setValue('interviews', value);
                  setSelectedOption(value)
                }}
                sx={{
                  width: '400px'
                }}
                filterSelectedOptions
                inputValue={inputValue}
                onInputChange={(e, newInputValue) => {
                  setInputValue(newInputValue);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label='Interview to copy'
                    name='interviews'
                    variant='outlined'
                    fullWidth
                  />
                )}
              />
            )}
          />
        </Grid>
      </MuiContainer>
    </ThemeProvider>
  );

  const handleCloseToast = () => setOpenToast(false);

  const actions = (
    <>
      <MuiButton onClick={onClose} variant='text' sx={buttonCancelStyle}>
        <Typography fontFamily='Montserrat' fontSize={14} letterSpacing={0.25}>
          {t('cancel_btn')}
        </Typography>
      </MuiButton>
      <Button
        style={{...buttonActionStyle, width: '105px'}}
        variant='outlined'
        type='submit'
        loading={copyIsLoading}
        disabled={!isDirty}
        onClick={() => copyInterview()}>
        {!isLoading || !isLoadingOrgs ?
          <Typography fontFamily='Montserrat' fontSize={14}
                      letterSpacing={0.25}>
            {t('copy')}
          </Typography>
          : <Typography fontFamily='Montserrat' fontSize={14}
                        letterSpacing={0.25}>
            processing...
          </Typography>}
      </Button>
    </>
  );

  return (
    <FormProvider {...methods}>
      <Toast
        open={openToast}
        handleClose={handleCloseToast}
        severity='error'
        vertical='top'
        horizontal='center'
        message={error}
      />
      <Modal
        open={open}
        onClose={onClose}
        title={t('copy_interview')}
        actions={actions}
      >
        {copyForm}
      </Modal>
    </FormProvider>
  );
};

export default CopyInterviewModal;
