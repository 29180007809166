import * as React from 'react';
import {
  Box,
  CircularProgress,
  Grid,
  IconButton,
  InputAdornment,
  Typography
} from '@mui/material';
import {FormProvider, useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import * as yup from 'yup';
import {NavLink, useLocation, useNavigate} from 'react-router-dom';

import {useTranslation} from 'components/providers/TranslationProvider';
import {useAuth} from 'components/providers/AuthProvider';
import Button from 'components/common/Button';
import GoogleBrand from 'assets/img/google.png';
import MicrosoftBrand from 'assets/img/microsoft.png';
// Style and asset imports
import {
  buttonSocial,
  buttonSocialMicrosoft,
  buttonStyle,
  circularProgressStyle,
  fieldsForm,
  forgotPassStyle,
  inputAdornmentStyle,
  langContainer,
  MuiButtonColored,
  MuiDivPaper,
  MuiForm,
  MuiGoogleButton,
  MuiGridContainerButtons,
  MuiTextField,
  MuiTypography,
  MuiVisibilityIcon,
  MuiVisibilityOffIcon,
  spacingStyle,
  styleTitle,
  subTitleStyle,
  orText,
} from './styles/login';
import LanguageSelector from '../../common/LanguageSelector';
import {MdLockOutline, MdOutlinePerson} from 'react-icons/md';

const schema = yup.object().shape({
  email: yup.string().email('email_required').required('required'),
  password: yup.string().min(8, 'min_4_characters').required('required'),
});

const actionTypes = {
  SUBMIT_FORM: 'submit_form',
  LOGIN_SOCIAL_GOOGLE: 'google',
  LOGIN_SOCIAL_MICROSOFT: 'microsoft'
}

const Login = () => {
  const [redirectURL, setRedirectURL] = React.useState('/orgs');
  const [showPassword, setShowPassword] = React.useState(false);
  const [actionTaken, setActionTaken] = React.useState('');

  const {t} = useTranslation();
  const {
    login,
    isPending,
    loginWithSocial
  } = useAuth();

  const togglePasswordVisibility = () => setShowPassword(!showPassword);

  const location = useLocation();
  const navigate = useNavigate();

  React.useEffect(() => {
    const currentPath = location.pathname;
    if (currentPath !== '/login') {
      setRedirectURL(currentPath);
      navigate('/login', {replace: true});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const methods = useForm({
    resolver: yupResolver(schema),
    mode: 'all',
  });

  const {
    handleSubmit,
    formState: {errors},
    setError,
  } = methods;


  const handleError = (e) => {
    setError('password', { type: 'manual', message: e });
  };

  const onSubmit = (data) => {
    setActionTaken(actionTypes.SUBMIT_FORM);
    return login(data, redirectURL, handleError)
  };
  const loginSocial = (provider) => {
    setActionTaken(provider);
    return loginWithSocial(provider, redirectURL, handleError)
  };


  const GoogleIcon = () => {
    return (
      <img src={GoogleBrand} alt="Google"/>
    );
  }

  const MicrosoftIcon = () => {
    return (
      <img src={MicrosoftBrand} alt="Microsoft"/>
    );
  }


  return (
    <MuiDivPaper id={"login"}>
      <Box sx={{
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems:'center',
        width:'100%',
      }}>
        <LanguageSelector/>
      </Box>
      <Box sx={{
        display:'flex',
        flexDirection:'column',
        width:'100%'
      }}>
        <Box sx={{
          display:'flex',
          flexDirection:'column',
          width:'100%',
          gap:'18px',
        }}>
          <img src={'img/cv-welcome-logo.svg'} alt={'logo'} style={{
            height:'165px'
          }}/>
          <Box sx={{
            display:'flex',
            flexDirection:'column',
          }}>
            <Typography variant="h6" sx={subTitleStyle}>
              {t('qualitative')}
            </Typography>
            <Typography variant="h6" sx={subTitleStyle}>
              {t('login_subtitle')}
            </Typography>
          </Box>
        </Box>
        <Grid container direction={'column'} justifyContent="center"
              sx={{marginTop: '38px'}}>
          <Grid container item alignItems={"center"} direction={'column'}>
            <Grid item>
              <MuiGoogleButton
                variant='outlined'
                color='primary'
                size='large'
                disabled={isPending}
                startIcon={<GoogleIcon/>}
                onClick={() => loginSocial('google')}
                sx={{ marginBottom: '7px' }}
              >
                {isPending && actionTaken === actionTypes.LOGIN_SOCIAL_GOOGLE ? (
                  <CircularProgress style={circularProgressStyle} size={26}/>
                ) : (
                  <Typography fontFamily='Montserrat'
                              sx={buttonSocial}>{t('login_google')}</Typography>
                )}
              </MuiGoogleButton>
            </Grid>
            <Grid item>
              <MuiGoogleButton
                variant='outlined'
                color='primary'
                size='large'
                disabled={isPending}
                startIcon={<MicrosoftIcon/>}
                onClick={() => loginSocial('microsoft')}
              >
                {isPending && actionTaken === actionTypes.LOGIN_SOCIAL_MICROSOFT  ? (
                  <CircularProgress style={circularProgressStyle} size={26}/>
                ) : (
                  <Typography fontFamily='Montserrat'
                              sx={buttonSocialMicrosoft}>{t('login_microsoft')}</Typography>
                )}
              </MuiGoogleButton>
            </Grid>
          </Grid>
          <Grid item>
            <MuiTypography sx={orText}>{t('o_or')}</MuiTypography>
          </Grid>

          <Grid container item justifyContent={'center'}>
            <FormProvider {...methods}>
              <MuiForm onSubmit={handleSubmit(onSubmit)} style={{width: '312px'}}>
                <Grid container>
                  <Grid item>
                    <MuiTextField
                      variant='outlined'
                      fullWidth
                      label={t('email')}
                      placeholder="email@example.com"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <MdOutlinePerson style={inputAdornmentStyle}/>
                          </InputAdornment>
                        ),
                      }}
                      name='email'
                      autoComplete='email'
                      error={t(errors.email?.message)}
                      sx={{...fieldsForm, paddingBottom: '0px !important', marginBottom: '16px', marginTop: '0px'}}
                    />
                  </Grid>
                  <Grid item>
                    <MuiTextField
                      variant='outlined'
                      fullWidth
                      name='password'
                      placeholder="************"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <MdLockOutline style={inputAdornmentStyle}/>
                          </InputAdornment>
                        ),
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton sx={{color: '#6793A5'}}
                                        onClick={togglePasswordVisibility}>
                              {showPassword ? <MuiVisibilityOffIcon/> : <MuiVisibilityIcon/>}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      type={showPassword ? 'text' : 'password'}
                      autoComplete='current-password'
                      error={t(errors.password?.message)}
                      label={t('password')}
                      sx={{...fieldsForm, paddingBottom: '0px !important', marginTop: '0px !important', marginBottom: 0}}
                    />
                  </Grid>
                </Grid>
                <MuiGridContainerButtons
                  container
                  sx={spacingStyle} display='flex'
                >
                  <Grid item>
                    <Button
                      size="small"
                      sx={buttonStyle}
                      variant="text"
                      component={NavLink}
                      to='/forgot-password'
                    >
                      <Typography variant='caption' style={forgotPassStyle}>
                        {t('forgot_password')}
                      </Typography>
                    </Button>
                  </Grid>
                  <MuiButtonColored
                    loading={ isPending && actionTaken === actionTypes.SUBMIT_FORM }
                    type='submit'
                    data-cy='login'
                    sx={{ boxShadow: 'none' }}
                  >
                    {isPending ? t('login...') : t('login')}
                  </MuiButtonColored>
                </MuiGridContainerButtons>
              </MuiForm>
              {/*<Typography style={{fontFamily:'Poppins', fontSize: '10px', width:'312px'}}>Al continuar aceptas las <strong>Condiciones de servicio</strong> de CRITERIA y reconoces que leíste nuestra <strong>Política de Privacidad</strong></Typography>*/}
            </FormProvider>
          </Grid>
        </Grid>
      </Box>
    </MuiDivPaper>
  );
};
export default Login;
