import axiosSurveyInstance from "../axios/axiosSurveyInstance";
import {status200} from "../status.utils";
import {useMutation} from "react-query";

const provisionNetworkInfrastructure = async (
  service_id, environment = "prod"
) => {
  const tgCreationResponse = await axiosSurveyInstance.post(`/api/tg/create`, {
    service_id, environment
  }, status200).then((resp) => resp.data);
  if (tgCreationResponse.status !== "success") {
    throw new Error("Target group creation failed")
  }
  return axiosSurveyInstance.post(`/api/lb/create`, {
    service_id, environment
  }, status200).then((resp) => resp.data);
}

const provisionResponseProcessor = async (
  service_id, environment = "prod"
) => {
  return axiosSurveyInstance.post(`/api/processor/create/response`, {
    service_id, environment
  }).then(resp => resp.data);
}

const provisionAudioProcessor = async (service_id, environment = "prod") => {
  return axiosSurveyInstance.post(`/api/processor/create/audio`, {
    service_id, environment
  }).then(resp => resp.data);
}

const provisionCommunicationInterfaceAPI = async (
  service_id,
  environment = "dev"
) => {
  const clusterResponse = await axiosSurveyInstance.post(`/api/cl/create`, {
    service_id, environment
  }).then((resp) => resp.data);
  if (clusterResponse.status !== "success") {
    throw new Error("Cluster creation failed")
  }
  const taskDefinitionResponse = await axiosSurveyInstance.post(`/api/td/create`, {
    service_id, environment
  }).then(rsp => rsp.data);
  if (taskDefinitionResponse.status !== "success") {
    throw new Error("Task definition creation failed")
  }
  return axiosSurveyInstance.post(`/api/srv/create`, {
    service_id, environment
  }).then(resp => resp.data);
}

async function provisionResponseProcessors(service_id, environment) {
  const responseProcessor = provisionResponseProcessor(service_id, environment);
  const audioProcessor = provisionAudioProcessor(service_id, environment);

  return await Promise.all([responseProcessor, audioProcessor]).then(responses => {
    if (responses.some(r => r.status !== "success")) {
      throw new Error("Processor creation failed");
    }
    return {
      status: "success"
    };
  });
}


export const useProvisionNetworkingInfrastructure = () => {
  return useMutation(async ({
                              serviceId,
                              environment
                            }) => provisionNetworkInfrastructure(serviceId, environment), {
      onSuccess: (data) => data,
      onError: (error) => {
        console.error(error);
        return error;
      },
      onSettled: (data) => {
        if (data) {
          return data;
        }
      }
    }
  );
}

export const useProvisionResponseProcessors = () => {
  return useMutation(async ({
                              serviceId,
                              environment
                            }) => provisionResponseProcessors(serviceId, environment), {
    onSuccess: (data) => data,
    onError: (error) => {
      console.error(error);
      return error;
    },
    onSettled: (data) => {
      if (data) {
        return data;
      }
    }
  })
}


export const useProvisionDefaultCommunicationInterface = () => {
  return useMutation(async ({
                              serviceId,
                              environment
                            }) => provisionCommunicationInterfaceAPI(serviceId, environment), {
    onSuccess: (data) => data,
    onError: (error) => {
      console.error(error);
      return error;
    },
    onSettled: (data) => {
      if (data) {
        return data;
      }
    }
  })
}

/**
 * How to use it:
 *
 * const {
 *     mutateAsync: provisionInfrastructure,
 *     isError: infrastructureProvError,
 *     error: provisioningError
 *   } = useProvisionInfrastructure();
 *
 *   const service_id = "test";
 *   const environment = "prod";
 *
 *  response= await provisionInfrastructure(service_id,environment);
 *  console.log(response);
 *  >>>{"message":"Service created successfully", "status":"success", "service_arn": "arn:aws:ecs:us-east-1:134657922211:service/test-prod-cluster/test-prod-service"}
 *
 *  useEffect(() => {
 *    if (infrastructureProvError) {
 *      console.error(provisioningError);
 *      // DISABLE THE OPTION TO TEST THE SURVEY. Figure out what to do next
 *      //Most likely cause for the process to fail is a network error, or
 *      //that the name generated from the user-friendly name given to the survey
 *      //was not suitable to create infrastructure resources.
 *    }
 *  }, [infrastructureProvError]);
 */

