import {useQuery} from "react-query";
import axiosSurveyInstance from "../axios/axiosSurveyInstance";

const fetchSurveyResponsesByUser = async (data) => {
  return axiosSurveyInstance.request({
    url: '/api/survey/analytics/responses-by-user',
    data: data,
    method: 'post',
    maxBodyLength: Infinity
  }).then(rsp => rsp.data).then((data) => {
    return data
  }).catch(err => {
    console.log("Error fetching survey responses by user", err)
    return {
      data: [],
      totalRespondents: 0
    };
  })
}

const useSurveyResponsesByUser = (data) => useQuery(
  ['survey-responses-by-user', data?.service_id, data?.environment, data?.filterQuestionId, data?.filterResponses, data?.pageStart, data?.pageEnd, data?.startDate, data?.endDate,data?.targetStatus,data?.channels],
  () => fetchSurveyResponsesByUser(data),
  {
    enabled: Boolean(data?.service_id && data?.environment && data?.pageEnd),
    refetchOnWindowFocus: false,
    placeholderData: {data: null, totalRespondents: 0}
  }
);

export default useSurveyResponsesByUser;
