import {Badge, Box, lighten, styled, Typography} from "@mui/material";

export const MuiTypography = styled(Typography)(({ theme }) => ({
  cursor: 'pointer',
  fontFamily: 'Montserrat',
  textAlign: 'start',
  width: 'auto',
  letterSpacing: '0.5px',
}));

export const MuiBoxButtonsAction = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  marginBottom:'16px',
  marginTop:'6px',
  width: '100%'
}));

export const selectStyle = {
  width : 229,
  height: 56,
  '& .MuiInputBase-input': {
    fontFamily: 'Montserrat',
  },
  '& .MuiFormLabel-root': {
    fontFamily: 'Montserrat',
    fontSize: 12,
  }
}
export const labelStyle = {
  fontFamily: 'Montserrat',
  fontSize: 12,
  fontWeight: 400
}
export const styleBoxCard = {
  minWidth: '295px',
  maxHeight: '600px',
  height:'100%',
  padding: '16px 16px 0 16px',
  backgroundColor: '#f5f5f5',
  borderRadius: '19px',
  boxShadow: 'none',
  display: 'flex',
  flexDirection: 'column',
  justifyContent:'center'
}
export const cardMedia = {
  height: '190px',
  width: '253px',
  borderRadius: '11px'
}

export const BadgeTopics = styled(Badge)(({ theme }) => ({
  '.MuiBadge-badge':{
    backgroundColor: lighten(theme.palette.primary.main, 0.6),
    fontFamily: 'Montserrat',
    fontSize: 10,
  }
}))
