import * as React from 'react';
import NewSurvey from './NewSurvey';
import {
  Grid,
  Box
} from '@mui/material';
import {MuiTypographyTitle} from '../styles/actionBar';
import {useTranslation} from '../../../providers/TranslationProvider';
import FileCopyIcon from "@mui/icons-material/FileCopy";
import {MuiButton} from "../styles/scenario";
import {useLocation, useNavigate} from "react-router-dom";
import CopyInterviewModal from "./CopyInterviewModal";

const ActionBar = () => {
  const [open, setOpen] = React.useState(false);

  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const query = new URLSearchParams(location.search);

  const handleClose = () => {
    query.delete('create');
    navigate(`${location.pathname}${query && ''}`, { replace: true });
  };

  const handleCopyInterview = () =>
    navigate(`${location.pathname}?copy=interview`, {
      replace: true,
    });

  React.useEffect(() => {
    const createQuery = query.get('copy');
    setOpen(createQuery === 'interview');
  }, [query]);

  return (
    <>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', marginLeft: '5px' }}>
        <Box>
          <MuiTypographyTitle variant='subtitle1'>
            {t('surveys_list')}
          </MuiTypographyTitle>
        </Box>
        <Box sx={{ display: 'flex', gap: '10px' }}>
          <MuiButton
            variant='outlined'
            color='primary'
            startIcon={<FileCopyIcon />}
            onClick={handleCopyInterview}
          >
            {t('copy_interview')}
          </MuiButton>
          <NewSurvey />
        </Box>
      </Box>
      {open && <CopyInterviewModal action='create' open={open} onClose={handleClose} />}
    </>
  );
};

export default ActionBar;
