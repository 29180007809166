import * as React from 'react';
import {Grid, Paper} from '@mui/material';
import {ThemeProvider} from '@mui/material/styles';

import {Outlet} from 'react-router-dom';

import logo from '../../../assets/img/CrowdViewImgLogin.png';
import themeCrowdView from 'theme/crowdView';
import {MuiGridRoot} from './styles/login';

const LoginLayout = () => {

  return (
    <ThemeProvider theme={themeCrowdView}>
      <MuiGridRoot container component='main'>
        <Grid item xs={false} sm={false} md={8} sx={{
          backgroundImage: `url(${logo})`,
          backgroundRepeat: 'no-repeat',
          backgroundColor: (t) =>
            t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          maxWidth: '100% !important', flexGrow: '1 !important',
          boxSizing: 'border-box',
          height:{
            xs:'100vh',
            sm:'100vh',
            md:'100vh',
            lg:'100vh',
            xl:'100vh'
          }
        }}>
        </Grid>
        <Grid item xs={12} sm={12} md={4} sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'flex-start',
          minWidth: '300px',
          maxWidth:'556px !important',
          width:'556px !important',
          boxSizing: 'border-box',
          height: '100vh',
        }}>
          <Outlet />
        </Grid>
      </MuiGridRoot>
    </ThemeProvider>
  );
};

export default LoginLayout;
