import Modal from "../../../common/Modal";
import React from "react";
import {Box, IconButton, Typography} from "@mui/material";
import QrCodeIcon from '@mui/icons-material/QrCode';
import {useTheme} from "../../../providers/CustomThemeProvider";
import {lighten} from "@mui/material/styles";
import QRCode from "react-qr-code";
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import {useTranslation} from "../../../providers/TranslationProvider";

const QRModal = ({open, handleClose, survey}) => {
  const {theme} = useTheme();
  const qrvalue = `https://chat.crowdvoice.ai/landing?mode=whatsapp&service_id=${survey.id}&environment=${survey.environment || 'dev'}`;

  const {t} = useTranslation();
  const handleStart = () => {
    window.open(qrvalue, "_blank");
  };

  const modalForm = (
    <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
      <QrCodeIcon sx={{
        fontSize: 32,
        padding: '4px',
        color: lighten(theme.palette.primary.main, 0.6),
        borderRadius: '50%',
        backgroundColor: lighten(theme.palette.primary.main, 0.9)
      }}/>
      <Typography sx={{
        fontFamily: 'Raleway',
        fontSize: '20px',
        color: '#212121',
        marginTop: '16px'
      }}
      >
        {t('ready_to_test_chat')}
      </Typography>
      <Typography sx={{
        fontFamily: 'Montserrat',
        fontSize: '10px',
        color: '#424242',
        marginTop: '16px',
        textAlign: 'center',
        textTransform: 'uppercase'
      }}>
        {t('click_to_start_web_chat')}
      </Typography>
      <Box sx={{
        padding: '18px',
        mt: '16px'
      }}>
        <QRCode value={qrvalue} size={274}
                fgColor={lighten(theme.palette.primary.main, 0.1)}/>
      </Box>
    </Box>
  );

  const actions = (
    <Box sx={{display: 'flex', justifyContent: 'center', gap: '8px'}}>
      <IconButton
        sx={{
          display: "flex",
          alignItems: "center",
          gap: "5px",
          borderRadius: "20px",
          height: "38px",
          width: "112px",
          padding: "10px 16px",
          color: lighten(theme.palette.primary.main, 0.1),
          fontFamily: "Montserrat",
          fontSize: "14px",
          textTransform: "uppercase"
        }}
        onClick={handleClose}
      >
        {t('cancel_generic_btn')}
      </IconButton>
      <IconButton
        sx={{
          display: "flex",
          alignItems: "center",
          gap: "5px",
          border: `1px solid ${lighten(theme.palette.primary.main, 0.2)}`,
          borderRadius: "20px",
          height: "38px",
          width: "189px",
          padding: "10px 24px 10px 16px",
          color: lighten(theme.palette.primary.main, 0.1),
          fontFamily: "Montserrat",
          fontSize: "14px",
          textTransform: "uppercase"
        }}
        onClick={() => handleStart()}
      >
        <PlayCircleOutlineIcon
          sx={{color: lighten(theme.palette.primary.main, 0.2)}}/>
        {t('start_web_chat')}
      </IconButton>
    </Box>
  );

  return (
    <Modal open={open}
           onClose={handleClose}
           widthInPixels={360}
           heightInPixels={550}
           actions={actions}
    >
      {modalForm}
    </Modal>
  );
}
export default QRModal;
