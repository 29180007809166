import axiosSurveyInstance from "../axios/axiosSurveyInstance";
import {status200} from "../status.utils";
import {useMutation, useQueryClient} from "react-query";

const publishSurvey = (service_id) => {
  return axiosSurveyInstance.post(`api/survey/publish?service_id=' + ${service_id}`, {
    service_id
  }, status200).then((resp) => {
    const {data} = resp;
    return data || null;
  })
}

export const usePublishSurvey = () => {
  const queryClient = useQueryClient();
  return useMutation(async (values) => await publishSurvey(values), {
    onSuccess: (data) => {
      queryClient.resetQueries('publish');
      return data;
    },
    onError: (error) => {
      console.error(error);
      return error;
    },
    onSettled: (data) => {
      queryClient.invalidateQueries('publish');
      return data;
    },
  })
};
