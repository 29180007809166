import {lighten, styled, ToggleButton, ToggleButtonGroup} from "@mui/material";

export const DetailLevelToggleGroup = styled(ToggleButtonGroup)(({theme}) => ({
  height: '32px',
  borderRadius: '16px',
  padding: '4px',
  border: `1px solid ${theme.palette.primary.main}`,
  backgroundColor: lighten(theme.palette.primary.main, 0.8),
  gap: '4px',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center'
}))

export const DetailLevelToggleButton = styled(ToggleButton)(({theme}) => ({
  height: '100%',
  borderRadius: '16px',
  padding: '12px 10px',
  color: theme.palette.primary.main,
  border: '1px solid transparent',
  backgroundColor: 'transparent',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  fontFamily: 'Montserrat',
  fontSize: '12px',
  letterSpacing:'0.004em',
  textTransform:'none',
  '&.Mui-selected': {
    backgroundColor: theme.palette.primary.main,
    color: '#FFFFFF',
    borderRadius:'12px'
  },
  '&.MuiToggleButton-root:hover': {
    borderRadius: '12px',
    border:`1px solid ${lighten(theme.palette.primary.main, 0.5)}`,
    backgroundColor: lighten(theme.palette.primary.main, 0.8)
  },
  '&.MuiToggleButton-root.Mui-selected:hover': {
    borderRadius: '12px',
    border:`1px solid ${theme.palette.primary.main}`,
    backgroundColor: lighten(theme.palette.primary.main,0.2)
  }
}))
