import axiosSurveyInstance from "../axios/axiosSurveyInstance";
import {useQuery} from "react-query";

const fetchOrganizationSurveys = ({organization_id}) => {
  return axiosSurveyInstance.get(`/api/survey/list/${organization_id}?include_status=true`).then((resp) => {
    const {data} = resp;
    return data?.['surveys'] || [];
  }).catch((err) => {
    console.error("Error fetching organization surveys", err);
    return [];
  });
}

const useOrganizationSurveys = ({organization_id}) => useQuery(
  ['organizationSurveys', organization_id],
  () => fetchOrganizationSurveys({organization_id}),
  {
    enabled: Boolean(organization_id),
    refetchOnWindowFocus: false
  }
);

export default useOrganizationSurveys;
