import {
  useState,
  useMemo, useEffect,
} from 'react';
import {
  MuiTextfield,
} from 'components/views/organizations/surveys/styles/generalInformation';
import SurveyTranslations from '../SurveyTranslations';
import {
  Box,
  Typography,
  Grid2 as Grid,
  IconButton,
  lighten, CircularProgress,
} from "@mui/material";
import useQuestions from 'api/hooks/useQuestions';
import useSurveyGenerateQuestionTraslation
  from "api/hooks/useSurveyGenerateQuestionTraslation";
import {
  MuiButton,
  MuiSubTitle,
} from 'components/views/organizations/surveys/styles/translations';
import {
  MuiStatus,
  MuiIndicatorStatus,
} from 'components/views/organizations/surveys/styles/whatsapptemplate';
import {useTranslation} from 'components/providers/TranslationProvider';
import {
  Translate as TranslateIcon,
  PlayArrow as PlayArrowIcon
} from '@mui/icons-material';
import {useTheme} from 'components/providers/CustomThemeProvider';
import useSurveyRequestQuestionTemplate
  from "api/hooks/useSurveyRequestQuestionTemplate";
import QRModal from "components/views/organizations/surveys/QRModal.js";
import useSurveyTemplateStatus from "api/hooks/useSurveyTemplateStatus.js";
import TranslateTextPopover from "../TranslateTextPopover";
import spanishFlag from "assets/img/spanish.png";
import englishFlag from "assets/img/english.png";
import frenchFlag from "assets/img/french.png";
import brazilFlag from "assets/img/portuguese-brazil.png";
import arabicFlag from "assets/img/arabic.png";
import {useLoading} from 'components/providers/LoadingProvider.js';
import TryInterview from "../TryInterview";

const WhatsappTemplate = (props) => {

  const {
    survey,
    setSurvey,
  } = props;

  const {
    data: questions,
  } = useQuestions({
    serviceId: survey.serviceId,
  });

  const {
    mutateAsync: requestQuestionTemplate,
    isLoading: requestQuestionTemplateIsLoading,
  } = useSurveyRequestQuestionTemplate();

  const [anchorEl, setAnchorEl] = useState(null);
  const [translationData, setTranslationData] = useState({});
  const [internationalizationData, setInternationalizationData] = useState({});
  const [questionData, setQuestionData] = useState({});
  const testInterview = survey && survey.status === 'Running';
  const [modalQR, setModalQR] = useState(false);

  const {t} = useTranslation();
  const questionMessages = useMemo(() => {
    if (questions) {
      return questions.map(q => ({
        id: q.id,
        shortLabel: q.shortLabel,
        internationalization: q.internationalization,
      }));
    }
    return [];
  }, [questions]);

  const {theme} = useTheme();

  const {
    mutateAsync: generateQuestionTraslation,
    isLoading: questionTraslationIsLoading,
  } = useSurveyGenerateQuestionTraslation();

  const {
    setIsLoading,
    setTitle,
    setProgress,
    setProgressMessage,
    setShowProgressBar,
    setIsClassificationLoading
  } = useLoading();

  const {
    data: templateStatus,
    error: templateStatusError,
    refetch: refetchTemplateStatus,
    isLoading: templateStatusIsLoading
  } = useSurveyTemplateStatus({
    serviceId: survey.serviceId,
    env: survey.environment,
    language: internationalizationData?.language,
    questionId: questionData?.id,
  });

  const questionTranslationState = {
    isLoading: questionTraslationIsLoading
  };

  const templateStatusMapped = useMemo(() => {
    if (templateStatusError?.message) {
      return {
        color: '#FF5549',
        message: templateStatusError?.message
      }
    } else if (templateStatus?.templateStatus === 'approved') {
      return {
        message: templateStatus?.templateStatus,
        color: '#4CAF50'
      }
    } else {
      return {
        message: templateStatus?.templateStatus || 'Loading...',
        color: '#007AFF'
      }
    }

  }, [templateStatus, templateStatusError?.message]);

  useEffect(() => {
    if (templateStatus?.templateStatus !== 'received' && templateStatus?.templateStatus !== 'pending') {
      return;
    }
    const interval = setInterval(() => {
      if (templateStatus?.templateStatus === 'received' || templateStatus?.templateStatus === 'pending') {
        refetchTemplateStatus();
      }
    }, 5000);
    return () => clearInterval(interval);

  }, [templateStatus]);

  const messageKeys = useMemo(() => (
    [
      {
        code: 'text',
        label: 'Main text',
        remote: 'MAIN_TEXT',
      },
      {
        code: 'footerText',
        label: 'Footer',
        remote: 'FOOTER_TEXT',
      },
      {
        code: 'allowedAnswers',
        label: 'Options',
        remote: 'OPTIONS',
      },
      {
        code: 'title',
        label: 'Title',
        remote: 'TITLE',
      }
    ]
  ), []);

  const predefinedLanguages = [
    {name: "english", code: "en", flag: englishFlag},
    {name: "spanish", code: "es", flag: spanishFlag},
    {name: "french", code: "fr", flag: frenchFlag},
    {name: "portuguese", code: "pt", flag: brazilFlag},
    {name: "arabic", code: "ar", flag: arabicFlag},
  ];

  const languagesToExclude = useMemo(() => {
    return predefinedLanguages
      .map(lang => lang.name)
      .filter(name =>
        name !== survey.defaultLanguage &&
        !survey.additionalLanguages.some(lang => lang.name === name)
      );
  }, [survey.additionalLanguages, survey.defaultLanguage]);

  const handleGenerateQuestionTranslation = async (translationData, selectedQuestionId) => {
    await generateQuestionTraslation({
      serviceId: survey.serviceId,
      environment: survey.environment,
      sourceLanguage: translationData.from,
      targetLanguages: translationData.to,
      questionSections: translationData.selectedQuestionsSections.map((s) => s.remote),
      questionId: selectedQuestionId,
    });
  };

  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onInternationalizationDataChange = (internationalizationData) => {
    setInternationalizationData(internationalizationData);
  }
  const onQuestionChange = (selectedQuestionData) => {
    setQuestionData(selectedQuestionData);
  }

  const updateTemplateId = (e) => {
    setInternationalizationData((prev) => ({
      ...prev,
      templateId: e.target.value,
    }))
  }

  const handleRequestQuestionTemplate = async () => {
    const rsp = await requestQuestionTemplate({
      questionId: questionData?.id,
      environment: survey.environment,
      serviceId: survey.serviceId,
      language: internationalizationData?.language,
    });
    console.log(rsp);
    refetchTemplateStatus();
  }

  const handleTranslate = async () => {
    setIsLoading(true);
    setProgress(0);
    setTitle(t('translating_selected_text'));
    setShowProgressBar(true);
    setIsClassificationLoading(true);

    const totalQuestions = translationData?.selectedQuestions?.length || 0;
    const globalTotalMessages = totalQuestions;

    let globalCounter = 0;

    const updateProgress = () => {
      setProgress(Math.round((globalCounter / globalTotalMessages) * 100));
    };

    if (globalTotalMessages === 0) {
      setProgress(100);
      setIsLoading(false);
      setIsClassificationLoading(false);
      return;
    }

    for (const [index, q] of translationData.selectedQuestions.entries()) {
      setProgressMessage(`${t('translating_selected_questions')} ${index + 1}/${totalQuestions}`);
      await handleGenerateQuestionTranslation(translationData, q.id);
      globalCounter++;
      updateProgress();
      await new Promise(resolve => setTimeout(resolve, 200));
    }

    setProgress(100);
    setIsLoading(false);
    setProgress(0);
    setTitle('');
    setShowProgressBar(false);
    setIsClassificationLoading(false);
    handleClose();
  };

  return (
    <>
      <Grid item xs={12}>
        <Box sx={{
          display: "flex",
          justifyContent: "space-between",
          marginTop: '35px'
        }}>
          <MuiSubTitle sx={{marginTop: '30px', marginBottom: '18px'}}>
            {t('whatsapp_template')}
          </MuiSubTitle>
          <Box sx={{display: "flex"}}>
            <MuiButton
              startIcon={<TranslateIcon/>}
              variant="outlined"
              sx={{
                marginRight: '12px',
                padding: '4px 12px 4px 8px',
                borderRadius: '13px',
                width: '228px',
                height: '26px',
                alignSelf: 'center'
              }}
              onClick={handleOpen}
            >
              {t('translate_full_interview')}
            </MuiButton>
            <TryInterview survey={survey}/>
            <TranslateTextPopover
              anchorEl={anchorEl}
              onClose={handleClose}
              dataToTranslate={questionMessages}
              dataKeys={messageKeys}
              contentType="multipleObjects"
              setTranslationData={setTranslationData}
              onTranslate={handleTranslate}
              presetFromLanguage={internationalizationData?.language}
              presetExcludeToLanguage={languagesToExclude}
            />
          </Box>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <SurveyTranslations
          survey={survey}
          setSurvey={setSurvey}
          messages={questionMessages}
          onGenerateTranslation={handleGenerateQuestionTranslation}
          messageType="whatsappTemplate"
          translationState={questionTranslationState}
          onInternationalizationDataChange={onInternationalizationDataChange}
          onQuestionChange={onQuestionChange}
          templateId={internationalizationData?.templateId || ''}
        >
          <>
            <Typography sx={{
              marginTop: '12px',
              marginBottom: '14px',
              fontWeight: 400,
              fontSize: '12px'
            }}>{t('whatsapp_template')}</Typography>
            <Box sx={{display: 'flex', alignItems: "center"}}>
              <MuiTextfield
                label={`${t('template_id')}:`}
                variant="outlined"
                sx={{width: '452px'}}
                value={internationalizationData?.templateId || ''}
                onChange={updateTemplateId}
              />
              <Box sx={{marginLeft: '23px', marginRight: '32px'}}>
                <MuiStatus>{t('status')}:</MuiStatus>
                {(templateStatusIsLoading) ? (
                  <CircularProgress size={'28px'}/>
                ) : (
                  <MuiIndicatorStatus
                    sx={{
                      backgroundColor: templateStatusMapped.color,
                      display: 'flex',
                      flexDirection: 'column',
                    }}
                  >
                    <Typography sx={{
                      fontFamily: 'Montserrat',
                      fontWeight: 400,
                      fontSize: '13px',
                      color: '#FFFFFF'
                    }}>
                      {
                        templateStatusMapped?.message
                          ? templateStatusMapped.message.charAt(0).toUpperCase() + templateStatusMapped.message.slice(1)
                          : ''
                      }
                    </Typography>
                  </MuiIndicatorStatus>
                )}
              </Box>
              <MuiButton
                variant="outlined"
                sx={{
                  width: '235px',
                  height: '26px',
                  borderRadius: '13px',
                  fontWeight: 500,
                  fontSize:
                    '10px',
                  padding:
                    '4px 12px',
                  lineHeight: '100%',
                  letterSpacing: '0.1px',
                  top: '9px',
                }}
                onClick={handleRequestQuestionTemplate}
                disabled={requestQuestionTemplateIsLoading}
              >
                {t('update_template_whatsapp')}
              </MuiButton>
            </Box>
          </>
        </SurveyTranslations>
      </Grid>
    </>
  );
};
export default WhatsappTemplate;
