import {useMutation, useQueryClient} from 'react-query';
import axiosSurveyInstance from 'api/axios/axiosSurveyInstance';

const configUpdate = (data) => {
    const {
      environment,
      serviceId,
      configuration,
    } = data;
    
    return axiosSurveyInstance.post(`/api/survey/config/update`, {
        service_id: serviceId,
        environment,
        configuration,
    }).then(resp => resp.data);
};

const useSurveyConfigUpdate = () => {
  return useMutation((parameters) => configUpdate(parameters));
};
export default useSurveyConfigUpdate;
