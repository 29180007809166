import {Grid, IconButton, styled, Switch, Typography} from "@mui/material";

export const MuiGridOthersTab = styled(Grid)(({ theme }) => ({
  display: 'flex',
  maxHeight: 40,
  alignItems: 'center',
  justifyContent:'space-between',
  marginLeft: 16,
}));

export const MuiOptionsTypography = styled(Typography)(({ theme }) => ({
  color:'#757575',
  textAlign:'left',
  marginLeft:'-16px',
  fontFamily: 'Montserrat',
  textTransform:'uppercase',
  letterSpacing:'0.4px',
  fontWeight:500,
  fontSize:13,
}));

export const MuiIconTranslate = styled(IconButton)(({ theme }) => ({
  border:'1px solid #E0E0E0',
  borderRadius:'50%',
  height:26,
  width:26,
  marginTop:'10px',
}));

export const MuiSwitchOthers = styled(Switch, {
  shouldForwardProp: (prop) => prop !== 'primaryColor'
})(({ theme,primaryColor }) => ({
  '&.MuiSwitch-root': {
    marginBottom: 10,
    padding: 0,
  },
  '& .MuiSwitch-switchBase': {
    color: '#757575',
    paddingLeft: '5px',
    '&.Mui-checked': {
      color: '#FFFFFF',
      transform: 'translateX(18px)',
      '& + .MuiSwitch-track': {
        border: 'none',
        backgroundColor: primaryColor,
        opacity: 1,
      },
    },
  },
  '& .MuiSwitch-track': {
    background: '#FFFFFF',
    border: '1px solid',
    borderColor: '#757575',
    borderRadius: 12,
    marginTop: 12,
    width: 43,
    height: 24,
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 14,
    height: 14,
    marginTop: 8,
  },
}));
export const MuiSwitchTypography = styled(Typography)(({ theme }) => ({
  fontFamily: 'Montserrat',
  textTransform:'uppercase',
  letterSpacing:'1.5px',
  fontWeight:400,
  fontSize:10
}));
export const dividerStyle = {
  marginLeft:'5px',
  fontSize:'10px',
  marginRight:'5px'
}
export const containerSwitchStyle = {
  display:'flex',
  marginTop:'17px',
  marginLeft:'-5px'
}


export const editorSurveyStyle = `.ck.ck-editor__editable {
                              border-radius: 0 0 8px 8px!important;
                              border: none !important;
                              background-color: #FAFAFA !important;
                              padding: 10px !important;
                            }
                             .ck.ck-editor__top .ck-sticky-panel .ck-sticky-panel__content {
                              border: none !important;
                            }
                            .ck.ck-toolbar {
                              border-radius: 8px 8px 0 0 !important;
                              background-color: #ECEFF1 !important;
                              border: none !important;
                              box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1) !important;
                            }
                            .ck.ck-toolbar .ck-button {
                              margin: 4px !important;
                            }
                            .ck.ck-toolbar .ck-button:hover,
                            .ck.ck-toolbar .ck-button:active {
                              background-color: #CFD8DC !important;
                            }`
