import {useTranslation} from "../../../../../providers/TranslationProvider";
import {getContrastTonality} from "../../../../../../utils/colorPaletteUtils";
import {IconButton, Typography} from "@mui/material";
import * as React from "react";
import CloseIcon from "@mui/icons-material/Close";
import PushPinIcon from "@mui/icons-material/PushPin";
import PushPinOutlinedIcon from "@mui/icons-material/PushPinOutlined";

export const CustomTooltip = ({bar, isSegmented, hideTooltip, isPinned,
                                setIsPinned}) => {

  const handleTogglePin = () => setIsPinned((prev) => !prev);

  const SvgIcon = ({base64Icon, color}) => {

    const contrastColor = () => {
      return getContrastTonality(color) === "light" ? "#FFFFFF" : '#212121'
    }
    const decodeSvg = (base64) => {
      // Decode the Base64 SVG
      const svgContent = atob(base64);
      // Parse the SVG as a DOM object
      const parser = new DOMParser();
      const doc = parser.parseFromString(svgContent, 'image/svg+xml');
      const svgElement = doc.querySelector('svg');
      // Update the fill or stroke color
      if (svgElement) {
        svgElement.setAttribute('fill', contrastColor()); // Set fill color
      }
      // Serialize back to a string
      const updatedSvg = new XMLSerializer().serializeToString(svgElement);
      // Convert back to Base64
      return btoa(updatedSvg);
    };

    const updatedBase64Icon = decodeSvg(base64Icon);
    return (
      <div style={{
        width: '32px',
        minWidth: '32px',
        height: '32px',
        minHeight: '32px',
        maxHeight: '32px',
        maxWidth: '32px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: color,
        borderRadius: '8px',
        marginTop: '8px',
        zIndex: 999999
      }}>
        <img
          src={`data:image/svg+xml;base64,${updatedBase64Icon}`}
          alt=""
          style={{
            width: '20px',
            height: '20px',
            objectFit: 'scale-down',
          }}
        />
      </div>
    );
  };

  if (!bar) return null;

  return (
    <div style={{
      width: '440px',
      maxHeight: 'fit-content',
      backgroundColor: '#FFFFFF',
      gap: '0px',
      borderRadius: '0 12px 12px 12px',
      boxShadow: '0px 2px 6px 2px rgba(0, 0, 0, 0.1)',
      zIndex: 999999
    }}>
      <div style={{display: 'flex', alignItems: 'center', width: '100%'}}>
        <div style={{
          display: 'flex',
          alignItems: 'flex-start',
          gap: '8px',
          padding: '16px 16px 0 16px',
          width: '100%'
        }}>
          {(bar?.data?.icon) && (<SvgIcon base64Icon={bar.data.icon}
                                          color={isSegmented ? bar.data[`color_${bar.id}`] : bar.data.color}/>)}
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
            }}>
            <Typography
              sx={{
                fontFamily: 'Montserrat',
                fontWeight: 500,
                fontSize: '16px',
                lineHeight: '20px',
                textAlign: 'left',
                color: '#212121',
                letterSpacing: '0.005em'
              }}>{(!isSegmented) ? bar.data.tooltipText : bar.data[`tooltipText_${bar.id}`]}</Typography>
            <div style={{display: 'flex', alignItems: 'center', gap: '48px'}}>
              {(!!bar?.data?.countSubtopics) && (
                <Typography
                  sx={{
                    fontFamily: 'Montserrat',
                    fontWeight: 400,
                    fontSize: '12px',
                    lineHeight: '25px',
                    textAlign: 'center',
                    color: '#424242',
                    letterSpacing: '0.004em',
                    textTransform: 'uppercase'
                  }}
                >
                  Subtopics: {bar?.data?.countSubtopics || 0}
                </Typography>
              )}
              <Typography
                sx={{
                  fontFamily: 'Montserrat',
                  fontWeight: 400,
                  fontSize: '12px',
                  lineHeight: '25px',
                  textAlign: 'center',
                  color: '#424242',
                  letterSpacing: '0.004em',
                  textTransform: 'uppercase'
                }}
              >
                Summarized
                Quotes: {isSegmented ? bar.data[`countAnswers_${bar.id}`] : (bar?.data?.countAnswers || 0).toLocaleString('en-US')}
              </Typography>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              flex: 1,
              gap: "8px",
            }}
          >
            <IconButton
              onClick={handleTogglePin}
              sx={{
                width: "26px",
                height: "26px",
                borderRadius: "13px",
                backgroundColor: "transparent",
                "&:hover": {
                  backgroundColor: "transparent",
                },
                cursor: "pointer",
              }}
            >
              {isPinned ? (
                <PushPinIcon color="primary" sx={{ width: "16px", height: "16px" }} />
              ) : (
                <PushPinOutlinedIcon sx={{ width: "16px", height: "16px" }} />
              )}
            </IconButton>
            <IconButton
              onClick={hideTooltip}
              sx={{
                width: '26px',
                height: '26px',
                borderRadius: '13px',
                backgroundColor: 'transparent',
                '&:hover': {
                  backgroundColor: 'transparent',
                },
                cursor: 'pointer'
              }}>
              <CloseIcon sx={{
                width: '16px',
                height: '16px',
                cursor: 'pointer'
              }}/>
            </IconButton>
          </div>
        </div>
      </div>
      {(!!bar?.data.aiSummary) && (
        <div style={{
          padding: '12px 16px 16px 16px',
        }}>
          <Typography
            component={'p'}
            sx={{
              fontFamily: 'Montserrat',
              fontWeight: 400,
              fontSize: '14px',
              lineHeight: '17px',
              letterSpacing: '0.0025em',
              color: '#424242',
              textAlign: 'justify'
            }}>{bar?.data?.aiSummary || ''}</Typography>
        </div>)}
    </div>

  );
};
