import {
  Box,
  CircularProgress,
  IconButton,
  Typography,
  useTheme
} from "@mui/material";
import {lighten} from "@mui/material/styles";
import {PlayArrow} from "@mui/icons-material";
import React, {useState} from "react";
import {
  useInfrastructureProgress
} from "../../../providers/InfrastructureProgressProvider";
import {useTranslation} from "../../../providers/TranslationProvider";
import QRModal from "./QRModal";

const TryInterview = ({survey, buttonHeight = 26}) => {
  const {isLoading, progress} = useInfrastructureProgress();
  const testInterview = survey && survey.status === 'Running' && !isLoading;
  const [qrModal, setModalQR] = useState(false);
  const {t} = useTranslation();
  const theme = useTheme();
  return (
    <Box>
      <IconButton
        sx={{
          display: "flex",
          alignItems: "center",
          gap: "5px",
          border: `1px solid ${isLoading ? '#BDBDBD' : lighten(theme.palette.primary.main, 0.2)}`,
          borderRadius: "13px",
          height: `${buttonHeight}px`,
          padding: "4px 12px 4px 8px",
          color: isLoading ? '#BDBDBD' : lighten(theme.palette.primary.main, 0.1),
          fontFamily: "Montserrat",
          fontSize: "10px",
        }}
        onClick={() => testInterview ? setModalQR(true) : null}
      >
        {(isLoading) ? (
          <Box sx={{
            position: 'relative',
            display: 'inline-flex',
            color: '#D89525',
            width: '20px',
            height: '20px',
          }}>
            {/*<CircularProgress*/}
            {/*  variant={'determinate'}*/}
            {/*  value={progress}*/}
            {/*  color={'inherit'}*/}
            {/*  size={20}*/}
            {/*/>*/}
            <svg width="29" height="20" viewBox="0 0 29 20">
              <path d={"M10,0 H19 A10,10 0 0 1 19,20 H10 A10,10 0 0 1 10,0 Z"}
                    fill="none" stroke="#EFE898" strokeWidth="2"/>
              <path id="progress"
                    d="M10,0 H19 A10,10 0 0 1 19,20 H10 A10,10 0 0 1 10,0 Z"
                    fill="none" stroke="#D89525" strokeWidth="2"
                    strokeDasharray="80.83"
                    strokeDashoffset={80.83 * (1 - progress / 100)}
                    transform="rotate(0 14.5 10)"/>
            </svg>
            <Box
              sx={{
                top: 0,
                left: 0,
                width: '20px',
                height: '20px',
                position: 'absolute',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Typography
                variant="caption"
                component="div"
                sx={{
                  color: '#D89525',
                  fontFamily: 'Montserrat',
                  fontSize: '10px',
                  fontWeight: 600,
                  letterSpacing: '0.0025em'
                }}
              >
                {`${Math.round(progress)}`}
              </Typography>
            </Box>
          </Box>
        ) : (
          <PlayArrow
            sx={{color: lighten(theme.palette.primary.main, 0.2)}}/>
        )}
        {t('test_interview')}
      </IconButton>
      <QRModal
        open={qrModal}
        handleClose={() => setModalQR(false)}
        survey={{id: survey.serviceId, environment: survey.environment}}
      />
    </Box>
  );
}

export default TryInterview;
