import {status200} from "../status.utils";
import axiosSurveyInstance from "../axios/axiosSurveyInstance";
import {useMutation, useQueryClient} from "react-query";

const copySurvey = async (data) => {
  return axiosSurveyInstance.post(`/api/survey/copy/${data.id || data.service_id}/${data.organization_id}`, {}, status200)
    .then((resp) => resp.data);
}

export const useCopySurvey = () => {
  const queryClient = useQueryClient();
  return useMutation(async (data) => copySurvey(data), {
    onSuccess: (data) => {
      queryClient.resetQueries('organizationSurveys');
      return data;
    },
    onError: (error) => {
      console.error(error);
      return error;
    },
    onSettled: (data) => {
      queryClient.invalidateQueries('organizationSurveys');
      return data;
    },
  })
}
