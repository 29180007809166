import axiosSurveyInstance from "../axios/axiosSurveyInstance";
import {useMutation, useQuery, useQueryClient} from "react-query";

const fetchSurvey = ({id}) => {
  return axiosSurveyInstance.get(`/api/survey/config?service_id=${id}&environment=dev`).then((resp) => {
    const {data} = resp;
    return data?.configuration || null;
  }).catch((err) => {
    console.error("Error fetching survey", err);
    return null;
  });
};

const useSurveyConfig = (data) => useQuery(
  ['survey-config', data],
  () => fetchSurvey(data),
  {
    enabled: true,
    refetchOnWindowFocus: false
  }
);



export default useSurveyConfig;
