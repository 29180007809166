import {
    StepLabel,
    styled,
    Typography,
    StepConnector,
    Button,
} from '@mui/material';
import { stepConnectorClasses } from "@mui/material/StepConnector";
import {darken} from "@mui/material/styles"

export const MuiStepLabel = styled(StepLabel)(({ theme }) => ({
    '& .MuiStepIcon-text': {
        fill: '#FFFFFF',
        fontSize: '13px',
        fontWeight: 500,
        lineHeight: '20px',
        fontFamily: 'Montserrat'
    }
}));

export const MuiStepLabelTypography = styled(Typography)(({ theme, activeStep }) => ({
    textTransform: 'uppercase',
    fontFamily: 'Montserrat',
    fontWeight: 500,
    fontSize: 12,
    lineHeight: 12,
    color: activeStep === 0 ? '#212121' : '#9E9E9E'
}));

export const SurveyConnector = styled(StepConnector)(() => ({
    [`& .${stepConnectorClasses.line}`]: {
        flex: "0 0 77px",
        borderColor: "#E0E0E0",
        borderStyle: "solid",
        borderWidth: "1px"
      },
}));

export const MuiCancelActionButton = styled(Button)(({ theme }) => ({
    height: '37px',
    width: '112px',
    fontFamily: 'Montserrat',
    fontSize: '14px',
    color: '#FF5549',
    lineHeight: '17.07px'
  }));

export const MuiActionButton = styled(Button)(({ theme }) => ({
    height: '37px',
    width: 'auto',
    fontFamily: 'Montserrat',
    fontSize: '14px',
    color:darken(theme.palette.primary.main,0.3),
}));
