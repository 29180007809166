import {Box, Typography} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import {MuiButton} from "./styles/actionBar";
import {useLocation, useNavigate} from "react-router-dom";
import {useTranslation} from "../../../../providers/TranslationProvider";

const ActionBar = ({handleTestInterview, surveyStatus}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const {t} = useTranslation();
  const handleEditInterview = () => {
    navigate(`${location.pathname.replace('analytics', 'specification')}`, {
      replace: true
    })
  }

  return (
    <Box
      sx={{
        display: 'flex',
        width: '100%',
        height: '38px',
        alignItems: 'center',
        justifyContent: 'space-between'
      }}
    >
      <Typography
        sx={{
          lineHeight: '29px',
          color: '#000000',
          fontFamily: 'Raleway',
          fontSize: '25px'
        }}
      >
        {t('responses_overview_title')}
      </Typography>
      <Box
        sx={{
          display: 'flex',
          height: '38px',
          alignItems: 'center',
          justifyContent: 'flex-end',
          gap: '12px'
        }}
      >
        <MuiButton
          startIcon={<EditIcon sx={{width: '14px', height: '14px'}}/>}
          onClick={handleEditInterview}
        >
          {t('edit_interview')}
        </MuiButton>
        <MuiButton
          startIcon={<PlayCircleOutlineIcon
            sx={{width: '14px', height: '14px'}}/>}
          onClick={handleTestInterview}
          disabled={!surveyStatus || surveyStatus !== 'Running'}
        >
          {t('test_interview')}
        </MuiButton>
      </Box>
    </Box>)
}

export default ActionBar;
