import {
  Box,
  CircularProgress,
  Divider, IconButton,
  List,
  Pagination,
  Typography
} from "@mui/material";
import {useEffect, useMemo, useState} from "react";
import useSurveyResponsesByUser
  from "../../../../../api/hooks/useSurveyResponsesByUser";
import ResponseItem from "./ResponseItem";
import {useTranslation} from "../../../../providers/TranslationProvider";
import {Download} from "@mui/icons-material";
import axiosSurveyInstance from "../../../../../api/axios/axiosSurveyInstance";
import {AudioControlProvider} from "../../../../providers/VoiceProvider";


const ResponsesByUser = ({
                           survey,
                           filterQuestion,
                           filterResponses,
                           startDate,
                           endDate,
                           interactionKind = 'all',
                           selectedChannels,
                           autoRefresh
                         }) => {
  const [pageStart, setPageStart] = useState(0);
  const pageEnd=1;
  const [pageResponses, setPageResponses] = useState([]);
  const [totalRespondents, setTotalRespondents] = useState(0);
  const [participationDate, setParticipationDate] = useState('');
  const {t} = useTranslation();

  const memoizedPayload = useMemo(() => ({
    service_id: survey?.serviceId,
    environment: survey?.environment,
    pageStart,
    pageEnd,
    startDate,
    endDate,
    filterQuestionId: filterQuestion && filterResponses?.length > 0 ? filterQuestion.id : null,
    filterResponses: filterResponses?.length > 0 ? filterResponses : null,
    targetStatus: interactionKind,
    channels: selectedChannels?.length < 3 && selectedChannels.length > 0 ? selectedChannels : null
  }), [survey, pageStart, pageEnd, filterQuestion, filterResponses, startDate, endDate, interactionKind, selectedChannels]);

  const {isLoading, data, refetch} = useSurveyResponsesByUser(memoizedPayload);

  useEffect(() => {
    if (autoRefresh && autoRefresh !== 'off') {
      refetch();
    }
  }, [autoRefresh]);

  const formatDate = (dateStr) => {
    if (!dateStr) return '';
    const isoDateStr = dateStr.replace(" ", "T");
    const dateObj = new Date(isoDateStr);

    const year = dateObj.getFullYear();
    const month = (dateObj.getMonth() + 1).toString().padStart(2, "0"); // Months are 0-indexed
    const day = dateObj.getDate().toString().padStart(2, "0");
    const hours = dateObj.getHours().toString().padStart(2, "0");
    const minutes = dateObj.getMinutes().toString().padStart(2, "0");

    return `${year}/${month}/${day} - ${hours}:${minutes}`;
  }

  useEffect(() => {
    if (!isLoading && data?.data && (data.data?.at(0)?.responses?.length > 0 || data.data?.at(0)?.responses?.length !== pageResponses?.length)) {
      const localTotalResp = data?.totalRespondents;
      if (localTotalResp !== totalRespondents) {
        setTotalRespondents(localTotalResp);
        if(localTotalResp<=pageStart&&localTotalResp>0){
          // setPageEnd(localTotalResp);
          setPageStart(localTotalResp-1);
        }
      }
      setParticipationDate(formatDate(data?.data?.at(0)?.respondent || ''));
      setPageResponses(data.data?.at(0)?.responses || []);
    }
  }, [isLoading, data]);

  if (isLoading || !survey) {
    return <Box
      sx={{
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <CircularProgress size={'100px'}/>
    </Box>
  }

  return (
    <AudioControlProvider>
      <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: '100%',
        borderRadius: '16px',
        backgroundColor: '#FAFAFA',
        boxSizing: 'border-box',
        padding: '24px',
        gap: '12px'
      }}>
        <Box sx={{
          display: 'flex',
          width: '100%',
          justifyContent: 'flex-end',
        }}>
          <Typography sx={{
            fontFamily: 'Raleway',
            fontSize: '21px',
            letterSpacing: '0.0015em',
            fontWeight: 500,
            color: '#000000',
            textAlign: 'center',
            flexGrow: 1
          }}
          >
            {t('interview_responses_by_user')}
          </Typography>
          <IconButton
            sx={{
              width: '24px',
              height: '24px',
              border: '1px solid #E0E0E0',
            }}
            onClick={() => {

              axiosSurveyInstance.get(`/api/survey/analytics/download?service_id=${survey.serviceId}`).then((rsp)=>{
                const {data} = rsp;
                const link = document.createElement('a');
                link.href=data.link
                link.download = 'ResponsesByUser.json';
                link.click();
              })
            }}
          >
            <Download sx={{width: '16px', height: '16px'}}/>
          </IconButton>
        </Box>
        <Box sx={{
          display: 'flex',
          width: '100%',
          height: '56px',
          justifyContent: 'space-between',
          alignItems: 'center',
          boxSizing: 'border-box'
        }}>
          <Typography
            sx={{
              fontFamily: 'Montserrat',
              fontSize: '16px',
              letterSpacing: '0.005em',
              color: '#143440'
            }}
          >
            {pageResponses?.length>0?(`User ${pageStart+1} (${participationDate})`):''}
          </Typography>
          <Pagination
            count={totalRespondents}
            page={pageStart+1}
            onChange={(e, page) => {
              setPageStart(page - 1);
              // setPageEnd(page);
            }}
            sx={{
              '& .MuiPaginationItem-root': {
                fontFamily: 'Montserrat',
                fontSize: '12px',
                color: '#475569',
              }
            }}
          />
        </Box>
        <Divider/>
        <Box sx={{
          display: 'flex',
          width: '100%',
          height: '100%',
          boxSizing: 'border-box',
          flexDirection: 'column',
        }}>
          <List
            sx={{
              width: '100%',
              height: '100%',
              backgroundColor: '#FAFAFA',
              pt: 0
            }}
          >
            {pageResponses?.map((item, index) => {
              return (
                <ResponseItem key={index} headerText={item.question}
                              responseText={item.response} audio={item.audio||null} audioKey={index}/>
              )
            })}
            {!pageResponses||pageResponses?.length===0&&(
              <Typography
                sx={{
                  fontFamily: 'Montserrat',
                  fontSize: '16px',
                  letterSpacing: '0.005em',
                  color: '#143440'
                }}
              >
                {t('interview_no_response')}
              </Typography>
            )}
          </List>
        </Box>
      </Box>
    </AudioControlProvider>
  );
}

export default ResponsesByUser;
