import axiosSurveyInstance from "../axios/axiosSurveyInstance";
import {useMutation, useQuery, useQueryClient} from "react-query";

const createQuestion = (data) => {
  return axiosSurveyInstance.post(`/api/survey/question/create`, data).then((resp) => {
    const {response} = resp;
    return response?.question || null;
  }).catch((err) => {
    console.error("Error creating question", err);
    return null;
  });
};
const deleteQuestion = (data) => {
  return axiosSurveyInstance.delete(`/api/survey/question/${data.id}/delete?service_id=${data.service_id}&environment=${data.environment}`, data).then((resp) => {
    return resp.status === 200 ;
  }).catch((err) => {
    console.error("Error deleting question", err);
    return null;
  });
};

const useManageQuestion = () =>{
  const queryClient= useQueryClient();
  const createMutation = useMutation(createQuestion, {
    onSuccess: () => {
      queryClient.invalidateQueries('question-create');
    },
    onError: (error) => {
      console.error("Error creating question", error);
    },
  });
  const deleteMutation = useMutation(deleteQuestion, {
    onSuccess: () => {
      queryClient.invalidateQueries('question-delete');
    },
    onError: (error) => {
      console.error("Error deleting question", error);
    },
  });
  return {
    createQuestion : createMutation.mutateAsync,
    deleteQuestion : deleteMutation.mutateAsync
  };
};
export default useManageQuestion;
