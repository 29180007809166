// External library imports
import {Button, Grid, styled, Typography} from "@mui/material";
import {lighten} from "@mui/material/styles";

export const MuiGridHeaderContainer = styled(Grid)(({ theme }) => ({
  justifyContent: 'space-between',
  marginTop: 56,

}));

export const MuiButton = styled(Button)(({ theme }) => ({
  justifySelf: 'end',
  borderRadius:'20px',
  padding:'10px 24px 10px 16px',
  height:'38px'
}));

export const ActionBarButton = styled(Button)(({ theme }) => ({
  justifyContent: 'center',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  borderRadius: '20px',
  padding: '10px 24px 10px 16px',
  gap: '0px',
  height: '38px',
  width: '230px',
  backgroundColor: '#ffffff',
  borderColor: lighten('#00BBD4', 0.1),
  color: lighten('#00BBD4', 0.1),
  '& svg': {
    fill: lighten('#00BBD4', 0.2),
  },
  '&:hover': {
    backgroundColor: lighten('#00BBD4', 0.9),
    borderColor: lighten('#00BBD4', 0.1),
  },
}));

export const MuiTitle = styled(Typography)(({ theme }) => ({
  fontSize: 29,
  fontWeight:700,
  fontStyle:'normal',
  color:'#212121'
}));

export const MuiCommonText = styled(Typography)(({ theme }) => ({
  fontWeight: 500,
  fontSize: '14px',
  fontFamily: 'Montserrat'
}));

export const MuiSubTitle = styled(Typography)(({ theme }) => ({
  fontSize: 16,
  fontWeight:400,
  fontStyle:'normal',
  color:'#212121'
}));

export const MuiGrid = styled(Grid)(({ theme }) => ({
  justifyContent: 'center',
  alignContent: 'center',
  // margin: 'auto',
}));

export const MuiGridContainer = styled(Grid)(({ theme }) => ({
  display: 'grid',
  marginTop: 30,
  gridTemplateColumns: 'repeat(4, 1fr)',
  padding:0,
  gap: '23px',
  justifyContent: 'flex-start',
  width: '100%',
  '@media (min-width: 1751px)': {
    gridTemplateColumns: 'repeat(6, 1fr)',
  },
  '@media (min-width: 1475px) and (max-width: 1750px)': {
    gridTemplateColumns: 'repeat(5, 1fr)',
  },
  '@media (min-width: 1197px) and (max-width: 1474px)': {
    gridTemplateColumns: 'repeat(4, 1fr)',
  },
  '@media (min-width: 920px) and (max-width: 1196px)': {
    gridTemplateColumns: 'repeat(3, 1fr)',
  },
  '@media (min-width: 643px) and (max-width: 919px)': {
    gridTemplateColumns: 'repeat(2, 1fr)',
  },
  '@media (max-width: 642px)': {
    gridTemplateColumns: 'repeat(1, 1fr)',
  },
}));

export const containerStyle = {
  width: '1360px',
  '@media (min-width: 1920px)': {
    maxWidth: '1650px !important',
  },
}

export const toolbarStyle = {
    paddingLeft:'0px !important'
}

export const notPaddingStyle = {
  paddingLeft:0,
  paddingRight: 0,
};
