import * as React from 'react';
import {useMemo} from 'react';
import {
  Box,
  Card,
  CardContent, CardHeader,
  CardMedia,
  Divider,
  Grid,
  IconButton,
  Link,
  List,
  ListItem,
  ListItemIcon,
  Tabs
} from "@mui/material";
import ellipse from 'assets/img/ellipsi.jpg'
import powered from 'assets/img/powered_by.png'
import EditIcon from '@mui/icons-material/Edit';
import PieChart from "../analysis/tree/PieChart";
import {lighten} from "@mui/material/styles";
import BarGraph from "../analysis/bars/BarGraph";
import {useDispatch, useSelector} from "react-redux";
import {Masonry} from "@mui/lab";
import {
  ArrowBack,
  ArrowUpward,
  CheckCircle,
  SaveAs,
} from "@mui/icons-material";
import MoreVert from "@mui/icons-material/MoreVert";
import LinkIcon from "@mui/icons-material/Link";
import {MuiPDFIcon} from "../../../../common/styles/navigationMenu";
import {exportPDF, getRandomImages} from "./exportToPDF";
import {
  generateScaledPalette,
  getContrastTonality
} from "../../../../../utils/colorPaletteUtils";
import useOrganization from "../../../../../api/hooks/useOrganization";
import {useAuth} from "../../../../providers/AuthProvider";
import {useLocation, useNavigate} from "react-router-dom";
import CircleIcon from '@mui/icons-material/Circle';
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import UserMenu from "../../../login/UserMenu";
import {CKEditor} from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import {
  checkCircleStyle,
  circleIconStyle,
  editorStyle,
  iconButtonStyle,
  iconStyle,
  imgConclusionStyle,
  imgEllipseStyle,
  imgLogoStyle,
  imgOrgStyle,
  imgQuoteStyle,
  MuiAboutCompanySection,
  MuiAcceptButton,
  MuiArrowButton,
  MuibackToAnalysisButton,
  MuiBox,
  MuiBoxButtons,
  MuiBoxConclusion,
  MuiBoxContainer,
  MuiBoxContainerSummary,
  MuiBoxContent,
  MuiBoxGallery,
  MuiBoxImages,
  MuiBoxImgConclusion,
  MuiBoxItem,
  MuiBoxItemConclusion,
  MuiBoxItemQuote,
  MuiBoxList,
  MuiBoxListConclusion,
  MuiBoxPartial,
  MuiBoxQuoteHeader,
  MuiBoxSummary,
  MuiBoxTitle, MuiColors,
  MuiConclusionSection,
  MuiContentHTML,
  MuiDiv,
  MuiDivContainer, MuiDivEllipse,
  MuiDivHeader,
  MuiDivider,
  MuiDivItem, MuiDivMasonry,
  MuiDivQuote,
  MuiDivSummary,
  MuiDivTabs,
  MuiDivTitleContainer,
  MuiEditor,
  MuiElementHTML,
  MuiEvenGrid,
  MuiExecutiveSummarySection,
  MuiGallerySection,
  MuiGrid,
  MuiGridContainer, MuiGridKeyFindings,
  MuiGridLeft,
  MuiGridQuotes,
  MuiGridRight,
  MuiGridSummary,
  MuiGridTextHTML,
  MuiHorizontalBarChartSection,
  MuiIconButton, MuiImgConclusion,
  MuiImgMasonry,
  MuiImgProfile,
  MuiIndexSectionName,
  MuiKeyFindingsContainer,
  MuiKeyFindingsSection,
  MuiMoreVertButton,
  MuiOddGrid,
  MuiOrganizationName,
  MuiPieChartSection,
  MuiQoutesSection,
  MuiQuestionNumber,
  MuiQuestionText,
  MuiQuestionTitle,
  MuiRepresentativity,
  MuiSampleAnswer,
  MuiSaveAsButton,
  MuiSectionInitial,
  MuiSectionName,
  MuiSubTopicQuote,
  MuiSubtopicSampleAnswer,
  MuiSummary,
  MuiSummaryTitle,
  MuiTab,
  MuiTitle,
  MuiTopicQuote,
  MuiTypographyDate,
  MuiTypographyDescription,
  MuiTypographyHeader,
  MuiTypographyLink,
  MuiTypographyQuestionTopic,
  MuiTypographyReportName,
  MuiTypographyTitle,
  MuiUserMenu,
  subItemStyle,
  tabsStyle
} from "./styles/viewReportPDF";
import {
  setJsonSchemePDF,
  setTargetCollection,
  setTargetScheme
} from "../../../../../store/appSlice";
import question from "lodash";
import VoicePlayer from "../../../../common/VoicePlayer";
import {AudioControlProvider} from "../../../../providers/VoiceProvider";
import {generateRandomString} from "../../../../../utils/text";
import useScheme from "../../../../../api/hooks/useScheme";
import {useLoading} from "../../../../providers/LoadingProvider";
import useUpdateScheme from "../../../../../api/hooks/useUpdateScheme";
import {getRandomId} from "../../../../../utils/formatter";
import {useTranslation} from "../../../../providers/TranslationProvider";

const CurrentQuestionContent = ({
                                  tabId,
                                  openQuestionAnalysis,
                                  generalContent,
                                  activeEditor,
                                  matchEditorElement,
                                  handleCloseEditor,
                                  handleToggleEditor,
                                  handleEditorChange,
                                  handleDispatch,
                                  scheme,
                                  collection,
                                  pluginsEditor,
                                  toolbarEditor,
                                  getColorsByImages,
                                  t
                                }) => {

  const [currentQuestion, setCurrentQuestion] = React.useState({});
  const [mainTopicsSection, setMainTopicsSection] = React.useState([]);
  const [analysisPerSegment, setAnalysisPerSegment] = React.useState([]);
  const [patialConclusions, setPatialConclusions] = React.useState([]);
  const [keyFindingsContent, setKeyFindingsContent] = React.useState([]);
  const [imagesPartialConclusion, setImagesPartialConclusion] = React.useState([]);
  const [segmentationVariables, setSegmentationVariables] = React.useState([]);

  const selectedPallete = useSelector(state => state.app.treeMapColorScheme);
  const palette = generateScaledPalette(selectedPallete, Math.max(10, mainTopicsSection?.length || 10)).reverse();
  const barChartData = mainTopicsSection?.flatMap((item, index) => (
    item.subtopics?.length > 0
      ? item.subtopics.map(subtopic => ({
        topic: subtopic.topic,
        value: subtopic.representativity,
      }))
      : [{topic: item.topic, value: item.representativity}]
  ))

  const barAggregationData = {data: barChartData, indexBy: "topic", keys: []}
  const topics = [...mainTopicsSection];
  const allTopics = topics.sort((a, b) => b.representativity - a.representativity)
  const rid = generateRandomString(10);
  const audioKey = `audio-${rid}`;

  const getBarAggregationData = useMemo(() => {
    const barData = mainTopicsSection?.flatMap((item, index) => (
      item.subtopics?.length > 0
        ? item.subtopics.map(subtopic => ({
          topic: subtopic.topic,
          value: subtopic.representativity,
        }))
        : [{topic: item.topic, value: item.representativity}]
    ));

    return {data: barData, indexBy: "topic", keys: []}
  }, [mainTopicsSection]);

  const getDataVerticalGraph = (data) => {
    const keys = data[0].categories.map((item, index) => (item.name))
    const objs = data.map((item, index) => (
      {
        topic: item.topic,
        value: item.value,
        ...keys.reduce((acc, key, index) => {
          acc[item.collapsed_categories[index]?.name] = item.collapsed_categories[index]?.percentage;
          return acc;
        }, {})
      }
    ))
    return {data: objs, indexBy: "topic", keys: keys}
  }

  function extractListItemsFromHTML(htmlContent) {
    const instance = new DOMParser();
    const doc = instance.parseFromString(htmlContent, "text/html");
    const items = doc.querySelectorAll("li");

    return Array.from(items)
      .map(li => {
        const strongElement = li.querySelector("strong");
        const title = strongElement ? strongElement.textContent.trim() : "";

        if (!title) return null; // Si title está vacío, no se devuelve el objeto

        // Clonar el <li> para eliminar las sublistas sin modificar el DOM original
        const liClone = li.cloneNode(true);
        liClone.querySelectorAll("ul").forEach(ul => ul.remove());

        const argument = strongElement
          ? liClone.textContent.replace(strongElement.textContent, "").trim()
          : liClone.textContent.trim();

        // Obtener elementos dentro de sublistas
        const subItems = Array.from(li.querySelectorAll("ul > li")).map(subLi => subLi.textContent.trim());

        return {title, argument, subItems};
      })
      .filter(Boolean); // Filtra los valores nulos
  }

  const isDarkColor = (hexColor) => {
    hexColor = hexColor.replace("#", "");

    let r = parseInt(hexColor.substring(0, 2), 16);
    let g = parseInt(hexColor.substring(2, 4), 16);
    let b = parseInt(hexColor.substring(4, 6), 16);

    let luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255;

    return luminance < 0.5;
  };

  const getIconColor = (backgroundColor) => {
    return isDarkColor(backgroundColor) ? "white" : "black";
  };

  const matchTopic = (topic) => {
    const topicMatched = currentQuestion.topics.find((item) => item.topic === topic)
    return topicMatched.icon
  }

  const rgbToHex = (rgbString) => {
    const match = rgbString?.match(/\d+/g);

    if (!match || match.length !== 3) {
      throw new Error("Formato incorrecto. Debe ser 'rgb(R, G, B)'");
    }
    const [r, g, b] = match.map(Number);
    const toHex = (c) => c.toString(16).padStart(2, '0');

    return `#${toHex(r)}${toHex(g)}${toHex(b)}`.toUpperCase();
  };

  const subTopicMap = (subtopics, color) => {
    return (subtopics?.length > 0) && (
      <>
        {
          subtopics?.flatMap(item => item.sample_answers).sort(() => Math.random() - Math.random()).slice(0, 10).filter(v => !!v?.trim()).map((value) => (
            <MuiSubtopicSampleAnswer color={color}>
              <span
                style={{
                  filter: getIconColor(rgbToHex(color)) === "black" ?
                    "invert(1)" : "invert(0)"
                }}>
                {value}
              </span>
            </MuiSubtopicSampleAnswer>
          ))
        }
      </>
    )
  }

  React.useEffect(() => {
    if (scheme && Object.keys(scheme).length > 0 && collection.variables.length > 0 && Object.keys(openQuestionAnalysis).length > 0) {
      setCurrentQuestion(scheme.policies[tabId]);
      setImagesPartialConclusion(getRandomImages(scheme, 6));
      setSegmentationVariables(collection.variables.filter((item) => item.category === 'categorical'));
      const analysisData = openQuestionAnalysis[scheme.policies[tabId].longName].sections;
      setMainTopicsSection(
        analysisData.find(section => section.sectionName === 'Main topics referenced')?.additionalData || []
      );
      setAnalysisPerSegment(
        analysisData.find(section => section.sectionName === 'DETAILED ANALYSIS PER SEGMENT')?.sectionContent || []
      );
      setPatialConclusions(
        analysisData.find(section => section.sectionName === 'PARTIAL CONCLUSIONS') || []
      );
      setKeyFindingsContent(
        analysisData.find(section => section.sectionName === 'KEY FINDINGS')?.sectionContent || []
      );
    }
  }, [scheme, collection, openQuestionAnalysis, tabId])

  React.useEffect(() => {
    document.body.style.overflow = activeEditor ? 'hidden' : ''
  }, [activeEditor]);

  return (
    <>
      {scheme && scheme?.policies?.length > 0 && Object.keys(currentQuestion).length > 0 && (
        <>
          <Grid id='sectionGraphH' container sx={{marginTop: '50px'}}>
            <MuiGridContainer
              item xs={6}
              display='flex'
              direction='column'
              justifyContent="flex-start"
              alignItems="center"
              primaryColor={generalContent.primary_color}
            >
              <MuiQuestionText>
                {t('question')} {tabId + 1}
              </MuiQuestionText>
              <MuiQuestionTitle>
                {`3.${tabId + 1}.${Object.keys(openQuestionAnalysis)[tabId]}`}
              </MuiQuestionTitle>
            </MuiGridContainer>
            <Grid item xs={6} sx={{height: '580px', padding: '0px 40px'}}>
              <MuiRepresentativity>
                {t('representativity_topic')} (%)
              </MuiRepresentativity>
              <BarGraph
                data={getBarAggregationData}
                policy={currentQuestion}
                segmentationVariables={segmentationVariables}
                callingComponent={"ViewReportPDF"}
                layout={'horizontal'}
              />
            </Grid>
          </Grid>

          {/*6. Key Findings*/}
          <MuiKeyFindingsSection id='sectionKeyFindings'>
            <MuiKeyFindingsContainer item xs={6}>
              <MuiTitle variant="h4">
                {`3.${tabId + 1}.1`} {t('main')} <span
                style={{color: generalContent.primary_color}}>{t('findings')}</span>
              </MuiTitle>
            </MuiKeyFindingsContainer>
            <Grid
              container
              spacing={2}
              display='flex'
              justifyContent="space-evenly"
              alignItems="flex-start"
            >
              <MuiGridKeyFindings item>
                {keyFindingsContent.slice(0, 2).map((item, index) => (
                  <>
                    {index === 0 ? (
                      <MuiDivItem primaryColor={'#FAFAFA'}
                                  sx={{marginBottom: '32px'}}>
                        <MuiBoxItem>
                          <MuiSectionName>
                            {item.sectionName}
                          </MuiSectionName>
                          <IconButton edge="end" size="small" aria-label="edit"
                                      sx={iconButtonStyle}>
                            {activeEditor && matchEditorElement === index ? (
                              <CloseIcon style={{
                                ...iconStyle,
                                color: generalContent.primary_color
                              }} onClick={handleCloseEditor}/>
                            ) : (
                              <EditIcon
                                style={iconStyle}
                                onClick={() => handleToggleEditor(index)}/>
                            )}
                          </IconButton>
                        </MuiBoxItem>
                        <MuiBoxContent>
                          {activeEditor && matchEditorElement === index ? (
                            <MuiEditor elevation={0}>
                              <Box sx={{height: 'auto', width: '100%'}}>
                                <CKEditor
                                  editor={ClassicEditor}
                                  data={item.sectionContent}
                                  config={{
                                    removePlugins: pluginsEditor,
                                    toolbar: toolbarEditor,
                                  }}
                                  onChange={(e, editor) => handleEditorChange(e, editor, 'open_question_analysis', index, 'keyFindings')}
                                />
                              </Box>
                              <MuiAcceptButton
                                startIcon={(<CheckIcon/>)}
                                variant='outlined'
                                primaryColor={generalContent.primary_color}
                                onClick={() => handleDispatch('open_question_analysis', index, 'keyFindings')}
                              >
                                {t('accept')}
                              </MuiAcceptButton>
                            </MuiEditor>
                          ) : (
                            <MuiContentHTML
                              variant="body1"
                              dangerouslySetInnerHTML={{__html: item.sectionContent}}>
                            </MuiContentHTML>
                          )}
                        </MuiBoxContent>
                      </MuiDivItem>
                    ) : (
                      <>
                        <MuiBoxItem>
                          <MuiSectionName>
                            {item.sectionName}
                          </MuiSectionName>
                          <IconButton edge="end" size="small" aria-label="edit"
                                      sx={iconButtonStyle}>
                            {activeEditor && matchEditorElement === index ? (
                              <CloseIcon style={{
                                ...iconStyle,
                                color: generalContent.primary_color
                              }} onClick={handleCloseEditor}/>
                            ) : (
                              <EditIcon
                                style={iconStyle}
                                onClick={() => handleToggleEditor(index)}/>
                            )}
                          </IconButton>
                        </MuiBoxItem>
                        <MuiBoxContent>
                          {activeEditor && matchEditorElement === index ? (
                            <MuiEditor elevation={0}>
                              <Box sx={{height: 'auto', width: '100%'}}>
                                <CKEditor
                                  editor={ClassicEditor}
                                  data={item.sectionContent}
                                  config={{
                                    removePlugins: pluginsEditor,
                                    toolbar: toolbarEditor,
                                  }}
                                  onChange={(e, editor) => handleEditorChange(e, editor, 'open_question_analysis', index, 'keyFindings')}
                                />
                              </Box>
                              <MuiAcceptButton
                                startIcon={(<CheckIcon/>)}
                                variant='outlined'
                                primaryColor={generalContent.primary_color}
                                onClick={() => handleDispatch('open_question_analysis', index, 'keyFindings')}
                              >
                                {t('accept')}
                              </MuiAcceptButton>
                            </MuiEditor>
                          ) : (
                            <MuiContentHTML
                              variant="body1"
                              dangerouslySetInnerHTML={{__html: item.sectionContent}}>
                            </MuiContentHTML>
                          )}
                        </MuiBoxContent>
                      </>
                    )}
                  </>
                ))}
              </MuiGridKeyFindings>

              <MuiGridKeyFindings item>
                {keyFindingsContent.slice(2, 4).map((item, index) => (
                  <>
                    {index === 0 ? (
                      <>
                        <MuiBoxItem sx={{marginTop: '20px'}}>
                          <MuiSectionName>
                            {item.sectionName}
                          </MuiSectionName>
                          <IconButton edge="end" size="small" aria-label="edit"
                                      sx={iconButtonStyle}>
                            {activeEditor && matchEditorElement === index + 2 ? (
                              <CloseIcon style={{
                                ...iconStyle,
                                color: generalContent.primary_color
                              }} onClick={handleCloseEditor}/>
                            ) : (
                              <EditIcon
                                style={iconStyle}
                                onClick={() => handleToggleEditor(index + 2)}/>
                            )}
                          </IconButton>
                        </MuiBoxItem>
                        <MuiBoxContent>
                          {activeEditor && matchEditorElement === index + 2 ? (
                            <MuiEditor elevation={0}>
                              <Box sx={{height: 'auto', width: '100%'}}>
                                <CKEditor
                                  editor={ClassicEditor}
                                  data={item.sectionContent}
                                  config={{
                                    removePlugins: pluginsEditor,
                                    toolbar: toolbarEditor,
                                  }}
                                  onChange={(e, editor) => handleEditorChange(e, editor, 'open_question_analysis', index + 2, 'keyFindings')}
                                />
                              </Box>
                              <MuiAcceptButton
                                startIcon={(<CheckIcon/>)}
                                variant='outlined'
                                primaryColor={generalContent.primary_color}
                                onClick={() => handleDispatch('open_question_analysis', index + 2, 'keyFindings')}
                              >
                                {t('accept')}
                              </MuiAcceptButton>
                            </MuiEditor>
                          ) : (
                            <MuiContentHTML
                              variant="body1"
                              dangerouslySetInnerHTML={{__html: item.sectionContent}}>
                            </MuiContentHTML>
                          )}
                        </MuiBoxContent>
                      </>
                    ) : (
                      <MuiDivItem primaryColor={generalContent.primary_color}>
                        <MuiBoxItem>
                          <MuiSectionName sx={{color: 'white'}}>
                            {item.sectionName}
                          </MuiSectionName>
                          <IconButton edge="end" size="small" aria-label="edit"
                                      sx={iconButtonStyle}>
                            {activeEditor && matchEditorElement === index + 2 ? (
                              <CloseIcon
                                style={{...iconStyle, color: 'white'}}
                                onClick={handleCloseEditor}/>
                            ) : (
                              <EditIcon
                                style={{...iconStyle, color: 'white'}}
                                onClick={() => handleToggleEditor(index + 2)}/>
                            )}
                          </IconButton>
                        </MuiBoxItem>
                        <MuiBoxContent>
                          {activeEditor && matchEditorElement === index + 2 ? (
                            <MuiEditor elevation={0}
                                       sx={{background: 'transparent'}}>
                              <Box sx={{height: 'auto', width: '100%'}}>
                                <CKEditor
                                  editor={ClassicEditor}
                                  data={item.sectionContent}
                                  config={{
                                    removePlugins: pluginsEditor,
                                    toolbar: toolbarEditor,
                                  }}
                                  onChange={(e, editor) => handleEditorChange(e, editor, 'open_question_analysis', index + 2, 'keyFindings')}
                                />
                              </Box>
                              <MuiAcceptButton
                                startIcon={(<CheckIcon/>)}
                                variant='outlined'
                                primaryColor={generalContent.primary_color}
                                onClick={() => handleDispatch('open_question_analysis', index, 'keyFindings')}
                              >
                                {t('accept')}
                              </MuiAcceptButton>
                            </MuiEditor>
                          ) : (
                            <MuiContentHTML
                              variant="body1"
                              sx={{color: 'white'}}
                              dangerouslySetInnerHTML={{__html: item.sectionContent}}>
                            </MuiContentHTML>
                          )}
                        </MuiBoxContent>
                      </MuiDivItem>
                    )}
                  </>
                ))}
              </MuiGridKeyFindings>
              <MuiDivider/>
            </Grid>
          </MuiKeyFindingsSection>

          {/*7. Citas*/}
          <MuiQoutesSection id='sectionQuotes'>
            <MuiGridQuotes item xs={6}>
              <MuiTitle variant="h4">
                {`3.${tabId + 1}.2`} {t('main_topics')} <span
                style={{color: generalContent.primary_color}}>{t('referenced')}</span>
              </MuiTitle>
            </MuiGridQuotes>
            <Grid
              container
              spacing={2}
              display='flex'
              justifyContent="space-evenly"
              alignItems="flex-start"
            >
              <Grid
                item
                sx={{width: '600px', height: 'auto'}}
              >
                {allTopics.filter(itm => (itm.sample_answers?.some(q => !!q?.trim())) || itm.subtopics?.flatMap(st => st.sample_answers)?.some(q => !!q?.trim())).map((item, index) => index % 2 === 0 &&
                  <MuiDivSummary key={index}>
                    <MuiBoxItem>
                      <div style={{display: 'flex', flexDirection: 'row'}}>
                        <MuiBoxItemQuote primaryColor={palette[index]}>
                          <img
                            alt="icon"
                            width="28"
                            height="28"
                            src={`data:image/svg+xml;base64,${matchTopic(item.topic)}`}
                            style={{
                              ...imgQuoteStyle,
                              filter: getIconColor(rgbToHex(palette[index])) === "white"
                                ? "invert(1)" : "invert(0)",
                            }}
                          />
                        </MuiBoxItemQuote>
                        <MuiDivQuote>
                          <MuiTopicQuote>
                            {item.topic} ({item.representativity.toFixed(0)}%)
                          </MuiTopicQuote>
                          <MuiSubTopicQuote>
                            <span>{t('subtopics')}: {item.subtopics?.length || 0}</span>
                            <span style={{marginLeft: '18px'}}>
                              {t('quotes')}: {item.count}
                            </span>
                          </MuiSubTopicQuote>
                        </MuiDivQuote>
                      </div>
                    </MuiBoxItem>
                    <MuiBoxContainerSummary>
                      <MuiDivSummary>
                        <MuiSummary>
                          {t('summary')}:
                        </MuiSummary>
                        <MuiSummaryTitle>
                          {item.summary}
                        </MuiSummaryTitle>
                      </MuiDivSummary>
                      <div style={{marginLeft: '5px'}}>
                        <MuiSummary>
                          {t('quotes')}:
                        </MuiSummary>
                      </div>
                      <div style={{maxHeight: '300px', overflowY: 'auto'}}>
                        {item.sample_answers && item.sample_answers.length > 0 ? (
                          item.sample_answers.filter(q => !!q?.trim()).map((value, indice) => (
                            <MuiSampleAnswer color={palette[index]}>
                              <span style={{
                                filter: getIconColor(rgbToHex(palette[index])) === "black"
                                  ? "invert(1)" : "invert(0)"
                              }}>
                                {value}
                              </span>
                              <AudioControlProvider>
                                {(item.audio && item.audio[indice] !== null &&
                                  item.audio[indice] !== 'None'
                                  && item.audio[indice] !== undefined) && (
                                  <VoicePlayer
                                    audio={item.audio[indice]}
                                    key={`${audioKey}-${indice}`}
                                    audioKey={`${audioKey}-${indice}`}
                                    color={getContrastTonality(palette[index]) === 'dark' ? '#212121' : '#FFFFFF'}
                                    theme={getContrastTonality(palette[index])}
                                  />
                                )}
                              </AudioControlProvider>
                            </MuiSampleAnswer>
                          ))
                        ) : (
                          <>
                            {subTopicMap(item.subtopics, palette[index])}
                          </>
                        )}
                      </div>
                    </MuiBoxContainerSummary>
                  </MuiDivSummary>
                )}
              </Grid>

              <Grid
                item
                sx={{width: '600px', height: 'auto'}}
              >
                {allTopics.filter(itm => (itm.sample_answers?.some(q => !!q?.trim())) || itm.subtopics?.flatMap(st => st.sample_answers)?.some(q => !!q?.trim())).map((item, index) => (index % 2 !== 0) &&
                  <div key={index} style={{marginBottom: '30px'}}>
                    <MuiBoxQuoteHeader>
                      <div style={{display: 'flex', flexDirection: 'row'}}>
                        <MuiBoxTitle color={palette[index]}>
                          <img
                            alt="icon"
                            width="28"
                            height="28"
                            src={`data:image/svg+xml;base64,${matchTopic(item.topic)}`}
                            style={{
                              ...imgQuoteStyle,
                              filter: getIconColor(rgbToHex(palette[index])) === "white"
                                ? "invert(1)" : "invert(0)",
                            }}
                          />
                        </MuiBoxTitle>
                        <MuiDivQuote>
                          <MuiTopicQuote>
                            {item.topic} ({item.representativity.toFixed(0)}%)
                          </MuiTopicQuote>
                          <MuiSubTopicQuote>
                            <span>{t('subtopics')}: {item.subtopics?.length || 0}</span>
                            <span
                              style={{marginLeft: '18px'}}>
                              {t('quotes')}: {item.count}
                            </span>
                          </MuiSubTopicQuote>
                        </MuiDivQuote>
                      </div>
                    </MuiBoxQuoteHeader>
                    <MuiBoxContainerSummary>
                      <MuiDivTitleContainer>
                        <MuiSummary>
                          {t('summary')}:
                        </MuiSummary>
                        <MuiSummaryTitle>
                          {item.summary}
                        </MuiSummaryTitle>
                      </MuiDivTitleContainer>
                      <div style={{marginLeft: '5px'}}>
                        <MuiSummary>
                          {t('quotes')}:
                        </MuiSummary>
                      </div>
                      <div style={{maxHeight: '300px', overflowY: 'auto'}}>
                        {item.sample_answers && item.sample_answers.length > 0 ? (
                          item.sample_answers.filter(v => !!v?.trim()).map((value) => (
                            <MuiSampleAnswer color={palette[index]}>
                              <span style={{
                                filter: getIconColor(rgbToHex(palette[index])) === "black"
                                  ? "invert(1)" : "invert(0)"
                              }}>
                                {value}
                              </span>
                            </MuiSampleAnswer>
                          ))
                        ) : (
                          <>
                            {subTopicMap(item.subtopics, palette[index])}
                          </>
                        )}
                      </div>
                    </MuiBoxContainerSummary>
                  </div>)}
              </Grid>

              <MuiDivider/>
            </Grid>
          </MuiQoutesSection>

          {/*8. Grafico de barras vertical*/}
          <section id='sectionGraphBarV' style={{height: 'auto'}}>
            <Grid item xs={6}
                  style={{textAlign: 'center', marginLeft: '-1.3rem'}}>
              <MuiTitle variant="h4">
                {`3.${tabId + 1}.3`} {t('detailed_analysis')} <span
                style={{color: generalContent.primary_color}}>{t('per_segment')}</span>
              </MuiTitle>
            </Grid>
            <Grid
              container
              spacing={2}
              display='flex'
              justifyContent="space-evenly"
              alignItems="center"
            >
              {analysisPerSegment.map((item, index) => (
                <>
                  <Grid item style={{width: '80%', height: 'auto'}}>
                    <MuiBoxItem>
                      <MuiSectionName>
                        {item.sectionName}
                      </MuiSectionName>
                      <IconButton edge="end" size="small" aria-label="edit"
                                  sx={iconButtonStyle}>
                        {activeEditor && matchEditorElement === index ? (
                          <CloseIcon style={{
                            ...iconStyle,
                            color: generalContent.primary_color
                          }} onClick={handleCloseEditor}/>
                        ) : (
                          <EditIcon
                            style={iconStyle}
                            onClick={() => handleToggleEditor(index)}/>
                        )}
                      </IconButton>
                    </MuiBoxItem>
                    <MuiGrid container>
                      {activeEditor && matchEditorElement === index ? (
                        <MuiEditor elevation={0}>
                          <Box sx={{height: 'auto', width: '100%'}}>
                            <CKEditor
                              editor={ClassicEditor}
                              data={item.sectionContent}
                              config={{
                                removePlugins: pluginsEditor,
                                toolbar: toolbarEditor,
                              }}
                              onChange={(e, editor) => handleEditorChange(e, editor, 'open_question_analysis', index, 'analysisPerSegment')}
                            />
                          </Box>
                          <MuiAcceptButton
                            startIcon={(<CheckIcon/>)}
                            variant='outlined'
                            onClick={() => handleDispatch('open_question_analysis', index, 'analysisPerSegment')}
                            primaryColor={generalContent.primary_color}
                          >
                            {t('accept')}
                          </MuiAcceptButton>
                        </MuiEditor>
                      ) : (
                        <>
                          {extractListItemsFromHTML(item.sectionContent).map((element, index) => (
                            <Grid item xs={6} key={index}>
                              <MuiElementHTML>
                                  <span style={{
                                    color: generalContent.primary_color,
                                    fontWeight: 700
                                  }}>
                                    • {element.title}
                                  </span>
                                {element.argument}
                              </MuiElementHTML>
                              {element.subItems.length > 0 && (
                                <ul style={{
                                  paddingLeft: '40px',
                                  marginTop: '5px'
                                }}>
                                  {element.subItems.map((sub, subIndex) => (
                                    <li key={subIndex} style={subItemStyle}>
                                      {sub}
                                    </li>
                                  ))}
                                </ul>
                              )}
                            </Grid>
                          ))}
                        </>
                      )}

                      <Grid item xs={12}
                            sx={{height: 550, paddingLeft: '40px'}}>
                        <BarGraph
                          layout='vertical'
                          callingComponent='ViewReportPDF'
                          data={getDataVerticalGraph(item.additionalData)}
                          segmentName={item.sectionName}
                          policy={currentQuestion}
                          segmentationVariables={segmentationVariables}
                        />
                      </Grid>
                    </MuiGrid>
                  </Grid>
                </>
              ))}
              <Divider
                style={{
                  borderColor: '#E0E0E0',
                  width: '86%',
                  marginTop: '100px'
                }}/>
            </Grid>
          </section>

          {/*9. Conclusiones parciales*/}
          <section id='sectionPartialConclutions' style={{height: 'auto'}}>
            <Grid
              container
              spacing={2}
              display='flex'
              justifyContent="space-evenly"
              alignItems="center"
              sx={{marginTop: '50px'}}
            >
              <Grid item style={{width: '651px', height: '560px'}}>
                <MuiBoxItem>
                  <MuiTitle variant="h4">
                    {`3.${tabId + 1}.4`} {t('partial')} <span
                    style={{color: generalContent.primary_color}}>{t('conclusion')}</span>
                  </MuiTitle>
                  <IconButton edge="end" size="small" aria-label="edit"
                              sx={iconButtonStyle}>
                    {activeEditor ? (
                      <CloseIcon
                        style={{
                          ...iconStyle,
                          color: generalContent.primary_color
                        }}
                        onClick={handleCloseEditor}/>
                    ) : (
                      <EditIcon
                        style={iconStyle}
                        onClick={() => handleToggleEditor(-1)}/>
                    )}
                  </IconButton>
                </MuiBoxItem>
                <MuiBoxPartial>
                  <MuiTypographyTitle variant="body1">
                    {t('partial_conclusions_info')}:
                  </MuiTypographyTitle>
                  {activeEditor ? (
                    <MuiEditor elevation={0}>
                      <Box sx={{height: 'auto', width: '100%'}}>
                        <CKEditor
                          editor={ClassicEditor}
                          data={patialConclusions.sectionContent}
                          config={{
                            removePlugins: pluginsEditor,
                            toolbar: toolbarEditor,
                          }}
                          onChange={(e, editor) => handleEditorChange(e, editor, 'open_question_analysis', 3, 'partialConclusions')}
                        />
                      </Box>
                      <MuiAcceptButton
                        startIcon={(<CheckIcon/>)}
                        variant='outlined'
                        primaryColor={generalContent.primary_color}
                        onClick={() => handleDispatch('open_question_analysis', 3, 'partialConclusions')}
                      >
                        {t('accept')}
                      </MuiAcceptButton>
                    </MuiEditor>
                  ) : (
                    <MuiContentHTML
                      variant="body1"
                      dangerouslySetInnerHTML={{__html: patialConclusions.sectionContent}}>
                    </MuiContentHTML>
                  )}
                </MuiBoxPartial>
              </Grid>
              <Grid item alignSelf='flex-start'>
                <MuiBoxImages>
                  <Grid
                    container
                    columnGap={'4px'}
                    rowGap={'4px'}
                    display={'grid'}
                    gridTemplateColumns={'repeat(2,1fr)'}>
                    {imagesPartialConclusion.length !== 0 ? (
                      <>
                        {imagesPartialConclusion.map((image, index) => (
                          <MuiImgConclusion item key={index} height={'137px'}>
                            <Box
                              component="img"
                              src={image}
                              alt={`img-${index}`}
                              sx={imgConclusionStyle}
                            />
                          </MuiImgConclusion>
                        ))}
                      </>
                    ) : (
                      <>
                        {getColorsByImages(10).slice(0, 6).map((color, index) => (
                          <Grid item xs={6} key={index}>
                            <Box sx={{
                              height: 137,
                              background: color,
                              width: 296
                            }}/>
                          </Grid>
                        ))}
                      </>
                    )}
                  </Grid>
                </MuiBoxImages>
              </Grid>
            </Grid>
          </section>
        </>
      )}
    </>

  )
}

const ReportPDF = () => {

  const [activeTab, setActiveTab] = React.useState(0);
  const [indicatorWidth, setIndicatorWidth] = React.useState(0);
  const [currentQuestion, setCurrentQuestion] = React.useState({});
  const [questions, setQuestions] = React.useState([]);
  const [isShowOptions, setIsShowOptions] = React.useState(false);
  const [dataReport, setDataReport] = React.useState({});
  const [executiveSummary, setExecutiveSummary] = React.useState(dataReport.executive_summary || {sections: []});
  const [openQuestionAnalysis, setOpenQuestionAnalysis] = React.useState(dataReport.open_question_analysis || {});
  const [segmentsAnalysis, setSegmentsAnalysis] = React.useState(dataReport.segments_analysis || {sections: []});
  const [conclusions, setConclusions] = React.useState(dataReport.conclusions || {sections: []});
  const [generalContent, setGeneralContent] = React.useState(dataReport.general || {});
  const [imagesMasonry, setImagesMasonry] = React.useState([]);
  const [imagesConclusion, setImagesConclusion] = React.useState([]);
  const [isFixed, setIsFixed] = React.useState(false);
  const [isCopied, setIsCopied] = React.useState(false);
  const [showGoHome, setShowGoHome] = React.useState(false);
  const [showSave, setShowSave] = React.useState(false);
  const [htmlContent, setHtmlContent] = React.useState('');
  const [activeEditor, setActiveEditor] = React.useState(false);
  const [matchEditorElement, setMatchEditorElement] = React.useState(null);

  const location = useLocation();
  const {t} = useTranslation();
  const {user} = useAuth();
  const {setIsLoading, setTitle, setShowProgressBar} = useLoading();
  const orgId = location.pathname.split('/')[2] === 'workspace' ? user.id : location.pathname.split('/')[2];
  const scenario = useScheme({
    user_id: orgId,
    scheme_id: location.pathname.split('/')[4],
    enabled: true
  });
  const {mutateAsync: updateScheme} = useUpdateScheme();
  const dispatch = useDispatch();
  const selectedPallete = useSelector(state => state.app.treeMapColorScheme);
  const palette = generateScaledPalette(selectedPallete, 10).reverse();
  const {data: organization} = useOrganization(orgId);
  const navigate = useNavigate();
  const isPublic = user.type !== 'level_0'
  const tabs = Object.keys(openQuestionAnalysis).map((item, index) => (
    {id: index, value: `Question ${index + 1}`}
  ))
  const pluginsEditor = ['ImageUpload', 'EasyImage', 'MediaEmbed', 'BlockQuote']
  const toolbarEditor = [
    'heading', '|', 'bold', 'italic', 'underline', '|',
    'bulletedList', 'numberedList', '|',
    'undo', 'redo'
  ]
  const getEditorStyle = `${editorStyle}
            .ck.ck-toolbar .ck-button .ck-icon {
            color: ${generalContent.primary_color} !important;
          }`

  let scheme = {};
  let collection = {};

  const initReport = React.useCallback(() => {
    if (scenario.data && scenario.data.scheme && scenario.data.collection && Object.keys(scenario.data.collection).length > 0) {
      scheme = scenario.data.scheme;
      collection = scenario.data.collection;
      let jsonScheme = scenario.data.scheme.jsonSchemePDF;
      if (typeof jsonScheme === 'string') {
        jsonScheme = JSON.parse(jsonScheme)
        scheme.jsonSchemePDF = jsonScheme
      }
      setCurrentQuestion(scheme.policies[activeTab]);
      dispatch(setTargetScheme(scheme));
      dispatch(setTargetCollection(collection));
      setGeneralContent(jsonScheme.general);
      setOpenQuestionAnalysis(jsonScheme.open_question_analysis);
      setQuestions(scheme.policies);
      setImagesMasonry(getRandomImages(scheme, 20));
      setHtmlContent(jsonScheme.general.htmlContent);
      setExecutiveSummary(jsonScheme.executive_summary);
      setSegmentsAnalysis(jsonScheme.segments_analysis);
      setImagesConclusion(getRandomImages(scheme, 4));
      setConclusions(jsonScheme.conclusions);
    }
  }, [scenario.data.scheme, scenario.data.collection]);

  const handleChangeQuestion = (_, newValue) => setActiveTab(newValue);

  const handleScroll = () => setIsFixed(window.scrollY > 3885);

  const handleCopyUrl = () => {
    const currentUrl = window.location.href;
    navigator.clipboard.writeText(currentUrl).then(() => {
      setIsCopied(true);
      setTimeout(() => {
        setIsCopied(false);
      }, 1200);
    }).catch(err => {
      console.error('Error al copiar la URL:', err);
    });
  };

  const backToAnalysis = () => {
    const currentUrl = window.location.pathname;
    const newUrl = currentUrl.replace('/report', '');
    navigate(newUrl);
  }

  const getDataPieChart = (segments) => {
    let segmentCopy = [...segments]
    let dataSegment = [];
    const valueTotal = segments.reduce((sum, obj) => sum + (obj.occurrences || 0), 0);
    const sortedCategoires = segmentCopy.sort((a, b) => b.occurrences - a.occurrences);
    const topSeven = sortedCategoires.slice(0, 7);
    const others = sortedCategoires.slice(7);
    const otherOccurrences = others.reduce((sum, obj) => sum + (obj.occurrences || 0), 0);

    topSeven.forEach((segment, index) => {
      dataSegment.push({
        id: `${segment.name}`,
        label: `${segment.name} (${segment.occurrences})`,
        value: Math.round(segment.occurrences * 100 / valueTotal, 1),
        color: palette[index % palette.length]
      });
    })

    if (others.length > 0) {
      dataSegment.push({
        label: `Other (${otherOccurrences})`,
        value: Math.round(otherOccurrences * 100 / valueTotal, 1),
        color: palette[7 % palette.length] // or however you want to pick the color
      });
    }

    return dataSegment
  }

  const getSectionId = (nameSection, nameItem) => {
    const idSection = nameSection !== '' ? `${nameSection}-${nameItem}` : `${nameItem}`
    return idSection.toLowerCase().replaceAll(' ', '_')
  }

  const sections = [
    {id: "sectionExecutiveSummary", title: t('executive_summary'), root: true},
    {id: "executiveSummary-0", title: t('concise_overview'), root: false},
    {id: "executiveSummary-1", title: t('actionable_insights'), root: false},
    {id: "executiveSummary-2", title: t('key_findings'), root: false},
    {id: "executiveSummary-3", title: t('conclusions'), root: false},
    {id: "sectionGraphPie", title: t('relevant_segments'), root: true},
    ...segmentsAnalysis.sections.map((item, index) => (
      {
        id: getSectionId('sectionGraphPie', item.sectionName),
        title: item.sectionName,
        root: false
      }
    )),
    {id: "sectionGraphBarH", title: "Responses Analysis", root: true},
    ...Object.keys(openQuestionAnalysis).map((item, index) => (
      {
        id: getSectionId('', item),
        title: `Question ${index + 1} - ${item}`,
        index: index,
        root: false
      }
    )),
    {id: "sectionConclutions", title: "Conclutions", root: true},
    {id: "sectionGallery", title: "Gallery", root: true},
  ];

  const indexToQuestion = (id, index) => {
    if (id.includes('?')) {
      setActiveTab(index)
    }
  }

  const handleGoToSection = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({behavior: "smooth", block: "start"});
    }
  };

  const formatDate = (dateStr) => {
    if (!dateStr) return null;
    const date = new Date(dateStr.trim());
    const options = {month: 'long', day: 'numeric', year: 'numeric'};
    const formattedDate = date.toLocaleDateString('en-US', options);

    return `${formattedDate}`;
  }

  const handleToggleEditor = (index) => {
    setActiveEditor(true)
    setMatchEditorElement(matchEditorElement === index ? null : index);
  };

  const handleEditorChange = (event, editor, key, index, sectionName = '') => {
    let arraySectionsCopy, objConclution;
    if (key === 'executive_summary') {
      arraySectionsCopy = [...executiveSummary.sections];
    } else if (key === 'segments_analysis') {
      arraySectionsCopy = [...segmentsAnalysis.sections];
    } else if (key === 'open_question_analysis') {
      arraySectionsCopy = [...openQuestionAnalysis[currentQuestion.longName].sections];
      arraySectionsCopy[index] = {
        ...arraySectionsCopy[index],
        sectionContent: editor.getData()
      };
      setHtmlContent({
        question: Object.keys(openQuestionAnalysis)[activeTab],
        sectionName: sectionName,
        dataUpdated: editor.getData()
      });
      return;
    } else if (key === 'conclusions') {
      objConclution = editor.getData().toString().replace(/<\/?p>/g, "").split(':');
      arraySectionsCopy = [...conclusions.sections[0].sectionContent];
      arraySectionsCopy[index] = {
        ...arraySectionsCopy[index],
        conclusionTitle: objConclution[0],
        conclusionContent: objConclution[1]
      };
      setHtmlContent(arraySectionsCopy[index]);
      return
    }
    arraySectionsCopy[index] = {
      ...arraySectionsCopy[index],
      sectionContent: editor.getData()
    };
    setHtmlContent(editor.getData());
  };

  const handleDispatch = async (key, index, sectionName = '') => {
    if (key === 'executive_summary') {
      setExecutiveSummary(prevState => ({
        ...prevState,
        sections: prevState.sections.map((section, i) =>
          i === index ? {...section, sectionContent: htmlContent} : section
        )
      }));
    } else if (key === 'segments_analysis') {
      setSegmentsAnalysis(prevState => ({
        ...prevState,
        sections: prevState.sections.map((section, i) =>
          i === index ? {...section, sectionContent: htmlContent} : section
        )
      }));
    } else if (key === 'open_question_analysis') {
      if (sectionName === 'keyFindings') {
        setOpenQuestionAnalysis(prevState => ({
          ...prevState,
          [currentQuestion.longName]: {
            ...prevState[currentQuestion.longName],
            sections: prevState[currentQuestion.longName].sections.map((section, sectionIndex) =>
              section.sectionName === 'KEY FINDINGS'
                ? {
                  ...section,
                  sectionContent: section.sectionContent.map((item, contentIndex) =>
                    contentIndex === index
                      ? {...item, sectionContent: htmlContent.dataUpdated}
                      : item
                  )
                }
                : section
            )
          }
        }));
      } else if (sectionName === 'partialConclusions') {
        setOpenQuestionAnalysis(prevState => ({
          ...prevState,
          [currentQuestion.longName]: {
            ...prevState[currentQuestion.longName],
            sections: prevState[currentQuestion.longName].sections.map((section) =>
              section.sectionName === 'PARTIAL CONCLUSIONS'
                ? {
                  ...section,
                  sectionContent: htmlContent.dataUpdated
                } : section
            )
          }
        }));
      } else if (sectionName === 'analysisPerSegment') {
        setOpenQuestionAnalysis(prevState => ({
          ...prevState,
          [currentQuestion.longName]: {
            ...prevState[currentQuestion.longName],
            sections: prevState[currentQuestion.longName].sections.map((section) =>
              section.sectionName === 'DETAILED ANALYSIS PER SEGMENT'
                ? {
                  ...section,
                  sectionContent: section.sectionContent.map((item, contentIndex) =>
                    contentIndex === index
                      ? {...item, sectionContent: htmlContent.dataUpdated}
                      : item
                  )
                } : section
            )
          }
        }));
      }
    } else if (key === 'conclusions') {
      setConclusions(prevState => ({
        ...prevState,
        sections: prevState.sections.map(section =>
          section.sectionName === "Conclusions"
            ? {
              ...section,
              sectionContent: section.sectionContent.map((item, contentIndex) =>
                contentIndex === index
                  ? {
                    ...item,
                    conclusionContent: htmlContent.conclusionContent || item.conclusionContent
                  }
                  : item
              )
            }
            : section
        )
      }));
    }
    dispatch(setJsonSchemePDF({key: key, index: index, data: htmlContent}));
    setActiveEditor(false);
    setShowSave(true);
  }

  const handleSave = async () => {
    let localTargetScheme = {
      id: scenario && scenario.data.scheme.id ? scenario.data.scheme.id : getRandomId(),
      name: scenario && scenario.data.scheme.name !== '' ? scenario.data.scheme.name : "scheme_" + getRandomId(),
      analysisObjective: scenario.data.scheme.analysisObjective,
      contextDescription: scenario.data.scheme.contextDescription,
      audienceDescription: scenario.data.scheme.audienceDescription,
      colorPalette: scenario.data.scheme.colorPalette,
      description: scenario.data.scheme.description,
      dataset: scenario.data.scheme.dataset,
      reportUpdateDate: scenario.data.scheme.reportUpdateDate,
      weight: scenario.data.scheme.weight,
      collection_method: scenario.data.scheme.collection_method || [],
      segmentation_variables: scenario.data.scheme.segmentation_variables || [],
      statistic_variables: scenario.data.scheme.statistic_variables || [],
      policies: scenario.data.scheme.policies,
      schemeCollectionConfig: scenario.data.scheme.schemeCollectionConfig,
      jsonSchemePDF: {
        executive_summary: executiveSummary,
        segments_analysis: segmentsAnalysis,
        open_question_analysis: openQuestionAnalysis,
        conclusions: conclusions,
        general: generalContent
      }
    };
    await updateScheme({
      user_id: orgId,
      scheme: localTargetScheme,
    });
    setShowSave(false);
  }

  const handleCloseEditor = () => {
    setActiveEditor(false);
    setMatchEditorElement(null);
  }

  const getColorsByImages = (numColors) => generateScaledPalette(selectedPallete, numColors).reverse();

  const matchQuestion = (questionJson) => {
    const question = questions.find((item) => item.longName === questionJson)

    return (
      <Grid container spacing={3} sx={{marginTop: '24px'}}>
        {question.topics.map((item, index) => (
          <Grid item xs={4} sx={{paddingTop: '0px !important'}} key={index}>
            <Card sx={{maxWidth: 400}} elevation={0}>
              {(!!item.image_url) ? (<CardMedia
                sx={{height: 264, width: 388, borderRadius: '8px'}}
                image={item.image_url}
                title="green iguana"
              />) : (
                <CardHeader
                  sx={{height: 140, backgroundColor: palette[index]}}
                />
              )}
              <CardContent sx={{paddingLeft: '0px'}}>
                <MuiTypographyQuestionTopic>
                  {item.topic}
                </MuiTypographyQuestionTopic>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    )
  }

  React.useEffect(() => {
    const updateIndicatorWidth = () => {
      const selectedTab = document.querySelector('.Mui-selected');
      if (selectedTab) {
        setIndicatorWidth(selectedTab.offsetWidth);
      }
    };

    updateIndicatorWidth();
    if (scenario.data && scenario.data.scheme) {
      setCurrentQuestion(scenario.data.scheme.policies[activeTab]);
    }

    window.addEventListener('resize', updateIndicatorWidth);
    return () => window.removeEventListener('resize', updateIndicatorWidth);
  }, [activeTab]);

  React.useEffect(() => {
    if (scenario.data.scheme.dataset === '') {
      setIsLoading(true);
      setTitle('Loading data for your report')
    } else {
      setIsLoading(false);
    }
  }, [scenario.data]);

  React.useEffect(() => {
    if (scenario.data && scenario.data.scheme && scenario.data.collection && Object.keys(scenario.data.collection).length > 0) {
      initReport();
    }
  }, [initReport]);

  React.useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  React.useEffect(() => {
    const handleScroll = () => {
      setShowGoHome(window.scrollY > window.innerHeight);
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <>
      {(scenario.data.scheme.policies.length > 0 && !!organization) && (
        <>
          <style>{getEditorStyle}</style>

          <MuiSectionInitial id='sectionInitial'>
            <Box sx={{width: '100%', minHeight: 217}}>
              <Masonry columns={5} spacing={0.5}>
                {imagesMasonry.length !== 0 ? (
                  <>
                    {imagesMasonry.map((src, index) => (
                      <div key={index}>
                        {(src) ? (<MuiImgMasonry
                          src={src}
                          index={index}
                          alt={`Gallery ${index}`}
                          loading="lazy"
                        />) : null}
                      </div>
                    ))}
                  </>
                ) : (
                  <>
                    {getColorsByImages(20).map((color, index) => (
                      <MuiDivMasonry key={index} color={color} index={index}/>
                    ))}
                  </>
                )}
              </Masonry>
              <MuibackToAnalysisButton
                primaryColor={generalContent.primary_color}
                onClick={backToAnalysis}
              >
                <ArrowBack sx={{color: 'white'}}/> {t('back_analysis')}
              </MuibackToAnalysisButton>
              {isPublic &&
                <MuiUserMenu primaryColor={generalContent.primary_color}>
                  <UserMenu/>
                </MuiUserMenu>
              }
            </Box>
            <MuiDivContainer primaryColor={generalContent.primary_color}>
              <MuiDiv>
                <MuiImgProfile
                  src={organization?.attrs.profile_img_url}
                  alt="Organization Logo"
                />
              </MuiDiv>
            </MuiDivContainer>
            <MuiBoxContainer>
              <MuiBox>
                <MuiDivHeader>
                  <MuiTypographyHeader>
                    {t('análisis')}
                  </MuiTypographyHeader>
                  <MuiTypographyReportName>
                    {generalContent.reportName}
                  </MuiTypographyReportName>
                  <MuiTypographyDate variant="body2">
                    {formatDate(scenario.data.scheme?.reportUpdateDate)}
                  </MuiTypographyDate>
                  <MuiOrganizationName>
                    {organization.name}
                  </MuiOrganizationName>
                  <div style={{marginTop: '16px'}}>
                    <img
                      src={powered}
                      alt="FGV Logo"
                      style={imgLogoStyle}
                    />
                  </div>
                  <div>
                    <a href='https://www.crowdvoice.ai/info' target='_blank'
                       style={{textDecoration: 'none'}}>
                      <MuiTypographyLink>
                        www.crowdvoice.ai/info
                      </MuiTypographyLink>
                    </a>
                  </div>
                </MuiDivHeader>
              </MuiBox>
            </MuiBoxContainer>
            <MuiBoxButtons sx={{gap: '12px'}}>
              {isShowOptions && (
                <>
                  <MuiIconButton
                    onClick={() => exportPDF(scenario.data.scheme, scenario.data.scheme.jsonSchemePDF, organization, palette, setIsLoading, setTitle, setShowProgressBar)}
                  >
                    <MuiPDFIcon color='#424242'/>
                  </MuiIconButton>
                  <MuiIconButton
                    onClick={handleCopyUrl}
                  >
                    <LinkIcon sx={{color: '#424242'}}/>
                    {isCopied && <CheckCircle sx={{
                      ...checkCircleStyle,
                      color: generalContent.primary_color,
                    }}/>}
                  </MuiIconButton>
                </>
              )}
              <MuiMoreVertButton
                onClick={() => setIsShowOptions(!isShowOptions)}>
                <MoreVert sx={{color: 'black'}}/>
              </MuiMoreVertButton>
              {showSave && (
                <MuiSaveAsButton
                  primaryColor={generalContent.primary_color}
                  onClick={handleSave}
                >
                  <SaveAs sx={{color: 'white', fontSize: '32px'}}/>
                </MuiSaveAsButton>)}
              {showGoHome &&
                <MuiArrowButton
                  primaryColor={generalContent.primary_color}
                  onClick={() => window.scrollTo({top: 0, behavior: 'smooth'})}
                >
                  <ArrowUpward sx={{color: 'white'}}/>
                </MuiArrowButton>}
            </MuiBoxButtons>
          </MuiSectionInitial>

          {/*2. About Company*/}
          <MuiAboutCompanySection id='sectionAboutCompany'>
            <Grid
              container
              spacing={6}
              display='flex'
              justifyContent="space-evenly"
              alignItems="stretch"
            >
              <Grid item style={{width: '551px'}}>
                <Box sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  height: '65%'
                }}>
                  <MuiTitle variant="h4">
                    {t('about')}
                    <span style={{
                      color: generalContent.primary_color,
                      marginLeft: '15px'
                    }}
                    >
                  {organization.name}
                   </span>
                  </MuiTitle>
                  <MuiTypographyDescription variant="body1">
                    {generalContent.description}
                  </MuiTypographyDescription>
                </Box>
                <Box sx={{display: 'flex'}}>
                  <MuiDivEllipse>
                    <MuiImgProfile
                      sx={{width: 257, height: 258}}
                      src={generalContent.image}
                      alt="Organization Logo"
                    />
                  </MuiDivEllipse>
                  <img
                    src={ellipse}
                    alt="img7"
                    style={imgEllipseStyle}
                  />
                </Box>
              </Grid>

              <Grid item style={{width: '551px', height: 'auto'}}>
                <Box>
                  <MuiTitle variant="h4">
                    {t('content')} <span
                    style={{color: generalContent.primary_color}}>{t('list')}</span>
                  </MuiTitle>
                  <MuiBoxList primaryColor={generalContent.primary_color}>
                    <List>
                      {sections.map((section) => (
                        <ListItem key={section.id} sx={{padding: "4px 0"}}>
                          {!section.root && (<ListItemIcon>
                            <CircleIcon sx={circleIconStyle}/>
                          </ListItemIcon>)}
                          <Link component="button"
                                onMouseMove={() => indexToQuestion(section.id, section.index)}
                                onClick={() => handleGoToSection(section.id)}
                                sx={{textDecoration: "none", color: "white"}}>
                            <MuiIndexSectionName
                              variant="subtitle1"
                              isRoot={section.root}
                            >
                              {section.title}
                            </MuiIndexSectionName>
                          </Link>
                        </ListItem>
                      ))}
                    </List>
                  </MuiBoxList>
                </Box>
              </Grid>
              <MuiDivider/>
            </Grid>
          </MuiAboutCompanySection>

          {/*3. Executive Summary*/}
          <MuiExecutiveSummarySection id='sectionExecutiveSummary'>
            <MuiGridSummary item xs={6}>
              <MuiTitle variant="h4">
                1.{t('executive')} <span
                style={{color: generalContent.primary_color}}>{t('summary')}</span>
              </MuiTitle>
            </MuiGridSummary>
            <Grid
              container
              spacing={2}
              display='flex'
              justifyContent="space-evenly"
              alignItems="flex-start"
            >
              <MuiGridLeft item>
                {executiveSummary?.sections?.slice(0, 2).map((item, index) => (
                  <>
                    <MuiBoxSummary id={`executiveSummary-${index}`}>
                      <MuiSectionName>
                        1.{index + 1}.{item.sectionName}
                      </MuiSectionName>

                      <IconButton edge="end" size="small" aria-label="edit"
                                  sx={iconButtonStyle}>
                        {activeEditor && matchEditorElement === index ? (
                          <CloseIcon style={{
                            ...iconStyle,
                            color: generalContent.primary_color
                          }} onClick={handleCloseEditor}/>
                        ) : (
                          <EditIcon
                            style={iconStyle}
                            onClick={() => handleToggleEditor(index)}/>
                        )}
                      </IconButton>
                    </MuiBoxSummary>
                    <Box sx={{display: 'flex', alignItems: 'flex-start'}}>
                      {activeEditor && matchEditorElement === index ? (
                        <MuiEditor elevation={0}>
                          <Box sx={{height: 'auto', width: '100%'}}>
                            <CKEditor
                              editor={ClassicEditor}
                              data={item.sectionContent}
                              config={{
                                removePlugins: pluginsEditor,
                                toolbar: toolbarEditor,
                              }}
                              onChange={(e, editor) => handleEditorChange(e, editor, 'executive_summary', index)}
                            />
                          </Box>
                          <MuiAcceptButton
                            startIcon={(<CheckIcon/>)}
                            variant='outlined'
                            primaryColor={generalContent.primary_color}
                            onClick={() => handleDispatch('executive_summary', index)}
                          >
                            {t('accept')}
                          </MuiAcceptButton>
                        </MuiEditor>
                      ) : (
                        <MuiContentHTML
                          variant="body1"
                          dangerouslySetInnerHTML={{__html: item.sectionContent}}>
                        </MuiContentHTML>
                      )}
                    </Box>
                  </>
                ))}
              </MuiGridLeft>

              <MuiGridRight item>
                {executiveSummary.sections.slice(2, 4).map((item, index) => (
                  <>
                    <MuiBoxSummary id={`executiveSummary-${index + 2}`}>
                      <MuiSectionName>
                        1.{index + 3}.{item.sectionName}
                      </MuiSectionName>
                      <IconButton edge="end" size="small" aria-label="edit"
                                  sx={iconButtonStyle}>
                        {activeEditor && matchEditorElement === index + 2 ? (
                          <CloseIcon style={{
                            ...iconStyle,
                            color: generalContent.primary_color
                          }} onClick={handleCloseEditor}/>
                        ) : (
                          <EditIcon
                            style={iconStyle}
                            onClick={() => handleToggleEditor(index + 2)}/>
                        )}
                      </IconButton>
                    </MuiBoxSummary>
                    <Box sx={{display: 'flex', alignItems: 'flex-start'}}>
                      {activeEditor && matchEditorElement === index + 2 ? (
                        <MuiEditor elevation={0}>
                          <Box sx={{minHeight: '300px', width: '100%'}}>
                            <CKEditor
                              editor={ClassicEditor}
                              data={item.sectionContent}
                              config={{
                                removePlugins: pluginsEditor,
                                toolbar: toolbarEditor,
                              }}
                              onChange={(e, editor) => handleEditorChange(e, editor, 'executive_summary', index + 2)}
                            />
                          </Box>
                          <MuiAcceptButton
                            startIcon={(<CheckIcon/>)}
                            variant='outlined'
                            primaryColor={generalContent.primary_color}
                            onClick={() => handleDispatch('executive_summary', index + 2)}
                          >
                            {t('accept')}
                          </MuiAcceptButton>
                        </MuiEditor>
                      ) : (
                        <MuiContentHTML
                          variant="body1"
                          dangerouslySetInnerHTML={{__html: item.sectionContent}}>
                        </MuiContentHTML>
                      )}
                    </Box>
                  </>
                ))}
              </MuiGridRight>
              <MuiDivider/>
            </Grid>
          </MuiExecutiveSummarySection>

          {/*4. Grafico de pastel*/}
          <MuiPieChartSection id='sectionGraphPie'>
            <Grid item xs={6}
                  style={{textAlign: 'center', marginLeft: '-4rem'}}>
              <MuiTitle variant="h4">
                2.{t('relevant')} <span
                style={{color: generalContent.primary_color}}>{t('segments')}</span>
              </MuiTitle>
            </Grid>
            <Grid
              container
              spacing={2}
              display='flex'
              direction='column'
              justifyContent="space-evenly"
              alignItems="center"
            >
              {segmentsAnalysis?.sections?.map((item, index) => (
                <>
                  {index % 2 === 0 ? (
                    <MuiEvenGrid
                      item
                      id={getSectionId('sectionGraphPie', item.sectionName)}
                    >
                      <div style={{width: '50%'}}>
                        <MuiBoxItem>
                          <MuiSectionName>
                            2.{index + 1}.{item.sectionName}
                          </MuiSectionName>
                          <IconButton edge="end" size="small" aria-label="edit"
                                      sx={iconButtonStyle}>
                            {activeEditor && matchEditorElement === index ? (
                              <CloseIcon style={{
                                ...iconStyle,
                                color: generalContent.primary_color
                              }} onClick={handleCloseEditor}/>
                            ) : (
                              <EditIcon style={iconStyle}
                                        onClick={() => handleToggleEditor(index)}/>
                            )}
                          </IconButton>
                        </MuiBoxItem>
                        <MuiBoxContent>
                          {activeEditor && matchEditorElement === index ? (
                            <MuiEditor elevation={0}>
                              <Box sx={{height: 'auto', width: '100%'}}>
                                <CKEditor
                                  editor={ClassicEditor}
                                  data={item.sectionContent}
                                  config={{
                                    removePlugins: pluginsEditor,
                                    toolbar: toolbarEditor,
                                  }}
                                  onChange={(e, editor) => handleEditorChange(e, editor, 'segments_analysis', index)}
                                />
                              </Box>
                              <MuiAcceptButton
                                startIcon={(<CheckIcon/>)}
                                variant='outlined'
                                primaryColor={generalContent.primary_color}
                                onClick={() => handleDispatch('segments_analysis', index)}
                              >
                                {t('accept')}
                              </MuiAcceptButton>
                            </MuiEditor>
                          ) : (
                            <MuiContentHTML
                              variant="body1"
                              dangerouslySetInnerHTML={{__html: item.sectionContent}}>
                            </MuiContentHTML>
                          )}
                        </MuiBoxContent>
                      </div>
                      <PieChart
                        isInteractive={true}
                        width={422}
                        height={220}
                        top={20}
                        left={-140}
                        data={getDataPieChart(item.additionalData.categories)}
                        translateX={-170}
                      />
                    </MuiEvenGrid>
                  ) : (
                    <MuiOddGrid
                      item
                      id={getSectionId('sectionGraphPie', item.sectionName)}
                    >
                      <PieChart
                        isInteractive={true}
                        width={622}
                        height={220}
                        top={20}
                        left={-60}
                        data={getDataPieChart(item.additionalData.categories)}
                        translateX={-230}
                      />
                      <div style={{width: '50%'}}>
                        <MuiBoxItem>
                          <MuiSectionName>
                            2.{index + 1}.{item.sectionName}
                          </MuiSectionName>
                          <IconButton edge="end" size="small" aria-label="edit"
                                      sx={iconButtonStyle}>
                            {activeEditor && matchEditorElement === index ? (
                              <CloseIcon style={{
                                ...iconStyle,
                                color: generalContent.primary_color
                              }} onClick={handleCloseEditor}/>
                            ) : (
                              <EditIcon
                                style={iconStyle}
                                onClick={() => handleToggleEditor(index)}/>
                            )}
                          </IconButton>
                        </MuiBoxItem>
                        <MuiBoxContent>
                          {activeEditor && matchEditorElement === index ? (
                            <MuiEditor elevation={0}>
                              <Box sx={{height: 'auto', width: '100%'}}>
                                <CKEditor
                                  editor={ClassicEditor}
                                  data={item.sectionContent}
                                  config={{
                                    removePlugins: pluginsEditor,
                                    toolbar: toolbarEditor,
                                  }}
                                  onChange={(e, editor) => handleEditorChange(e, editor, 'segments_analysis', index)}
                                />
                              </Box>
                              <MuiAcceptButton
                                startIcon={(<CheckIcon/>)}
                                variant='outlined'
                                primaryColor={generalContent.primary_color}
                                onClick={() => handleDispatch('segments_analysis', index)}
                              >
                                {t('accept')}
                              </MuiAcceptButton>
                            </MuiEditor>
                          ) : (
                            <MuiContentHTML
                              variant="body1"
                              dangerouslySetInnerHTML={{__html: item.sectionContent}}>
                            </MuiContentHTML>
                          )}
                        </MuiBoxContent>
                      </div>
                    </MuiOddGrid>
                  )}
                </>
              ))}
              <MuiDivider/>
            </Grid>
          </MuiPieChartSection>

          {/*5. Grafico de barras horizontal*/}
          <MuiHorizontalBarChartSection id='sectionGraphBarH'>
            <Grid container spacing={4}
                  justifyContent="center"
                  alignItems="center"
                  direction='column'
                  style={{borderRadius: 0}}>
              <MuiTitle variant="h4">
                3. {t('responses')}<span
                style={{color: generalContent.primary_color}}> {t('analysis')}</span>
              </MuiTitle>
              <MuiDivTabs isFixed={isFixed}>
                <Tabs
                  sx={tabsStyle}
                  value={activeTab}
                  onChange={handleChangeQuestion}
                  orientation="horizontal"
                  indicatorColor="secondary"
                  TabIndicatorProps={{
                    style: {
                      backgroundColor: lighten(generalContent.primary_color || '#ff0000', 0.1),
                      height: 3,
                      width: 85,
                      marginLeft: 17,
                      borderRadius: '10px 10px 0 0',
                      transition: 'width 0.3s',
                    },
                  }}
                >
                  {tabs.map((tab) => (
                    <MuiTab
                      key={tab.id}
                      primaryColor={generalContent.primary_color}
                      tab={tab.id}
                      activeTab={activeTab}
                      label={tab.value}
                      value={tab.id}
                      onClick={() => setActiveTab(tab.id)}
                    />
                  ))}
                </Tabs>
              </MuiDivTabs>
            </Grid>
            <Grid item xs={12}>
              <div
                id={getSectionId('', Object.keys(openQuestionAnalysis)[activeTab])}>
                <CurrentQuestionContent
                  tabId={activeTab}
                  generalContent={scenario.data.scheme.jsonSchemePDF.general}
                  openQuestionAnalysis={openQuestionAnalysis}
                  activeEditor={activeEditor}
                  matchEditorElement={matchEditorElement}
                  handleCloseEditor={handleCloseEditor}
                  handleToggleEditor={handleToggleEditor}
                  handleEditorChange={handleEditorChange}
                  handleDispatch={handleDispatch}
                  scheme={scenario.data.scheme}
                  collection={scenario.data.collection}
                  pluginsEditor={pluginsEditor}
                  toolbarEditor={toolbarEditor}
                  getColorsByImages={getColorsByImages}
                  t={t}
                />
              </div>
            </Grid>
          </MuiHorizontalBarChartSection>

          {/*10. Conclusiones*/}
          <MuiConclusionSection
            id='sectionConclutions'
            primaryColor={generalContent.primary_color}>
            <Grid
              container
              spacing={2}
              display='flex'
              justifyContent="space-evenly"
              alignItems="center"
              sx={{marginTop: '50px', padding: '32px 10px'}}
            >
              <MuiGridTextHTML item alignSelf='flex-start'>
                <MuiBoxConclusion>
                  <MuiTitle variant="h4" sx={{color: 'white'}}>
                    4.{t('conclusions')}
                  </MuiTitle>
                </MuiBoxConclusion>
                <MuiBoxListConclusion>
                  {conclusions?.sections[0]?.sectionContent.map((item, index) => (
                    <MuiBoxItemConclusion>
                      {activeEditor && matchEditorElement === index ? (
                        <MuiEditor elevation={0}
                                   sx={{background: 'transparent'}}>
                          <Box sx={{height: 'auto', width: '100%'}}>
                            <CKEditor
                              editor={ClassicEditor}
                              data={`${item.conclusionTitle}:${item.conclusionContent}`}
                              config={{
                                removePlugins: pluginsEditor,
                                toolbar: toolbarEditor,
                              }}
                              onChange={(e, editor) => handleEditorChange(e, editor, 'conclusions', index)}
                            />
                          </Box>
                          <MuiAcceptButton
                            startIcon={(<CheckIcon/>)}
                            variant='outlined'
                            primaryColor={generalContent.primary_color}
                            onClick={() => handleDispatch('conclusions', index)}
                          >
                            {t('accept')}
                          </MuiAcceptButton>
                        </MuiEditor>
                      ) : (
                        <MuiContentHTML
                          sx={{color: 'white'}}
                          variant="body1"
                          dangerouslySetInnerHTML={{__html: `<strong>${item.conclusionTitle}:</strong> ${item.conclusionContent}`}}>
                        </MuiContentHTML>
                      )}

                      <IconButton edge="end" size="small" aria-label="edit"
                                  sx={{...iconButtonStyle, marginTop: '15px'}}>
                        {activeEditor && matchEditorElement === index ? (
                          <CloseIcon style={{...iconStyle, color: 'white'}}
                                     onClick={handleCloseEditor}/>
                        ) : (
                          <EditIcon style={{...iconStyle, color: 'white'}}
                                    onClick={() => handleToggleEditor(index)}/>
                        )}
                      </IconButton>
                    </MuiBoxItemConclusion>
                  ))}
                </MuiBoxListConclusion>
              </MuiGridTextHTML>
              <Grid item alignSelf='flex-start'>
                <MuiBoxImgConclusion
                  primaryColor={generalContent.primary_color}>
                  <Grid container columnGap={'4px'} rowGap={'4px'}
                        display={'grid'} gridTemplateColumns={'repeat(2,1fr)'}>
                    {imagesConclusion.length !== 0 ? (
                      <>
                        {imagesConclusion.map((image, index) => (
                          <MuiImgConclusion item key={index} height={'460px'}>
                            <Box
                              component="img"
                              src={image}
                              alt={`img-${index}`}
                              sx={imgConclusionStyle}
                            />
                          </MuiImgConclusion>
                        ))}
                      </>
                    ) : (
                      <>
                        {getColorsByImages(10).slice(0, 4).map((color, index) => (
                          <Grid item xs={6} key={index}>
                            <MuiColors color={color}/>
                          </Grid>
                        ))}
                      </>
                    )}
                  </Grid>
                </MuiBoxImgConclusion>
              </Grid>
            </Grid>
          </MuiConclusionSection>

          {/*11. Galeria*/}
          {(questions?.flatMap(q => q.topics).some(t => !!t?.image_url)) && (
            <MuiGallerySection id='sectionGallery'>
              <Grid
                container
                spacing={2}
                display='flex'
                justifyContent="space-evenly"
                alignItems="center"
                sx={{marginTop: '50px'}}
              >
                <Grid item style={{width: '85%', height: 'auto'}}>
                  <MuiBoxGallery>
                    <MuiTitle variant="h4">
                      5.{t('gallery')}
                    </MuiTitle>
                    {Object.keys(openQuestionAnalysis).map((item, index) => (
                      <Box sx={{marginTop: '32px', width: '100%'}}>
                        <MuiQuestionNumber>
                          Question {index + 1}
                        </MuiQuestionNumber>
                        <div style={{width: '50%'}}>
                          <MuiSectionName>
                            {item}
                          </MuiSectionName>
                        </div>
                        {matchQuestion(item)}
                      </Box>
                    ))}
                  </MuiBoxGallery>
                </Grid>
              </Grid>
            </MuiGallerySection>)}
        </>
      )}
    </>
  );
};

export default ReportPDF;
