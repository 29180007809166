import React, {useEffect, useState} from 'react';
import {v4 as uuidv4} from 'uuid';
import {getNormalPolicy} from '../../../../../../utils/template';
import {useDispatch, useSelector} from 'react-redux';
import {setActivePoliciesTab} from '../../../../../../store/appSlice';
import * as _ from 'lodash';
import {Grid, Tab, Tabs, Typography} from "@mui/material";
import {useSnackbar} from "notistack";
import {useTranslation} from "../../../../../providers/TranslationProvider";
import Question from "./steps/Question";
import DragHandleIcon from '@mui/icons-material/DragHandle';
import {
  SortableContainer,
  SortableElement,
  sortableHandle
} from 'react-18-compat-sortable-hoc';
import {
  addQuestionButtonStyle, customTabStyle,
  dragHandleStyle,
  MuiAddIcon,
  MuiBoxTabs,
  MuiButton,
  MuiCommonTypography,
  MuiDeleteIcon,
  MuiGridTabContent,
  MuiPaperContainer,
  MuiQuestionText,
  SubordinateQuestionIcon,
  WarningContainer
} from "./styles/openQuestions";
import {useTheme} from "../../../../../providers/CustomThemeProvider";
import WarningIcon from '@mui/icons-material/Warning';
import swap from 'lodash-move';
import {normalizeString} from "../../../../../../utils/text";

const OpenQuestions = ({
                         questions,
                         analysisObjective,
                         contextDescription,
                         audienceDescription,
                         dataRegion,
                         variables,
                         setQuestions,
                         dataset,
                         weight,
                         handleSaveScheme
                       }) => {

  const [activeQuestion, setActiveQuestion] = useState(0);
  const activePoliciesTab = useSelector(state => state.app.activePoliciesTab);

  const dispatch = useDispatch()
  const {enqueueSnackbar} = useSnackbar();

  const {t} = useTranslation();
  const {theme} = useTheme();

  const customTabsStyle = {
    height: '100%',
    width: '100%',
    '& .MuiTabs-indicator': {
      maxWidth: 3,
      height: '28px !important',
      marginTop: '15px',
      borderRadius: '2px',
      width: '100%',
      backgroundColor: theme.palette.primary.main,
    },
  }

  const warningToast = (msg) => {
    enqueueSnackbar(msg, {variant: 'warning'})
  }

  const onChange = (newActiveKey, newValue) => {
    const questionIndex = questions.findIndex(p => p.id === newValue)
    const localQuestions = [...questions]
    const question = questions[questionIndex];
    if (question.topics?.length > 0 && question.parentPolicy) {
      const parentPolicy = questions.find(p => p.id === question.parentPolicy?.id);
      if (parentPolicy) {
        const parentTopics = parentPolicy.topics.flatMap(pt => [
          {
            topic: pt.topic,
            description: pt.description,
            image_url: pt.image_url,
            image_description: pt.image_description,
            subtopics: []
          },
          ...pt.subtopics?.length > 0 ? (pt.subtopics.map(st => ({
            topic: `${pt.topic} - ${st.subtopic}`,
            description: st.description,
            image_url: st.image_url,
            image_description: st.image_description,
            subtopics: []
          }))) : []
        ]);
        let images_updated = false;
        question.topics.forEach(t => {
          if (!t.image_url || !t.image_description) {
            images_updated = true;
            const pt = parentTopics.find(tp => normalizeString(tp.topic) === normalizeString(t.topic))
            t.image_url = pt?.image_url
            t.image_description = pt?.image_description
          }
        });
        if (images_updated) {
          localQuestions[questionIndex] = question;
          setQuestions(localQuestions);
        }
      }
    } else {
      questions.forEach((lq, index) => {
        if (lq.parentPolicy && lq.parentPolicy.id === question.parentPolicy?.id) {
          const parentTopics = question.topics.flatMap(pt => [
            {
              topic: pt.topic,
              description: pt.description,
              image_url: pt.image_url,
              image_description: pt.image_description,
              subtopics: []
            },
            ...pt.subtopics?.length > 0 ? (pt.subtopics.map(st => ({
              topic: `${pt.topic} - ${st.subtopic}`,
              description: st.description,
              image_url: st.image_url,
              image_description: st.image_description,
              subtopics: []
            }))) : []
          ]);
          let images_updated = false;
          lq.topics.forEach(t => {
            if (!t.image_url || !t.image_description) {
              images_updated = true;
              const pt = parentTopics.find(tp => normalizeString(tp.topic) === normalizeString(t.topic))
              t.image_url = pt?.image_url
              t.image_description = pt?.image_description
            }
          });
          if (images_updated) {
            localQuestions[index] = lq;
            setQuestions(localQuestions);
          }
        }
      });
    }
    setActiveQuestion(questionIndex);
    dispatch(setActivePoliciesTab(newValue))
  };

  const selectQuestion = (qIndex) => {
    if (qIndex < questions.length) {
      setActiveQuestion(qIndex);
      dispatch(setActivePoliciesTab(questions[qIndex].id))
    }
  }
  useEffect(() => {
    if (!questions || !questions.length && dataset && variables) {
      add();
    } else if (questions?.length > 0) {
      setActiveQuestion(0);
      dispatch(setActivePoliciesTab(questions[0].id))
    }
  }, []);

  useEffect(() => {
    if (questions.length > 0) {
      if (activePoliciesTab) {
        const questionIndex = questions.findIndex(p => p.id === activePoliciesTab)
        if (questionIndex >= 0 && questionIndex !== activeQuestion) {
          setActiveQuestion(questionIndex);
        } else if (questionIndex < 0) {
          setActiveQuestion(0);
        }
      }
    }
  }, [activePoliciesTab, questions]);

  const add = () => {
    const id = uuidv4();
    let allQuestions = [...questions];
    let newQuestion = {
      id,
      name: "Question " + (allQuestions.length + 1),
      answerVar: '',
      audioVar: null,
      tree_state: null,
      parentPolicy: null,
      pos: questions.length + 1,
      selectedConditionalSegment: null,
      type: 'normal',
      topics: [],
      classified: false,
      segmentation_variables: {
        in_dataset: [], custom: null,
      },
    };

    newQuestion = getNormalPolicy(newQuestion)

    allQuestions.push(newQuestion);
    setQuestions(allQuestions);
    setActiveQuestion(allQuestions.length - 1);
    dispatch(setActivePoliciesTab(newQuestion.id));
  };

  const remove = (targetKey) => {
    let indexToDelete = questions.findIndex(item => item.id === targetKey);
    let localQuestions = [...questions]
    localQuestions.splice(indexToDelete, 1);
    setQuestions(localQuestions);
    let newActiveQuestionIndex = activeQuestion;
    if (activeQuestion >= localQuestions.length || activeQuestion === indexToDelete) {
      newActiveQuestionIndex = Math.max(0, localQuestions.length - 1);
      dispatch(setActivePoliciesTab(localQuestions[newActiveQuestionIndex]?.id))
      setActiveQuestion(newActiveQuestionIndex);

    }

  };

  const handleQuestionChange = (question) => {
    const questionIndex = questions.findIndex(p => p.id === question.id)
    let localQuestions = [...questions]
    if (!question.parentPolicy) {
      localQuestions.forEach(lq => {
        if (lq.parentPolicy !== null && lq.parentPolicy?.id === question.id && lq.id !== question.id && lq.topics?.length > 0) {
          const subordinateTopics = lq.parentPolicy.topics.map((pt) => {
            return {
              topic: pt.topic,
              description: pt.description,
              representativity: pt.representativity,
            }
          });
          const updatedParentTopics = question.topics.map((pt) => {
            return {
              topic: pt.topic,
              description: pt.description,
              representativity: pt.representativity,
            }
          });
          if (!_.isEqual(subordinateTopics, updatedParentTopics)) {
            if (lq.classified || lq.topics?.length > 0) {
              warningToast(`The topics for "${lq.name}" have been overridden by the parent question, please reclassify the question.`);
            }
            lq.parentPolicy = question
            lq.topics = []
            lq.classified = false;
          }
        }
      });
    }
    localQuestions[questionIndex] = question;
    setQuestions(localQuestions);
  }


  const onSortEnd = ({oldIndex, newIndex}) => {
    let localQuestions = [...questions];
    setQuestions(swap(localQuestions, oldIndex, newIndex));
  };


  const DragHandle = sortableHandle(({id}) => (
    <DragHandleIcon
      color='primary'
      style={{
        ...dragHandleStyle,
        color: id === activePoliciesTab ? theme.palette.primary.main : '#757575'
      }}
    />
  ));

  const SortableTabs = SortableContainer(({children}) => {
    return (
      <Tabs
        orientation="vertical"
        variant="scrollable"
        value={activePoliciesTab || questions[0]?.id || null}
        aria-label="Vertical tabs example"
        sx={customTabsStyle}
      >
        {children}
      </Tabs>
    );
  });

  const SortableTab = SortableElement(({value, label}) => (
    <Tab
      onChange={onChange}
      value={value}
      key={value}
      label={label}
      style={{
        ...customTabStyle,
        background: value === activePoliciesTab ? '#FAFAFA' : '#FFFFFF'
      }}
    />
  ));

  return (
    <MuiPaperContainer id={'open-question-global-cont'}
                       sx={{width: '100%', maxWidth: '100%'}}>
      <>
        {questions.length > 0 ? (
          <Grid id={'boxContainer'} container item direction={'row'} xs={12} sx={{width: '100%',flexWrap: 'nowrap'}}>
            <Grid item sx={{width: '342px', flex: '0 0 auto'}}>
              <MuiQuestionText>
                {t('questions')}
              </MuiQuestionText>
              <MuiBoxTabs id={'box-tabs'}>
                <SortableTabs onSortEnd={onSortEnd} useDragHandle>
                  {questions.map((tab, index) => (
                    <SortableTab
                      value={tab.id}
                      key={tab.id}
                      index={index}
                      label={
                        <Grid container direction='column'
                              id={'tab-label-container'}
                              alignItems="center">
                          <Grid container item direction='row'
                                alignItems='center' alignContent='center'>
                            <Grid item xs={1}>
                              <DragHandle id={tab.id}/>
                            </Grid>
                            {(tab.parentPolicy) && (
                              <Grid item xs={'auto'}>
                                <SubordinateQuestionIcon
                                  color={tab.id === activePoliciesTab ? "primary" : "#424242"}/>
                              </Grid>)}
                            <Grid
                              container
                              direction='column'
                              item
                              xs={true}
                            >
                              <Grid item id='question-name'>
                                <Typography sx={{
                                  textAlign: 'initial',
                                  fontWeight: 500,
                                  fontFamily: 'Montserrat',
                                  marginLeft:'-3px',
                                  fontSize: '14px',
                                  letterSpacing: '0.1px',
                                  color: tab.id === activePoliciesTab
                                    ? theme.palette.primary.main : '#757575',
                                }}>
                                  {tab.name}
                                </Typography>
                              </Grid>
                              {(tab.parentPolicy && tab.topics.length === 0) && (
                                <WarningContainer container item direction='row'
                                                  alginContent='center'
                                                  justifyContent='flex-start'
                                                  columnGap={'5px'} wrap={'nowrap'}>
                                  <WarningIcon sx={{
                                    width: '10px',
                                    height: '10px',
                                    color: 'warning.main',
                                  }}/>
                                  <Typography sx={{
                                    fontSize: '8px',
                                    color: 'warning.main',
                                    fontFamily: 'Montserrat'
                                  }}>
                                    {t('re_classification_warning')}
                                  </Typography>
                                </WarningContainer>
                              )}
                            </Grid>
                            <Grid container item xs={2} justifyContent='center'
                                  alignContent='center'>
                              <MuiDeleteIcon sx={{
                                width: '26px',
                                height: '26px',
                                borderRadius: '13px',
                                '&:hover': {
                                  backgroundColor: 'transparent'
                                }
                              }}
                                             onClick={(evt) => {
                                               evt.preventDefault();
                                               evt.stopPropagation();
                                               remove(tab.id)
                                             }}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                      }
                    />
                  ))}
                </SortableTabs>
                <MuiButton
                  variant='outlined'
                  color='primary'
                  startIcon={<MuiAddIcon/>}
                  onClick={add}
                >
                  <MuiCommonTypography variant='body2'>
                    {t('add_question')}
                  </MuiCommonTypography>
                </MuiButton>
              </MuiBoxTabs>
            </Grid>
            <MuiGridTabContent item id={'tab-content'} sx={{
              background: '#FAFAFA',
              borderRadius: '20px',
              minHeight: '100%',
              flex: '1 1 auto',
              minWidth: 0
              // width: '100% !important'
            }}
            >
              <Question
                question={questions[activeQuestion]}
                questionIndex={activeQuestion}
                existingQuestionsCount={questions?.length || 0}
                addQuestion={add}
                nextQuestion={selectQuestion}
                dataRegion={dataRegion}
                parentCandidates={questions.filter(p => p.id !== activePoliciesTab)}
                variables={variables.filter((v) => {
                  if (v.category !== 'answer' && v.category !== 'audio') {
                    return true;
                  }
                  const qIdx = questions.findIndex(p => (p.answerVar === v.propName || p.audioVar === v.propName) && p.id !== activePoliciesTab);
                  return qIdx === -1;
                })}
                handleQuestionChange={(p) => handleQuestionChange(p)}
                collection={dataset}
                weight={weight}
                key={activePoliciesTab}
                analysisObjective={analysisObjective}
                contextDescription={contextDescription}
                audienceDescription={audienceDescription}
                handleSaveScheme={handleSaveScheme}
              />
            </MuiGridTabContent>
          </Grid>
        ) : (
          <MuiButton
            sx={addQuestionButtonStyle}
            variant='outlined'
            color='primary'
            startIcon={<MuiAddIcon/>}
            onClick={add}
          >
            <MuiCommonTypography variant='body2'>
              {t('add_question')}
            </MuiCommonTypography>
          </MuiButton>
        )}
      </>
    </MuiPaperContainer>);
};

export default OpenQuestions;
