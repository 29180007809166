import {useMutation, useQuery, useQueryClient} from "react-query";
import axiosSurveyInstance from "../axios/axiosSurveyInstance";

const fetchQuestionsGraph = (id, language) => {
  return axiosSurveyInstance.get(`/api/survey/question/graph?service_id=${id}&environment=dev&language=${language}`).then((resp) => {
    const {data} = resp;
    return data?.questions || null;
  }).catch((err) => {
    console.error("Error fetching survey", err);
    return null;
  });
};

const useGetQuestionsGraph = (data) =>
  useQuery(
  ['survey-graph', data.serviceId],
  () => fetchQuestionsGraph(data?.serviceId, data?.language),
  {
    enabled: Boolean(data?.serviceId),
    refetchOnWindowFocus: false,
    placeholderData: null
  }
);

export default useGetQuestionsGraph;
