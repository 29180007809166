import {useQuery} from "react-query";
import axiosSurveyInstance from "../axios/axiosSurveyInstance";

const fetchSurveyQuestions = (id) => {
  return axiosSurveyInstance.get(`/api/survey/question/list?service_id=${id}&environment=dev`).then((resp) => {
    const {data} = resp;
    return data?.questions || null;
  }).catch((err) => {
    console.error("Error fetching questions", err);
    return null;
  });
};

const useSurveyQuestions = (data) => useQuery(
  ['survey-questions', data?.serviceId],
  () => fetchSurveyQuestions(data?.serviceId),
  {
    enabled: Boolean(data?.serviceId),
    refetchOnWindowFocus: false,
    placeholderData: null
  }
);

export default useSurveyQuestions;
