// React and External Libraries
import * as React from 'react';
import {NavLink} from 'react-router-dom';

// Custom Hooks and Contexts
import {useTranslation} from '../providers/TranslationProvider';
import {useAuth} from 'components/providers/AuthProvider';

// Style and asset imports
import {
  MuiAccountTreeIconIcon,
  MuiAppsIcon, MuiBoxNavigation,
  MuiBreadcrumbs,
  MuiGrid,
  MuiHomeIcon,
  MuiIconButton,
  MuiPersonIcon,
  MuiScenarioIcon,
  MuiSettingsIcon,
} from './styles/navigationMenu';
import {MuiTypography} from "./styles/navigationMenu";
import {useSelector} from "react-redux";
import useSurvey from "../../api/hooks/useSurvey";

const NavigationMenu = ({location, column: vertical}) => {
  const {t} = useTranslation();
  const {user} = useAuth();

  const targetScheme=useSelector(state => state.app.targetScheme);
  const surveyId = location.pathname.split('/')[4];
  const nameSurvey = useSurvey({ id: surveyId })?.data?.name;

  const getLastElement = () => {
    if (
      location.pathname.split('/')[1] &&
      !location.pathname.split('/')[2] &&
      !location.pathname.split('/').includes('specification') &&
      !location.pathname.split('/').includes('analysis')&&!location.pathname.split('/').includes('analytics')
    )
      return 0;
    else if (
      location.pathname.split('/')[2] &&
      !location.pathname.split('/').includes('specification') &&
      !location.pathname.split('/').includes('analysis')&&!location.pathname.split('/').includes('analytics')
    )
      return 1;
    else if (location.pathname.split('/').includes('surveys')) {
      return location.pathname.split('/').includes('create') ? 4 : 5;
    }
    else if (location.pathname.split('/').includes('specification')) return 2;
    else if (location.pathname.split('/').includes('analysis')) return 3;
    else if (location.pathname.split('/').includes('analytics')) return 2;


  };

  return (
    <MuiGrid
      container
      direction={vertical ? 'column' : 'row'}
    >
      <MuiBreadcrumbs aria-label='breadcrumb'>
        {user && (user.type === 'super_admin' || user.type === 'admin' || user.orgs_user_level.length !== 0) && (
          <MuiBoxNavigation>
            <MuiIconButton
              component={NavLink}
              to={`${location.pathname.split('/')[0]}/orgs`}
              disableRipple
            >
              <MuiAppsIcon/>
              <MuiTypography variant="subtitle1" color='inherit'
                             component="span"
                             sx={{marginLeft: '8px'}}> {t('organizations')}</MuiTypography>
            </MuiIconButton>
          </MuiBoxNavigation>)}
        {getLastElement() >= 1 && location.pathname.split('/')[2] !== 'workspace' ? (
          getLastElement() === 1 ?
            (<MuiBoxNavigation>
              <MuiIconButton
                component={NavLink}
                to={`${location.pathname.split('/').slice(0, 3).join('/')}/scenarios`}
                disableRipple
              >
                <MuiHomeIcon/>
                <MuiTypography variant="subtitle1" color='inherit'
                               component="span"> {t('organization')}</MuiTypography>
              </MuiIconButton>
            </MuiBoxNavigation>) :
            (<MuiBoxNavigation>
              <MuiIconButton
                component={NavLink}
                to={`${location.pathname.split('/').slice(0, 3).join('/')}/scenarios`}
                disableRipple
              >
                <MuiHomeIcon/>
                <MuiTypography variant="subtitle1" color='inherit'
                               component="span"> {t('organization')}</MuiTypography>
              </MuiIconButton>
            </MuiBoxNavigation>)
        ) : (
          location.pathname.split('/')[2] === 'workspace' && (
            getLastElement() === 1 ?
              (<MuiBoxNavigation>
                <MuiIconButton
                  component={NavLink}
                  to={`${location.pathname.split('/').slice(0, 3).join('/')}/scenarios`}
                  disableRipple
                >
                  <MuiPersonIcon/>
                  <MuiTypography variant="subtitle1" color='inherit'
                                 component="span"> {t('organization')}</MuiTypography>
                </MuiIconButton>
              </MuiBoxNavigation>) :
              (<MuiBoxNavigation>
                <MuiIconButton
                  component={NavLink}
                  to={`${location.pathname.split('/').slice(0, 3).join('/')}/scenarios`}
                  disableRipple
                >
                  <MuiPersonIcon/>
                  <MuiTypography variant="subtitle1" color='inherit'
                                 component="span"> {t('organization')}</MuiTypography>
                </MuiIconButton>
              </MuiBoxNavigation>)
          )
        )}
        {getLastElement() >= 2 && (user.type === 'super_admin' || user.type === 'admin') && (
          getLastElement() === 2 &&
          (<MuiBoxNavigation>
              <MuiIconButton
                component={NavLink}
                to={`${location.pathname.split('/').slice(0, 3).join('/')}/scenarios/${
                  location.pathname.split('/')[4]
                }/specification`}
                disableRipple
              >
                <MuiSettingsIcon/>
                <MuiTypography variant="subtitle1" color='inherit'
                               component="span"> {t('specification')}</MuiTypography>
              </MuiIconButton>
            </MuiBoxNavigation>
          ))}
        {getLastElement() === 3 &&
          (<MuiBoxNavigation>
            <MuiIconButton
              component={NavLink}
              to={`${location.pathname}`} disableRipple>
              <MuiScenarioIcon/>
              <MuiTypography variant="subtitle1" color='inherit'
                             component="span"> {targetScheme?.name||('scenario')}</MuiTypography>
            </MuiIconButton>
          </MuiBoxNavigation>)}
        {(getLastElement() === 4 || getLastElement() === 5)  &&
        (<MuiBoxNavigation>
          <MuiIconButton
            component={NavLink}
            to={`${location.pathname}`} disableRipple>
            <MuiAccountTreeIconIcon/>
            <MuiTypography
              variant="subtitle1"
              color='inherit'
              component="span"
            >
              {getLastElement() === 4 ? t('surveys') : nameSurvey}
            </MuiTypography>
          </MuiIconButton>
        </MuiBoxNavigation>)}
      </MuiBreadcrumbs>
    </MuiGrid>
  );
};
export default NavigationMenu;
