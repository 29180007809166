import {SvgIcon, ToggleButton} from "@mui/material";
import {Leaderboard} from "@mui/icons-material";
import SpaceDashboardIcon from "@mui/icons-material/SpaceDashboard";
import {MUIToggleGroup} from "./styles/treePage";
import * as React from "react";

export const VisualizationSelector = ({
                                        visualization,
                                        updateAnalysisVisualizationType,
                                        hasImages
                                      }) => {
  const selectedStyle = {
    '&.Mui-selected': {
      borderRadius: '12px',
      height: '24px',
      width: '32px',
      margin: '4px 8px 4px 4px'
    },

  }
  return (
    <MUIToggleGroup exclusive value={visualization} sx={{
      height: '32px',
      width: '75px',
      margin: '0px',
      padding: '0px 0px 0px 0px',
      borderRadius: '16px',
      border: '1px solid #E0E0E0',
    }}
                    onChange={(event, value) => {
                      updateAnalysisVisualizationType(value);
                    }}
    >
      <ToggleButton
        value={'bars'}
        sx={visualization === 'bars' ? selectedStyle : {
          borderRadius: '16px',
          border: '0px solid #E0E0E0',
          backgroundColor: 'transparent',
          height: '24px',
          width: '32px',
          margin: '4px 4px 4px 4px',
        }}
        onClick={(evt) => {
          if (visualization === 'bars') {
            evt.preventDefault();
            evt.stopPropagation();
          }
        }}
      >
        <Leaderboard
          style={{
            transform: 'rotate(90deg)'
          }}
          sx={{
            width: '16px',
            height: '16px',
          }}
        />
      </ToggleButton>
      {hasImages ? (
        <ToggleButton
          value={'art'}
          sx={visualization === 'art' ? selectedStyle : {
            borderRadius: '16px',
            border: '0px solid #E0E0E0',
            backgroundColor: 'transparent',
            height: '24px',
            width: '32px',
            margin: '3px 4px 4px 4px',
          }}
          onClick={(evt) => {
            if (visualization === 'art') {
              evt.preventDefault();
              evt.stopPropagation();
            }
          }}
        >
          <SvgIcon sx={{
            width: '18px',
            height: '18px',
          }} viewBox="0 0 18 18">
            <path
              d="M11.9997 9.99984V13.3332L2.66634 13.3332L2.66634 3.99984L5.99967 3.99984C7.33301 3.33317 7.33301 3.33317 7.33301 3.33317C7.97301 2.6665 7.65301 2.99984 7.97301 2.6665L2.66634 2.6665C1.93301 2.6665 1.33301 3.2665 1.33301 3.99984L1.33301 13.3332C1.33301 14.0665 1.93301 14.6665 2.66634 14.6665L11.9997 14.6665C12.733 14.6665 13.333 14.0665 13.333 13.3332V10.6665H12.6663L11.9997 9.99984ZM10.9997 11.9998L3.66634 11.9998L5.49967 9.6465L6.80634 11.2198L8.63967 8.85984L10.9997 11.9998Z"
              fill={visualization === 'art' ? "#212121" : "#757575"}/>
            <path
              d="M12.475 7.72083L11.3333 8.26683L12.475 8.81283L13 10.0002L13.525 8.81283L14.6667 8.26683L13.525 7.72083L13 6.5335L12.475 7.72083ZM10.5 6.5335L11.2833 4.74816L13 3.9335L11.2833 3.11883L10.5 1.3335L9.71667 3.11883L8 3.9335L9.71667 4.74816L10.5 6.5335Z"
              fill={visualization === 'art' ? "#212121" : "#757575"}/>
          </SvgIcon>
        </ToggleButton>
      ) : (
        <ToggleButton value={'tree'}
                      sx={visualization === 'tree' ? selectedStyle : {
                        borderRadius: '16px',
                        border: '0px solid #E0E0E0',
                        backgroundColor: 'transparent',
                        height: '24px',
                        width: '32px',
                        margin: '3px 4px 4px 4px',
                      }}
                      onClick={(evt) => {
                        if (visualization === 'tree') {
                          evt.preventDefault();
                          evt.stopPropagation();
                        }
                      }}
        >
          <SpaceDashboardIcon sx={{
            width: '16px',
            height: '16px',
          }}/>
        </ToggleButton>
      )}

    </MUIToggleGroup>
  );
}
