import {useQuery} from 'react-query';

import {status200} from '../status.utils';
import axios from '../axios/axiosInstance';
import apiPaths from '../apiPaths';
import lz from "lz-string";

const fetchSchemes = ({ user_id, scheme_id }) => {
  return axios
    .post(apiPaths.scheme, { user_id: user_id, scheme_id: scheme_id }, status200)
    .then((resp) => {
      const {data} = resp;
      if(data.scheme.jsonSchemePDF&&typeof data.scheme.jsonSchemePDF === 'string') {
        try {
          data.scheme.jsonSchemePDF = JSON.parse(lz.decompressFromBase64(data.scheme.jsonSchemePDF));
        } catch (e) {
          console.log("Error decompressing data", e);
        }
      }
      return data;
    });
}

const useScheme = (data) =>
  useQuery(['scheme', data.scheme_id], () => fetchSchemes(data), {
    enabled: data.enabled,
    refetchOnWindowFocus: false,
    placeholderData: {
      collection: {},
      scheme: {
        id: null,
        name: '',
        dataset: '',
        match: {
          in_dataset: [],
          custom: []
        },
        // prioritizationCustomVariables: [],
        // weight: '',
        policies: [],
        // aggregation: {},
        schemeCollectionConfig: []
      }
    }
  });

export default useScheme;
