import axiosSurveyInstance from "../axios/axiosSurveyInstance";
import {useQuery} from "react-query";

const fetchSegmentCategories = async (data) => {
  return axiosSurveyInstance.request({
    url: '/api/survey/analytics/aggregate-segment',
    data: data,
    method: 'post',
    maxBodyLength: Infinity
  }).then(rsp => rsp.data).then((data) => {
    if (data) {
      return Object.keys(data).map((k, index) => {
        return {
          id: index,
          label: k,
          value: data[k]
        }
      }).sort((a, b) => b.value - a.value)
    }
    return []
  }).catch(err => {
    console.log("Error fetching participation aggregation bins", err)
    return [];
  })
}

const useSurveySegmentAggregation = (data) => useQuery(
  ['survey-analytics-segment-agg', data?.service_id, data?.environment, data?.segmentQuestionId, data?.startDate, data?.endDate, data?.channels,data?.targetStatus],
  () => fetchSegmentCategories(data),
  {
    enabled: Boolean(data?.service_id) && Boolean(data?.segmentQuestionId),
    refetchOnWindowFocus: false,
    placeholderData: []
  }
);
export default useSurveySegmentAggregation;


