import * as React from "react";
import TurndownService from "turndown";
import {useEffect} from "react";
import {
  editorSurveyStyle, MuiGridOthersTab,
  MuiIconTranslate, MuiOptionsTypography
} from "../styles/genericModalMessage";
import {
  Box,
  Button as MuiButton, Chip, Divider, FormControl,
  Grid, IconButton, MenuItem,
  TextField,
  Typography
} from "@mui/material";
import TranslateIcon from "@mui/icons-material/Translate";
import {MuiEditor} from "../../scenarios/exporting/styles/viewReportPDF";
import {CKEditor} from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import {
  labelStyle, MuiCharCountTitle,
  MuiTitleField
} from "../../scenarios/specification/styles/generateReportModal";
import {
  MuiAddIcon,
  MuiCommonTypography
} from "../../scenarios/specification/sections/styles/openQuestions";
import {labelInputStyle} from "../../scenarios/specification/sections/styles/generalParameters";
import {MuiAutocomplete} from "../../scenarios/specification/sections/styles/segmentationParameters";
import {
  SortableContainer,
  SortableElement,
  SortableHandle
} from "react-18-compat-sortable-hoc";
import DragHandleIcon from "@mui/icons-material/DragHandle";
import DeleteIcon from "@mui/icons-material/Delete";
import swap from "lodash-move";

const DragHandle = SortableHandle(() => (
  <DragHandleIcon style={{cursor: 'row-resize',
    marginRight: 8,
    color:'#757575'}}/>
));

const SortableItem = SortableElement(({ itemIndex, item, updateOption, removeOption }) => {
  const [isEditing, setIsEditing] = React.useState(false);
  const [editedText, setEditedText] = React.useState(item?.answer);

  const handleEdit = () => setIsEditing(true);
  const handleChange = (event) => {
    if (event.target.value.length <= 20) {
      setEditedText(event.target.value);
    }
  };

  const handleBlur = () => {
    setIsEditing(false);
    updateOption(itemIndex, editedText); // Llamar a la función del padre para actualizar el estado
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleBlur();
    }
  };
  return (
    <Grid container sx={{padding:'12px 12px 12px 4px'}}>
      <Grid item xs={11} display={'flex'}>
        <DragHandle />
        {isEditing ? (
          <TextField
            value={editedText}
            onChange={handleChange}
            onBlur={handleBlur}
            onKeyDown={handleKeyDown}
            autoFocus
            variant="standard"
            fullWidth
            inputProps={{ maxLength: 20 }}
          />
        ) : (
          <Typography
            noWrap
            variant="body1"
            style={{
              color: '#212121',
              fontFamily: 'Montserrat',
              fontWeight: 400,
              fontSize: '16px',
              marginLeft: '8px',
              cursor: 'pointer',
            }}
            onClick={handleEdit}
          >
            {item?.answer}
          </Typography>
        )}
      </Grid>
      <Grid item xs={1}>
        <IconButton edge="end" size="small" aria-label="edit">
          {removeOption && (
            <DeleteIcon
              style={{color: '#757575', marginTop:'-5px', height:18, width:18}}
              onClick={() => removeOption(itemIndex)}
            />
          )}
        </IconButton>
      </Grid>
      <Divider sx={{ borderColor: '#E0E0E0', width:'100%', marginTop:'8px' }}/>
    </Grid>
  );
});

const SortableList = SortableContainer(({ items,updateOption, removeOption }) => {

  return (
    <Grid container justifyContent='flex-start'>
      {items?.map((item, index) => (
        <Box key={index} sx={{width:'100%'}}>
          <SortableItem
            index={index}
            item={item}
            itemIndex={index}
            removeOption={removeOption}
            updateOption={updateOption}
          />
        </Box>
      ))}
    </Grid>
  );
});

const OptionsSort = ({ options, setOptions, updateOption, removeOption }) => {
  let localOptions = [...options];

  const onSortEnd = ({ oldIndex, newIndex }) => {
    setOptions(swap(localOptions, oldIndex, newIndex));
  };

  return (
    <SortableList
      items={options}
      onSortEnd={onSortEnd}
      useDragHandle
      helperClass='helper-class'
      updateOption={updateOption}
      removeOption={removeOption}
    />
  );
};

const MessageForm = ({ methods, t, handleChangeShortName,charTitleCount, survey,
                       selectedOption, mainTextEditor, setMainTextEditor, publicName,
                       setPublicName, options,setOptions,setTextCardPhone, tabId,
                       questions , conditions, setConditions, handleFooterChange,
                       footerCount, handleParentQuestionChange, parentQuestion,
                       language, listLanguagesInterview, imageUrl, textCardPhone,
                       getLanguagesOfQuestion, setUpdateTranslation,
                       setDisabledTabsLanguage, questionTranslate
                     }) => {
  const [selectedQuestion, setSelectedQuestion] = React.useState('')
  const [allowedAnswers, setAllowedAnswers] = React.useState([]);

  const turndownService = new TurndownService({
    headingStyle: 'atx',
    codeBlockStyle: 'fenced',
  });

  turndownService.addRule('bold', {
    filter: ['strong', 'b'],
    replacement: (content) => `**${content}**`
  });

  const maxCharsTitle = 20;

  const handleEditorChange = (event, editor) => {
    const htmlText = editor.getData()
    setMainTextEditor(htmlText);
    const markdownText = turndownService.turndown(htmlText);
    methods.setValue('mainText', markdownText);
  }
  const handleChangeName = (event, editor) => {
    const htmlText = editor.getData()
    setPublicName(htmlText);
    const markdownText = turndownService.turndown(htmlText);
    methods.setValue('title', markdownText);
  }

  const addOption = () => {
    let newOption = `Option ${options.length + 1}`
    setOptions((prevOptions) => [...prevOptions, {id: String(prevOptions.length) , answer: newOption}]);
  };

  const addCondition = () => {
    const localCondition = {
      id: conditions.length,
      name: `Condition ${conditions.length + 1}`,
      selectedQuestion: '',
      selectedAnswer: []
    }
    setConditions((prevOptions) => [
      ...prevOptions,
      { id: prevOptions.length, ...localCondition }
    ]);
  }

  const updateOption = (index, newValue) => {
    setOptions((prevItems) =>
      prevItems.map((item, i) =>
        i === index ? { ...item, answer: newValue } : item
      )
    );
  };

  const removeOption = (indexToRemove) => {
    setOptions((prevOptions) => prevOptions.filter((_, index) => index !== indexToRemove));
  };

  React.useEffect(() => {
    const markdownText = turndownService.turndown(mainTextEditor);
    setTextCardPhone(markdownText);
  },[mainTextEditor])

  const onchangeQuestion = (item, event) => {
    const newValue = event.target.value;
    setConditions((prevConditions) =>
      prevConditions.map((condition) =>
        condition.id === item
          ? { ...condition, selectedQuestion: newValue, selectedAnswer: [] , delete: false}
          : condition
      )
    );
    setSelectedQuestion(newValue)
  }
  const onChangeAnswers = (id, values) => {
    setConditions((prevConditions) =>
      prevConditions.map((condition) =>
        condition.id === id
          ? { ...condition, selectedAnswer: values }
          : condition
      )
    );
  }

  useEffect(()=> {
    if (selectedQuestion !== '') {
      const question= questions.find(item => item.id === selectedQuestion);
      if(question){
        setAllowedAnswers(
          question.internationalization.find( (item) => item.language === language)?.allowedAnswers?.map((answer) => ({id: answer.id, propName: answer.answer}))
        )
      }
    }
  }, [selectedQuestion]);

  useEffect(()=> {
    if (conditions.length > 0) {
      let temp = [];
      conditions.forEach( (condition) => {
        const question= questions.find(item => item.id === condition.selectedQuestion);
        if(question){
          temp.push(...question.internationalization[0].allowedAnswers.map((answer) => ({id: answer.id, propName: answer.answer})))
        }
      });
      setAllowedAnswers(temp)
    }
  },[conditions])

  const handleTranslation = async () => {
    let language = listLanguagesInterview[tabId]?.name

    let textsToTranslate = {
      mainText: textCardPhone,
      footerText:methods.getValues('footerText'),
      title:methods.getValues('shortName'),
      options: options
    }
    let localData = {
      serviceId: survey.serviceId,
      sourceLanguage: language,
      targetLanguages: listLanguagesInterview.filter(item => item.name !== language).map(item => item.name),
      updateAtributes: ["MAIN_TEXT","FOOTER_TEXT","OPTIONS","TITLE"],
      internationalizations: getLanguagesOfQuestion(textsToTranslate, listLanguagesInterview, imageUrl)
    }

    const response = await questionTranslate(localData);

    setUpdateTranslation(response)
    setDisabledTabsLanguage(false)
  };

  return(
    <>
      <style>{editorSurveyStyle}</style>
      <Grid container direction={'column'}>
        <Grid item sx={{display:'flex', justifyContent:'space-between'}}>
          <Typography style={{
            color: '#212121', fontFamily: 'Montserrat',
            fontWeight: 500, fontSize: '14px', margin:'12px 0px'
          }}>
            {t('text')}
          </Typography>
          <MuiIconTranslate onClick={handleTranslation}>
            <TranslateIcon sx={{height:16, width:16}}/>
          </MuiIconTranslate>
        </Grid>
        {selectedOption === 'Confirmation Card' && (
          <>
            <Grid item>
              <Typography variant="caption" style={{
                color: '#000000', fontFamily: 'Montserrat',
                fontWeight: 400, fontSize: '12px'
              }}>
                {t('public_name_interview')}
              </Typography>
            </Grid>
            <Grid item sx={{ marginBottom: '24px' }}>
              <MuiEditor elevation={0}>
                <Box sx={{height: 'auto', width: '104%', marginLeft:'-12px'}}>
                  <CKEditor
                    editor={ClassicEditor}
                    data={publicName}
                    config={{
                      removePlugins: ['ImageUpload', 'EasyImage', 'MediaEmbed', 'BlockQuote'],
                      toolbar: ['bold', 'italic']
                    }}
                    onChange={(e, editor) => handleChangeName(e, editor)}
                  />
                </Box>
              </MuiEditor>
            </Grid>
          </>
        )}
        <Grid item>
          <Typography variant="caption" style={{
            color: '#000000', fontFamily: 'Montserrat',
            fontWeight: 400, fontSize: '12px'
          }}>
            {t('main_text')}
          </Typography>
        </Grid>
        <Grid item sx={{ marginBottom: '24px' }}>
          <MuiEditor elevation={0}>
            <Box sx={{height: 'auto', width: '104%', marginLeft:'-12px'}}>
              <CKEditor
                editor={ClassicEditor}
                data={mainTextEditor}
                config={{
                  removePlugins: ['ImageUpload', 'EasyImage', 'MediaEmbed', 'BlockQuote'],
                  toolbar: ['bold', 'italic']
                }}
                onChange={(e, editor) => handleEditorChange(e, editor)}
              />
            </Box>
          </MuiEditor>
        </Grid>
        <Grid container item justifyContent='center'
              sx={{ position: 'relative', marginBottom: '24px' }}>
          <MuiTitleField
            id="outlined-multiline-static"
            name='shortName'
            label={t('custom_title')}
            variant='outlined'
            InputLabelProps={{
              shrink: true,
              style: labelStyle
            }}
            inputProps={{maxLength: 20}}
            {...methods?.register('shortName')}
            onChange={handleChangeShortName}
          />
          <MuiCharCountTitle>
            <span style={{color: '#7a7a7a'}}>{`${charTitleCount}/`}</span>
            <span style={{color: '#000000'}}>{maxCharsTitle}</span>
          </MuiCharCountTitle>
        </Grid>
        {selectedOption === 'Multiple-Choice' && (
          <Grid item sx={{ marginBottom: '24px' }}>
            <Typography style={{
              color: '#000000', fontFamily: 'Montserrat',
              fontWeight: 500, fontSize: '14px'
            }}>
              Options
            </Typography>
            <OptionsSort
              options={options}
              setOptions={setOptions}
              removeOption={removeOption}
              updateOption={updateOption}
            />
            <MuiButton
              sx={{
                minWidth:'100%',
                borderRadius:'20px',
                color:'#6793A5',
                padding:'10px 24px 10px 16px',
                marginTop:'12px',
                borderStyle:'dashed'}}
              variant='outlined'
              startIcon={<MuiAddIcon/>}
              onClick={addOption}
            >
              <MuiCommonTypography variant='body2' color='primary'>
                ADD AN Option
              </MuiCommonTypography>
            </MuiButton>
          </Grid>
        )}
        {(selectedOption === 'Open-Ended' || selectedOption === 'Follow-Up' || selectedOption === 'Closing Message') && (
          <Grid container item justifyContent='center'
                sx={{ position: 'relative' }}>
            <MuiTitleField
              id="outlined-multiline-static"
              name='footerText'
              label='Footer'
              variant='outlined'
              InputLabelProps={{
                shrink: true,
                style: labelStyle
              }}
              inputProps={{maxLength: 50}}
              {...methods?.register('footerText')}
              onChange={handleFooterChange}
            />
            <MuiCharCountTitle>
              <span style={{color: '#7a7a7a'}}>{`${footerCount}/`}</span>
              <span style={{color: '#000000'}}>{50}</span>
            </MuiCharCountTitle>
          </Grid>
        )}
        {selectedOption === 'Follow-Up' && (
          <Grid container item justifyContent='flex-start'
                sx={{ position: 'relative' }}>
            <Typography style={{
              color: '#212121', fontFamily: 'Montserrat',
              fontWeight: 500, fontSize: '14px', marginTop:'24px'
            }}>
              Parent question
            </Typography>
            <TextField
              name='parent-question'
              value={parentQuestion}
              label='Select parent question'
              variant='outlined'
              {...methods?.register('parentQuestion')}
              fullWidth
              select
              onChange={handleParentQuestionChange}
              sx={{mt: '24px'}}
            >
              <Divider light/>
              {questions.map((item, i) => (
                <MenuItem key={i} value={item.id}>
                  {item.shortLabel}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
        )}
        { selectedOption === 'Confirmation Card' && (
          <MuiGridOthersTab item xs={12} direction='column' alignItems="flex-start">
            <MuiOptionsTypography sx={{color: '#212121'}}>
              What options will the user have to choose from?
            </MuiOptionsTypography>
            <OptionsSort
              options={options}
              setOptions={setOptions}
              updateOption={updateOption}
            />
          </MuiGridOthersTab>
        )}
        { (selectedOption === 'Open-Ended' || selectedOption === 'Multiple-Choice') && (
          <MuiGridOthersTab item xs={12} sx={{mt: "24px"}}>
            <MuiOptionsTypography >
              Do you want to add a condition to the question?
            </MuiOptionsTypography>
            {/*<div style={{ display:'flex' }}>*/}
            {/*  <MuiSwitchOthers*/}
            {/*    name='showConditions'*/}
            {/*    primaryColor={theme.palette.primary.main}*/}
            {/*    onChange={() => setConditions([])}*/}
            {/*    checked={conditions?.length >0 }*/}
            {/*    disabled={true}*/}
            {/*  />*/}
            {/*  <div style={containerSwitchStyle}>*/}
            {/*    <MuiSwitchTypography style={{ color:  '#000000' }}>*/}
            {/*      No*/}
            {/*    </MuiSwitchTypography>*/}
            {/*    <span style={dividerStyle}>/</span>*/}
            {/*    <MuiSwitchTypography*/}
            {/*      style={{ color: theme.palette.primary.main }}*/}
            {/*    >*/}
            {/*      Yes*/}
            {/*    </MuiSwitchTypography>*/}
            {/*  </div>*/}
            {/*</div>*/}
          </MuiGridOthersTab>
        )}

        {conditions?.length > 0 && (
          <Grid item>
            <Typography variant='caption' style={{
              color: '#000000', fontFamily: 'Montserrat',
              fontWeight: 400, fontSize: '12px'
            }}>
              Define rules for displaying this question based on previous
              answers. Select a previous survey question and specify which
              answer(s) should trigger it. If the respondent's answer matches one of the selected answers,
              this question will be displayed, allowing for a dynamic and personalized survey experience.
            </Typography>
            <div style={{marginTop:'22px'}}>
              {conditions.map((item, index) => (
                <Grid item key={item.id}>
                  <Typography variant='caption' style={{
                    color: '#000000', fontFamily: 'Montserrat',
                    fontWeight: 400, fontSize: '12px', marginBottom:'14px'
                  }}>
                    {item.name}
                  </Typography>
                  <TextField
                    name={`selected-question-${item.id}`}
                    label='Select Question'
                    variant='outlined'
                    fullWidth
                    InputLabelProps={{style: labelInputStyle}}
                    value={item.selectedQuestion || ""}
                    select
                    onChange={(event)=>onchangeQuestion(item.id, event)}
                    sx={{mb: '24px'}}
                  >
                    <Divider light/>
                    {questions.map((value) => (
                      <MenuItem key={value.id} value={value.id}>
                        {value.shortLabel || value.question}
                      </MenuItem>
                    ))}
                  </TextField>
                  <FormControl fullWidth variant="outlined" >
                    <MuiAutocomplete
                      id={`selected-answer${item.id}`}
                      multiple
                      options={allowedAnswers}
                      value={item.selectedAnswer}
                      onChange={(e, values) => {onChangeAnswers(item.id, values);}}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          label='Select Answer'
                          InputLabelProps={{style: labelInputStyle}}
                        />
                      )}
                    />
                  </FormControl>
                  {index + 1 !== conditions.length && (
                    <Divider style={{margin:'22px 0px'}}>
                      <Chip label="AND" size="small" />
                    </Divider>
                  )}
                </Grid>
              ))}
              <MuiButton
                sx={{
                  minWidth:'100%',
                  borderRadius:'20px',
                  color:'#6793A5',
                  padding:'10px 24px 10px 16px',
                  marginTop:'12px',
                  borderStyle:'dashed'}}
                variant='outlined'
                startIcon={<MuiAddIcon/>}
                onClick={addCondition}
              >
                <MuiCommonTypography variant='body2' color='primary'>
                  ADD a Condition
                </MuiCommonTypography>
              </MuiButton>
            </div>
          </Grid>
        )}
      </Grid>
    </>
  )
}

export default MessageForm;
