import {
    useEffect,
    useState,
  } from 'react'
import {
    Grid2 as Grid,
    Box,
    FormControlLabel,
    FormGroup,
    Checkbox,
    FormControl,
    IconButton,
    lighten,
} from '@mui/material';
import {
    MuiSubTitle,
} from 'components/views/organizations/surveys/styles/translations';
import {
    MuiTitleTypography,
    MuiTextfield,
    MuiInfoIconButton,
    MuiInfoOutlinedIcon,
} from 'components/views/organizations/surveys/styles/generalInformation';
import { useTranslation } from 'components/providers/TranslationProvider';
import useSurveyConfigUpdate from  'api/hooks/useSurveyConfigUpdate';
import { useTheme } from "components/providers/CustomThemeProvider";
import {
    PlayArrow as PlayArrowIcon
} from '@mui/icons-material';
import QRModal from "components/views/organizations/surveys/QRModal.js";
import TryInterview from "../TryInterview";

const AgentBehavior = ({ survey, setSurvey }) => {

    const {t} = useTranslation();
    const { theme } = useTheme();
    const [modalQR, setModalQR] = useState(false);

    const testInterview = survey && survey.status==='Running';

    const updateSurveyConfiguration = (event) => {
        const { name, type, value, checked } = event.target;
        let newValue = type === "checkbox" ? checked : value;
        if (type === "number") {
            newValue = value === "" ? "" : Number(value);
        }
        setSurvey((prevSurvey) => {
          prevSurvey.configuration = prevSurvey.configuration || {};
          prevSurvey.configuration[name] = newValue;
          return { ...prevSurvey };
        });
      };

      const {
        mutateAsync: configUpdate,
      } = useSurveyConfigUpdate();

      useEffect(() => {
        if (!survey?.serviceId || !survey?.environment || !survey?.configuration) {
            return;
        }
        const fieldsInForm = [
            "absenceMaxMinutes",
            "absenceReminder",
            "activeListening",
            "languageModeration",
            "maxFollowUps",
            "maxReminders",
            "minOpenAnswerLength",
            "relevanceModeration",
            "smartFollowUp",
            "storeRespondentPhoneNumber"
        ];

        const filteredConfig = Object.fromEntries(
            Object.entries(survey.configuration)
                .filter(([key, value]) => fieldsInForm.includes(key) && value !== "")
        );

        const configData = {
            serviceId: survey.serviceId,
            environment: survey.environment,
            configuration: filteredConfig,
        };

        const updateConfig = async () => {
            try {
                await configUpdate(configData);
            } catch (error) {
                console.error("Error updating survey configuration:", error);
            }
        };
        updateConfig();
    }, [survey, configUpdate]);



    return (
        <>
            <Grid item xs={12}>
                <Box sx={{ display: "flex", justifyContent: "space-between", marginTop: '35px' }}>
                    <MuiTitleTypography variant="h6">
                        {t('agent_behavior')}
                    </MuiTitleTypography>
                    <TryInterview survey={survey}/>
                </Box>
            </Grid>
            <Grid item xs={12}>
                <MuiSubTitle sx={{ marginTop: '30px', marginBottom: '18px', textTransform: 'uppercase' }}>
                    {t('moderation_behavior')}
                </MuiSubTitle>
            </Grid>
            <Grid item xs={12}>
                <Box sx={{ display: 'flex' }}>
                    <Box sx={{ display: 'flex', flexDirection: "column" }}>
                        <Box sx={{ display: 'flex', marginBottom: '18px' }}>
                            <MuiTextfield
                                sx={{ width: '435px' }}
                                label={t('min_open_answer_length')}
                                name="minOpenAnswerLength"
                                variant="outlined"
                                type="number"
                            value={ survey?.configuration?.minOpenAnswerLength ?? 5 }
                            onChange={updateSurveyConfiguration}
                            />
                            <MuiInfoIconButton sx={{ alignSelf: 'center' }}>
                                <MuiInfoOutlinedIcon />
                            </MuiInfoIconButton>
                        </Box>
                        <Box sx={{ display: 'flex' }}>
                            <MuiTextfield
                                sx={{ width: '435px' }}
                                label={t('max_follow_ups')}
                                name="maxFollowUps"
                                variant="outlined"
                                type="number"
                            value={survey?.configuration?.maxFollowUps ?? 5 }
                            onChange={updateSurveyConfiguration}
                            />
                            <MuiInfoIconButton sx={{ alignSelf: 'center' }}>
                                <MuiInfoOutlinedIcon />
                            </MuiInfoIconButton>
                        </Box>
                    </Box>
                    <Box sx={{ marginLeft: '16px' }}>
                        <FormControl component="fieldset">
                            <FormGroup sx={{ gap: '29px' }}>
                                <FormControlLabel
                                    sx={{ height: '24px' }}
                                    control={
                                        <Checkbox
                                        checked={survey?.configuration?.smartFollowUp ?? true }
                                        onChange={updateSurveyConfiguration}
                                        name="smartFollowUp"
                                        />
                                    }
                                    label={
                                        <Box>
                                            {t('smart_follow_up')}
                                            <MuiInfoIconButton sx={{ alignSelf: 'center' }}>
                                            <MuiInfoOutlinedIcon />
                                            </MuiInfoIconButton>
                                        </Box>
                                    }
                                />
                                <FormControlLabel
                                    sx={{ height: '24px' }}
                                    control={
                                        <Checkbox
                                            checked={survey?.configuration?.languageModeration ?? true }
                                            onChange={updateSurveyConfiguration}
                                            name="languageModeration"
                                        />
                                    }
                                    label={
                                        <Box>
                                            {t('language_moderation')}
                                            <MuiInfoIconButton sx={{ marginLeft: '12px', alignSelf: 'center' }}>
                                            <MuiInfoOutlinedIcon />
                                            </MuiInfoIconButton>
                                        </Box>
                                    }
                                />
                                <FormControlLabel
                                    sx={{ height: '24px' }}
                                    control={
                                        <Checkbox
                                        checked={survey?.configuration?.relevanceModeration ?? true }
                                        onChange={updateSurveyConfiguration}
                                        name="relevanceModeration"
                                        />
                                    }
                                    label={
                                        <Box>
                                            {t('relevance_moderation')}
                                            <MuiInfoIconButton sx={{ alignSelf: 'center' }}>
                                            <MuiInfoOutlinedIcon />
                                            </MuiInfoIconButton>
                                        </Box>
                                    }
                                />
                                {/* ))} */}
                            </FormGroup>
                        </FormControl>
                    </Box>
                    <Box sx={{ marginLeft: '181px' }}>
                        <FormControl component="fieldset">
                            <FormGroup>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                        checked={survey?.configuration?.activeListening ?? true }
                                        onChange={updateSurveyConfiguration}
                                        name="activeListening"
                                        sx={{ marginTop: '-9px' }}
                                        />
                                    }
                                    label={
                                        <Box>
                                            {t('active_listening')}
                                            <MuiInfoIconButton sx={{ marginLeft: '12px', alignSelf: 'center' }}>
                                            <MuiInfoOutlinedIcon />
                                            </MuiInfoIconButton>
                                        </Box>
                                    }
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                        checked={survey?.configuration?.storeRespondentPhoneNumber ?? true }
                                        onChange={updateSurveyConfiguration}
                                        name="storeRespondentPhoneNumber"
                                        />
                                    }
                                    label={
                                        <Box>
                                            {t('store_respondent_phone_number')}
                                            <MuiInfoIconButton sx={{ marginLeft: '12px', alignSelf: 'center' }}>
                                            <MuiInfoOutlinedIcon />
                                            </MuiInfoIconButton>
                                        </Box>
                                    }
                                />
                            </FormGroup>
                        </FormControl>
                    </Box>
                </Box>
            </Grid>
            <Grid item xs={12}>
                <MuiSubTitle sx={{ marginTop: '46px', marginBottom: '18px' }}>
                    {t('reengagement_behavior')}
                </MuiSubTitle>
            </Grid>
            <Grid item xs={12}>
                <Box sx={{ display: "flex" }}>
                <Box sx={{ display: "flex" }}>
                    <MuiTextfield
                        sx={{ width: '430px' }}
                        label={t('max_reminders')}
                        name="maxReminders"
                        variant="outlined"
                        value={survey?.configuration?.maxReminders ?? 5 }
                        onChange={updateSurveyConfiguration}
                        type="number"
                    />
                    <MuiInfoIconButton sx={{ alignSelf: 'center' }}>
                        <MuiInfoOutlinedIcon />
                    </MuiInfoIconButton>
                </Box>
                <Box sx={{ display: 'flex', marginLeft: '24px', marginRight:'16px' }}>
                    <MuiTextfield
                    sx={{ width: '423px' }}
                        label={t('absence_max_minutes')}
                        name="absenceMaxMinutes"
                        variant="outlined"
                        value={survey?.configuration?.absenceMaxMinutes ?? 5 }
                        onChange={updateSurveyConfiguration}
                        type="number"
                    />
                    <MuiInfoIconButton sx={{ alignSelf: 'center' }}>
                        <MuiInfoOutlinedIcon />
                    </MuiInfoIconButton>
                </Box>
                <Box sx={{ display: 'flex' }}>
                    <FormControlLabel
                    sx={{marginRight: '12px'}}
                        control={
                            <Checkbox
                            checked={survey?.configuration?.absenceReminder ?? true }
                            onChange={updateSurveyConfiguration}
                            name="absenceReminder"
                            />
                        }
                        label={t('absence_reminder')}
                    />
                    <MuiInfoIconButton sx={{ alignSelf: 'center', marginLeft: 0 }}>
                        <MuiInfoOutlinedIcon />
                    </MuiInfoIconButton>
                </Box>
                </Box>
            </Grid>
            <QRModal
                open={modalQR}
                handleClose={() => setModalQR(false)} survey={{id:survey.serviceId}}
            />
        </>
    );
};

export default AgentBehavior;
