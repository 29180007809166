import * as React from "react";
import {useCallback, useRef} from "react";
import {animated} from "react-spring";
import * as _ from "lodash";
import {getContrastTonality} from "../../../../../../utils/colorPaletteUtils";
import color from "color";

function getContrastColor(colorInput) {
  return getContrastTonality(colorInput) === "light" ? "#FFFFFF" : '#212121'
}

export const CustomBarComponent = (props) => {
  // const {showTooltipFromEvent, showTooltipAt, hideTooltip} = useTooltip();
  const tooltipTimeout = useRef(null);
  const {
    bar,
    style,
    keys,
    selectedNodeData,
    updateSelectedNodeData,
    selectedTopic,
    selectedParentNodeData,
    updateSelectedParentNodeData,
    setTooltipData,
    layout,
    callingComponent,
  } = props;

  const adjustedHeight = Math.max(0, bar.height);
  const borderRadius =( bar.width > 10&&layout==='horizontal') || (bar.height > 10 && layout === 'vertical') ? 10 : 0;
  const barColor = bar.data.data[`color_${bar.data.id}`] || bar.data.data.color;

  const handleMouseEnter = useCallback((event) => {
    if (tooltipTimeout.current) {
      clearTimeout(tooltipTimeout.current);
    }
    props.onMouseEnter?.(bar, event);
  }, [bar]);

  const handleMouseMove = useCallback((event) => {
    if (tooltipTimeout.current) {
      clearTimeout(tooltipTimeout.current);
    }
    tooltipTimeout.current = setTimeout(() => {
      setTooltipData({ bar: bar.data, isSegmented: keys?.length > 1 });
    }, 250);
    props.onMouseMove?.(bar, event);
  }, [bar]);

  const handleMouseClick = (event) => {
    if (callingComponent === 'AnalysisView') {
      let newVar = _.cloneDeep(bar.data);
      newVar.data.color = bar.data.data.color || bar.data.data[`color_${bar.data.id}`];
      if (newVar.data.isParent) {
        updateSelectedParentNodeData(newVar);
      }
      if (selectedNodeData?.data?.isParent && selectedNodeData.id !== newVar.id) {
        updateSelectedParentNodeData(selectedNodeData)
      } else if (selectedParentNodeData && isSelected) {
        newVar = selectedParentNodeData;
        updateSelectedParentNodeData(newVar)
      } else if (isSelected) {
        newVar = null;
      }
      updateSelectedNodeData(newVar);
      props.onMouseClick?.(bar, event);
    }
  }

  const isSelected = selectedNodeData ? bar.data.id === selectedNodeData.id && bar.data.indexValue === selectedNodeData.indexValue : false
  let barStyle = {
    opacity: 0.95,
    strokeWidth: 1,
    stroke: bar.data.data.color || bar.data.data[`color_${bar.data.id}`]
  };
  if (selectedNodeData && !selectedTopic?.isParent) {
    barStyle = {
      opacity: isSelected && selectedParentNodeData?.indexValue !== selectedNodeData?.indexValue ? 0.95 : 0.4,
      strokeWidth: isSelected ? 5 : 1,
      stroke: isSelected ? color(bar.data.data.color || bar.data.data[`color_${bar.data.id}`]).darken(0.2).hex() : bar.data.data.color || bar.data.data[`color_${bar.data.id}`]
    };
  }
  if (selectedParentNodeData?.indexValue === selectedNodeData?.indexValue) {
    barStyle = {
      opacity: 0.95,
      strokeWidth: 1,
      stroke: isSelected ? color(bar.data.data.color || bar.data.data[`color_${bar.data.id}`]).darken(0.2).hex() : bar.data.data.color || bar.data.data[`color_${bar.data.id}`]
    };
  }

  const getShapeTheBars = () => {
    if (layout === 'vertical') {
      return style.width.to((width) => `
        M${bar.x},${bar.y + adjustedHeight}
        L${bar.x},${bar.y + borderRadius}
        Q${bar.x},${bar.y} ${bar.x + borderRadius},${bar.y}
        L${bar.x + width - borderRadius},${bar.y}
        Q${bar.x + width},${bar.y} ${bar.x + width},${bar.y + borderRadius}
        L${bar.x + width},${bar.y + adjustedHeight}
        Z
  `)
    } else {
      return style.width.to((width) => `
        M${bar.x},${bar.y + adjustedHeight}
        L${bar.x},${bar.y}
        L${bar.x + width - borderRadius},${bar.y}
        Q${bar.x + width},${bar.y} ${bar.x + width},${bar.y + borderRadius}
        L${bar.x + width},${bar.y + adjustedHeight - borderRadius}
        Q${bar.x + width},${bar.y + adjustedHeight} ${bar.x + width - borderRadius},${bar.y + adjustedHeight}
        Z
      `)
    }
  }

  return (
    <animated.g
      width={bar.width}
      height={bar.height}
      x={bar.x}
      y={bar.y}
      onMouseEnter={handleMouseEnter}
      onMouseMove={handleMouseMove}
      onMouseDown={handleMouseClick}
      cursor={callingComponent === 'AnalysisView' ? 'pointer' : 'default'}
    >
      <animated.path
        d={getShapeTheBars()}
        fill={bar.data.data.color || bar.data.data[`color_${bar.data.id}`]}
        {...barStyle}
      >
      </animated.path>
      {props.shouldRenderLabel && (
        <text
          x={bar.x + (bar.width / 2)}
          y={layout === 'vertical' ? bar.y - 10 : bar.y + (bar.height / 2)}
          textAnchor={'middle'}
          dominantBaseline={'middle'}
          fontSize={'12px'}
          fontWeight={'500'}
          fontFamily={'Montserrat'}
          fontStyle={'normal'}
          fill={layout === 'vertical' ? `${barColor}` : getContrastColor(barColor)}
        >
          {props.label}
        </text>
      )}
    </animated.g>
  )
    ;
};
